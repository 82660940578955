import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-initiatesso',
  templateUrl: './initiatesso.component.html',
  styleUrls: ['./initiatesso.component.scss']
})
export class InitiatessoComponent {

    constructor() {
        window.location.href = environment.samlEndpoint + '/InitiateSingleSignOn?returnUrl=https://' + window.location.hostname.split('.')[0] + '.commportal.net/retrievesso';
        // window.location.href = environment.samlEndpoint + '/InitiateSingleSignOn?returnUrl=http://' + window.location.hostname.split('.')[0] + ':44378/retrievesso'; // Uncomment for local testing
    }
}
