import { Component, OnInit, Input, ViewChild, } from '@angular/core';
import { DxTreeListComponent } from 'devextreme-angular';
import { CoreColumn } from '../../models/core-column';
import { TreeListProps } from '../../models/core-tree-list-properties';
import { CoreEventArguments } from '../../models/core-event-arguments';
import { CoreDataGridCellTemplates } from '../../constants/dev-extreme-enums';

@Component({
    selector: 'core-tree-list',
    templateUrl: './core-tree-list.component.html',
    styleUrls: ['./core-tree-list.component.scss']
})

export class CoreTreeListComponent implements OnInit {
    @Input() dataSource: any[];
    @Input() columns: CoreColumn[];
    @Input() props: TreeListProps;

    @ViewChild('treeList', { static: false }) treeList: DxTreeListComponent;

    indicatorUrl: string = '../../../../assets/images/spinning-gears.gif';

    constructor() {
        this.onCoreTreeButtonClick = this.onCoreTreeButtonClick.bind(this);
        this.onCoreTreeButtonItemClick = this.onCoreTreeButtonItemClick.bind(this);
    }

    ngOnInit() {

    }

    onToolbarPreparing(e: any): void {
        if (this.props.isRowSelectionVisible) {
            e.toolbarOptions.items.unshift({
                    location: 'left',
                    widget: 'dxButton',
                    name: 'columnSelection',
                    locateInMenu: 'never',
                    options: {
                        icon: 'selectall',
                        hint: 'Show/Hide row selection',
                        onClick: () => {
                            this.props.isRowSelectionEnabled = !this.props.isRowSelectionEnabled;
                        }
                    }
                }
            );
        }
        if (this.props.toolBarItems?.length > 0) {
            for (const toolBarItem of this.props.toolBarItems) {
                e.toolbarOptions.items.unshift(toolBarItem);
            }
        }
    }

    onCoreTreeButtonClick(e: any, data?: any): void {
        if (e && e.column && e.column.dataField && e.column.type && e.column.type === 'buttons'
            && this.columns.find(x => x.dataField === e.column.dataField)) {
            const clickFunction = this.columns.find(x => x.dataField === e.column.dataField).columnType.clickFunction;

            if (clickFunction) {
                clickFunction(new CoreEventArguments(e, null));
            }
        } else if (data && data.data && data.column) {
            const column = this.columns.find(x => x.dataField === data.column.dataField);
            if (column && column.columnType && column.columnType.clickFunction) {
                column.columnType.clickFunction(new CoreEventArguments(data, null));
            }
        }
    }

    onCoreTreeButtonItemClick(e: any, data?: any): void {
        if (data && data.data && data.column) {
            const column = this.columns.find(x => x.dataField === data.column.dataField);
            if (column && column.columnType && column.columnType.itemClickFunction) {
                column.columnType.itemClickFunction(new CoreEventArguments(e, null, data));
            }
        }
    }

    getColumnByDataField(dataField: string, data?: any): CoreColumn {
        const column = this.columns.filter(x => x.dataField === dataField);
        if (column && column.length === 1) {
            return column[0];
        }
    }

    getDataForCellDropDownButton(column: CoreColumn, datapoint: any): any[] {
        if (column.columnType.cellTemplateName === CoreDataGridCellTemplates.DropDownButtonCellTemplate && column.columnType.getDataFunction) {
            return column.columnType.getDataFunction(column, datapoint);
        }
    }

    expandAll(): void {
        this.treeList.instance.option('expandedRowKeys', []);
        this.treeList.instance.option('autoExpandAll', false);
        this.treeList.instance.option('autoExpandAll', true);
    }

    collapseAll(): void {
        this.treeList.instance.option('expandedRowKeys', []);
        this.treeList.instance.option('autoExpandAll', false);
    }

    getRowData(data: any) {
        return data.data;
    }

}
