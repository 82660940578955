import { Injectable } from '@angular/core';
import { id } from 'date-fns/locale';
import { truncatedControlScripts } from 'devexpress-reporting/scopes/reporting-designer-controls-metadata';
import { combineLatest, forkJoin, Observable, Subscription, zip } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { Dictionary } from 'src/app/shared/dictionary';
import { EnumFormulaBuilderDataSet } from 'src/app/shared/constants/enums';
import { BbObject, Gear, GearProperty, GearPropertyValue, GearType } from 'src/app/shared/models/building-blocks';
import { BuildingBlocksService } from 'src/app/shared/services/building-blocks.service';
import { FieldService } from 'src/app/shared/services/field.service';
import { FormulaBuilderService } from 'src/app/shared/services/formula-builder.service';
import { BuildingBlockHelperService } from './building-block-helper.service';

@Injectable({
	providedIn: 'root'
})
export class GearPropertyService {

	objectStore: Dictionary<string, BbObject> = null;
	allGearProps: readonly GearProperty[];
	gearSpecificProps: string[] = ['sourceProcessId', 'auxProcessId', 'fields', 'aggregateFields', 'sourceAndAuxFields', 'updateFields',
		'filterBuilderObject', 'displayFormulaString', 'sqlFormulaString', 'headProcessId', 'fieldMaps', 'savedColumns', 'auxFields'];
	listOfFunctions: Record<string, (name: string) => any>;
	showEndDatedRules: boolean = false;

	private mappings: Record<string, any> = { };
	private $mappings: BehaviorSubject<Record<string, any>> = new BehaviorSubject<Record<string, any>>(null);

	constructor(private buildingBlockHelper: BuildingBlockHelperService,
		private buildingBlockService: BuildingBlocksService,
		private fieldService: FieldService,
		private formulaBuilderService: FormulaBuilderService) {

		// ToDo: Keep this till criteria filter has been refactored, may not need and can directly
		// call from constructor loop
		this.listOfFunctions = {
			periodFilterString: this.getLookUpForDropDown,
			dateRangeTypeCode: this.getLookUpForDropDown,
			earnDateSystemName: this.getLookUpForDropDown,
			runningSumDateFieldSystemName: this.getLookUpForDropDown,
			beginDateField: this.getLookUpForDropDown,
			endDateField: this.getLookUpForDropDown,
			activityDateField: this.getLookUpForDropDown,
			sourceProcessId: this.getLookUpForDropDown,
			auxProcessId: this.getLookUpForDropDown,
			fields: this.getLookupForFields,
			aggregateFields: this.getLookupForFields,
			filterBuilderObject: this.getLookupForSourceAndAuxFields,
			displayFormulaString: this.getLookupForFormulaBuilder,
			sqlFormulaString: this.getLookupForFormulaBuilder,
			sourceAndAuxFields: this.getLookupForSourceAndAuxFields,
			joinType: this.getLookUpForDropDown,
			joinHint: this.getLookUpForDropDown,
			nonKeyDatasources: this.getLookUpForDropDown,
			datatype: this.getLookUpForDropDown,
			fieldMaps: this.getLookupForSourceAndAuxFields,
			savedColumns: this.getLookupForFields,
			datasourceId: this.getLookUpForDropDown,
			updateFields: this.getLookUpForDropDown,
		};

		this.allGearProps = [].concat.apply([], this.buildingBlockService.getGearTypeArray().map(gearType => gearType.getProperties()));
		this.buildingBlockHelper.getObjectStore().subscribe(res => {
			if (res) {
				this.objectStore = res;
				this.updateMappings(null);
			}
		});

		this.buildingBlockHelper.getShowEndDatedRules().subscribe(res => {
			this.showEndDatedRules = res;
		});
	}

	getLookUpForDropDown = (name: string): { dataSource: any[], valueExpr: string, displayExpr: string, readOnly?: boolean, hint?: string } => {
		let returnObject: { dataSource: any[], valueExpr: string, displayExpr: string } = null;
		if (this.objectStore) {
			returnObject = this.buildingBlockHelper.getLookupByDatatype(name);
		}
		return returnObject;
	};

    getLookupForFields = (name: string): { dataSource: any[], valueExpr: string, displayExpr: string } => {
		zip(
			this.buildingBlockHelper.getFocusedObjectChanges().pipe(take(1)),
			this.buildingBlockHelper.getScopeContainer()
		).subscribe(([focusedObject, scopeContainer]) => {
			const fieldOrigin = name === 'auxFields' ? 'auxProcessId' : 'sourceProcessId';
			const sourceProcess = name === 'savedColumns' ? focusedObject?.headProcessId : focusedObject?.propertyValues?.find(prop => prop.property.systemName === fieldOrigin)?.value;
			if (sourceProcess) {
				const inputGear = this.buildingBlockHelper.getObjectById(sourceProcess);
				if(inputGear == null)
				{
					return;
				}
				const inputId = inputGear.id;
				this.buildingBlockHelper.setShowLoadPropertyPanel(true);
				this.buildingBlockService.getProcessDataColumnsByProcessId(scopeContainer.id, inputId, scopeContainer.periodBeginId ?? -1).subscribe(res => {
					const idCols = this.buildingBlockHelper.getAllIdColumns().map(col => col.systemName);
					res = res.filter(col => !idCols.includes(col.systemName));
					if(name === 'savedColumns'){
						res = res.filter(col => ['decimal', 'number'].includes(this.buildingBlockHelper.getDataColumnBySystemName(col.systemName)?.datatype));
					}
					if(!this.showEndDatedRules){
						const savedColumns = this.buildingBlockHelper.getAllSavedColumnsForPeriodRange(scopeContainer.periodBeginId, scopeContainer.periodEndId);
						res = res.filter(col => {
							if(col.systemName.startsWith('saved_column_') && !savedColumns.includes(col.systemName)){
								return false;
							}
							return true;
						});
					}
					this.mappings[name] = this.buildingBlockHelper.convertProcessDataColumnsToDropdownDatasource(res, name === 'savedColumns');
					this.$mappings.next(this.mappings);
					this.buildingBlockHelper.setShowLoadPropertyPanel(false);
				},
				err => {
					this.buildingBlockHelper.setShowLoadPropertyPanel(false);
				});
			}
		});
		return {dataSource: [], valueExpr: 'refName', displayExpr: 'value'};
	};

	getLookupForSourceAndAuxFields = (name: string): { dataSource: any[], valueExpr: string, displayExpr: string } => {
		this.getLookupForFields('fields');
		this.getLookupForFields(name);
		this.getLookupForFields('auxFields');
		return null;
	};

	getLookupForFormulaBuilder = (name: string): { value: any[] } => {
		this.buildingBlockHelper.setShowLoadPropertyPanel(true);
		zip(this.buildingBlockHelper.getFocusedObjectChanges(), this.buildingBlockHelper.getRecurrenceId()).pipe(take(1)).subscribe(([focusedObjectChanges, recurrenceId]) => {
			if(focusedObjectChanges) {
				const [gearType, sourceProcess] = focusedObjectChanges?.id.slice(0, 2) === 'Sg' ? [EnumFormulaBuilderDataSet.SegmentGear, focusedObjectChanges?.id] :
					[EnumFormulaBuilderDataSet.BuildingBlocks, focusedObjectChanges?.propertyValues?.find(prop => prop.property.systemName === 'sourceProcessId').value ?? ''];
				this.formulaBuilderService.getFormulaBuilderData(gearType, recurrenceId, focusedObjectChanges?.id, sourceProcess, !this.showEndDatedRules).pipe(take(1)).subscribe(data => {
					this.mappings[name] = data;
					this.$mappings.next(this.mappings);
				}).add(() => {this.buildingBlockHelper.setShowLoadPropertyPanel(false);});
			} else {
				this.buildingBlockHelper.setShowLoadPropertyPanel(false);
			}
		});
		return { value: [] };
	};

	getMappings(): Observable<Record<string, any>> {
		return this.$mappings.asObservable();
	}

	updateMappings(gearPropNames: readonly any[]) {
		if (this.objectStore) {
			const gearProps = gearPropNames === null ?
			this.allGearProps.map(prop => prop.systemName).filter(p => !this.gearSpecificProps.includes(p)).concat(['nonKeyDatasources']) : gearPropNames;

			for (const gearProperty of gearProps) {
				if (this.listOfFunctions[gearProperty]) {
					this.mappings[gearProperty] = this.listOfFunctions[gearProperty](gearProperty);
				}
			}
			this.$mappings.next(this.mappings);
		}
	}
}
