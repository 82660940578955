import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { BbObject, Container, DataColumn, DataColumnType, DataColumnUsageCode, Group, ProcessDataColumn } from 'src/app/shared/models/building-blocks';
import { BuildingBlocksService } from 'src/app/shared/services/building-blocks.service';
import { BuildingBlockHelperService } from '../building-block-helper.service';
import { FieldService } from 'src/app/shared/services/field.service';
import { filter, first, skip, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { EnumContainerType } from 'src/app/shared/constants/enums';
import { ColDef, ColumnMovedEvent, ColumnApi, PinnedRowDataChangedEvent, RowNode, RowSelectedEvent } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { HelperService } from 'src/app/shared/services/helper.service';
import { DateRange } from 'src/app/shared/models/date-range';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { CoreDropdownProperties } from 'src/app/shared/models/core-dropdown-properties';
import { AuditGridFilterCriteria, AuditType } from 'src/app/shared/models/audit-grid-filter-criteria';
import { SiteThemeService } from 'src/app/shared/services/site-theme.service';
import { AppElementsService } from 'src/app/shared/services/app-element.service';

@Component({
    selector: 'app-bb-grid',
    templateUrl: './bb-grid.component.html',
    styleUrls: ['./bb-grid.component.scss']
})
export class BbGridComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('agGrid', { static: false }) agGrid: AgGridAngular;
	gridColumnApi: ColumnApi;
    focusedObject: BbObject;
    focusedObjectPath: string;
    filterBuilderObject: any;
    filterDataColumns: any = [];
    processDataColumns: ProcessDataColumn[];
    seriesId: number = 1;
    periodId: number;
    subscriptions: Subscription[] = [];
    selectedRow: AuditGridFilterCriteria = null;
    liveReload: boolean = false;
    scopeContainer: Container = null;
    auditSQLStrings: Record<string, string> = null;
    auditSQLPopupVisible: boolean = false;
    SQLTypeName: string = 'Audit';
    showAdvancedView: boolean = false;
    isImplementer: boolean = false;
    processLogPopupVisible: boolean = false;
    plvDateRange: DateRange = null;
    isProcessedOutput: boolean = false;
    auditPageMode: boolean = false;
    currentlyAuditedObject: any;
    currentlyAuditedPath: string;
    periodDropdownProps: CoreDropdownProperties[] = [];
    recurrenceId: number = 0;

    gridThemes: string[] = ['ag-theme-balham', 'ag-theme-balham-dark'];
    gridTheme: string = this.gridThemes[0];
    agColumns: any = [];
    agRecords: any = [];
    loadedRecords: any = [];
    keyFieldNames: string[] = [];
    leftPinnedColumns: string[] = ['seller_id_name'];
    rightPinnedColumns: string[] = [];
    columnOrderByGear: Record<string, string[]> = {};
    rowCount: number = 1000;
    rowCountOptions: any[] = [
        {
            name: '1000',
            id: 1000
        },
        {
            name: '5000',
            id: 5000
        },
        {
            name: 'All rows',
            id: -1
        },
    ];

    defaultColDef = {
        sortable: true,
		resizable: true
    };

    statusBar: any = {
		statusPanels: [
			{
				statusPanel: 'agTotalAndFilteredRowCountComponent',
				align: 'left',
			}
		]
	};

    columnTypes: Record<string, ColDef> = {
		defaultHidden: {
			hide: true,
		},
        lookupCol: {
			chartDataType: 'category',
			valueFormatter: (params) => (params.data ? (this.lookupByColumn(params)) : ''),
			filterParams: {
				valueFormatter: (params) => (this.lookupByColumn(params)),
			},
			valueGetter: (params) => (params.data ? (this.lookupByColumn(params)) : '')
		},
        formattedCol: {
			chartDataType: 'series',
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
                    {
						filter: 'agNumberColumnFilter',
						filterParams: {
							buttons: ['reset'],
                            valueFormatter: (params) => (this.formatFields(params)),
						}
                    },
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							defaultToNothingSelected: true,
							buttons: ['reset'],
                            valueFormatter: (params) => (this.formatFields(params)),
						}
					},
				],
			},
            valueFormatter: (params) => (this.formatFields(params)),
        },
        numericCol: {
            cellStyle: {'text-align': 'right'},
            aggFunc: 'sum',
			enableValue: true,
        },
        dateCol: {
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
                    {
                        filter: 'agDateColumnFilter',
                        filterParams: {
                            buttons: ['reset'],
                            inRangeInclusive: true,
                            valueFormatter: (params) => (this.formatFields(params)),
                            comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
                                const cellDateValue = new Date(new Date(cellValue).toDateString());
                                if (cellDateValue < filterLocalDateAtMidnight) {
                                    return -1;
                                } else if (cellDateValue > filterLocalDateAtMidnight) {
                                    return 1;
                                }
                                return 0;
                            }
                        }
                    },
                    {
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            defaultToNothingSelected: true,
                            buttons: ['reset'],
                            valueFormatter: (params) => (this.formatFields(params)),
                        }
                    }
				]
			},
            valueFormatter: (params) => (this.formatFields(params))
        },
        ignored: {
            cellClass: 'cell-ignored'
        },
        produced: {
            cellClass: 'cell-produced'
        },
        consumed: {
            cellClass: 'cell-consumed'
        },
        initialPinnedLeft: {
            initialPinned: 'left'
        },
        initialPinnedRight: {
            initialPinned: 'right'
        }
	};

    constructor(private buildingBlockHelper: BuildingBlockHelperService,
        private buildingBlocksService: BuildingBlocksService,
        private toastService: ToastrService,
        private helperService: HelperService,
        private siteThemeService: SiteThemeService,
        private appElementsService: AppElementsService,
        private permissionService: PermissionService) {
            this.periodDropdownProps['series'] = new CoreDropdownProperties()
            .createAll(true, 'id', 'name', true, true, true, true, false, false, 100);

            this.periodDropdownProps['period'] = new CoreDropdownProperties().createPeriodDropdownDefaults('dateRange', false, false, false, 'Period');
        }

    ngOnInit(): void {
        this.buildingBlocksService.gridDestroyed = new Subject<void>();

        this.subscriptions.push(this.buildingBlocksService.dataRecordResponse.subscribe(res => {
            this.loadedRecords = res['records'];
            this.agRecords = res['records'];
            this.agGrid.columnApi.setColumnsVisible(res['nullColumns'], false);
            setTimeout(() => this.updatePinnedBottomRowData(), 200);
        }));

        this.subscriptions.push(this.buildingBlocksService.dataColumnResponse.subscribe(res => {
            this.clearPinnedBottomRowData();
            try {
                if (res instanceof Array){
                    const processDataColumns = res;

                    this.agColumns = res
                    .sort((a, b) => {
                        const indexDifference = this.getProcessDataColumnSortIndex(a) - this.getProcessDataColumnSortIndex(b);
                        if(indexDifference === 0){
                            const friendlyNameA = this.buildingBlockHelper.getDataColumnBySystemName(a.systemName)?.friendlyName.toLocaleUpperCase() ?? a.systemName;
                            const friendlyNameB = this.buildingBlockHelper.getDataColumnBySystemName(b.systemName)?.friendlyName.toLocaleUpperCase() ?? b.systemName;
                            return friendlyNameA.localeCompare(friendlyNameB);
                        }
                        return indexDifference;
                    })
                    .map(col => {
                        const columnDefinitions: string[] = [];
                        if (col.usageCode === DataColumnUsageCode.Ignored) {
                            columnDefinitions.push('ignored');
                        } else if (col.usageCode === DataColumnUsageCode.Consumed) {
                            columnDefinitions.push('consumed');
                        } else if (col.usageCode === DataColumnUsageCode.Produced) {
                            columnDefinitions.push('produced');
                        }
                        const dataColumn = this.buildingBlockHelper.getDataColumnBySystemName(col.systemName);
                        const datatype = dataColumn?.datatype ?? 'string';
                        if(['decimal', 'number'].includes(datatype)){
                            columnDefinitions.push('numericCol');
                        }
                        if(['decimal', 'id', 'string', 'number'].includes(datatype) || datatype.endsWith('_name')){
                            columnDefinitions.push('formattedCol');
                        } else if (datatype === 'datetime') {
                            columnDefinitions.push('dateCol');
                        } else {
                            columnDefinitions.push('lookupCol');
                        }
                        if (['account', 'id', 'tag', 'product', 'customer', 'seller'].includes(datatype.split('_')[0]) && !datatype.endsWith('_name')) {
                            columnDefinitions.push('defaultHidden');
                        }
                        if(this.leftPinnedColumns.includes(col.systemName)){
                            columnDefinitions.push('initialPinnedLeft');
                        }
                        if(this.rightPinnedColumns.includes(col.systemName)){
                            columnDefinitions.push('initialPinnedRight');
                        }
                        return {field: col.systemName, headerName: dataColumn?.friendlyName ?? col.systemName, type: columnDefinitions};
                    });

                    const savedColumnOrder = this.columnOrderByGear[this.focusedObject.id];
                    if(savedColumnOrder){
                        this.agColumns = savedColumnOrder.map(columnName => this.agColumns.find(agCol => agCol.field === columnName));
                    }

                    this.agGrid.api.showLoadingOverlay();

                    this.keyFieldNames = processDataColumns.filter(col => col.isPrimaryKeyPart).map(col => col.systemName);
                    this.isProcessedOutput = this.focusedObject.objectTypeCode === 'Co' && this.focusedObject['typeId'] === EnumContainerType.ProductionRule;
                    const objId = this.focusedObject instanceof Group ? this.buildingBlockHelper.getHeadObjectIdsByContainer(this.focusedObject)[0] : this.focusedObject.id;
                    this.buildingBlocksService.dataRecordsRequest.next({
                        scopeProcessId: this.scopeContainer.id,
                        processId: objId,
                        seriesId: this.seriesId,
                        periodId: this.periodId,
                        filterObject: this.getFilterObject(),
                        rowCount: this.rowCount
                    });
                }
            } catch(err) {
                this.toastService.error(err.message);
                this.loadedRecords = [];
                this.agRecords = [];
            }
        }));

        this.subscriptions.push(this.buildingBlocksService.dataColumnError.subscribe(res => {
            this.agRecords = [];
            this.toastService.error('Error getting columns for audit grid. Check logger for more information.');
        }));

        this.subscriptions.push(this.buildingBlocksService.dataRecordError.subscribe(res => {
            this.agRecords = [];
            this.toastService.error('Error getting records for audit grid. Check logger for more information.');
        }));

        this.buildingBlockHelper.getFocusedObjectPath().subscribe(path => {
            this.focusedObjectPath = path;
        });

        this.setGridTheme();
        this.subscriptions.push(this.appElementsService.getDarkModeChanges().subscribe(x => {
            this.setGridTheme();
        }));

        this.buildingBlockHelper.getShowAdvancedView().subscribe(result => this.showAdvancedView = result);
        this.permissionService.getIsImplementer().subscribe(result => this.isImplementer = result);
    }

    ngAfterViewInit(): void {
        combineLatest([
            this.buildingBlockHelper.getSeriesId(),
            this.buildingBlockHelper.getPeriodId(),
            this.buildingBlockHelper.getFocusedObjectChanges(),
            this.buildingBlockHelper.getScopeContainer(),
            this.buildingBlockHelper.getSubObject(),
        ]).pipe(skip(2)).subscribe(([seriesId, periodId, focusedObject, scopeContainer, subObject]) => {
            this.seriesId = seriesId;
            this.periodId = periodId;
            this.scopeContainer = scopeContainer;

            if(scopeContainer){
                let plan: BbObject = scopeContainer;
                while(plan.id !== 'CoRoot' && plan['typeId'] !== EnumContainerType.Plan){
                    plan = this.buildingBlockHelper.getParentByChildObject(plan);
                }
                this.recurrenceId = plan['recurrenceId'];
            }

            const isRule = focusedObject?.id.startsWith('Co') && focusedObject.typeId === EnumContainerType.ProductionRule;
            this.SQLTypeName = isRule ? 'Processing' : 'Audit';
            const isSegment = this.scopeContainer?.id.startsWith('Sg');
            if(isSegment) {
                this.focusedObject = subObject ? subObject : scopeContainer;
            } else {
                this.focusedObject = focusedObject;
            }

            if(this.seriesId !== undefined && this.periodId !== undefined && this.scopeContainer !== null && this.focusedObject !== null){
                this.refreshFilterColumns();
            }
        });
        this.buildingBlockHelper.getOriginWindow().subscribe(originWindow => {
            this.auditPageMode = originWindow != null;
        });
    }

    ngOnDestroy(): void {
        this.buildingBlocksService.gridDestroyed.next();
        this.buildingBlocksService.gridDestroyed.complete();
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    onGridReady(params: any): void {
		this.gridColumnApi = params.columnApi;
    }

    setGridTheme(): void {
        const isDarkMode = this.siteThemeService.getIsDarkMode();
        this.gridTheme = this.gridThemes[(!isDarkMode ? 0 : 1)];
    }

    refreshFilterColumns(){
        if(this.scopeContainer?.id && this.focusedObject?.id && (this.currentlyAuditedObject?.id === this.focusedObject?.id || !this.currentlyAuditedObject)){
            this.buildingBlocksService.getProcessDataColumnsByProcessId(this.scopeContainer.id, this.focusedObject.id, this.periodId).subscribe(res => {
                this.processDataColumns = res;
                this.filterDataColumns = this.buildingBlockHelper.convertProcessDataColumnsToDropdownDatasource(res);
                this.setColumnWidths();
            });
        }
    }

    setColumnWidths(): void {
        // Set default column widths - user can still resize them
        setTimeout(() => {
            const state = this.gridColumnApi.getColumnState();
            state.forEach(column => {
                const dataColumn = this.buildingBlockHelper.getDataColumnBySystemName(column.colId);
                switch (dataColumn.datatype) {
                    case 'datetime':
                        column.width = 120;
                        break;
                    case 'number':
                        column.width = 100;
                        break;
                    default:
                        column.width = 180;
                        break;
                }
            });
            this.gridColumnApi.applyColumnState({ state, applyOrder: false });
        }, 250);
    }

    onAgRowClick(row: RowSelectedEvent){
        if(row.node.isSelected()){
            const keys = {};
            this.keyFieldNames.forEach(keyField => keys[keyField] = (Number.isNaN(row.data[keyField]) ? `'${row.data[keyField].replace('\'', '\'\'')}'` : row.data[keyField]));
            const path = this.currentlyAuditedPath?.split('.') ?? [this.currentlyAuditedObject['headProcessId']];
            let auditedObjId = this.currentlyAuditedObject.id;
            if(!this.currentlyAuditedPath){
                auditedObjId = this.currentlyAuditedObject['headProcessId'];
            }
            const selectedRow: AuditGridFilterCriteria = new AuditGridFilterCriteria().fromOrigin(keys, path, auditedObjId, '');
            this.selectedRow = selectedRow;
            if(!this.helperService.isNullOrUndefined(selectedRow)){
                this.buildingBlockHelper.setAuditedRow(selectedRow);
            }
        }
    }

    diagramAudit() {
        if(!this.helperService.isNullOrUndefined(this.selectedRow)){
            this.buildingBlockHelper.setAuditedRow(this.selectedRow);
            this.buildingBlockHelper.getAuditedRow().pipe(take(1)).subscribe(res => {
                this.buildingBlockHelper.beginDiagramAudit();
                this.refreshGrid();
            });
        }
    }

    diagramAuditFromProcessing(filterOverride: AuditGridFilterCriteria, origin: any) {
        const selectedRow = filterOverride;
        this.selectedRow = selectedRow;
        this.focusedObjectPath = this.selectedRow.auditedObjToRowPath.join('.');
        if(!this.helperService.isNullOrUndefined(this.selectedRow)){
            this.buildingBlockHelper.setAuditedRow(this.selectedRow);
            this.buildingBlockHelper.getAuditedRow().pipe(take(1)).subscribe(res => {
                this.buildingBlockHelper.beginDiagramAudit();
                this.refreshGrid();
            });
        }
    }

    clearSelectedRow(e) {
        this.selectedRow = null;
        this.agGrid.api.deselectAll();
        this.buildingBlockHelper.setAuditedRow(null);
        this.buildingBlockHelper.getAuditedRow().pipe(take(1)).subscribe(res => {
            this.buildingBlockHelper.beginDiagramAudit();
            this.refreshGrid();
        });
        this.agRecords = this.loadedRecords;
    }

    refreshGrid(): void {
        this.agRecords = null;
        this.agColumns = null;
        try {
            if (this.focusedObject && this.scopeContainer) {
                this.refreshFilterColumns();
                // Removed check if focusedObject was a group in favor of trying to identify the headProcess. May introduce bugs.
                try {
                    if (this.focusedObject instanceof Group) {
                        this.buildingBlocksService.dataColumnRequest
                            .next({ scopeProcessId: this.scopeContainer.id, processId: this.buildingBlockHelper.getHeadObjectIdsByContainer(this.focusedObject)[0], periodId: this.periodId});
                    } else {
                        this.buildingBlocksService.dataColumnRequest.next({scopeProcessId: this.scopeContainer.id, processId: this.focusedObject.id, periodId: this.periodId});
                    }
                    this.buildingBlockHelper.setAuditedObject(this.focusedObject);
                    this.currentlyAuditedPath = this.focusedObjectPath;
                    this.currentlyAuditedObject = this.focusedObject;
                } catch (ex) {
                    this.agRecords = [];
                    this.toastService.error('Error processing.');
                }
            }
        } catch (e) {
            this.agRecords = [];
            this.toastService.error('Error processing.');
        }
    }

    cancelAudit() {
        this.buildingBlocksService.cancelAudit().subscribe(response => console.log(response));
    }

    getProcessDataColumnSortIndex(processDataColumn: ProcessDataColumn): number {
        const accountAttributes = ['region_id', 'region_name', 'seller_group_id', 'seller_group_name', 'seller_type_id', 'seller_type_name', 'job_title_id', 'job_title_name'];
        let order = 0;
        if(processDataColumn.systemName.includes('period_')) {
            order = 0;
        } else if(processDataColumn.systemName.includes('xaction_id')) {
            order = 1;
        } else if(processDataColumn.systemName.includes('seller_id')) {
            order = 2;
        } else if(processDataColumn.systemName.includes('_value')) {
            order = 3;
        } else if(processDataColumn.systemName.includes('var_')) {
            order = 4;
        } else if(processDataColumn.systemName.includes('product') || processDataColumn.systemName.includes('customer')) {
            order = 5;
        } else if(processDataColumn.systemName.includes('qty_')) {
            order = 6;
        } else if(processDataColumn.systemName.includes('date')) {
            order = 7;
        } else if(processDataColumn.systemName.includes('tag_')) {
            order = 8;
        } else if(processDataColumn.systemName.includes('text_')) {
            order = 9;
        } else if(accountAttributes.includes(processDataColumn.systemName) || processDataColumn.systemName.includes('attrib_')) {
            order = 10;
        } else {
            order = 11;
        }
        return order - 100 * processDataColumn.usageCode;
    }

    refreshCache(): void {
        this.buildingBlockHelper.storeViewModes(this.scopeContainer.id).subscribe(_ => {
            this.buildingBlocksService.cacheAndLocalBuildingBlockRefresh();
        });
    }

    getFilterObject(): AuditGridFilterCriteria {
        const filterBuilderCopy = this.helperService.deepCopyTwoPointO(this.filterBuilderObject) ?? [];
        let path: Array<string> = [];
        let auditType = AuditType.Downstream;
        if(this.selectedRow) {
            const currentObjectPathArray = this.currentlyAuditedPath?.split('.') ?? [];
            const rowObjectPathArray = this.selectedRow.auditedObjToRowPath;
            if(rowObjectPathArray.length > 1){
                const inputArray = this.buildingBlockHelper.getObjectInputArray(this.buildingBlockHelper.getObjectById(rowObjectPathArray[0]), this.periodId);
                const isFinalActuallySource = !rowObjectPathArray[0].startsWith('Co') && inputArray.some(source => source.objectId === rowObjectPathArray[1]);
                if(!isFinalActuallySource){
                    path = [this.focusedObject.id, rowObjectPathArray[1]];
                    auditType = AuditType.EveryPathDownstream;
                }
            }
            if(auditType !== AuditType.EveryPathDownstream){
                if(this.currentlyAuditedPath?.includes(this.selectedRow.auditedObjToRowPath.join('.'))){
                    path = currentObjectPathArray.slice(rowObjectPathArray.length - 1);
                    auditType = AuditType.Upstream;
                } else if(this.selectedRow.auditedObjToRowPath.join('.').includes(this.currentlyAuditedPath)){
                    path = rowObjectPathArray.slice(currentObjectPathArray.length - 1);

                } else if(!this.currentlyAuditedPath){
                    path = [this.scopeContainer.id];
                } else {
                    throw Error('Audited gear is not upstream or downstream of selected row.');
                }
            }

        }
        return new AuditGridFilterCriteria().fromType(this.selectedRow ? this.selectedRow.rowKeyColumns : {}, path, auditType, JSON.stringify(filterBuilderCopy));
    }

    lookupByColumn(params): string{
        const datatype = this.buildingBlockHelper.getDataColumnBySystemName(params.column.colId).datatype;
        const mapping = this.buildingBlockHelper.getLookupByDatatype(datatype);
        if(!mapping){
            return params.data[params.column.colId];
        }
        const valueExpr = mapping.valueExpr;
        const displayExpr = mapping.displayExpr;
        const matchingLookup = mapping.dataSource.find(col => col[valueExpr] === params.data[params.column.colId]);
        return matchingLookup ? matchingLookup[displayExpr] : '';
    }

    formatFields(params): string {
        return this.buildingBlockHelper.formatValueByFieldName(params.colDef.field, params.value);
    }

    getAuditSQL(){
        this.auditSQLPopupVisible = true;
        this.buildingBlocksService.getAuditSQL(this.scopeContainer.id, this.focusedObject.id, this.seriesId, this.periodId, this.rowCount, this.getFilterObject()).subscribe(res => {
            if(res.result === null){
                this.auditSQLPopupVisible = false;
                this.toastService.error('Error generating audit SQL. Check logger for more information.');
            } else {
                this.auditSQLStrings = res.result;
            }
        });
        this.auditSQLStrings = null;
    }

    copySQL(auditString: string){
        navigator.clipboard.writeText(auditString).then(res => {
            this.toastService.success('Copied to clipboard!');
        });
    }

    clearPinnedBottomRowData() {
        this.agGrid.api.setPinnedBottomRowData([]);
    }

    updatePinnedBottomRowData() {
		this.agGrid.api.setPinnedBottomRowData([this.getPinnedTotals()]);
	}

    getPinnedTotals(): any {
        const result = {};
		this.agGrid.columnApi.getAllGridColumns().forEach(column => {
			const colDef = column.getColDef();
            result[colDef.field] = null;
			if (column['visible'] === true && colDef?.type?.includes('numericCol')) {
                result[colDef.field] = 0;
				this.agGrid.api.forEachNodeAfterFilter((rowNode: RowNode) => {
					if (rowNode && rowNode.data && rowNode.data[colDef.field]) {
						result[colDef.field] += rowNode.data[colDef.field];
					}
				});
			}
		});
        return result;
    }

    onColumnPinned(e: PinnedRowDataChangedEvent){
        const leftPinnedColumns = [];
        const rightPinnedColumns = [];
        e.columnApi.getColumns().forEach(column => {
            if(column.isPinnedLeft()){
                leftPinnedColumns.push(column.getId());
            }
            if(column.isPinnedRight()){
                rightPinnedColumns.push(column.getId());
            }
        });
        this.leftPinnedColumns = leftPinnedColumns;
        this.rightPinnedColumns = rightPinnedColumns;
    }

    onColumnMoved() {
        this.columnOrderByGear[this.focusedObject.id] = this.agGrid.columnApi.getAllGridColumns().map(col => col.getId());
    }

    showPLV(){
        this.plvDateRange = new DateRange(this.helperService.dateToUTCISOString(new Date(new Date().getTime() - 24 * 60 * 60 * 1000)), this.helperService.dateToUTCISOString(new Date()));
        this.processLogPopupVisible = true;
    }

    customRowCount(e: any){
        if(this.rowCountOptions[this.rowCountOptions.length - 1].id !== -1){
            this.rowCountOptions.pop();
        }
        e.customItem = { id: e.text, name: e.text };
        this.rowCountOptions.push(e.customItem);
    }

    toggleAdvancedView(e: any): void {
        this.buildingBlockHelper.setShowAdvancedView(e.value);
    }

    onPeriodChanged(id: number | null): void {
        this.buildingBlockHelper.setSeriesId(this.seriesId);
        this.buildingBlockHelper.setPeriodId(id);
    }

    toggleEndDatedRules(e: any){
        this.buildingBlockHelper.setShowEndDatedRules(e.value);
        this.buildingBlockHelper.setAuditedObject(this.focusedObject);
    }
}
