import { Component, ViewChild } from '@angular/core';
import { ColumnApi, GridApi, IAfterGuiAttachedParams, IStatusPanelParams } from 'ag-grid-community';
import { InsertTextViaHistoryResult } from 'devexpress-richedit/lib/core/model/manipulators/text-manipulator/text-manipulator';
import { DxTextBoxComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { CoreFeature, coreResponseCodes } from 'src/app/shared/constants/enums';
import { CoreDropdownProperties } from 'src/app/shared/models/core-dropdown-properties';
import { UiView } from 'src/app/shared/models/ui-view';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { UiViewService } from 'src/app/shared/services/ui-view.service';

@Component({
  selector: 'app-core-custom-left-statusbar',
  templateUrl: './core-custom-left-statusbar.component.html',
  styleUrls: ['./core-custom-left-statusbar.component.scss']
})
export class CoreCustomLeftStatusbarComponent{

	displayValue: string;

  	constructor(){}

	async agInit(params): Promise<void> {}

	updateRowCountText(filteredRowCount: number, unfilteredRowCount: number): void {
		this.displayValue = filteredRowCount === unfilteredRowCount ? String.format('{0:#,0}', filteredRowCount)
			: `${String.format('{0:#,0}', filteredRowCount)} of ${String.format('{0:#,0}', unfilteredRowCount)}`;
	}

	clearRowCount(): void {
		this.displayValue = '';
	}

}
