import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ViewContainerRef, ComponentRef } from '@angular/core';
import DevExpress from 'devextreme';
import { DxDiagramComponent, DxLoadPanelComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { Item } from 'devextreme/ui/diagram';
import { ToastrService } from 'ngx-toastr';
import { Dictionary } from 'src/app/shared/dictionary';
import { containerTypeIds, coreResponseCodes, DependencyType, EnumContainerType } from 'src/app/shared/constants/enums';
import {
    AssignGear, AccountNormalizeGear, AggregateProcess, BbObject, Gear, CalculateProcess, ConsolidateGear, Container, ContextGear, CounterGear, DatasourceProcess,
    DiagramGearMappings, EarnDateGear, FilterGear, FilterProcess, FormulaGear, Group, HierarchyGear, UpdateXactionGear, JoinGear, PeriodFilterGear,
    SegmentGearType, TransformProcess, UnionGear, UnionProcess, ProcessDataColumn, DataColumnUsageCode, DataColumnType, GearType, GearCode
} from 'src/app/shared/models/building-blocks';
import { AppElementsService } from 'src/app/shared/services/app-element.service';
import { BuildingBlocksService } from 'src/app/shared/services/building-blocks.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { Edge } from '../edge';
import {
    AggregateProcessNodeBehavior, CalculateProcessNodeBehavior, DatasourceProcessNodeBehavior, FilterProcessNodeBehavior, GroupContainerNodeBehavior, GroupNodeBehavior, Node, NodeBehavior,
    TransformProcessNodeBehavior, UnionProcessNodeBehavior, AssignNodeBehaviour, AccountNormalizeNodeBehaviour, GearNodeBehavior, ConsolidateNodeBehaviour, ContextNodeBehaviour,
    CounterNodeBehaviour, EarnDateNodeBehaviour, FilterNodeBehaviour, FormulaNodeBehaviour, HierarchyNodeBehaviour, JoinNodeBehaviour, PeriodFilterNodeBehaviour, UnionNodeBehaviour,
    UpdateXactionNodeBehaviour
} from '../node';
import { BuildingBlockHelperService } from '../building-block-helper.service';
import { BuildingBlocksSettings } from '../building-blocks-settings';
import { ObjectInput } from '../object-input';
import { filter, finalize, skip, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { GearboxContext } from 'src/app/shared/models/contexts/gearbox-template-context';
import { BbXactionMappingComponent } from 'src/app/shared/components/bb-xaction-mapping/bb-xaction-mapping.component';
import { confirm } from 'devextreme/ui/dialog';
import { AuditGridFilterCriteria, AuditType } from 'src/app/shared/models/audit-grid-filter-criteria';
import { AuditDetail } from 'src/app/shared/models/audit-detail';
import { rowsPropertyName } from 'devexpress-dashboard/model/index.metadata';

@Component({
    selector: 'app-bb-diagram',
    templateUrl: './bb-diagram.component.html',
    styleUrls: ['./bb-diagram.component.scss']
})
export class BbDiagramComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('diagram', { static: false }) diagram: DxDiagramComponent;
    @ViewChild('textSizeElement') textSizeElement: any;
    scopeContainer: Container;
    loadPanel: ComponentRef<DxLoadPanelComponent>;
    lockLoadPanel: boolean = false;
    loadPanelVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    objectStore: Dictionary<string, BbObject>;
    nodeStore: Dictionary<string, Node>;
    edgeStore: Dictionary<string, Edge>;
    previousScopeIdStack: string[] = [];
    isPageDestroyed: boolean = false;
    isDiagramUpdating: boolean = false;
    showAdvancedView: boolean = false;
    previousItems: Item[] = [];
    selectedNodes: Node[] = [];
    isGearboxViewEnabled: boolean = false;
    gearboxTemplates: any;
    auditRecords: AuditDetail[];
    processDataColumns: ProcessDataColumn[];
    selectedRow: any;
    selectedRowObject: any;
    selectedRowPath: string;
    seriesId: number;
    periodId: number;
    focusedObject: any;
    textToGetSizeOf: string;
    auditPageMode: boolean = false;
    currentlyAuditedObjectPath: string;
    newGearboxContext = new GearboxContext();
    isNewGearboxTemplatePopupVisible: boolean = false;
    createNewGearboxButtonOptions: any = {
        text: 'Create',
        type: 'default',
        stylingMode: 'contained',
        onClick: () => this.onCreateGearboxTemplateSubmit()
    };
    cancelNewGearboxButtonOptions: any = {
        text: 'Cancel',
        type: 'normal',
        stylingMode: 'contained',
        onClick: () => this.closeGearboxTemplatePopup()
    };
    contextMenuCommands: any[] = [
        'selectAll',
        'delete',
        'separator',
        { name: 'createGearbox', text: 'Save As Gearbox', icon: 'floppy' },
        { name: 'breakDownGearbox', text: 'Break Down Gearbox', icon: 'mediumiconslayout' }
    ];
    limitedContextMenuCommands: any[] = [
        'selectAll',
        'delete'
    ];

    private unsubscribe$ = new Subject<void>();

    constructor(private buildingBlockHelper: BuildingBlockHelperService,
        private buildingBlocksService: BuildingBlocksService,
        private appElementService: AppElementsService,
        private toast: ToastrService,
        private viewContainer: ViewContainerRef,
        private helperService: HelperService,
        private router: Router) { }

    ngOnInit() {
        this.nodeStore = new Dictionary<string, Node>([], 'id');
        this.edgeStore = new Dictionary<string, Edge>([], 'id');
        const sideNavOpenChanges = this.appElementService.getSideNavOpenChanges().subscribe(isOpen => {
            if (this.isPageDestroyed) {
                sideNavOpenChanges.unsubscribe();
            } else {
                if (this.diagram?.instance) {
                    setTimeout(this.repaint, this.appElementService.sideNavOuterToolbar.animationDuration);
                }
            }
        });
    }

    ngOnDestroy() {
        this.isPageDestroyed = true;
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngAfterViewInit() {

        this.buildingBlockHelper.getOriginWindow().subscribe(originWindow => {
            this.auditPageMode = originWindow != null;
            this.refreshDiagram();
        });
        this.buildingBlockHelper.getShowAdvancedView().pipe(takeUntil(this.unsubscribe$)).subscribe(showAdvancedView => {
            this.showAdvancedView = showAdvancedView;
            if (this.scopeContainer?.id.startsWith('Sg')) {
                this.diagram.instance.setSelectedItems([]);
                this.buildingBlockHelper.setFocusedObject(this.scopeContainer);
            }
        });

        this.buildingBlockHelper.getGearboxView().pipe(takeUntil(this.unsubscribe$)).subscribe(isGearboxViewEnabled => {
            if (this.isGearboxViewEnabled !== isGearboxViewEnabled) {
                this.isGearboxViewEnabled = isGearboxViewEnabled;
                this.refreshDiagram();
            }
        });

        this.buildingBlockHelper.getScopeContainer().pipe(takeUntil(this.unsubscribe$)).subscribe(scopeContainer => {
            if (this.scopeContainer === scopeContainer) {
                this.scopeContainer = scopeContainer;
                return;
            }
            this.scopeContainer = scopeContainer;
            this.markHeadProcess();
            this.refreshDiagram();
        });

        combineLatest([
            this.buildingBlockHelper.getSeriesId(),
            this.buildingBlockHelper.getPeriodId(),
            this.buildingBlockHelper.getFocusedObjectChanges(),
        ]).pipe(skip(2)).subscribe(([seriesId, periodId, focusedObject]) => {
            this.seriesId = seriesId;
            this.periodId = periodId;
            this.focusedObject = focusedObject;
        });

        this.buildingBlockHelper.getAuditedRow().subscribe(row => {
            if(!row || row.getOriginId() !== this.selectedRow?.getOriginId()){
                this.refreshDiagram();
            }
            this.selectedRow = row;
            this.selectedRowObject = row ? this.buildingBlockHelper.getObjectById(row.getOriginId()) : null;
            this.selectedRowPath = row?.auditedObjToRowPath.join('.');
        });

        this.buildingBlockHelper.getDiagramAuditRequest().subscribe(req => {
            if(this.scopeContainer && this.selectedRow){
                if(Object.keys(this.selectedRow.rowKeyColumns).length > 0){
                    this.showDiagramLoadPanel();
                    this.lockLoadPanel = true;
                    const diagramStartId = this.focusedObject.id === this.scopeContainer.id ? this.scopeContainer['headProcessId'] : this.focusedObject.id;
                    this.buildingBlocksService.getAuditDataRecords(this.scopeContainer.id, diagramStartId, this.seriesId, this.periodId, this.selectedRow)
                        .pipe(finalize(() => {
                            this.lockLoadPanel = false;
                            this.refreshDiagram();
                        }))
                        .subscribe(res => {
                            if(res.responseCode === coreResponseCodes.Success){
                                res.results.forEach(auditValue => {
                                    if(this.selectedRowObject.type === AuditType.EveryPathDownstream){
                                        auditValue.path = auditValue.path.replace(`${this.scopeContainer.id}.`, '');
                                    }
                                });
                                this.auditRecords = res.results;
                            } else {
                                throw new Error(res.message);
                            }
                        },
                        err => {
                            this.toast.error('Diagram audit failed.');
                        });
                } else {
                    this.refreshDiagram();
                }
            } else {
                this.selectedRowObject = null;
                this.selectedRowPath = '';
                this.auditRecords = [];
                this.refreshDiagram();
            }
        });

        this.buildingBlockHelper.getObjectStore().pipe(takeUntil(this.unsubscribe$)).subscribe(store => {
            if (store !== null && store !== undefined) {
                this.objectStore = store;
            }
        });

        this.buildingBlockHelper.getRefreshDiagramObservable().pipe(takeUntil(this.unsubscribe$)).subscribe(_ => {
            this.refreshDiagram();
        });

        this.buildingBlockHelper.getGearboxTemplatesFlat().subscribe(gearboxTemplates => this.gearboxTemplates = gearboxTemplates);

        this.executeWithinDiagramUpdate(() => {
            this.diagram.showGrid = false;
            this.diagram.simpleView = true;
            this.diagram.autoZoomMode = 'disabled';
            this.diagram.units = 'px';
            this.diagram.viewUnits = 'cm';
            this.diagram.customShapes = this.buildingBlocksService.getAllDiagramCustomShapes();

            this.buildingBlockHelper.getEditLock().pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
                const editProps = this.diagram.editing;
                editProps.allowDeleteShape = !result;
                editProps.allowMoveShape = !result;
                this.diagram.editing = editProps;
            });

            this.diagram.historyToolbar = { visible: false };
            this.diagram.toolbox = {
                visibility: 'disabled'
            };
            this.diagram.onItemDblClick.subscribe(e => this.onDiagramItemDblClick(e));
        });

        this.buildingBlockHelper.getAuditedObject().subscribe(obj => {
            if(obj){
                const nodes = this.diagram.instance.getSelectedItems()
                .filter(diagramItem => diagramItem.itemType === 'shape' && diagramItem.key !== undefined)
                .map<Node>(diagramItem => this.nodeStore.byKey((diagramItem.dataItem as Node).id));
                if(nodes.length > 0){
                    this.currentlyAuditedObjectPath = nodes[0].id;
                }
            }
            this.refreshDiagram();
        });
        this.createDiagramLoadPanel();

        document.getElementsByClassName('bb-diagram-container')[0].addEventListener('keydown', this.preventDiagramObjectPaste);
    }

    onDiagramSelectionChanged(e): void {
        if (!this.scopeContainer) {
            return;
        }

        const selectedNodes = (e.items as Item[])
            .filter(diagramItem => diagramItem.itemType === 'shape' && diagramItem.key !== undefined)
            .map<Node>(diagramItem => this.nodeStore.byKey((diagramItem.dataItem as Node).id));

        if (this.scopeContainer.id.startsWith('Sg') && !this.showAdvancedView) {
            this.buildingBlockHelper.setSubObject(selectedNodes.length === 1 ? selectedNodes[0].obj : this.scopeContainer);
            return;
        }

        if (e.items.length === this.previousItems?.length && e.items.map(a => a['id']).every(a => this.previousItems.map(b => b['id']).includes(a))) {
            return;
        }

        if (this.buildingBlockHelper.hasUnsavedChanges()) {
            this.diagram.instance.setSelectedItems(this.previousItems);
            if(!this.isDiagramUpdating){
                this.buildingBlockHelper.notifyUserOfUnsavedChanges();
            }
        } else {
            this.selectedNodes = selectedNodes;
            this.buildingBlockHelper.setSelectedNodes(selectedNodes);
            this.buildingBlockHelper.setFocusedObject(selectedNodes.length === 1 ? selectedNodes[0].obj : this.scopeContainer);
            this.buildingBlockHelper.setFocusedObjectPath(selectedNodes.length === 1 ? selectedNodes[0].id : null);
            this.previousItems = e.items;
        }
    }

    executeWithinDiagramUpdate(fn: () => void): void {
        this.diagram.instance.beginUpdate();
        try {
            fn();
        } finally {
            this.diagram.instance.endUpdate();
        }
    }

    refreshDiagram(): void {
        this.showDiagramLoadPanel();
        // HACK: DxDiagram doesn't publicly expose ScrollView, check this when upgrading DevEx
        const innerDiagramView = this.diagram.instance['_diagramInstance'].view.view;
        const scrollPos = innerDiagramView.scroll;
        if (this.scopeContainer === undefined || this.scopeContainer === null) {
            return;
        }
        setTimeout(() => {
            this.executeWithinDiagramUpdate(() => {
                const previouslySelectedObjectId = this.previousItems[0]?.key;
                const oldPaths = this.nodeStore.allValues().map(node => node.path);
                this.isDiagramUpdating = true;
                let nodeArrayStore = null;

                // HACK: Prevent databinding error when nodeStore & edgeStore are cleared. -DH
                this.diagram.edges = null;
                this.diagram.nodes = null;

                this.nodeStore.clear();
                this.edgeStore.clear();

                const headObjectIds = this.buildingBlockHelper.getHeadObjectIdsByContainer(this.scopeContainer);
                let rootObjects = [];
                if(headObjectIds.length === 0)
                {
                    const allScopeChildren = this.objectStore.byFilter(bbObject => bbObject.parentId === this.scopeContainer.id);
                    if(allScopeChildren.length > 0){
                        rootObjects = [allScopeChildren[0]];
                    }
                } else {
                    rootObjects = this.objectStore
                    .byFilter(obj => headObjectIds
                        .some(objectId => objectId === obj.id));
                }

                rootObjects = this.buildingBlockHelper.removeDuplicateGearsForLoadDiagram(rootObjects);
                for (const rootObject of rootObjects) {
                    this.recursiveLoadDiagram(rootObject, null, this.scopeContainer, 0);
                }
                this.markHeadProcess();
                if(this.auditRecords) {
                    const vals = this.nodeStore.allValues();
                    this.auditRecords.forEach(objDetail => {
                        const tags = [];
                        const obj = this.buildingBlockHelper.getObjectById(objDetail.sourceObj);
                        if(objDetail.rows.length > 1 || obj['gearTypeCode'] === GearCode.ContextGearCode || ['Ds', 'Co'].includes(obj.objectTypeCode)){
                            tags.push({
                                value: `${objDetail.rows.length} record(s)`,
                                hoverText: `${objDetail.rows.length} record(s)`,
                                colorStyle: 'fill: rgba(221, 255, 221, 1);',
                            });
                        } else if(objDetail.rows.length === 1){
                            const row = objDetail.rows[0];
                            const cols = Object.keys(row);
                            cols.filter(col => {
                                const dataColumn = this.buildingBlockHelper.getDataColumnBySystemName(col);
                                return dataColumn.type !== DataColumnType.RecordMetaField &&
                                dataColumn.type !== DataColumnType.CoreInternalField &&
                                dataColumn.type !== DataColumnType.RuleIntroduced &&
                                col !== 'seller_id_name' &&
                                col !== 'earn_date' &&
                                col !== 'period_begin_date' &&
                                col !== 'period_end_date';
                            }).forEach(col => {
                                const dataCol = this.buildingBlockHelper.getDataColumnBySystemName(col);
                                const processDataCol = objDetail.processDataColumns.find(pdc => pdc.systemName === col);
                                const value = this.buildingBlockHelper.formatValueByFieldName(col, row[col]);
                                const maxColumnNameLength = 20;
                                let friendlyName = dataCol.friendlyName;
                                let fullFriendlyName = dataCol.friendlyName;
                                if(friendlyName.length > maxColumnNameLength){
                                    friendlyName = dataCol.friendlyName.slice(0, 19) + '...';
                                }
                                if(friendlyName.length > 0 && !friendlyName.endsWith(': ')){
                                    friendlyName += ': ';
                                    fullFriendlyName += ': ';
                                }
                                if(processDataCol && processDataCol.usageCode !== DataColumnUsageCode.Ignored){
                                    tags.push({
                                        value: friendlyName + value,
                                        hoverText: fullFriendlyName + value,
                                        colorStyle: processDataCol.usageCode > 1 ? 'fill: rgba(221, 255, 221, 1);' : 'fill: rgb(177, 223, 255);',
                                    });
                                }
                            });
                        }
                        tags.forEach(tag => {
                            const nodes = vals.filter(val => val.id === objDetail.path);
                            if(nodes.length > 0){
                                nodes.forEach(node => {
                                    if(!node.tags){
                                        node.tags = [];
                                    }
                                    this.addTagToNode(node, tag);
                                });
                            }
                        });
                    });
                }
                let selectedRowNode;
                if(this.selectedRowPath){
                    selectedRowNode = this.nodeStore.byFilter(node => node.id === this.selectedRowPath)[0];
                }
                this.nodeStore.allValues().forEach((node) => {
                    if(node.id === this.selectedRowPath){
                        this.addTagToNode(node, {
                            value: '1 row selected',
                            hoverText: '1 row selected',
                            colorStyle: 'fill: rgb(180, 200, 200);',
                        });
                    }
                    if(this.currentlyAuditedObjectPath && node.id === this.currentlyAuditedObjectPath){
                        this.addTagToNode(node, {
                            value: 'Currently auditing',
                            hoverText: 'Currently auditing',
                            colorStyle: 'fill: rgb(230, 230, 150);',
                        });
                    }
                    if(selectedRowNode && !selectedRowNode?.id.includes(node.id)){
                        node.isLowOpacity = true;
                    }
                });

                nodeArrayStore = new ArrayStore({
                    key: 'id',
                    data: this.auditPageMode ? this.nodeStore.byFilter(node => !node.isForeign || node.id.includes('Co')) : this.nodeStore.allValues(),
                    onInserted: (value, key) => this.onNodeInserted(value, key),
                    onRemoved: (value) => this.onNodeRemoved(value)
                });

                const nodeDataSource = new DataSource({ store: nodeArrayStore, paginate: false });
                const edgeDataSource = new DataSource({ store: this.edgeStore.getStore(), paginate: false });

                this.diagram.nodes = {
                    dataSource: nodeDataSource,
                    keyExpr: 'id',
                    textExpr: 'text',
                    typeExpr: 'shapeCode',
                    lockedExpr: 'isLocked',
                    containerKeyExpr: 'parentId',
                    containerChildrenExpr: null,
                    autoLayout: {
                        type: BuildingBlocksSettings.useTreeLayout ? 'tree' : 'layered',
                        orientation: 'horizontal',
                    }
                };
                this.diagram.edges = {
                    dataSource: edgeDataSource,
                    keyExpr: 'id',
                    fromExpr: 'destNode.id',
                    toExpr: 'sourceNode.id',
                    lineTypeExpr: edge => this.getLineTypeCode(edge),
                    fromLineEndExpr: edge => this.getEdgeSourceEndType(edge),
                    toLineEndExpr: edge => this.getEdgeDestEndType(edge),
                    styleExpr: edge => this.getEdgeStyle(edge),
                    zIndexExpr: edge => -1
                };
                this.setArrowModification(false);
                if (previouslySelectedObjectId && this.previousScopeIdStack.length === 0 && this.nodeStore.keyExists(previouslySelectedObjectId as string)) {
                    const item = this.diagram.instance.getItemByKey(previouslySelectedObjectId);
                    this.diagram.instance.setSelectedItems([item]);
                    this.diagram.instance.scrollToItem(item);
                    if(oldPaths.every(path => this.nodeStore.keyExists(path))){
                        innerDiagramView.scroll = scrollPos;
                        setTimeout(() => {
                            innerDiagramView.scrollView.setScroll(scrollPos['x'], scrollPos['y']);
                            this.hideDiagramLoadPanel();
                        });
                    } else {
                        this.hideDiagramLoadPanel();
                    }
                } else {
                    this.hideDiagramLoadPanel();
                }
                this.isDiagramUpdating = false;
                this.diagram.instance.updateToolbox();
            });
        });
    }

    addTagToNode(node, tag: any){
        node.tags.push(tag);
        node.minWidth = this.getAuditWidthViaSample(node);
        this.nodeStore.remove(node.id);
        this.nodeStore.insert(node);
    }

    onDiagramItemDblClick(e): void {
        if (e.item.itemType === 'shape' && !this.buildingBlockHelper.hasUnsavedChanges()) {
            const obj = e.item.dataItem.obj;
            if (obj instanceof Container) {
                this.buildingBlockHelper.scopeDown(obj);
            }
        }
    }

    getEdgeStyle(edge: Edge): string {
        if (!(edge.sourceNode.obj instanceof BbObject) || !this.objectStore.keyExists(edge.destNode.id)) {
            return null;
        }
        let inputArray: ObjectInput[];
        if (edge.sourceNode.obj instanceof Container) {
            inputArray = this.buildingBlockHelper
                .getObjectInputArray(edge.destNode.obj, this.periodId)
                .filter(input => this.buildingBlockHelper.getHeadObjectIdsByContainer(edge.sourceNode.obj as Container).some(id => id === input.objectId));
        } else {
            inputArray = this.buildingBlockHelper.getObjectInputArray(edge.destNode.obj, this.periodId).filter(input => input.objectId === edge.sourceNode.obj.id);
        }
        if (inputArray.length === 0) {
            return 'stroke-dasharray: 8, 4';
        } else if (inputArray[0].type === DependencyType.Main) {
            return '';
        } else {
            return 'stroke-dasharray: 2, 3';
        }
    }

    getLineTypeCode(node): string {
        return 'straight';
    }

    getNodeText(node: Node): string {
        return '';
    }

    getEdgeText(edge: Edge): string {
        return edge.name;
    }

    getEdgeSourcePointIndex(edge: Edge): number {
        return undefined;
    }

    getEdgeDestPointIndex(edge: Edge): number {
        return undefined;
    }

    getEdgeSourceEndType(edge: Edge): string {
        return 'arrow';
    }

    getEdgeDestEndType(edge: Edge): string {
        return 'none';
    }

    recursiveLoadDiagram(sourceObject: BbObject, destNode: Node, parentContainer: Container, recursionDepth: number, allowPathedId: boolean = true): void {
        this.checkRecursionDepth({ recursionDepth });
        const isGearbox = sourceObject instanceof Group && sourceObject.typeId === EnumContainerType.Group;
        const isHiddenGearboxChildInput = this.isGearboxViewEnabled && destNode && destNode.obj.parentId !== parentContainer?.id && destNode.obj.parentId !== sourceObject?.parentId;

        if (!sourceObject.id.startsWith('Bb')) {
            sourceObject = this.getContextualObject(sourceObject, parentContainer);
        }

        if (isHiddenGearboxChildInput) {
            return;
        }

        if (parentContainer.parentId !== null
            && this.buildingBlockHelper.getHeadObjectIdsByContainer(parentContainer).some(headProcessId => headProcessId === sourceObject.id)
            && (destNode === null || !destNode.isForeign)) {

            for (const consumer of this.objectStore
                .byFilter(obj => obj.parentId === parentContainer.parentId && this.buildingBlockHelper.getObjectInputArray(obj, this.periodId).some(input => input.objectId === sourceObject.id))) {
                if (this.buildingBlockHelper.getObjectInputArray(consumer, this.periodId).some(input => input.objectId === sourceObject.id)) {
                    const alternateDestNode = this.createNode(destNode, consumer.id, false);
                    alternateDestNode.isForeign = true;
                    this.recursiveLoadDiagram(sourceObject, alternateDestNode, parentContainer, recursionDepth, false);
                }
            }
        }

        const sourceNodeId = Node.getId(destNode, sourceObject.id, !BuildingBlocksSettings.shareNodes && allowPathedId);
        let isSourceNodeNew: boolean;
        let sourceNode: Node;
        if (this.nodeStore.keyExists(sourceNodeId)) {
            sourceNode = this.nodeStore.byKey(sourceNodeId);
            isSourceNodeNew = false;
        } else {
            sourceNode = this.createNode(destNode, sourceObject.id, !BuildingBlocksSettings.shareNodes && allowPathedId);
            isSourceNodeNew = true;
        }

        if (destNode !== null && sourceNode.id !== destNode.id && !this.edgeStore.keyExists(Edge.getId(sourceNode, destNode))) {
            const edge = new Edge(sourceNode, destNode, null);
            this.edgeStore.insert(edge);
        }

        if (sourceObject.parentId !== parentContainer.id) {
            if (!this.isGearboxViewEnabled || !this.nodeStore.byFilter(x => x.obj.id === sourceObject.parentId)[0]) {
                sourceNode.isForeign = true;
            }
        }

        // prevent infinite loops from circular segment references
        if (sourceNode.path.includes(sourceNode.obj.id + '.')) {
            sourceNode.isDuplicate = true;
        }

        // prevent duplicate objects
        if (isSourceNodeNew && !sourceNode.isDuplicate && !sourceNode.isForeign) {
            const sourceNodeInputArray = this.buildingBlockHelper.getObjectInputArray(sourceNode.obj, this.periodId).filter(x => x.objectId);

            if (this.isGearboxViewEnabled && isGearbox) {
                const gearboxHeads = this.buildingBlockHelper.getHeadObjectIdsByContainer(sourceObject as Container).map(id => this.objectStore.byKey(id));
                for (const gearboxHead of gearboxHeads) {
                    this.recursiveLoadDiagram(gearboxHead, null, parentContainer, recursionDepth);
                }
            }

            for (const sourceInput of sourceNodeInputArray) {
                this.recursiveLoadDiagram(this.buildingBlockHelper.getObjectById(sourceInput.objectId), sourceNode, parentContainer, recursionDepth);
            }
        }
    }

    checkRecursionDepth(byref: { recursionDepth: number }): void {
        if (++byref.recursionDepth > BuildingBlocksSettings.maxRecursionDepth) {
            throw new Error('Too much recursion');
        }
    }

    getContextualObject(obj: BbObject, scopeContainer: Container): BbObject {
        const scopeContainerAncestors = this.buildingBlockHelper.getObjectAncestors(scopeContainer);
        const objParent = this.buildingBlockHelper.getParentByChildObject(obj);
        while (objParent && !scopeContainerAncestors.some(ancestor => ancestor.id === objParent.id) && objParent.parentId !== 'CoRoot' && obj.parentId !== 'CoRoot') {
            obj = this.buildingBlockHelper.getParentByChildObject(obj);
        }
        return obj;
    }

    createNode(pathingNode: Node, objectId: string, isPathed: boolean): Node {
        const storedObject: BbObject = this.objectStore.byKey(objectId);
        const obj = this.objectStore.byKey(objectId);
        const parentNodeId = this.nodeStore.byFilter(x => x.obj.id === obj.parentId)[0]?.id;
        const node: Node = new Node(pathingNode, obj, isPathed, this.getNodeBehaviorByObjectTypeCode(storedObject), parentNodeId);
        this.nodeStore.insert(node);
        return node;
    }

    getNodeBehaviorByObjectTypeCode(obj: BbObject): NodeBehavior {
        switch (true) {
            case DatasourceProcess.getObjectTypeCode() === obj.objectTypeCode:
                return DatasourceProcessNodeBehavior.instance;
            case TransformProcess.getObjectTypeCode() === obj.objectTypeCode:
                return TransformProcessNodeBehavior.instance;
            case FilterProcess.getObjectTypeCode() === obj.objectTypeCode:
                return FilterProcessNodeBehavior.instance;
            case CalculateProcess.getObjectTypeCode() === obj.objectTypeCode:
                return CalculateProcessNodeBehavior.instance;
            case AggregateProcess.getObjectTypeCode() === obj.objectTypeCode:
                return AggregateProcessNodeBehavior.instance;
            case UnionProcess.getObjectTypeCode() === obj.objectTypeCode:
                return UnionProcessNodeBehavior.instance;
            case Group.getObjectTypeCode() === obj.objectTypeCode:
                return this.isGearboxViewEnabled && obj instanceof Group && obj.typeId === EnumContainerType.Group ? GroupContainerNodeBehavior.instance : GroupNodeBehavior.instance;
            case new SegmentGearType().getGearTypeCode() === (obj as Gear).gearTypeCode:
                return GearNodeBehavior.instance;
            case new PeriodFilterGear().getGearTypeCode() === (obj as Gear).gearTypeCode:
                return PeriodFilterNodeBehaviour.instance;
            case new AssignGear().getGearTypeCode() === (obj as Gear).gearTypeCode:
                return AssignNodeBehaviour.instance;
            case new AccountNormalizeGear().getGearTypeCode() === (obj as Gear).gearTypeCode:
                return AccountNormalizeNodeBehaviour.instance;
            case new EarnDateGear().getGearTypeCode() === (obj as Gear).gearTypeCode:
                return EarnDateNodeBehaviour.instance;
            case new CounterGear().getGearTypeCode() === (obj as Gear).gearTypeCode:
                return CounterNodeBehaviour.instance;
            case new ContextGear().getGearTypeCode() === (obj as Gear).gearTypeCode:
                return ContextNodeBehaviour.instance;
            case new FormulaGear().getGearTypeCode() === (obj as Gear).gearTypeCode:
                return FormulaNodeBehaviour.instance;
            case new FilterGear().getGearTypeCode() === (obj as Gear).gearTypeCode:
                return FilterNodeBehaviour.instance;
            case new JoinGear().getGearTypeCode() === (obj as Gear).gearTypeCode:
                return JoinNodeBehaviour.instance;
            case new ConsolidateGear().getGearTypeCode() === (obj as Gear).gearTypeCode:
                return ConsolidateNodeBehaviour.instance;
            case new HierarchyGear().getGearTypeCode() === (obj as Gear).gearTypeCode:
                return HierarchyNodeBehaviour.instance;
            case new UnionGear().getGearTypeCode() === (obj as Gear).gearTypeCode:
                return UnionNodeBehaviour.instance;
            case new UpdateXactionGear().getGearTypeCode() === (obj as Gear).gearTypeCode:
                return UpdateXactionNodeBehaviour.instance;
            default:
                throw new Error('Bad object type code: ' + obj.objectTypeCode);
        }
    }
    // Todo: Make this scale better
    onNodeInserted(values, key): void {
        this.insertGear(values.shapeCode);
    }

    insertGear(type: string): void {
        this.buildingBlocksService.createGear(this.scopeContainer.id, DiagramGearMappings[type]).subscribe(res => {
            if(res.responseCode === coreResponseCodes.Success){
                this.buildingBlockHelper.addObjectsToStore(res.results, true);
                this.buildingBlockHelper.forceRefreshDiagram();
                this.buildingBlockHelper.fetchDataColumns();
                this.buildingBlocksService.getContainerById(this.scopeContainer.id).subscribe(scopeContainer => {
                    this.buildingBlockHelper.setScopeContainer(scopeContainer);
                    this.buildingBlockHelper.replaceObjectInStore(scopeContainer);
                    if (scopeContainer.typeId === containerTypeIds.Group) {
                        this.buildingBlockHelper.setGearboxTemplates();
                    }
                });
                const gearType = this.diagram.customShapes.find(x => x.type === type);
                this.toast.success(`New ${gearType.title} successfully added.`);
            } else {
                this.toast.error(res.message);
            }
        });
    }

    async onNodeRemoved(key: string) {
        const objectToDelete = this.buildingBlockHelper.identifySelectedObject(key);
        if (objectToDelete.includes('Bb') || objectToDelete.includes('Co')) {
            this.showDiagramLoadPanel();
            const requiresConfirmation = await this.buildingBlocksService.validateDeleteObject(objectToDelete).toPromise();
            if(requiresConfirmation.responseCode === coreResponseCodes.RequiresConfirmation){
                const isDeleteCancelled = !await confirm(requiresConfirmation.message, 'Saved Columns Affected');
                if(isDeleteCancelled){
                    this.refreshScope();
                    this.repaint();
                    return;
                }
            }
            if (objectToDelete.includes('Bb')) {
                this.buildingBlocksService.deleteGear(objectToDelete).subscribe(res => {
                    if (res.responseCode === coreResponseCodes.Error || res.responseCode === coreResponseCodes.InvalidRequest) {
                            this.toast.error(res.message);
                    } else {
                            this.buildingBlockHelper.removeObjectAndChildrenFromObjectStore(objectToDelete, true);
                            this.toast.success('Gear Deleted');
                    }
                    if (this.scopeContainer.typeId === containerTypeIds.Group) {
                        this.buildingBlockHelper.setGearboxTemplates();
                    }
                    this.refreshScope();
                    this.repaint();
                });
            } else {
                this.buildingBlocksService.deleteContainer(objectToDelete).subscribe(res => {
                    if (res.responseCode !== coreResponseCodes.Success) {
                        this.toast.error(res.message);
                    } else {
                        this.buildingBlockHelper.removeObjectAndChildrenFromObjectStore(objectToDelete, true);
                        this.toast.success('Gearbox Deleted');
                    }
                    this.refreshScope();
                    this.repaint();
                });
            }
        }
        await this.buildingBlockHelper.updateDependentSourceProcess(key);
    }

    refreshScope = () => {
        this.hideDiagramLoadPanel();
        this.buildingBlocksService.getContainerById(this.scopeContainer.id).subscribe(scopeContainer => {
            this.buildingBlockHelper.setScopeContainer(scopeContainer);
            this.buildingBlockHelper.replaceObjectInStore(scopeContainer);
        });
    };

    repaint = (showLoadPanel: boolean = true) => {
        this.diagram.instance.repaint();
        if (showLoadPanel) {
            this.createDiagramLoadPanel();
        }
    };

    markHeadProcess() {
        const headProcessId = this.scopeContainer?.['headProcessId'];
        if (headProcessId && this.nodeStore.keyExists(headProcessId)) {
            this.nodeStore.byKey(headProcessId).isHead = true;
        }
    }

    handleEdit(e: any) {
        if (e.operation === 'deleteShape' && e.reason === 'modelModification') {
            let scopeObjectJson = JSON.parse(this.scopeContainer.objectJson);
            if (this.scopeContainer.typeId === EnumContainerType.Group) {
                const parentRule = this.buildingBlockHelper.getObjectById(this.scopeContainer.parentId) as Container;
                scopeObjectJson = JSON.parse(parentRule.objectJson);
            }
            if (this.buildingBlockHelper.hasUnsavedChanges()) {
                this.buildingBlockHelper.revertUnsavedChanges();
            }
            this.setArrowModification(true);

            if (this.isGearboxViewEnabled && e.args.shape.dataItem.obj.typeId === EnumContainerType.Group) {
                this.onNodeRemoved(e.args.shape.key);
            } else if (e.args.shape.dataItem.obj.typeId === EnumContainerType.ProductionRule) {
                e.allowed = false;
                this.toast.warning('Deleting a rule must be done from the Plans page and changing the source of a gear must done in the downstream gear\'s properties.');
            } else if (e.args.shape.dataItem.obj.id.startsWith('Ds')) {
                e.allowed = false;
                this.toast.warning('Deleting a datasource must be done from the System page and changing the source of a gear must done in the downstream gear\'s properties.');
            }
        }
    }

    setArrowModification(value: boolean) {
        const editProps = this.diagram.editing;
        editProps.allowChangeConnection = value;
        this.diagram.editing = editProps;
    }

    handleCustomCommand(e): void {
        if (e.name === 'createGearbox') {
            if (this.selectedNodes.length) {
                this.onCreateGearboxTemplateClick();
            }
        } else if (e.name === 'breakDownGearbox') {
            if (this.selectedNodes[0]?.obj?.['typeId'] === containerTypeIds.Group) {
                const gearboxId = this.selectedNodes[0]?.obj.id;
                this.showDiagramLoadPanel();

                this.buildingBlocksService.decomposeGearbox(gearboxId).subscribe(result => {
                    if (result.responseCode !== coreResponseCodes.Success) {
                        this.toast.error(result.message);
                    } else {
                        this.buildingBlockHelper.removeObjectFromObjectStore(gearboxId, true);
                        this.buildingBlockHelper.refreshDataColumns();
                    }
                    this.refreshScope();
                });
            }
        }
    }

    onCreateGearboxTemplateClick = () => {
        this.newGearboxContext.name ??= this.buildingBlockHelper.getNewGearboxTemplateNameSuggestion();
        this.newGearboxContext.description ??= '';
        this.newGearboxContext.createInstance = true;
        this.newGearboxContext.createTemplate = true;
        this.isNewGearboxTemplatePopupVisible = true;
    };

    closeGearboxTemplatePopup = () => this.isNewGearboxTemplatePopupVisible = false;

    areNewGearboxCheckboxesValid = () => this.newGearboxContext.createInstance || this.newGearboxContext.createTemplate;

    isGearboxTemplateNameUnique = (e) => !this.gearboxTemplates.some(x => x.name.toLowerCase() === (e.value?.toLowerCase()));

    onCreateGearboxTemplateSubmit = () => {
        if (!(this.newGearboxContext.name.trim() && this.areNewGearboxCheckboxesValid() && this.isGearboxTemplateNameUnique({value: this.newGearboxContext.name}))) {
            return;
        }

        this.closeGearboxTemplatePopup();
        this.newGearboxContext.ruleId = this.scopeContainer.id;
        this.newGearboxContext.selectedIds = this.selectedNodes.map(x => x.obj.id).filter(id => id.startsWith('Bb'));

        this.showDiagramLoadPanel();

        this.buildingBlocksService.createGearboxTemplate(this.newGearboxContext).subscribe(response => {
            this.refreshScope();
            this.buildingBlockHelper.refreshDataColumns();
            this.buildingBlockHelper.setGearboxTemplates();
            this.newGearboxContext.name = undefined;
            this.newGearboxContext.description = undefined;
            if (response.responseCode === coreResponseCodes.InvalidRequest) {
                this.toast.error(response.message);
            }
        });
    };

    getAuditWidth(element: any){
        const bbox = element.getBBox();
        const padding = 10;
        if(bbox.width === 0){
            return 0;
        }
        return bbox.width + padding;
    }

    getAuditWidthViaSample(sampleNode: any){
        let maxWidth = 96;
        const padding = 10;
        sampleNode['tags']?.forEach(element => {
            const sampleText = element.friendlyName + ': ' + element.value;
            this.textToGetSizeOf = sampleText;
            this.textSizeElement.nativeElement.innerHTML = this.textToGetSizeOf;
            const boundingWidth = this.textSizeElement.nativeElement.getComputedTextLength();
            if(boundingWidth + padding > maxWidth){
                maxWidth = boundingWidth + padding;
            }
        });
        return maxWidth;
    }

    createDiagramLoadPanel(){
        let isCurrentLoadPanelShown: boolean = false;
        if(this.loadPanel){
            isCurrentLoadPanelShown = this.loadPanel.instance.visible;
            this.loadPanel.destroy();
        }
        setTimeout(() => {
            this.loadPanel = this.helperService.createLoadPanel({ my: 'center', at: 'center', of: '.bb-diagram' });
            this.loadPanel.instance.showPane = true;
            this.loadPanel.instance.container = '.bb-diagram';
            this.helperService.injectComponent(this.viewContainer, this.loadPanel);
            this.loadPanel.instance.visible = isCurrentLoadPanelShown;
        });
    }

    showDiagramLoadPanel(){
        if(this.loadPanel){
            this.loadPanel.instance.visible = true;
        }
    }

    hideDiagramLoadPanel(){
        if(this.loadPanel && !this.lockLoadPanel){
            this.loadPanel.instance.visible = false;
        }
    }

    preventDiagramObjectPaste(e): void {
        if ((e.ctrlKey && e.code === 'KeyV' && !e.altKey && !e.shiftKey && !e.metaKey)) {
            e.preventDefault();
        }
    }
}
