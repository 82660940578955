<dx-popup
    [(width)]="popupWidth"
    [(height)]="popupHeight"
    [minWidth]="450"
    [minHeight]="300"
    [showTitle]="true"
    [title]="title"
    [dragEnabled]="true"
    container=".dx-viewport"
    [hideOnOutsideClick]="false"
    [(visible)]="isPopupVisible"
    [showCloseButton]="false"
    [resizeEnabled]="true"
    (onHidden)="onHidden()"
    (onResize)="resizePopupContent($event)"
    titleTemplate="titleTemplate"
    class="attribute-permissions-popup">
    <div *dxTemplate="let data of 'titleTemplate'">
        <div class="dx-toolbar dx-widget dx-visibility-change-handler dx-collection" role="toolbar">
            <div class="dx-toolbar-items-container">
                <div class="dx-toolbar-before">
                    <div class="dx-item dx-toolbar-item dx-toolbar-label">
                        <div class="dx-item-content dx-toolbar-item-content">
                            <div class="popup-title-text">{{title}}</div>
                            <div id="popupTitleTooltip" class="popup-title-info-icon">
                                <i class="dx-icon-info"></i>
                            </div>
                            <dx-tooltip target="#popupTitleTooltip" position="right" showEvent="dxhoverstart" hideEvent="dxhoverend">
                                <div *dxTemplate="let data of 'content'" class="popup-title-tooltip">
                                    <span>Permissions are assigned at either the Attribute Level OR at the Account level. 
                                        Any checked accounts will be able to see the report, and any accounts that have the 
                                        selected attributes currently assigned will also see the report.</span>
                                </div>
                            </dx-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="popup-content-wrapper">
        <dx-tab-panel itemTitleTemplate="itemTitleTemplate">
            <div *dxTemplate="let content of 'itemTitleTemplate'">
                {{getTabTitle(content)}}
            </div>
            <dxi-item title="Attributes">
                <div class="tab-content-wrapper">
                    <dx-responsive-box>
                        <dxi-row [ratio]="1"></dxi-row>
    
                        <dxi-col [ratio]="1"></dxi-col>
                        <dxi-col [ratio]="1"></dxi-col>
    
                        <dxi-item>
                            <dxi-location [row]="0" [col]="0"></dxi-location>
    
                            <div class="dx-field-label-margin">Attribute Type</div>
                            <div class="select-all-attribute-classes">
                                <div class="attribute-type-item">
                                    <dx-check-box [value]="selectAllCheckboxValue" (onValueChanged)="selectAllChanged($event)"></dx-check-box>
                                    <div class="display-name">
                                        <span>(Select All)</span>
                                    </div>
                                </div>
                            </div>
                            <dx-list
                                [dataSource]="attributeClasses"
                                keyExpr="id"
                                selectionMode="single"
                                [showSelectionControls]="false"
                                itemTemplate="itemTemplate"
                                [height]="attributeClassListHeight"
                                (onSelectionChanged)="attributeClassChanged($event)"
                                class="attribute-class-list">
                                <div *dxTemplate="let attributeClass of 'itemTemplate'">
                                    <div class="attribute-type-item">
                                        <dx-check-box [value]="attributeClass.selected" (onValueChanged)="attrClassCheckboxValueChanged($event, attributeClass)"></dx-check-box>
                                        <div class="display-name">
                                            <span>{{ attributeClass.friendlyName }}</span>
                                        </div>
                                    </div>
                                </div>
                            </dx-list>
                        </dxi-item>
    
                        <dxi-item>
                            <dxi-location [row]="0" [col]="1"></dxi-location>
    
                            <div class="dx-field-label-margin">Viewable by: {{ activeAttributeClass?.friendlyName }}</div>
                            <dx-list
                                [dataSource]="activeAttributeClass?.attributes"
                                keyExpr="id"
                                displayExpr="name"
                                [showSelectionControls]="true"
                                selectionMode="multiple"
                                [height]="attributeListHeight"
                                [(selectedItemKeys)]="activeAttributeClassSelectedAttributeIds"
                                (onSelectionChanged)="attributeChanged($event)"
                                class="attribute-list">
                            </dx-list>
                        </dxi-item>
                    </dx-responsive-box>
                </div>
            </dxi-item>
            <dxi-item title="Accounts">
                <div class="tab-content-wrapper">
                    <dx-responsive-box>
                        <dxi-row [ratio]="1"></dxi-row>
    
                        <dxi-col [ratio]="1"></dxi-col>
    
                        <dxi-item>
                            <dxi-location [row]="0" [col]="0"></dxi-location>
                            <dx-list
                                [dataSource]="sellers"
                                [(selectedItemKeys)]="selectedSellerIds"
                                keyExpr="id"
                                displayExpr="name"
                                selectionMode="multiple"
                                [showSelectionControls]="true"
                                [searchEnabled]="true"
                                searchExpr="name"
                                [height]="accountListHeight">
                            </dx-list>
                        </dxi-item>
                    </dx-responsive-box>
                </div>
            </dxi-item>
        </dx-tab-panel>
    </div>

    <div class="popup-button-wrapper">
        <dxi-item class="button-item">
            <dx-button class="popup-button dx-button-default" (click)="okClick()"> {{submitLabel}} </dx-button>
        </dxi-item>
        <dxi-item class="button-item">
            <dx-button class="popup-button" (click)="cancelClick()"> Cancel </dx-button>
        </dxi-item>
    </div>
</dx-popup>
