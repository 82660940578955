import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BuildingBlocksRoutingModule } from './building-blocks-routing.module';
import { BuildingBlocksComponent } from './building-blocks.component';
import { DxScrollViewModule,
    DxPopupModule,
    DxDiagramModule,
    DxResponsiveBoxModule,
    DxDataGridModule,
    DxSelectBoxModule,
    DxButtonModule,
    DxFormModule,
    DxSpeedDialActionModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxCheckBoxModule,
    DxResizableModule,
    DxBoxModule,
    DxDropDownBoxModule,
    DxFilterBuilderModule,
    DxTreeViewModule,
    DxNumberBoxModule,
    DxLoadPanelModule,
    DxDrawerModule,
    DxSwitchModule,
    DxContextMenuModule,
    DxTooltipModule,
    DxTabPanelModule,
    DxLoadIndicatorModule,
    DxDropDownButtonModule,
    DxAccordionModule,
    DxPopoverModule} from 'devextreme-angular';
import { MainLayoutComponent, MainLayoutModule } from '../../layouts/main-layout/main-layout.component';
import { CoreNumericInputModule } from 'src/app/shared/components/core-numeric-input/core-numeric-input.component';
import { CoreStringInputModule } from 'src/app/shared/components/core-string-input/core-string-input.component';
import { CoreMultiLineStringInputModule } from 'src/app/shared/components/core-multiline-string-input/core-multiline-string-input.component';
import { BbNavigationTreeComponent } from './bb-navigation-tree/bb-navigation-tree.component';
import { BbDiagramComponent } from './bb-diagram/bb-diagram.component';
import { BbPropertyPanelComponent } from './bb-property-panel/bb-property-panel.component';
import { BbHeaderComponent } from './bb-header/bb-header.component';
import { BbGridComponent } from './bb-grid/bb-grid.component';
import { CoreComponentModule } from 'src/app/shared/components/core-component.module';
import { FormulaBuilderModule } from 'src/app/shared/components/formula-builder/formula-builder.module';
import { BbFormulaBuilderComponent } from 'src/app/shared/components/bb-formula-builder/bb-formula-builder.component';
import { BbFormulaBuilderModule } from 'src/app/shared/components/bb-formula-builder/bb-formula-builder.module';
import { AgGridModule } from 'ag-grid-angular';
import { BbVersionSelectorComponent } from './bb-version-selector/bb-version-selector.component';
import { NbButtonModule, NbChatModule, NbIconModule, NbInputModule, NbLayoutModule, NbThemeModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { RuleBotChatModule } from 'src/app/shared/components/corebot/rulebot-chat/rulebot-chat.module';
import { BbCircularSourcesPopupComponent } from 'src/app/shared/components/bb-circular-sources-popup/bb-circular-sources-popup.component';

@NgModule({
    imports: [
        CommonModule,
        BuildingBlocksRoutingModule,
        DxBoxModule,
        DxResizableModule,
        DxDropDownBoxModule,
        DxScrollViewModule,
        DxResponsiveBoxModule,
        DxDataGridModule,
        DxPopupModule,
        DxTextBoxModule,
        DxNumberBoxModule,
        DxTextAreaModule,
        DxCheckBoxModule,
        DxSelectBoxModule,
        DxButtonModule,
        DxFormModule,
        MainLayoutModule,
        DxDiagramModule,
        DxFilterBuilderModule,
        DxTreeViewModule,
        CoreNumericInputModule,
        CoreStringInputModule,
        CoreMultiLineStringInputModule,
        DxLoadPanelModule,
        DxResponsiveBoxModule,
        CoreComponentModule,
        FormulaBuilderModule,
        BbFormulaBuilderModule,
        DxDrawerModule,
        DxSwitchModule,
        DxTooltipModule,
        DxContextMenuModule,
        DxLoadIndicatorModule,
        DxTabPanelModule,
        DxDropDownButtonModule,
        DxAccordionModule,
        AgGridModule,
        RuleBotChatModule
    ],
    declarations: [
        BuildingBlocksComponent,
        BbNavigationTreeComponent,
        BbDiagramComponent,
        BbPropertyPanelComponent,
        BbHeaderComponent,
        BbGridComponent,
        BbVersionSelectorComponent,
        BbCircularSourcesPopupComponent
    ],
    exports: [
        BuildingBlocksComponent,
    ]
})
export class BuildingBlocksModule {
}
