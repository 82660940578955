import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { toastrConstants } from '../../constants/constants';
import { CoreDropdownPopupProperties } from '../../models/core-dropdown-popup-properties';
import { CoreEventArguments } from '../../models/core-event-arguments';
import { CoreDropdownProperties } from '../../models/core-dropdown-properties';
import { CoreColumn } from '../../models/core-column';
import { PeriodService } from 'src/app/shared/services/period.service';
import { Period, PublishPeriod } from 'src/app/shared/models/period';
import { CoreFormFieldProperties } from '../../models/core-form-field-properties';
import { CoreInputEditorType, RowSelectionModes } from '../../constants/dev-extreme-enums';
import { GridProps } from '../../models/core-data-grid-properties';
import { SettingService } from '../../services/setting.service';
import { IdLongValueString } from '../../models/id-long-value-string';
import { ToastrService } from 'ngx-toastr';
import { CoreDropdownComponent } from '../core-dropdown/core-dropdown.component';
import { HelperService } from '../../services/helper.service';
import { Recurrence } from '../../models/recurrence';
import { RecurrenceService } from '../../services/recurrence.service';
import { forkJoin } from 'rxjs';
import { SeriesService } from '../../services/series.service';
import { Series } from '../../models/series';
import { Attribute } from '../../models/attribute';
import { AttributeClass } from '../../models/attribute-class';
import { CorePopupStep } from '../../models/core-popup-step';
import { CoreDynamicInputProperties } from '../../models/core-dynamic-input-properties';
import { CorePopupProperties } from '../../models/core-popup-properties';
import { CorePopupComponent } from '../core-popup/core-popup.component';
import { AccountAttributeClassService } from 'src/app/shared/services/account-attributeClass.service';
import { coreResponseCodes, settingClassIds } from '../../constants/enums';
import { CoreResponse } from '../../models/core-response';

@Component({
    selector: 'app-period-dropdown',
    templateUrl: './period-dropdown.component.html',
    styleUrls: ['./period-dropdown.component.scss'],
    providers: []
})

export class PeriodDropdownComponent implements OnInit, OnChanges {
    @Input() dropdownProps: CoreDropdownProperties[];
    @Input() selectedSeriesId: number;
    @Input() selectedPeriodId: number;
    @Input() selectedRecurrenceId: number;
    @Output() selectedPeriodIdChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() selectedRecurrenceIdChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() selectedSeriesIdChange: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('periodDropdown', { static: false }) periodDropdown: CoreDropdownComponent;
    @ViewChild('seriesDropdown', { static: false }) seriesDropdown: CoreDropdownComponent;
    @ViewChild('recurrenceDropdown', { static: false }) recurrenceDropdown: CoreDropdownComponent;
    @ViewChild('lockPublishPopup', { static: false }) lockPublishPopup: CorePopupComponent;

    showPeriod: boolean;
    showSeries: boolean;
    showRecurrence: boolean;
    periods: Period[];
    filteredPeriods: Period[];
    toastConsts = toastrConstants;
    attributeClasses: AttributeClass[];
    popupDefaultHeight: string = '200';
    popupDefaultWidth: string = '500';

    periodForPublishing: PublishPeriod;
    recurrences: Recurrence[];
    isReassignVersionsPopupVisible: boolean = false;

    addPeriodFormProperties: CoreFormFieldProperties[] = [
        new CoreFormFieldProperties().createAll('Recurrence', CoreInputEditorType.SelectBox, true, 2),
        new CoreFormFieldProperties().createAll('StartDate', CoreInputEditorType.DateBox, true, 1),
        new CoreFormFieldProperties().createAllWithCustomRules('EndDate', CoreInputEditorType.DateBox, true, [{ callbackFunction: this.addPeriodValidateEndDate, message: '', isReevaluated: true }], 1)
    ];

    periodPopupProps: CoreDropdownPopupProperties[] = [
        new CoreDropdownPopupProperties().setBodyMessage('delete',
            '<p><div class="one-line">Deleting a period <span class="color-red">CANNOT</span> be undone.</div><p>All imported records, processed records, logs, period references in segments,'
            + 'and data caches for this period will be deleted.<p> Close all other open windows before continuing.<p>Enter the following value below to proceed: yes',
            350,
            400,
            'yes'),
        new CoreDropdownPopupProperties().createWithForm('add', this.addPeriodFormProperties, 2, 280, 300)
    ];

    lockPublishPopupProps: CorePopupProperties = new CorePopupProperties().createMessageOnly(this.popupDefaultWidth, this.popupDefaultHeight, true, 'Lock/Publish');
    lockPublishPopupSteps: CorePopupStep[] = [];

    periodDropDownColumns: CoreColumn[] = [
        new CoreColumn('id', '', false),
        new CoreColumn('beginDate', 'Start Date', true, 'date', null, false, 'MM/dd/yyyy'),
        new CoreColumn('endDate', 'End Date', true, 'date', null, false, 'MM/dd/yyyy'),
        new CoreColumn('isLocked', 'Locked', true, 'boolean', null, false, null, null, null, null, null, null, null, null, null, 'Locked', 'Unlocked'),
        new CoreColumn('isPublished', 'Published', true, 'boolean', null, false, null, null, null, null, null, null, null, null, null, 'Published', 'Unpublished'),
    ];
    series: Series[];
    finalDropdownProps: CoreDropdownProperties;

    periodDeleteErrors: any = {
        FK_calculation_xaction: 'This period has calculation output referenced from another period. ',
        FK_container_period: 'This period is referenced as the begin or end date of a rule version. ',
        FK_etl_plan_version_period: 'This period is referenced as the begin or end date of an etl plan version. ',
    };
    deleteWithReassignPopupPropsTitle = 'Reassign Period References';
    deleteWithReassignPopupProps: CorePopupProperties = new CorePopupProperties().createMessageOnly('350', this.popupDefaultHeight, true, this.deleteWithReassignPopupPropsTitle);
    deleteWithReassignPopupSteps: CorePopupStep[] = [];

    constructor(private periodService: PeriodService,
        private toast: ToastrService,
        private settingService: SettingService,
        private recurrenceService: RecurrenceService,
        private seriesService: SeriesService,
        private helperService: HelperService,
        private attributeClassService: AccountAttributeClassService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dropdownProps?.firstChange) {
            this.showPeriod = !(this.dropdownProps['period'] === undefined);
            this.showRecurrence = !(this.dropdownProps['recurrence'] === undefined);
            this.showSeries = !(this.dropdownProps['series'] === undefined);
            const periodDropDownGridProps: GridProps = new GridProps('periodDropDownGrid', null, false, true, false, false)
                .setToSingleRowSelection();
            periodDropDownGridProps.disableSearch(true);
            this.finalDropdownProps = this.dropdownProps['period'].convertToTable(periodDropDownGridProps, 450, 300);

            if(this.finalDropdownProps.isLockShown){
                this.finalDropdownProps = this.finalDropdownProps.addLockButton(this.openLockPeriodDialogue.bind(this));
            }

            if (this.finalDropdownProps.isPublishShown) {
                this.finalDropdownProps = this.finalDropdownProps.addPublishButton(this.openPublishPeriodDialogue.bind(this));
            }
        }
        if(changes.selectedRecurrenceId){
            this.onRecurrenceChanged(changes.selectedRecurrenceId.currentValue);
        }
    }

    ngOnInit() {
        forkJoin([this.seriesService.getAllSeries(),
            this.periodService.getPeriods(),
            this.recurrenceService.GetAllRecurrences(),
            this.settingService.getSettingsByClassIds([
                settingClassIds.InternalMRUSeries,
                settingClassIds.InternalMRUFromPeriod,
            ])
        ]).subscribe(([series, periods, recurrences, settings]) => {
            this.series = series.filter(x => !x.isDeleted);
            this.periods = periods.filter(x => !x.isDeleted)
                .sort((a, b) => a.beginDate > b.beginDate ? 1 : a.beginDate < b.beginDate ? -1 : 0);

            this.periods.map(x => {
                x['dateRange'] = this.helperService.createDateRangeString(x.beginDate, x.endDate);
            });

            this.recurrences = recurrences.filter(x => x.duration !== 0).sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0);

            const savedPeriodId = Number(settings.find(x => x.settingClassId === settingClassIds.InternalMRUFromPeriod)?.value);
            const savedSeriesId = Number(settings.find(x => x.settingClassId === settingClassIds.InternalMRUSeries)?.value);
            let periodId: number;
            if (this.periods.some(p => p.id === savedPeriodId)) {
                periodId = savedPeriodId;
            } else {
                const periodsInRecurrence = this.periods.filter(p => this.recurrences.some(r => r.id === p.recurrenceId));
                periodId = periodsInRecurrence[periodsInRecurrence.length - 1]?.id;
            }
            const seriesId = savedSeriesId ?? this.series[0].id;

            this.selectedSeriesId = this.series.filter(x => x.id === seriesId).length === 1
                ? seriesId
                : this.series.filter(x => x.name === 'Main').length === 1
                    ? this.series.filter(x => x.name === 'Main')[0].id
                    : this.series[0].id;
            this.selectedRecurrenceId = this.periods.filter(x => x.id === periodId).length === 1
                ? this.periods.find(x => x.id === periodId).recurrenceId
                : this.recurrences[0]?.id;

            const chosenPeriod = this.helperService.isNullOrUndefined(this.selectedPeriodId) || this.selectedPeriodId === -1 ? periodId : this.selectedPeriodId;
            this.onPeriodChanged(chosenPeriod, true);

            this.populateDefaultAddPeriodFormData();
        });

        this.attributeClassService.getFatAttributeClassesByTextExcluded(false, false).subscribe(attributeClasses => {
            this.attributeClasses = attributeClasses;
            this.attributeClasses.sort((a, b) => a.friendlyName.toLowerCase() > b.friendlyName.toLowerCase() ? 1
                : (a.friendlyName.toLowerCase() < b.friendlyName.toLowerCase() ? -1 : 0));
            this.attributeClasses.forEach(x => x.attributes = x.attributes.sort((a, b) => a.name === 'unassigned' ? -1 : b.name === 'unassigned' ? 1 : a.name.localeCompare(b.name)));
        });
    }

    onPeriodChanged(value: any, isInit: boolean = false): void {
        this.filteredPeriods = this.periods.filter(x => x.recurrenceId === this.selectedRecurrenceId);
        const periodId = value ?? this.filteredPeriods[this.filteredPeriods.length - 1]?.id;
        this.selectedPeriodId = periodId;
        this.selectedRecurrenceIdChange.emit(this.selectedRecurrenceId);
        this.selectedSeriesIdChange.emit(this.selectedSeriesId);
        this.selectedPeriodIdChange.emit(this.selectedPeriodId);
        if (periodId !== null && !isInit) {
            this.settingService.upsertUserSettingsByClassIdBulk([new IdLongValueString(settingClassIds.InternalMRUFromPeriod, periodId + '')])
                .subscribe();
        }
        this.updateDynamicButtons();
    }

    updateDynamicButtons(): void {
        if (this.dropdownProps['period']?.dynamicButtons.length) {
            const period = this.periods.find(x => x.id === this.selectedPeriodId);
            const publishButtonClasses = document.getElementById('publish-button').classList;
            const lockButtonClasses = document.getElementById('lock-button').classList;

            if ((period?.isLocked ?? false) !== lockButtonClasses.contains('active')) {
                lockButtonClasses.toggle('active');
            }
            if ((period?.isPublished ?? false) !== publishButtonClasses.contains('active')) {
                publishButtonClasses.toggle('active');
            }
        }
    }

    addPeriod(e: CoreEventArguments) {
        const newPeriod = new Period();
        // API stores dates in UTC in database and converts dates to UTC upon receipt - converting to UTC prevents time change
        newPeriod.beginDate = this.helperService.getUTCMidnight(new Date(e.component.StartDate));
        newPeriod.endDate = this.helperService.getUTCEndOfDay(new Date(e.component.EndDate));
        newPeriod.recurrenceId = this.recurrences.find(x => x.name === e.component.Recurrence).id;

        // date comparisons won't work once converted to UTC, use values returned from component
        const duplicatePeriods = this.periods.filter(x =>
            (x.recurrenceId === newPeriod.recurrenceId
                && this.helperService.getDateString(x.beginDate) === this.helperService.getDateString(e.component.StartDate)
                && this.helperService.getDateString(x.endDate) === this.helperService.getDateString(e.component.EndDate))
        );

        if (duplicatePeriods.length > 0) {
            this.toast.error(this.toastConsts.duplicatePeriod);
        } else {
            this.periodService.insertPeriodFromProcessing(newPeriod).subscribe(ourPeriod => {
                ourPeriod['dateRange'] = this.helperService.createDateRangeString(ourPeriod.beginDate, ourPeriod.endDate);
                this.periods.push(ourPeriod);
                this.recurrenceService.getRecurrenceById(ourPeriod.recurrenceId).subscribe(updatedRecurrence => {
                    this.recurrences[this.recurrences.findIndex(x => x.id === ourPeriod.recurrenceId)] = updatedRecurrence;
                    if (this.selectedRecurrenceId !== ourPeriod.recurrenceId) {
                        this.onRecurrenceChanged(ourPeriod.recurrenceId);
                    }

                    // set dropdown to new period
                    this.onPeriodChanged(ourPeriod.id);
                    // adding a period updates the recurrence and we need to get the new next begin date and end date in the add form
                    this.populateDefaultAddPeriodFormData();
                    this.toast.success('Period created');
                });
            },
            error => {
                this.toast.error('Period creation failed');
            });
        }
    }

    onRecurrenceChanged(value: any): void {
        this.selectedRecurrenceId = value;
        this.populateDefaultAddPeriodFormData();
        this.selectedRecurrenceIdChange.emit(value);
        if (this.periods.find(p => p.id === this.selectedPeriodId)?.recurrenceId !== value) {
            this.onPeriodChanged(null);
        }
    }

    deletePeriod(e: CoreEventArguments) {
        const periodId = e.component.selectedItem.value;
        if (this.periods.find(x => x.id === periodId).isLocked) {
            this.toast.error(this.toastConsts.lockedPeriodDelete);
        } else {
            this.periodService.deletePeriod(periodId).subscribe(response => {
                this.handleDeletePeriodResponse(response);
            });
        }
    }

    handleDeletePeriodResponse(response: CoreResponse<number>) {
        if (response.responseCode === coreResponseCodes.Success) {
            this.periods.splice(this.periods.findIndex(x => x.id === this.selectedPeriodId), 1);
            this.onPeriodChanged(null);
            this.populateDefaultAddPeriodFormData();
            this.toast.success('Period deleted');
        } else {
            let msg = '';
            let isVersionIssue = false;
            let errorIndex = 0;
            for (const [keyword, warning] of Object.entries(this.periodDeleteErrors)) {
                if (response.message.includes(keyword)) {
                    msg += warning;
                    isVersionIssue = errorIndex > 0;
                }
                errorIndex++;
            }
            msg = msg ? 'Delete failed: ' + msg : response.message;
            this.toast.error(msg);
            if (isVersionIssue) {
                this.openPeriodReassignPopup();
            }
        }
    }

    openPeriodReassignPopup() {
        const eligibleReplacementPeriods = this.filteredPeriods.filter(p => p.id !== this.selectedPeriodId && !p.isLocked);
        this.deleteWithReassignPopupSteps = [
            new CorePopupStep(
                'Rule and ETL plan versions need to have their start or end periods re-mapped before this period can be deleted. Please select a replacement period:<br><br>',
                (e: CorePopupStep) => this.deletePeriodAndReassignVersions(e.itemsProps[0].selectedValue),
                null,
                this,
            ).appendItemProp(new CoreDynamicInputProperties('', true, eligibleReplacementPeriods, 'dateRange', 'id', 0, false).createForSelectBox())
        ];
        this.deleteWithReassignPopupProps.title = this.deleteWithReassignPopupPropsTitle;
        this.deleteWithReassignPopupProps.visible = true;
    }

    deletePeriodAndReassignVersions(newPeriodId: number) {
        this.periodService.deletePeriodAndReassignVersions(this.selectedPeriodId, newPeriodId).subscribe(response => {
            this.handleDeletePeriodResponse(response);
        });
    }

    addPeriodValidateEndDate(e: any): boolean  {
        // Helper Service will not exist in this context as this method is used as a callback
        const hs = new HelperService();
        let isValid = true;
        if (this['addForm'] && this['addForm'].formData) {
            isValid = (hs.dateToMidnight(this['addForm'].formData.StartDate) <= hs.dateToMidnight(e.value));
        }

        return isValid;
    }

    populateDefaultAddPeriodFormData() {
        this.addPeriodFormProperties.forEach(prop => {
            if (prop.label === 'Recurrence') {
                prop.dataset = this.recurrences.map(x => x.name);
            }
        });

        const rec = this.selectedRecurrenceId ? this.recurrences.find(x => x.id === this.selectedRecurrenceId) : this.recurrences[0];
        const addPeriodFormDefaults = {
            Recurrence: rec.name,
            StartDate: rec.nextBeginDate,
            EndDate: this.helperService.addToDayByUomId(rec.dateUomId, new Date(rec.nextBeginDate), rec.duration)
        };

        this.periodPopupProps.filter(x => x.buttonType === 'add')[0].defaultFormValues = addPeriodFormDefaults;
        if (this.periodDropdown) {
            this.periodDropdown.setAddFormDefaults(addPeriodFormDefaults);
        }
    }

    onSeriesChanged(value: any): void {
        this.selectedSeriesId = value;
        this.selectedSeriesIdChange.emit(value);
        this.selectedPeriodIdChange.emit(this.selectedPeriodId);
        this.settingService.upsertUserSettingsByClassIdBulk([new IdLongValueString(settingClassIds.InternalMRUSeries, value + '')])
            .subscribe();
    }

    openLockPeriodDialogue(event: any): void {
        if (this.periods.find(x => x.id === this.selectedPeriodId).isLocked) {
            this.toast.warning('Period is already locked');
        } else {
            this.changePopupPurpose('Lock Period', '225', '325');
            const message: string = '<p><div class="one-line">Locking a period <span class="color-red">CANNOT</span> be undone.</div><p>Enter the following value below to proceed: yes';
            const lockingStep = new CorePopupStep(message, (e: CorePopupStep) => { this.lockPeriod(this.selectedPeriodId); }, null, this, 'Lock')
                .createPasswordProtectedStep('yes', 'Wait!');

            this.lockPublishPopupSteps.push(lockingStep);
            this.lockPublishPopupProps.visible = true;
            this.lockPublishPopup.props.closeOnOutsideClick = true;
        }
    }

    lockPeriod(selectedPeriodId: number): void {
        this.periodService.lockPeriod(selectedPeriodId, this.selectedSeriesId).subscribe(response => {
            if (response.responseCode === coreResponseCodes.Success) {
                this.periods[this.periods.findIndex(x => x.id === selectedPeriodId)].isLocked = true;
                this.toast.success('Period locked');
                this.periodService.setPeriodLockedEvent();
            } else {
                this.toast.error('Period could not be locked');
            }
            this.updateDynamicButtons();
        });
    }

    openPublishPeriodDialogue(event: any): void {
        this.changePopupPurpose('Publish Period', '450', '550');
        const listHeight = '250';
        this.periodForPublishing = new PublishPeriod(this.periods.find(x => x.id === this.selectedPeriodId));
        if (!this.periodForPublishing.isPublished) {
            this.periodForPublishing.periodAttributeIds = [].concat.apply([], this.attributeClasses.map(x => x.attributes.map(a => a.id)));
        }
        const message: string = `<div class="one-line">Confirm Attributes for Period: ${this.periodForPublishing['dateRange']}</div><p>Select the appropriate positions below:</p>`;
        const selectedAttributeClassIds = this.periodForPublishing.periodAttributeIds
            ? this.attributeClasses.filter(x => x.attributes.some(a => this.periodForPublishing.periodAttributeIds.some(sai => sai === a.id))).map(x => x.id)
            : null;
        const partiallySelectedAttributeClassIds = this.getPartiallySelectedClassIds();

        const publishStep = new CorePopupStep(message, this.publishPeriod, this.unpublishPeriod, this, 'Publish', 'Unpublish', false, false, true)
            .appendItemProp(new CoreDynamicInputProperties('Attribute Type', false, this.attributeClasses, 'friendlyName', 'id', null, false)
                .createForList(listHeight, true, RowSelectionModes.Multiple, selectedAttributeClassIds, this, this.publishPeriodAttributeClassSelectionChanged, true, true,
                  partiallySelectedAttributeClassIds, true)
                    .addUniqueId('attribute-class-list-period-dropdown'));

        const currAttrClass: AttributeClass = selectedAttributeClassIds?.length > 0
            ? publishStep.itemsProps[0].dataset.find(x => x.id === selectedAttributeClassIds[0])
            : publishStep.itemsProps[0].dataset[0];

        const selectedAttributeIds = this.getSelectedAttributeIds(currAttrClass);
        publishStep.appendItemProp(new CoreDynamicInputProperties(this.getPublishPeriodAttributeLabel(currAttrClass), false, currAttrClass?.attributes, 'name', 'id', null, false)
            .createForList(listHeight, true, RowSelectionModes.Multiple, selectedAttributeIds, this, this.publishPeriodAttributeSelectionChanged, false, false)
                .addUniqueId('attribute-list-period-dropdown'));

        this.lockPublishPopupSteps.push(publishStep);
        this.lockPublishPopupProps.visible = true;
        setTimeout(() => {
            this.updatePartiallySelectedClassIds();
        }, 100);
    }

    publishPeriodAttributeClassSelectionChanged(e: CoreEventArguments, index: number): void {
        if (e.event?.type === 'noSelectionChange') {
            const itemProp = this.lockPublishPopupSteps[0].itemsProps[1];
            itemProp.dataset = e.event?.item?.attributes ? e.event.item.attributes : [];
            itemProp.selectedItemKeys = this.getSelectedAttributeIds(e.event.item);
            itemProp.label = this.getPublishPeriodAttributeLabel(e.event.item);
        } else {
            if (e.event.addedItems.length === 1) {
                if (this.lockPublishPopupSteps[0].itemsProps[1].selectedItemKeys.length === 0
                    || !e.event.addedItems[0].attributes.find(x => x.id === this.lockPublishPopupSteps[0].itemsProps[1].selectedItemKeys[0])) {

                    if (this.lockPublishPopupSteps[0].itemsProps[1].selectedItemKeys.length === 0) {
                        this.lockPublishPopupSteps[0].itemsProps[1].selectedItemKeys = e.event.addedItems[0].attributes.map(x => x.id);
                    }

                    e.event.addedItems[0].attributes.forEach(attribute => {
                        if (this.periodForPublishing.periodAttributeIds.findIndex(x => x === attribute.id) === -1) {
                            this.periodForPublishing.periodAttributeIds.push(attribute.id);
                        }
                    });
                }
            } else if (e.event.removedItems.length === 1) {
                if (this.lockPublishPopupSteps[0].itemsProps[1].selectedItemKeys.length > 0) {
                    if (e.event.removedItems[0].attributes.find(x => x.id === this.lockPublishPopupSteps[0].itemsProps[1].selectedItemKeys[0])) {
                        this.lockPublishPopupSteps[0].itemsProps[1].selectedItemKeys = [];
                    }
                }

                e.event.removedItems[0].attributes.forEach(attribute => {
                    const idIndex: number = this.periodForPublishing.periodAttributeIds.findIndex(id => id === attribute.id);
                    if (idIndex > -1) {
                        this.periodForPublishing.periodAttributeIds.splice(idIndex, 1);
                    }
                });
            }

            this.updatePartiallySelectedClassIds();
        }
    }

    publishPeriodAttributeSelectionChanged(e: CoreEventArguments, index: number): void {
        if (e.event?.addedItems?.length === 1) {
            const added: Attribute = e.event.addedItems[0];
            if (this.periodForPublishing.periodAttributeIds.findIndex(x => x === added.id) === -1) {
                this.periodForPublishing.periodAttributeIds.push(added.id);
            }

            if (this.lockPublishPopupSteps[0].itemsProps[1].selectedItemKeys.findIndex(x => x === added.id) === -1) {
                this.lockPublishPopupSteps[0].itemsProps[1].selectedItemKeys.push(added.id);
            }

            const attributeClass: AttributeClass = this.attributeClasses.find(x => x.id === added.attributeClassId);
            if (this.lockPublishPopupSteps[0].itemsProps[1].selectedItemKeys.length > 0
                && this.lockPublishPopupSteps[0].itemsProps[0].selectedItemKeys.findIndex(x => x === added.attributeClassId) === -1) {
                    this.lockPublishPopupSteps[0].itemsProps[0].selectedItemKeys = this.lockPublishPopupSteps[0].itemsProps[0].selectedItemKeys.concat(attributeClass.id);
            }
        } else if (e.event?.removedItems?.length === 1 && this.lockPublishPopupSteps[0].itemsProps[1].dataset.find(x => x.id === e.event?.removedItems[0].id)) {
            const removed: Attribute = e.event.removedItems[0];
            const periodIndexToRemove: number = this.periodForPublishing.periodAttributeIds.findIndex(x => x === removed.id);
            const selectedItemIndexToRemove: number = this.lockPublishPopupSteps[0].itemsProps[1].selectedItemKeys.findIndex(x => x === removed.id);
            if (periodIndexToRemove > -1) {
                this.periodForPublishing.periodAttributeIds.splice(periodIndexToRemove, 1);
            }

            if (selectedItemIndexToRemove > -1) {
                this.lockPublishPopupSteps[0].itemsProps[1].selectedItemKeys.splice(selectedItemIndexToRemove, 1);
            }

            if (this.lockPublishPopupSteps[0].itemsProps[1].selectedItemKeys.length === 0) {
                const attrClassIndex: number = this.lockPublishPopupSteps[0].itemsProps[0].selectedItemKeys.findIndex(x => x === removed.attributeClassId);

                if (attrClassIndex > -1) {
                    this.lockPublishPopupSteps[0].itemsProps[0].selectedItemKeys.splice(attrClassIndex, 1);
                }
            }
        }

        this.updatePartiallySelectedClassIds();
    }

    getPublishPeriodAttributeLabel(attributeClass: AttributeClass): string {
        return `Viewable by: ${attributeClass?.friendlyName ? attributeClass?.friendlyName: ''}`;
    }

    getSelectedAttributeIds(attributeClass: AttributeClass): number[] {
        const result: number[] = [];
        if (this.periodForPublishing?.periodAttributeIds) {
            if (attributeClass.attributes) {
                attributeClass.attributes.forEach(attr => {
                    if (this.periodForPublishing.periodAttributeIds.find(x => x === attr.id)) {
                        result.push(attr.id);
                    }
                });
            }
        }

        return result;
    }

    publishPeriod(e: CorePopupStep): void {
        this.periodForPublishing.isPublished = true;
        this.periodService.publishPeriod(this.periodForPublishing).subscribe(response => {
            if (response.responseCode === coreResponseCodes.Success) {
                const selectedPeriod = this.periods[this.periods.findIndex(x => x.id === this.selectedPeriodId)];
                selectedPeriod.isPublished = true;
                selectedPeriod.attributes = response.result.attributes;
                this.toast.success('Period Published');
            } else {
                this.toast.error('Error, period could not be published and no updates were made');
            }
            this.updateDynamicButtons();
        });
    }

    unpublishPeriod(e: CorePopupStep): void {
        this.periodForPublishing.isPublished = false;
        this.periodService.publishPeriod(this.periodForPublishing).subscribe(response => {
            if (response.responseCode === coreResponseCodes.Success) {
                const selectedPeriod = this.periods[this.periods.findIndex(x => x.id === this.selectedPeriodId)];
                selectedPeriod.isPublished = false;
                this.toast.success('Period Unpublished');
            } else {
                this.toast.error('Error, period could not be unpublished and no updates were made');
            }
            this.updateDynamicButtons();
        });
    }

    changePopupPurpose(title: string, height: string = this.popupDefaultHeight, width: string = this.popupDefaultWidth): void {
        this.lockPublishPopupProps.title = title;
        this.lockPublishPopupSteps = [];
        this.lockPublishPopup.changePopupSize(height, width);
    }

    getSelectedPeriodDateRangeStr(): string {
        return this.periods.find(x => x.id === this.selectedPeriodId)?.['dateRange'] ?? '';
    }

    updatePartiallySelectedClassIds(): void {
        this.lockPublishPopupSteps[0].itemsProps[0].partiallySelectedItemKeys = this.getPartiallySelectedClassIds();
    }

    getPartiallySelectedClassIds(): number[] {
        return this.attributeClasses.filter(x => x.attributes.some(a => this.periodForPublishing.periodAttributeIds.some(id => id === a.id))
          && x.attributes.some(a => !this.periodForPublishing.periodAttributeIds.includes(a.id)))
            .map(x => x.id);
    }
}
