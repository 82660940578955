import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class TenantGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) { }

    canActivate(next: ActivatedRouteSnapshot) {
        const tenantString = this.authService.getUserFromToken().split('\\');
        const tenant = (tenantString[0].toLowerCase());

        if (tenant === next.data['tenant'] || tenant.startsWith('coretest')) {
            return true;
        }

        this.router.navigate(['/home']);
        return false;
    }
}
