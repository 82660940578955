import { HelperService } from '../services/helper.service';

export class Period {
    id: number;
    beginDate: Date;
    endDate: Date;
    workflowDirty: boolean;
    recurrenceId: number;
    isLocked: boolean;
    isDeleted: boolean;
    igDirty: boolean;
    isPublished: boolean;
    attributes: string;

    createFromPublishPeriod(publishedPeriod: PublishPeriod): Period {
        this.id = publishedPeriod.id;
        this.beginDate = publishedPeriod.beginDate;
        this.endDate = publishedPeriod.endDate;
        this.workflowDirty = publishedPeriod.workflowDirty;
        this.recurrenceId = publishedPeriod.recurrenceId;
        this.isLocked = publishedPeriod.isLocked;
        this.isDeleted = publishedPeriod.isDeleted;
        this.igDirty = publishedPeriod.igDirty;
        this.isPublished = publishedPeriod.isPublished;
        this.attributes = publishedPeriod.periodAttributeIds.length > 0 ? '' : null;
        publishedPeriod.periodAttributeIds.sort((a, b) => a > b ? 1 : (a < b ? -1 : 0)).forEach(id => {
            this.attributes += '[' + id + ']';
        });

        return this;
    }
}

export class PublishPeriod extends Period {
    periodAttributeIds: number[];
    dateRange: string;

    constructor(period: Period) {
        super();
        const helperService = new HelperService();
        this.id = period.id;
        this.beginDate = period.beginDate;
        this.endDate = period.endDate;
        this.workflowDirty = period.workflowDirty;
        this.recurrenceId = period.recurrenceId;
        this.isLocked = period.isLocked;
        this.isDeleted = period.isDeleted;
        this.igDirty = period.igDirty;
        this.isPublished = period.isPublished;
        this.attributes = period.attributes;
        this.periodAttributeIds = period.attributes
            ? (helperService.removeBracketsFromString(period.attributes, true) as string[]).map(x => parseInt(x, 10))
            : [];
        this.dateRange = period['dateRange'];
    }
}
