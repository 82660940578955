import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule } from './layouts/side-nav-outer-toolbar/side-nav-outer-toolbar.component';
import { SingleCardModule } from './layouts/single-card/single-card.component';
import { ErrorInterceptor } from './shared/interceptors/auth-error-handler';
import { AuthInterceptor } from './shared/interceptors/auth-request';
import { AppInfoService } from './shared/services/app-info.service';
import { AuthService } from './shared/services/auth.service';
import { DashboardService } from './shared/services/dashboard.service';
import { HistSellerService } from './shared/services/hist-seller.service';
import { ReportService } from './shared/services/report.service';
import { SellerService } from './shared/services/seller.service';
import { SellerImportService } from './shared/services/seller-import.service';
import { ScreenService } from './shared/services/screen.service';
import { RetrievessoModule } from './SSO/retrievesso/retrievesso.module';
import { InitiatessoModule } from './SSO/initiatesso/initiatesso.module';
import { MainLayoutModule } from './layouts/main-layout/main-layout.component';
import { AccountManagementModule } from './pages/account-management/account-management.module';
import { ProcessingModule } from './pages/processing/processing.module';
import { AccountFactorService } from './shared/services/account-factor.service';
import { DatasourceService } from './shared/services/datasource.service';
import { HelperService } from './shared/services/helper.service';
import { EmailService } from './shared/services/email.service';
import { AccountAttributeClassService } from './shared/services/account-attributeClass.service';
import { DisputeManagementService } from './shared/services/dispute-management.service';
import { RecurrenceService } from './shared/services/recurrence.service';
import { SeriesService } from './shared/services/series.service';
import { AltmanService } from './shared/services/altman.service';
import { PeriodService } from './shared/services/period.service';
import { FieldService } from './shared/services/field.service';
import { XactionService } from './shared/services/xaction.service';
import { ABCService } from './shared/services/abc.service';
import { BuildingBlocksModule } from './pages/building-blocks/building-blocks.module';
import { BuildingBlocksService } from './shared/services/building-blocks.service';
import { ProcessingService } from './shared/services/processing.service';
import { CalculationService } from './shared/services/calculation.service';
import { AppElementsService } from './shared/services/app-element.service';
import { FileProcessingService } from './shared/services/file-processing.service';
import { DatasourceMappingService } from './shared/services/datasource-mapping.service';
import { TagGroupService } from './shared/services/tag-group.service';
import { CustomPageService } from './shared/services/custom-page.service';
import { FormulaBuilderService } from './shared/services/formula-builder.service';
import { AnalyticService } from './shared/services/analytic.service';
import { LinkService } from './shared/services/links.service';
import { DistributionListService } from './shared/services/distribution-list.service';
import { MessageService } from './shared/services/message.service';
import { ProcessLogViewerModule } from './pages/process-log-viewer/process-log-viewer.module';
import { BuildingBlockHelperService } from './pages/building-blocks/building-block-helper.service';
import { RecordDataViewerModule } from './pages/record-data-viewer/record-data-viewer.module';
import { ImportFieldService } from './shared/services/import-field.service';
import { CondFormatService } from './shared/services/cond-format.service';
import { IntegrationClassService } from './shared/services/integration-class.service';
import { IntegrationValueService } from './shared/services/integration-value.service';
import { TrelloService } from './shared/services/trello.service';
import { CallbackModule } from './OAuth/callback/callback.module';
import { OauthService } from './shared/services/oauth.service';
import { IntegrationSourceService } from './shared/services/integration-source-service';
import { IntegrationMappingFieldService } from './shared/services/integration-mapping-field-service';
import { ServerSideDataProcessingService } from './shared/services/server-side-data-processing.service';
import { SAMLService } from './shared/services/saml.service';
import { LogoutService } from './shared/services/logout.service';
import { SiteThemeService } from './shared/services/site-theme.service';
import { BuildDiffService } from './shared/services/build-diff.service';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        SideNavOuterToolbarModule,
        SingleCardModule,
        ToastrModule.forRoot({ positionClass: 'toast-bottom-center', maxOpened: 3, autoDismiss: true }),
        BrowserAnimationsModule,
        InitiatessoModule,
        RetrievessoModule,
        AccountManagementModule,
        ProcessLogViewerModule,
        ProcessingModule,
        RetrievessoModule,
        MainLayoutModule,
        BuildingBlocksModule,
        MainLayoutModule,
        RecordDataViewerModule,
        CallbackModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        AuthService,
        ScreenService,
        AppInfoService,
        SellerService,
        DashboardService,
        ReportService,
        AccountAttributeClassService,
        HistSellerService,
        AccountFactorService,
        DatasourceService,
        IntegrationClassService,
        IntegrationValueService,
        HelperService,
        DisputeManagementService,
        RecurrenceService,
        SeriesService,
        AltmanService,
        EmailService,
        PeriodService,
        BuildingBlocksService,
        ProcessingService,
        FileProcessingService,
        FieldService,
        SellerService,
        SellerImportService,
        XactionService,
        CondFormatService,
        CalculationService,
        AppElementsService,
        DatasourceMappingService,
        ABCService,
        TagGroupService,
        CustomPageService,
        FormulaBuilderService,
        AnalyticService,
        LinkService,
        DistributionListService,
        MessageService,
        BuildingBlockHelperService,
        ImportFieldService,
        TrelloService,
        ImportFieldService,
        OauthService,
        IntegrationSourceService,
        IntegrationMappingFieldService,
        ServerSideDataProcessingService,
        IntegrationMappingFieldService,
        SAMLService,
        LogoutService,
        SiteThemeService,
        BuildDiffService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
