import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { RowSelectionModes } from '../../constants/dev-extreme-enums';
import { Attribute } from '../../models/attribute';
import { AttributeClass } from '../../models/attribute-class';
import { CoreDynamicInputProperties } from '../../models/core-dynamic-input-properties';
import { CoreEventArguments } from '../../models/core-event-arguments';
import { CorePopupProperties } from '../../models/core-popup-properties';
import { CorePopupStep } from '../../models/core-popup-step';
import { AccountAttributeClassService } from '../../services/account-attributeClass.service';
import { HelperService } from '../../services/helper.service';
import { Seller } from '../../models/seller';
import { SellerService } from '../../services/seller.service';

@Component({
  selector: 'app-attribute-permissions',
  templateUrl: './attribute-permissions.component.html',
  styleUrls: ['./attribute-permissions.component.scss']
})
export class AttributePermissionsComponent implements OnInit, OnChanges {
    @Input() attributeStr!: string;
    @Input() uniqueId: string;
    @Input() isPopupVisible!: boolean;
    @Input() selectedSellerIds: number[];
    @Input() title: string = 'Edit Permissions';
    @Input() submitLabel: string = 'Save';
    @Output() attributeStrChange = new EventEmitter<string>();
    @Output() isPopupVisibleChange = new EventEmitter<boolean>();
    @Output() onSubmit = new EventEmitter();
    @Output() selectedSellerIdsChange = new EventEmitter<number[]>();

    attributeClasses: AttributeClass[];
    activeAttributeClass: AttributeClass;
    allAttributeIds: number[];
    activeAttributeClassSelectedAttributeIds: number[];
    selectedAttributeIds: number[];
    selectedAttributeClassIds: number[];
    partiallySelectedAttributeClassIds: number[];
    selectAllCheckboxValue: boolean;
    sellers: Seller[];
    popupHeight: number = 450;
    popupWidth: number = 600;
    attributeClassListHeightOffset: number = 220;
    attributeListHeightOffset: number = 180;
    accountListHeightOffset: number = 175;
    attributeClassListHeight: number;
    attributeListHeight: number;
    accountListHeight: number;

	constructor(
        private attributeClassService: AccountAttributeClassService,
        private sellerService: SellerService,
        private helperService: HelperService,
        private cd: ChangeDetectorRef) {

    }

	ngOnInit() {
        this.attributeClassService.getFatAttributeClassesByTextExcluded(false, false).subscribe(attributeClasses => {
            this.attributeClasses = attributeClasses.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName));
            this.attributeClasses.forEach(x => x.attributes = x.attributes.sort((a, b) => a.name === 'unassigned' ? -1 : b.name === 'unassigned' ? 1 : a.name.localeCompare(b.name)));
            if (!this.attributeStr) {
                this.selectedAttributeIds = [].concat(...this.attributeClasses.filter(x => x.name !== 'job_title').map(x => x.attributes.map(a => a.id)));
                this.buildAttributeString();
            }
            this.allAttributeIds = attributeClasses.reduce((a, ac) => [ ...a, ...ac.attributes ], []).map(x => x.id);
            this.activeAttributeClass = this.attributeClasses[0];
        });

        this.sellerService.getAllSellersLookup(true).subscribe(sellers => {
            this.sellers = sellers;
            this.sellers.sort((a, b) => a.name.localeCompare(b.name));
        });

        this.resizePopupContent(null);
	}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.attributesStr !== undefined) {
            this.getSelectedAttributeIdsFromStr();
        }
        if (changes.isPopupVisible?.currentValue) {
            this.openPopup();
        }
    }

    openPopup(): void {
        this.getSelectedAttributeIdsFromStr();
        this.updateSelectedAttributeIdsOfActiveClass();
        this.updateSelectedAttributeClassIds();
        this.isPopupVisible = true;
    }

    attributeClassChanged(event: any): void {
        this.activeAttributeClass = event.addedItems[0];
        this.updateSelectedAttributeIdsOfActiveClass();
        this.cd.detectChanges();
    }

    attributeChanged(event: any): void {
        const itemId = event.addedItems.concat(event.removedItems)[0].id;

        if (event.addedItems?.length && !this.selectedAttributeIds.includes(itemId)) {
            this.selectedAttributeIds.push(itemId);
        } else if (event.removedItems?.length && this.activeAttributeClass.attributes.map(x => x.id).includes(itemId) && !this.activeAttributeClassSelectedAttributeIds.includes(itemId)) {
            this.selectedAttributeIds = this.selectedAttributeIds.filter(x => x !== itemId);
        }

        this.updateSelectedAttributeClassIds();

        this.cd.detectChanges();
    }

    getSelectedAttributeIdsFromStr(): number[] {
        return this.selectedAttributeIds = this.helperService.parseBracketedIdString(this.attributeStr);
    }

    updateSelectedAttributeClassIds(): void {
        this.selectedAttributeClassIds = this.attributeClasses
            .filter(x => x.attributes?.every(a => this.selectedAttributeIds?.some(id => id === a.id)))
            .map(x => x.id);
        this.partiallySelectedAttributeClassIds = this.attributeClasses
            .filter(x => x.attributes.some(a => this.selectedAttributeIds?.some(id => id === a.id)) && x.attributes.some(a => !this.selectedAttributeIds?.includes(a.id)))
            .map(x => x.id);
        this.updateAttributeClassCheckboxValues();
        this.updateSelectAllCheckboxValue();
    }

    updateSelectedAttributeIdsOfActiveClass(): void {
        this.activeAttributeClassSelectedAttributeIds = this.activeAttributeClass.attributes.map(x => x.id).filter(a => this.selectedAttributeIds.some(b => b === a));
    }

    closePopup(): void {
        this.isPopupVisible = false;
    }

    onHidden(): void {
        this.isPopupVisibleChange.emit(false);
    }

    buildAttributeString() {
        this.attributeStr = this.selectedAttributeIds.map(id => '[' + id + ']').join('');
        this.attributeStrChange.emit(this.attributeStr);
    }

    okClick(): void {
        this.buildAttributeString();
        this.selectedSellerIdsChange.emit(this.selectedSellerIds);
        this.onSubmit.emit();
        this.closePopup();
    }

    cancelClick(): void {
        this.closePopup();
    }

    updateAttributeClassCheckboxValues(): void {
        this.attributeClasses.forEach(attrClass => {
            if (this.partiallySelectedAttributeClassIds?.includes(attrClass.id)) {
                attrClass.selected = undefined;
            } else if (this.selectedAttributeClassIds?.includes(attrClass.id)) {
                attrClass.selected = true;
            } else {
                attrClass.selected = false;
            }
        });
    }

    attrClassCheckboxValueChanged(event: any, attrClass: AttributeClass): void {
        attrClass.attributes.forEach(x => {
            if (event.value && !this.selectedAttributeIds.includes(x.id)) {
                this.selectedAttributeIds.push(x.id);
            } else if (event.value === false && this.selectedAttributeIds.includes(x.id)) {
                this.selectedAttributeIds = this.selectedAttributeIds.filter(y => y !== x.id);
            }
        });

        this.updateSelectedAttributeClassIds();
        this.updateSelectedAttributeIdsOfActiveClass();

        this.cd.detectChanges();
    }

    updateSelectAllCheckboxValue(): any {
        if (this.selectedAttributeClassIds && this.attributeClasses && this.selectedAttributeClassIds.length === this.attributeClasses.length) {
            this.selectAllCheckboxValue = true;
        } else if (this.selectedAttributeClassIds && this.partiallySelectedAttributeClassIds && this.selectedAttributeClassIds.length === 0 && this.partiallySelectedAttributeClassIds.length === 0) {
            this.selectAllCheckboxValue = false;
        } else {
            this.selectAllCheckboxValue = undefined;
        }
    }

    selectAllChanged(event: any): void {
        if (event.value !== undefined) {
            this.selectedAttributeIds = event.value ? this.allAttributeIds : [];
            this.updateSelectedAttributeIdsOfActiveClass();
            this.updateSelectedAttributeClassIds();
        }

        this.cd.detectChanges();
    }

    getTabTitle(e: any): string {
        if (e.title.toUpperCase() === 'ACCOUNTS' && this.selectedSellerIds?.length > 0) {
            return `Accounts (${this.selectedSellerIds.length})`;
        } else if (e.title.toUpperCase() === 'ATTRIBUTES' && this.selectedAttributeIds?.length > 0) {
            return `Attributes (${this.selectedAttributeIds.length})`;
        } else {
            return e.title;
        }
    }

    resizePopupContent(e: any): void {
        let popupHeight = this.popupHeight;
        if (e !== null) {
            popupHeight = e.height;
        }
        this.attributeClassListHeight = popupHeight - this.attributeClassListHeightOffset;
        this.attributeListHeight = popupHeight - this.attributeListHeightOffset;
        this.accountListHeight = popupHeight - this.accountListHeightOffset;
    }
}
