import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-retrievesso',
  templateUrl: './retrievesso.component.html',
  styleUrls: ['./retrievesso.component.scss']
})
export class RetrievessoComponent {

    constructor(private httpClient: HttpClient, private toast: ToastrService) {
            // Get JWT from SAML Authorization
            this.retrieveSSOJwt()
                .subscribe((jwt) => {
                    localStorage.setItem('jwt', jwt);

                    if (window.location.origin.includes('localhost:44378')) {
                        window.location.href = 'http://localhost:44378/home';
                    }
                    else if (window.location.origin.includes('corecommissions.net:9443')) {
                        window.location.href = 'https://www.corecommissions.net:9443/home';
                    }
                    else if (window.location.origin.includes('corecommissions.net:9243')) {
                        window.location.href = 'https://www.corecommissions.net:9243/home';
                    }
                    else {
                        window.location.href = 'https://' + window.location.hostname.split('.')[0] + '.commportal.net/home';
                    }
                }, (err) => {
                    this.toast.error('Error fetching JWT: ', err);
                });
    }

    public retrieveSSOJwt() {
        return this.httpClient.get(environment.apiEndpoint + '/authenticate/', { withCredentials: true, responseType: 'text' });
    }
}

