<core-popup #lockPublishPopup [props]="lockPublishPopupProps" [steps]="lockPublishPopupSteps"> </core-popup>
<core-popup [props]="deleteWithReassignPopupProps" [steps]="deleteWithReassignPopupSteps"> </core-popup>
<div class="dropdowns-wrapper">
    <div class="working-in-content" *ngIf="showSeries">
        <div class="dropdown-label">Series</div>
        <core-dropdown
            #seriesDropdown
            class="working-in-content"
            [(selectedValue)]="selectedSeriesId"
            [dataSource]="series"
            [props]="dropdownProps['series']"
            (selectionChanged)="onSeriesChanged($event)">
        </core-dropdown>
    </div>
    <div class="working-in-content" *ngIf="showRecurrence">
        <div class="dropdown-label">Recurrence</div>
        <core-dropdown
            #recurrenceDropdown
            class="working-in-content"
            [(selectedValue)]="selectedRecurrenceId"
            [dataSource]="recurrences"
            [props]="dropdownProps['recurrence']"
            (selectionChanged)="onRecurrenceChanged($event)">
        </core-dropdown>
    </div>
    <div class="working-in-content" *ngIf="showPeriod">
        <div class="dropdown-label">{{dropdownProps['period'].itemDescription}}</div>
        <core-dropdown
            #periodDropdown
            class="working-in-content"
            dropdownName="period"
            [(selectedValue)]="selectedPeriodId"
            [dataSource]="filteredPeriods"
            [popupProps]="periodPopupProps"
            [props]="finalDropdownProps"
            [columns]="periodDropDownColumns"
            (selectionChanged)="onPeriodChanged($event)"
            (onDelete)="deletePeriod($event)"
            (onAdd)="addPeriod($event)">
        </core-dropdown>
    </div>
</div>
