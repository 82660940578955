import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DxTextBoxComponent } from 'devextreme-angular';
import { FormulaBuilderTreeItem } from '../../models/formula-builder-tree-item';
import { FormulaBuilderDataValue } from '../../models/formula-builder-data-value';
import { BuildingBlocksService } from '../../services/building-blocks.service';
import { ReadOnlyStringInputProperties } from '../../models/core-string-input-properties';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';

@Component({
    selector: 'app-formula-builder-popup',
    templateUrl: './formula-builder-popup.component.html',
    styleUrls: ['./formula-builder-popup.component.scss']
})
export class FormulaBuilderPopupComponent {
    @Input() visible: boolean = false;
    @Input() set value(value: string) {
        if (value === undefined || value === null) {
            value = '';
        }
        this.originalValue = value;
        this.friendlyValue = value;
    }
    @Input() set data(value: FormulaBuilderTreeItem[]) {
        if (value !== undefined && value !== null && value.length > 0) {
            value[0].selected = true;
            this.dataValues = value[0].values;
        }
        this.treeItems = value;
    }
    // Id must be unique - it is used for element id attributes
    @Input() id: string;
    // If text is blank or not set, the button/link will visibly consist of only the icon
    @Input() text: string;
    @Input() icon: string;
    @Input() launchElement: string;
    @Input() valuesToHide: string[] = [];
    @Output() valueChange = new EventEmitter<string>();
    @Output() visibleChange = new EventEmitter<boolean>();

    public treeItems: FormulaBuilderTreeItem[];
    public dataValues: FormulaBuilderDataValue[];
    public description: string;
    public formulaCursorPosition: number;
    public originalValue: string;
    public readOnlyStringProperties = new ReadOnlyStringInputProperties();
    public friendlyValue: string;

    constructor(private bbService: BuildingBlocksService,
        private bbHelperService: BuildingBlockHelperService) {
    }

    okClicked(): void {
        this.visible = false;
        this.visibleChange.emit(this.visible);
        this.valueChange.emit(this.friendlyValue);
    }

    cancelPopup(): void {
        this.visible = false;
        this.visibleChange.emit(this.visible);
        this.value = this.originalValue;
        this.valueChange.emit(this.originalValue);
    }

    onTreeViewItemSelectionChanged(e): void {
        if ((e.itemData.values === null || e.itemData.values.length === 0) && e.itemData.items !== null && e.itemData.items.length > 0) {
            const values = [];
            e.itemData.items.forEach(x => {
                if (x.values !== undefined && x.values !== null) {
                    x.values.forEach(y => {
                        values.push(y);
                    });
                }
            });
            this.dataValues = values.filter(x => !this.valuesToHide.includes(x.value));
        } else {
            this.dataValues = e.itemData.values.filter(x => !this.valuesToHide.includes(x.value));
        }

        this.description = '';
    }

    onToolbarPreparing(e): void {
        const toolbarItems = e.toolbarOptions.items;
        toolbarItems.forEach(item => {
            if (item.name === 'searchPanel') {
                item.location = 'before';
            }
        });
    }

    onRowDoubleClick(e): void {
        let textToInsert = e.data.value;

        if (this.formulaCursorPosition === undefined || this.formulaCursorPosition === this.friendlyValue.length) {
            if (this.friendlyValue.length && this.friendlyValue.substring(this.friendlyValue.length - 1, this.friendlyValue.length) !== ' ') {
                textToInsert = ' ' + textToInsert;
            }
            this.friendlyValue = this.friendlyValue + textToInsert;
            this.formulaCursorPosition = this.friendlyValue.length;
        } else {
            this.formulaCursorPosition = this.getValidCursorPositionForInsert();

            if (this.formulaCursorPosition > 0 && this.friendlyValue.substring(this.formulaCursorPosition - 1, this.formulaCursorPosition) !== ' ') {
                textToInsert = ' ' + textToInsert;
            }
            if (this.friendlyValue.substring(this.formulaCursorPosition, this.formulaCursorPosition + 1) !== ' ') {
                textToInsert = textToInsert + ' ';
            }

            const element = document.querySelector(`#formula-value-${this.id.toString()} textarea`) as HTMLTextAreaElement;
            const originalDisplayedFormula = element.value;
            this.friendlyValue = originalDisplayedFormula.slice(0, this.formulaCursorPosition) + textToInsert + originalDisplayedFormula.slice(this.formulaCursorPosition);
            this.formulaCursorPosition += textToInsert.length;
        }
    }

    onDataValueSelectionChanged(e): void {
        if (e.selectedRowsData !== null && e.selectedRowsData.length > 0) {
            this.description = e.selectedRowsData[0].description;
        }
    }

    getFormulaCursorPosition(): void {
        let position = 0;
        const doc = document as any;
        const element = doc.querySelector(`#formula-value-${this.id.toString()} textarea`);
        element.setAttribute('spellcheck', false);

        // IE Support
        if (doc.selection) {

            // Set focus on the element
            element.focus();

            // To get cursor position, get empty selection range
            const selection = doc.selection.createRange();

            // Move selection start to 0 position
            selection.moveStart('character', -element.value.length);

            // The caret position is selection length
            position = selection.text.length;
        } else if (element.selectionStart || element.selectionStart === '0') { // Firefox support
            position = element.selectionDirection === 'backward' ? element.selectionStart : element.selectionEnd;
        }

        this.formulaCursorPosition = position;
    }

    getValidCursorPositionForInsert(): number {
        let pos = this.formulaCursorPosition;
        const areBracketPairsBalanced = this.friendlyValue.split('[').length === this.friendlyValue.split(']').length;
        while (areBracketPairsBalanced && this.friendlyValue.slice(pos).split('[').length !== this.friendlyValue.slice(pos).split(']').length) {
            pos++;
        }
        return pos;
    }

    onTreeViewDisposing(e) {
        // Resetting the default selections, so that it will open in the same state every time\
        if (this.treeItems) {
            e.component.selectItem(this.treeItems[0]);
        }
    }
}
