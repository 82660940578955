/* eslint-disable guard-for-in */
import { ChangeDetectorRef, Component, HostListener, OnInit, AfterViewInit, ViewContainerRef } from '@angular/core';
import { ColDef, ColGroupDef, ColumnApi, ColumnRowGroupChangedEvent, ColumnState, ColumnVO, GridApi, IColumnToolPanel,
	IServerSideGetRowsRequest, ProcessCellForExportParams, RowNode, IRowNode } from 'ag-grid-community';
import { FontTextColorHistoryItem } from 'devexpress-richedit/lib/core/model/history/items/character-properties-history-items';
import { confirm } from 'devextreme/ui/dialog';
import dxTreeView from 'devextreme/ui/tree_view';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CoreSimplePopupComponent } from 'src/app/shared/components/core-simple-popup/core-simple-popup.component';
import { toastrConstants } from 'src/app/shared/constants/constants';
import { CoreInputEditorType } from 'src/app/shared/constants/dev-extreme-enums';
import { CoreFeature, processingDataViewerFunctions, ProcessingFieldType, coreResponseCodes, agGridSecondaryColumnType, EnumSettingClassId, RDVRequestsEnum } from 'src/app/shared/constants/enums';
import { AgGridFilterValuesRequest } from 'src/app/shared/models/ag-grid-filter-values-request';
import { AgGridSecondaryColumn } from 'src/app/shared/models/ag-grid-secondary-column';
import { AgGridServerSideRowsResponse } from 'src/app/shared/models/ag-grid-server-side-rows-response';
import { BbObject, DataColumnType } from 'src/app/shared/models/building-blocks';
import { DisplayDataArguments } from 'src/app/shared/models/core-display-data-arguments';
import { CoreEditorOptions } from 'src/app/shared/models/core-editor-options';
import { CoreResponse } from 'src/app/shared/models/core-response';
import { CoreSimplePopupProps } from 'src/app/shared/models/core-simple-popup-props';
import { GetFlatXactionForProcessing } from 'src/app/shared/models/get-flat-xaction-for-processing';
import { Period } from 'src/app/shared/models/period';
import { UpdateXactionFlat } from 'src/app/shared/models/UpdateXactionFlat';
import { BuildingBlocksService } from 'src/app/shared/services/building-blocks.service';
import { CalculationService } from 'src/app/shared/services/calculation.service';
import { DatasourceService } from 'src/app/shared/services/datasource.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { PeriodService } from 'src/app/shared/services/period.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { XactionService } from 'src/app/shared/services/xaction.service';
import { CoreCustomEditorDateBoxComponent } from './core-custom-editor-date-box/core-custom-editor-date-box.component';
import { CoreCustomEditorQtyComponent } from './core-custom-editor-qty/core-custom-editor-qty.component';
import { CoreCustomEditorSelectComponent } from './core-custom-editor-select/core-custom-editor-select.component';
import { CoreCustomLeftStatusbarComponent } from './core-custom-left-statusbar/core-custom-left-statusbar.component';
import { CoreCustomLoadingIndicatorComponent } from './core-custom-loading-indicator/core-custom-loading-indicator.component';
import { CoreCustomStatusbarComponent } from './core-custom-statusbar/core-custom-statusbar.component';
import { CoreCustomToolPanelComponent } from './core-custom-tool-panel/core-custom-tool-panel.component';
import { CoreFilterBuilderToolPanelComponent } from './core-filter-builder-tool-panel/core-filter-builder-tool-panel.component';
import { CoreInsertGridToolPanelComponent } from './core-insert-grid-tool-panel/core-insert-grid-tool-panel.component';
import { AgGridServerSideRowsRequest } from 'src/app/shared/models/ag-grid-server-side-rows-request';
import { UiViewService } from 'src/app/shared/services/ui-view.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { RequiredProperty } from 'src/app/shared/models/required-property';
import { SettingService } from 'src/app/shared/services/setting.service';
import { BuildingBlockHelperService } from '../building-blocks/building-block-helper.service';
import { SiteThemeBI, SiteThemeDefault } from 'src/app/shared/constants/theme-constants';
import { SiteThemeConfig } from 'src/app/shared/models/site-theme-config';
import { SiteThemeService } from 'src/app/shared/services/site-theme.service';
import { FieldService } from 'src/app/shared/services/field.service';
import { AuditGridFilterCriteria, AuditType } from 'src/app/shared/models/audit-grid-filter-criteria';
import { SavedColumn } from 'src/app/shared/models/saved-column';

@Component({
	selector: 'app-record-data-viewer',
	templateUrl: './record-data-viewer.component.html',
	styleUrls: ['./record-data-viewer.component.scss']
})
export class RecordDataViewerComponent implements OnInit, AfterViewInit {

	permissionEditGrid: boolean = false;

	gridThemes: string[] = ['ag-theme-balham', 'ag-theme-balham-dark'];
	gridTheme: string = this.gridThemes[0];
	siteThemeConfig: SiteThemeConfig = SiteThemeDefault;
	isBITheme: boolean = false;
    showLoadingThemeOverlay: boolean = true;
	DATASOURCE_LAYOUT_CONTEXTCODE: number = 16;
	PROCESSEDOUTPUT_LAYOUT_CONTEXTCODE: number = 17;
	masterMapping: any = {};
	frameworkComponents = {
		coreCustomToolPanel: CoreCustomToolPanelComponent,
		coreFilterBuilderToolPanel: CoreFilterBuilderToolPanelComponent,
		coreInsertGridToolPanel: CoreInsertGridToolPanelComponent,
		coreCustomEditorQty: CoreCustomEditorQtyComponent,
		coreCustomEditorSelect: CoreCustomEditorSelectComponent,
		coreCustomEditorDateBox: CoreCustomEditorDateBoxComponent,
		coreCustomLoadingIndicator: CoreCustomLoadingIndicatorComponent,
		coreCustomStatusBar: CoreCustomStatusbarComponent,
		coreCustomLeftStatusBar: CoreCustomLeftStatusbarComponent
	};
	valueChangedEvent: Subject<boolean> = new Subject<boolean>();
	traceOutputDisabledChangedEvent: Subject<boolean> = new Subject<boolean>();
	diagramAuditDisabledChangedEvent: Subject<boolean> = new Subject<boolean>();
	autoGroupColumnDef: ColDef = {
		headerValueGetter: params => params.colDef.headerName,
		// Property 'filter' needs to be set to 'agGroupColumnFilter' in order to use filters from original column definitions
		filter: 'agGroupColumnFilter',
		enableRowGroup: false,
		sortable: true,
		sort: 'asc',
		comparator: (a, b) => {
			if (!a || a === '') {
				return 1;
			}
			if (!b || b === '') {
				return -1;
			}
			return (a<b?-1:(a>b?1:0));
		},
		cellRenderer: 'agGroupCellRenderer',
		cellRendererParams: {
			innerRenderer: params => {
				if (params.hasOwnProperty('valueFormatted') && params.valueFormatted === null) {
					return 'Total';
				}
				const colType = this.setColumnType(params.colDef.showRowGroup);
				if (params.value === undefined || params.value === null || params.value === '') {
					return '(Blank)';
				} else if (colType.includes('dateField')) {
					return params.valueFormatted;
				} else {
					return params.value;
				}
			}
		}
	};
	defaultColDef: ColDef = {
		cellClassRules: {
			'to-update': (params) => {
				const idKey = this.isProcessedOutput ? 'set_id': 'id';
				if (params.data && Object.keys(this.cellsToUpdate).indexOf(params.data[idKey]) > -1) {
					if (Object.keys(this.cellsToUpdate[params.data[idKey]]).indexOf(params.colDef.field) > -1) {
						return true;
					}
					return false;
				}
				return false;
			},
			'value-found': (params) => {
				let value = params.value;
				if (params.colDef.aggFunc === 'sum' && params.value) {
					value = String.format('{0:F}', params.value);
				}
				const quickSearchValue = this.statusBarComponent.quickSearchValue.toLowerCase();
				if (value && quickSearchValue.length > 0 && value.toString().toLowerCase().indexOf(quickSearchValue) > -1) {
					return true;
				}
				return false;
			},
			'validation-error': (params) => {
				const idKey = this.isProcessedOutput ? 'set_id': 'id';
				return params.data !== undefined && this.isCellInvalid(params.data[idKey], params.colDef.field);
			},
			'last-processed-output-column': (params) => this.isProcessedOutput && params.colDef.colId === this.lastProcessedOutputColId,
			'saved-column-payment-column': (params) => params?.colDef?.colId?.startsWith('saved_column_') && this.isPaymentSavedColumn(params.colDef.colId)
		},
		editable: this.isCellEditable.bind(this),
		filter: 'agSetColumnFilter',
		sortable: true,
		resizable: true,
		enableRowGroup: true,
		filterParams: {
			defaultToNothingSelected: true,
			buttons: ['reset'],
			showTooltips: true,
			filterChangedCallback: () => {
				this.gridApi.onFilterChanged();
			}
		},
		menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
		enablePivot: true,
		getQuickFilterText: (params) => {
            if (params.column.isVisible) {
            	return params.value;
            }
        },
		onCellValueChanged: (changes) => {
			const idKey = this.isProcessedOutput ? 'cId': 'id';
			if (!(changes.node.data[idKey]).toString().includes('-') && changes.newValue !== changes.oldValue) {
				this.updateProcedure(changes);
				this.valueChangedEvent.next(true);
			}
		},
		suppressKeyboardEvent: (params) => {
			const key = params?.event?.key?.toLowerCase();
			const isEscapeKey = key === 'Escape';
			const isEnterKey = key === 'Enter';
			const isFkey = key === 'f';
			const isUkey = key === 'u';
			const isAKey = key === 'a';
			const isXkey = key === 'x';
			const isZKey = key === 'z';
			const isDKey = key === 'd';
			const isBKey = key === 'b';
			const isRKey = key === 'r';
			const isLkey = key === 'l';
			const isOkey = key === 'o';
			const isSpaceKey = params.event.code === 'Space';
			if (!params.editing) {
				params.event.preventDefault();
				if (isFkey && params.event.ctrlKey) {
					if (this.statusBarComponent) {
					    this.statusBarComponent.focusSearchInputBox();
					}
				}
				if (isBKey && params.event.ctrlKey) {
					this.bulkEditPopupVisible = true;
          			return true;
				}
				if (isRKey && params.event.ctrlKey) {
					this.refreshProcedure();
          			return true;
				}
				if (isLkey && params.event.ctrlKey) {
					this.periodFilterVisible = true;
					this.startPeriodRange = this.startPeriodRange;
					this.endPeriodRange = this.endPeriodRange;
					// this.setddArgsAndReload();
          			return true;
				}
				if (isAKey && params.event.ctrlKey) {
					this.selectAll();
          			return true;
				}
				if (isSpaceKey && params.event.ctrlKey) {
					this.saveChanges();
          			return true;
				}
				if (isXkey && params.event.ctrlKey) {
					this.deleteProcedure();
          			return true;
				}
				if (isEscapeKey) {
					this.gridApi.clearFocusedCell();
					this.gridApi.deselectAll();
					return true;
				}
				if (isDKey && params.event.ctrlKey) {
					this.duplicateRowProcedure();
					return true;
				}
				if (isEnterKey && params.event.ctrlKey) {
					if (!this.isAdding) {
						this.isAdding = true;
						this.addRowProcedure();
					}
					return true;
				}
				if (isUkey && params.event.ctrlKey) {
					this.unstageSelectedRows();
					return true;
				}
				if (isOkey && params.event.ctrlKey && this.isProcessedOutput) {
					this.showTraceOutputPopup();
					return true;
				}
				if (isZKey && params.event.ctrlKey) {
					const focusedCell = this.gridApi.getFocusedCell();
                    const rowNode: IRowNode<any>[] = this.gridApi.getSelectedNodes();
					if (rowNode.length > 1) {
						this.toast.warning('To undo a single cell only have one row selected.');
						return true;
					}
					for (const updateRowId in this.cellsToUpdate) {
						if (rowNode[0].data.id === updateRowId) {
							for (const field in this.cellsToUpdate[updateRowId]) {
								if (field === focusedCell.column.getColDef().field) {
									const data = rowNode[0].data;
									data[field] = this.cellsToUpdate[updateRowId][field][0];
									this.gridApi.applyTransaction({ update: [data]});
									delete this.cellsToUpdate[updateRowId][field];
									this.gridApi.refreshCells();
									if (Object.keys(this.cellsToUpdate[updateRowId]).length === 0) {
										delete this.cellsToUpdate[updateRowId];
									}
									return true;
								}
							}
						}
					}
					return true;
				}
			}
			return false;
		}
	};
	columnTypes: Record<string, ColDef> = {
		defaultHidden: {
			hide: true,
			suppressFiltersToolPanel: true,
			valueFormatter: params => params.value
		},
		notEditable: {
			editable: false
		},
		textArea: {
			editable: this.isCellEditable.bind(this),
			cellEditor: 'agLargeTextCellEditor',
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
				  	{
						filter: 'agTextColumnFilter',
						filterParams: {
							buttons: ['reset']
						}
				  	},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							defaultToNothingSelected: true,
							buttons: ['reset'],
							valueFormatter: (params) => (params.value ? (params.value as string).split('\n')[0] : ''),
							values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.String).subscribe(values => params.success(values)),
							refreshValuesOnOpen: true
						}
					},
				],
			},
			chartDataType: 'excluded',
			maxWidth: 400
		},
		textField: {
			editable: this.isCellEditable.bind(this),
			cellEditor: 'agTextCellEditor',
			valueFormatter: params => params.value,
			filterParams: {
				filters: [
				  	{
						filter: 'agTextColumnFilter',
						filterParams: {
							buttons: ['reset']
						}
				  	},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							defaultToNothingSelected: true,
							buttons: ['reset'],
							values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.String).subscribe(values => params.success(values)),
							refreshValuesOnOpen: true
						}
					},
				],
			},
			filter: 'agMultiColumnFilter'
		},
		sellerLookup: {
			editable: this.isCellEditable.bind(this),
			chartDataType: 'category',
			valueFormatter: (params) => (params.data ? (this.helperService.defaultToEmptyString(this.lookupMappingFunction(params.data[params.colDef.field], this.masterMapping['seller']))) : ''),
			filterParams: {
				filters: [
				  	{
						filter: 'agTextColumnFilter',
						filterParams: {
							buttons: ['reset']
						}
				  	},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							defaultToNothingSelected: true,
							buttons: ['reset'],
							valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['seller'])),
							values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.Long).subscribe(values => params.success(values)),
							comparator: (a, b) => this.sortColumnFilterValuesComparator(agGridSecondaryColumnType.SellerId, a, b),
							refreshValuesOnOpen: true
						}
					},
				],
			},
			cellEditor: 'coreCustomEditorSelect',
			filter: 'agMultiColumnFilter',
			cellEditorParams: params => ({
				values: this.extractSelectValues(this.masterMapping['seller'])
			}),
            onCellValueChanged: (changes) => {
				if (changes.newValue === undefined || changes.newValue === '') {
					changes.data.product_group_id = undefined;
				}
                if (changes.oldValue !== changes.newValue) {
                    this.updateProcedure(changes);
                    this.valueChangedEvent.next(true);
                }
            },
			valueGetter: (params) => (params.data ? (this.helperService.defaultToEmptyString(this.lookupMappingFunction(params.data[params.colDef.field], this.masterMapping['seller']))) : '-')
		},
		sellerImportLookup: {
			editable: this.isCellEditable.bind(this),
			chartDataType: 'category',
			valueFormatter: (params) => (params.data ? (this.helperService.defaultToEmptyString(params.value)) : ''),
			filterParams: {
				filters: [
				  	{
						filter: 'agTextColumnFilter',
						filterParams: {
							buttons: ['reset']
						}
				  	},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							defaultToNothingSelected: true,
							buttons: ['reset'],
							valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['sellerImport'])),
							values: (params: any) => this.getColumnFilterValues(this.getSellerColId(params.colDef.colId), ProcessingFieldType.Long)
								.subscribe(values => params.success(this.convertToSellerImportIds(values))),
							comparator: (a, b) => this.sortColumnFilterValuesComparator(agGridSecondaryColumnType.SellerImportId, a, b),
							refreshValuesOnOpen: true
						}
					},
				],
			},
			cellEditor: 'coreCustomEditorSelect',
			filter: 'agMultiColumnFilter',
			cellEditorParams: params => ({
				values: this.extractSelectValues(this.masterMapping['sellerImport'])
			}),
            onCellValueChanged: (changes) => {
				const sellerImportColId = changes.colDef.colId;
				const sellerColId = this.getSellerColId(sellerImportColId);
				changes.oldValue = changes.data[sellerColId];
				changes.colDef = this.columns.find(x => x.colId === sellerColId);
				changes.colDef.refData = this.masterMapping['seller'];
				const newSellerImportId = changes.data[sellerImportColId];
				const newSellerId = newSellerImportId === '<null>' ? newSellerImportId
					: this.lookupMappingFunction(newSellerImportId, this.masterMapping['importToSeller']);
				changes.data[sellerColId] = newSellerId;
				changes.node.data[sellerColId] = newSellerId;
				changes.newValue = newSellerId;

				if (changes.oldValue !== changes.newValue) {
					this.updateProcedure(changes);
					this.valueChangedEvent.next(true);
				}
            },
			valueGetter: (params) => (params.data ? (this.helperService.defaultToEmptyString(this.lookupMappingFunction(
				this.lookupMappingFunction(params.data[this.getSellerColId(params.colDef.field)], this.masterMapping['sellerToDefaultImport']),
				this.masterMapping['sellerImport']))) : '-'),
		},
		ruleLookup: {
			editable: this.isCellEditable.bind(this),
			chartDataType: 'category',
			valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['rule'])),
			filterParams: {
				filters: [
				  	{
						filter: 'agTextColumnFilter',
						filterParams: {
							buttons: ['reset']
						}
				  	},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							defaultToNothingSelected: true,
							buttons: ['reset'],
							valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['rule'])),
							values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.String).subscribe(values => params.success(values)),
							comparator: (a, b) => this.sortColumnFilterValuesComparator(agGridSecondaryColumnType.RuleId, a, b),
							refreshValuesOnOpen: true
						}
					},
				],
			},
			cellEditor: 'coreCustomEditorSelect',
			filter: 'agMultiColumnFilter',
			cellEditorParams: params => ({
				values: this.extractSelectValues(this.masterMapping['rule'])
			}),
			valueGetter: (params) => (params.data ? (this.helperService.defaultToEmptyString(this.lookupMappingFunction(params.data[params.colDef.field], this.masterMapping['rule']))) : ''),
		},
		tagLookup: {
			editable: this.isCellEditable.bind(this),
			chartDataType: 'category',
			valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping, params.colDef.field)),
			filterParams: {
				filters: [
				  	{
						filter: 'agTextColumnFilter',
						filterParams: {
							buttons: ['reset']
						}
				  	},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							defaultToNothingSelected: true,
							buttons: ['reset'],
							valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping, params.colDef.field)),
							values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.Long).subscribe(values => params.success(values)),
							comparator: (a, b) => this.emptyColumnFilterValuesComparator(a, b),
							refreshValuesOnOpen: true
						}
					},
				],
			},
			cellEditor: 'coreCustomEditorSelect',
			filter: 'agMultiColumnFilter',
			cellEditorParams: params => ({
				values: this.extractSelectValues(this.masterMapping[params.colDef.field], true)
			}),
			valueGetter: (params) => (params.data ? (this.helperService.defaultToEmptyString(this.lookupMappingFunction(params.data[params.colDef.field],
				this.masterMapping, params.colDef.field))) : '')
		},
		datasourceLookup: {
			chartDataType: 'category',
			editable: false,
			valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['datasource'])),
			filterParams: {
				valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['datasource'])),
                values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.Long).subscribe(values => params.success(values)),
                refreshValuesOnOpen: true
			},
			enablePivot: false,
			valueGetter: (params) => (params.data ? (this.helperService.defaultToEmptyString(this.lookupMappingFunction(params.data[params.colDef.field], this.masterMapping['datasource']))) : ''),
		},
		datasourceMappingLookup: {
			chartDataType: 'category',
			editable: false,
			valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['datasourceMapping'])),
			filterParams: {
				valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['datasourceMapping'])),
                values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.Long).subscribe(values => params.success(values)),
                refreshValuesOnOpen: true
			},
			enablePivot: false,
			valueGetter: (params) => (params.data ? (this.helperService.defaultToEmptyString(this.lookupMappingFunction(params.data[params.colDef.field],
				this.masterMapping['datasourceMapping']))) : ''),
		},
		seriesLookup: {
			chartDataType: 'excluded',
			editable: false,
			valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['series'])),
			filterParams: {
				valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['series'])),
                values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.Long).subscribe(values => params.success(values)),
                refreshValuesOnOpen: true
			},
			enablePivot: false
		},
		periodLookup: {
			chartDataType: 'category',
			editable: false,
			comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
				const dateAParts = valueA.split('-')[0].split('/');
				const dateBParts = valueB.split('-')[0].split('/');
				const dateA = new Date(Number(dateAParts[2]), Number(dateAParts[0] - 1), Number(dateAParts[1]));
				const dateB = new Date(Number(dateBParts[2]), Number(dateBParts[0] - 1), Number(dateBParts[1]));
				if (dateA === dateB) {
					return 0;
				}
				return (dateA > dateB) ? 1 : -1;
			},
			valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['period'])),
			filterParams: {
				valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['period'])),
                values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.Long).subscribe(values => params.success(values)),
                refreshValuesOnOpen: true
			},
			valueGetter: (params) => (params.data ? (this.helperService.defaultToEmptyString(this.lookupMappingFunction(params.data[params.colDef.field], this.masterMapping['period']))) : ''),
			enablePivot: false
		},
		productGroupLookup: {
			chartDataType: 'category',
			editable: false,
			valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['productGroup'])),
			filterParams: {
				filters: [
				  	{
						filter: 'agTextColumnFilter',
						filterParams: {
							buttons: ['reset']
						}
				  	},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							defaultToNothingSelected: true,
							buttons: ['reset'],
							valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['productGroup'])),
							values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.Long).subscribe(values => params.success(values)),
							comparator: (a, b) => this.sortColumnFilterValuesComparator(agGridSecondaryColumnType.ProductGroupId, a, b),
							refreshValuesOnOpen: true
						}
					},
				],
			},
			filter: 'agMultiColumnFilter',
            valueGetter: (params) => (params.data ? (this.helperService.defaultToEmptyString(this.lookupMappingFunction(params.data['product_id'] === undefined && params.data['product_group_id']
				? params.data['product_group_id'] : this.masterMapping['productToGroup'][params.data['product_id']], this.masterMapping['productGroup']))) : '')
        },
		productLookup: {
			editable: this.isCellEditable.bind(this),
			chartDataType: 'category',
			valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['product'])),
			filterParams: {
				filters: [
				  	{
						filter: 'agTextColumnFilter',
						filterParams: {
							buttons: ['reset']
						}
				  	},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							defaultToNothingSelected: true,
							buttons: ['reset'],
							valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['product'])),
							values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.Long).subscribe(values => params.success(values)),
							comparator: (a, b) => this.sortColumnFilterValuesComparator(agGridSecondaryColumnType.ProductId, a, b),
							refreshValuesOnOpen: true
						}
					},
				],
			},
			cellEditor: 'coreCustomEditorSelect',
			filter: 'agMultiColumnFilter',
			cellEditorParams: params => ({ values: this.extractSelectValues(this.masterMapping['product']) }),
            onCellValueChanged: (changes) => {
				if (changes.newValue === undefined || changes.newValue === '') {
					changes.data.product_group_id = undefined;
				}
                if (changes.oldValue !== changes.newValue) {
                    this.updateProcedure(changes);
                    this.valueChangedEvent.next(true);
                }
            },
			valueGetter: (params) => (params.data ? (this.helperService.defaultToEmptyString(this.lookupMappingFunction(params.data[params.colDef.field], this.masterMapping['product']))) : '')
		},
		customerGroupLookup: {
			chartDataType: 'category',
			editable: false,
			valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['customerGroup'])),
			filterParams: {
				filters: [
				  	{
						filter: 'agTextColumnFilter',
						filterParams: {
							buttons: ['reset']
						}
				  	},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							defaultToNothingSelected: true,
							buttons: ['reset'],
							valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['customerGroup'])),
							values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.Long).subscribe(values => params.success(values)),
							comparator: (a, b) => this.sortColumnFilterValuesComparator(agGridSecondaryColumnType.CustomerGroupId, a, b),
							refreshValuesOnOpen: true
						}
					},
				],
			},
			filter: 'agMultiColumnFilter',
			valueGetter: (params) => (params.data ? (this.helperService.defaultToEmptyString(this.lookupMappingFunction(params.data['customer_id'] === undefined && params.data['customer_group_id']
			? params.data['customer_group_id'] : this.masterMapping['customerToGroup'][params.data['customer_id']], this.masterMapping['customerGroup']))) : '')
		},
		customerLookup: {
			editable: this.isCellEditable.bind(this),
			chartDataType: 'category',
			valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['customer'])),
			filterParams: {
				filters: [
				  	{
						filter: 'agTextColumnFilter',
						filterParams: {
							buttons: ['reset']
						}
				  	},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							defaultToNothingSelected: true,
							buttons: ['reset'],
							valueFormatter: (params) => (this.lookupMappingFunction(params.value, this.masterMapping['customer'])),
							values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.Long).subscribe(values => params.success(values)),
							comparator: (a, b) => this.sortColumnFilterValuesComparator(agGridSecondaryColumnType.CustomerId, a, b),
							refreshValuesOnOpen: true
						}
					},
				],
			},
			cellEditor: 'coreCustomEditorSelect',
			filter: 'agMultiColumnFilter',
			cellEditorParams: params => ({ values: this.extractSelectValues(this.masterMapping['customer']) }),
			valueGetter: (params) => (params.data ? (this.helperService.defaultToEmptyString(this.lookupMappingFunction(params.data[params.colDef.field], this.masterMapping['customer']))) : '')
		},
		qtyField: {
			editable: this.isCellEditable.bind(this),
			chartDataType: 'series',
			cellEditor: 'coreCustomEditorQty',
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
				  	{
						filter: 'agNumberColumnFilter',
						filterParams: {
							buttons: ['reset'],
                            valueFormatter: (params) => (this.formatQtyFields(params, this.masterMapping['singletonFormat'])),
						}
				  	},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							defaultToNothingSelected: true,
							buttons: ['reset'],
                            valueFormatter: (params) => (this.formatQtyFields(params, this.masterMapping['singletonFormat'])),
                            values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.Double).subscribe(values => params.success(values)),
                            comparator: (a, b) => this.emptyColumnFilterValuesComparator(a, b),
							refreshValuesOnOpen: true
						}
					},
				],
			},
            valueFormatter: (params) => (this.formatQtyFields(params, this.masterMapping['singletonFormat'])),
			aggFunc: 'sum',
			enableValue: true,
			enablePivot: false
		},
		dateField: {
			editable: this.isCellEditable.bind(this),
			cellEditor: 'coreCustomEditorDateBox',
			comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
				if (valueA === null || valueA === undefined) {
					return -1;
				}
				if (valueB === null || valueB === undefined) {
					return 1;
				}
				let dateA;
				let dateB;
				if (valueA.match(/[\d]{4}-[\d]{2}-[\d]{2}T[\d]{2}:[\d]{2}:[\d]{2}.*/)) {
					dateA = new Date(valueA);
					dateB = new Date(valueB);
				} else {
					const dateAParts = valueA.split(' ')[0].split('/');
					const dateBParts = valueB.split(' ')[0].split('/');
					dateA = new Date(Number(dateAParts[2]), Number(dateAParts[0] - 1), Number(dateAParts[1]));
					dateB = new Date(Number(dateBParts[2]), Number(dateBParts[0] - 1), Number(dateBParts[1]));
				}
				if (dateA === dateB) {
					return 0;
				}
				return (dateA > dateB) ? 1 : -1;
			},
            onCellValueChanged: (changes) => {
				if (changes.newValue === '') {
                    changes.newValue = null;
                }
				const hasValueChanged = changes.oldValue !== changes.newValue;
                const idKey = this.isProcessedOutput ? 'set_id': 'id';
				let parsedDate: Date;
                if (changes.newValue instanceof Date) {
                    const enteredValue = `${changes.newValue.getMonth() + 1}/${changes.newValue.getDate()}/${changes.newValue.getFullYear()}`;
                    parsedDate = this.helperService.parseDate(enteredValue, false);
                    changes.newValue = `${parsedDate.getFullYear()}/${parsedDate.getMonth() + 1}/${parsedDate.getDate()}`;
                } else if (changes.newValue !== null) {
					const dateTimeParts = changes.newValue.split(' ');
                    parsedDate = this.helperService.parseDate(dateTimeParts[0], true);
                    changes.newValue = `${parsedDate.getFullYear()}/${parsedDate.getMonth() + 1}/${parsedDate.getDate()}`;
					if (dateTimeParts.length > 1 && !this.helperService.isNullOrEmpty(dateTimeParts[1])) {
						changes.newValue += ` ${dateTimeParts[1]}`;
					}
                }

				if (changes.newValue !== null && parsedDate.getTime() === this.helperService.defaultEmptyDate().getTime()) {
                    this.displayValidationError(changes.data[idKey], changes.colDef.field, changes.node.rowIndex, 'Date entered is invalid. Please try again.', 'Invalid Date Entered');
                } else if (changes.newValue !== null && !this.helperService.isDateValid({ value: parsedDate })) {
					this.displayValidationError(changes.data[idKey], changes.colDef.field, changes.node.rowIndex, 'Date values prior to 1/1/1800 are not permitted.', 'Invalid Date Entered');
				} else {
					this.clearAnyValidationErrors(changes.data[idKey], changes.colDef.field);
				}

                changes.data[changes.colDef.field] = changes.newValue;
                if (hasValueChanged || changes.oldValue !== changes.newValue) {
                    this.updateProcedure(changes);
                    this.valueChangedEvent.next(true);
                }
            },
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
				{
					filter: 'agDateColumnFilter',
					filterParams: {
						buttons: ['reset'],
                        valueFormatter: (params) => (this.formatDateFields(params, this.masterMapping['singletonFormat'])),
						comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
							const dateMinusTimeStamp = cellValue.split(' ')[0];

							if (dateMinusTimeStamp == null) {
								return 0;
							}
							const dateParts = dateMinusTimeStamp.split('/');
							const day = Number(dateParts[1]);
							const month = Number(dateParts[0]) - 1;
							const year = Number(dateParts[2]);
							const cellDate = new Date(year, month, day);
							if (cellDate < filterLocalDateAtMidnight) {
								return -1;
							} else if (cellDate > filterLocalDateAtMidnight) {
								return 1;
							}
							return 0;
						}
					}
				},
                {
                    filter: 'agSetColumnFilter',
                    filterParams: {
                        buttons: ['reset'],
                        defaultToNothingSelected: true,
                        valueFormatter: (params) => (this.formatDateFields(params, this.masterMapping['singletonFormat'])),
                        values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.DateTime).subscribe(values => params.success(values)),
                        refreshValuesOnOpen: true
                    }
                },
				]
			},
            valueFormatter: (params) => (this.formatDateFields(params, this.masterMapping['singletonFormat'])),
			allowedAggFuncs: ['min', 'max'],
			enablePivot: false
		},
		checkbox: {
			field: 'Payment',
            editable: false,
			cellClass: 'checkbox-cell',
            valueGetter: params => params.data?.is_payout?.toString().toUpperCase() === true.toString().toUpperCase() ? 'Checked' : 'Unchecked',
            cellRenderer: params => `<input type='checkbox' disabled ${params.value} onclick="return false;"/>`,
			filterParams: {
                valueFormatter: (params) => params.value.toString() === true.toString() ? 'Checked' : 'Unchecked',
                values: (params: any) => this.getColumnFilterValues(params.colDef.colId, ProcessingFieldType.Boolean).subscribe(values => params.success(values)),
                refreshValuesOnOpen: true
			},
		}
	};
	sideBar: any = {
		toolPanels: [
			{
				id: 'coreCustomToolPanel',
				labelDefault: 'Edit',
				labelkey: 'coreToolPanel',
				iconKey: 'grip',
				toolPanel: 'coreCustomToolPanel',
				toolPanelParams: () => ({
					showBulkEditPopup: this.showBulkEditPopup,
					showPeriodFilterRangePopup: this.showPeriodFilterRangePopup,
					showTraceOutputPopup: this.showTraceOutputPopup,
					showExportOutputPopup: this.showExportOutputPopup,
					openDiagramAudit: this.isProcessedOutput ? this.openDiagramAudit.bind(this, null) : this.openDiagramAuditPopup,
					deleteProcedure: this.deleteProcedure,
					addRowProcedure: this.addRowProcedure,
					duplicateRowProcedure: this.duplicateRowProcedure,
					saveChanges: this.saveChanges,
					toggleTheme: this.toggleTheme,
					toggleMode: this.toggleMode,
					refreshProcedure: this.refreshProcedure,
					filterBuilderValueChanged: this.filterBuilderValueChanged,
					periodId: this.periodId,
					isGridEditable: this.$isGridEditable,
					isProcessedOutput: this.isProcessedOutput,
					datasourceId: this.datasourceId,
					functionId: this.ruleId,
					rapidLoadMode: this.rapidLoadMode,
					loadAllModeWarningRowCount: this.loadAllModeWarningRowCount,
					valueChangedEvent: this.valueChangedEvent.asObservable(),
					traceOutputDisabledChangedEvent: this.traceOutputDisabledChangedEvent.asObservable(),
					diagramAuditDisabledChangedEvent: this.diagramAuditDisabledChangedEvent.asObservable()
				}),
			},
			{
				id: 'columns',
				labelDefault: 'Columns',
				labelKey: 'columns',
				iconKey: 'columns',
				toolPanel: 'agColumnsToolPanel',
				toolPanelParams: {
					suppressSyncLayoutWithGrid: true,
					supressColumnMove: true,
				},
			},
			{
				id: 'filters',
				labelDefault: 'Filters',
				labelKey: 'filters',
				iconKey: 'filter',
				toolPanel: 'agFiltersToolPanel',
			},
			{
				id: 'coreFilterBuilderToolPanel',
				labelDefault: 'Filter Builder',
				labelkey: 'coreFilterBuilderToolPanel',
				iconKey: 'filter',
				toolPanel: 'coreFilterBuilderToolPanel',
				toolPanelParams: () => ({
					periodId: this.periodId,
					isProcessedOutput: this.isProcessedOutput,
					datasourceId: this.datasourceId,
					functionId: this.ruleId,
					filterBuilderValueChanged: this.filterBuilderValueChanged
				}),
			}
		],
		position: 'right',
		defaultToolPanel: 'coreCustomToolPanel'
	};
	insertGridSideBar: any = {
		toolPanels: [
			{
				id: 'coreInsertGridToolPanel',
				labelDefault: ' ',
				labelkey: 'coreToolPanel',
				iconKey: 'grip',
				toolPanel: 'coreInsertGridToolPanel',
				toolPanelParams: () => ({}),
			}
		],
		position: 'right',
		defaultToolPanel: 'coreInsertGridToolPanel'
	};
	statusBar: any = {
		statusPanels: [
			{
				statusPanel: 'coreCustomStatusBar',
				align: 'center',
				statusPanelParams : () => ({
					layoutSourceId: this.layoutSourceId,
					layoutSourceField: this.layoutSourceField,
					isMultipleSources: this.isMultipleSources,
					contextCode: this.isProcessedOutput ? this.PROCESSEDOUTPUT_LAYOUT_CONTEXTCODE : this.DATASOURCE_LAYOUT_CONTEXTCODE,
					isProcessedOutput: this.isProcessedOutput,
					refreshInsertGridCells: this.refreshInsertGridCells.bind(this),
					handleLayoutFilterModelChangeOnComponent: this.onLayoutFilterModelChanged.bind(this),
					filterBuilderValueChanged: this.filterBuilderValueChanged.bind(this),
					layoutChanged: this.layoutChanged.bind(this)
				})
			},
			{
				statusPanel: 'coreCustomLeftStatusBar',
				align: 'left',
				statusPanelParams: () => ({})
			},
			{
				statusPanel: 'agAggregationComponent',
				statusPanelParams: {
					// possible values are: 'count', 'sum', 'min', 'max', 'avg'
					aggFuncs: ['count', 'sum', 'min', 'max', 'avg']
				}
			},
		]
	};
	_statusBarComponent: CoreCustomStatusbarComponent = null;
	get statusBarComponent(): CoreCustomStatusbarComponent {
		if (this._statusBarComponent) {
			return this._statusBarComponent;
		}
		try {
			const statusBarComponent = this.gridApi.getStatusPanel('coreCustomStatusBar');
			if (statusBarComponent) {
				  this._statusBarComponent = statusBarComponent as CoreCustomStatusbarComponent;
				  return this._statusBarComponent;
			}
		}
		catch (e) {
			this.toast.error('get StatusBarComponent Error:', e);
		}
		return null;
	}
	_leftStatusBarComponent: CoreCustomLeftStatusbarComponent = null;
	get leftStatusBarComponent(): CoreCustomLeftStatusbarComponent {
		if (this._leftStatusBarComponent) {
			return this._leftStatusBarComponent;
		}
		try {
			const leftStatusBarComponent = this.gridApi.getStatusPanel('coreCustomLeftStatusBar');
			if (leftStatusBarComponent) {
				  this._leftStatusBarComponent = leftStatusBarComponent as CoreCustomLeftStatusbarComponent;
				  return this._leftStatusBarComponent;
			}
		}
		catch (e) {
			this.toast.error('get LeftStatusBarComponent Error:', e);
		}
		return null;
	}
	_toolPanelComponent: CoreCustomToolPanelComponent = null;
	get toolPanelComponent(): CoreCustomToolPanelComponent {
		if (this._toolPanelComponent) {
			return this._toolPanelComponent;
		}
		try {
			const toolPanelComponent = this.gridApi.getToolPanelInstance('coreCustomToolPanel');
			if (toolPanelComponent) {
				this._toolPanelComponent = toolPanelComponent as unknown as CoreCustomToolPanelComponent;
				return this._toolPanelComponent;
			}
		}
		catch (e) {
			this.toast.error('get ToolPanelComponent Error:', e);
		}
		return null;
	}
	_filterBuilderToolPanelComponent: CoreFilterBuilderToolPanelComponent = null;
	get filterBuilderToolPanelComponent(): CoreFilterBuilderToolPanelComponent {
		if (this._filterBuilderToolPanelComponent) {
			return this._filterBuilderToolPanelComponent;
		}
		try {
			const filterBuilderToolPanelComponent = this.gridApi.getToolPanelInstance('coreFilterBuilderToolPanel');
			if (filterBuilderToolPanelComponent) {
				this._filterBuilderToolPanelComponent = filterBuilderToolPanelComponent as unknown as CoreFilterBuilderToolPanelComponent;
				return this._filterBuilderToolPanelComponent;
			}
		}
		catch (e) {
			this.toast.error('get FilterBuilderToolPanelComponent Error:', e);
		}
		return null;
	}

	loadingOverlayComponent = 'coreCustomLoadingIndicator';
    loadingOverlayComponentParams = {
      loadingMessage: 'One moment please...',
    };

	rowClassRules = {
		'to-delete': (param) => {
			const idKey = this.isProcessedOutput ? 'set_id' : 'id';
			return param.node?.data ? [...this.nodesToDelete].find(x => x.data[idKey] === param.node.data[idKey]) : false;
		},
		'to-insert': (param) => {
			const index = param.rowIndex;
			return this.nodesToInsert.has(param.node);
		}
	};

	columns: ColDef[] = [];
	colDefsSet: boolean = false;
	columnGroupMapping: any = {};
	insertGridColumns: ColDef[] = [];
	records: any[] = [];
	rapidLoadMode: boolean = true;
	ddArgs: DisplayDataArguments;
	gridApi: GridApi;
	insertGridApi: GridApi;
    gridColumnApi: ColumnApi;
    insertGridColumnApi: ColumnApi;
	layoutChangeHappening: boolean = false;
	rowGroupPanelShow: string = 'always';
	rowSelection: string = 'multiple';
	universalMappings = {
		id: 'xaction Id',
		datasource_id: 'Datasource',
		datasource_mapping_id: 'Datasource Mapping',
		series_id: 'Series',
		period_id: 'Period',
		product_id: 'Product',
		product_group_id: 'Product Group',
		product_import_name: 'Product (import)',
		customer_id: 'Customer',
		customer_group_id: 'Customer Group',
		customer_import_name: 'Customer (import)',
		notes: 'Import Notes',
	};
	calculationMappings = {
		cId: 'Calculation Id',
		set_id: 'Set Id',
		saved_column_id: 'Column Name',
		rule_id: 'Rule',
		is_payout: 'Payment',
		seller_id: 'Account',
		value: 'Result',
		cNotes: 'Processing Notes',
	};
	editableCalculationColumns = [];
	quickFilter: string;
	toastConsts = toastrConstants;
	uniqueListOfActiveColumns: Set<string>;
	orderedListOfColumns: string[] = [];
	lastProcessedOutputColId: string = null;
	periodId: number;
	seriesId: number;
	datasourceId: number;
	ruleId: string;
	functionGroupId: string;
	sellerFilterVals: string[];
	datasourceMappingId: number;
	isAdding: boolean = false;
	isPeriodLocked: boolean = false;
	nodesToDelete: Set<IRowNode> = new Set<IRowNode>();
	nodesToInsert: Set<IRowNode> = new Set<IRowNode>();
	cellsToUpdate: any = {};
	isProcessedOutput: boolean = false;
	isMultipleSources: boolean = false;
	$isGridEditable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	layoutSourceField: string;
	layoutSourceId: number;
	currentFilterModel: any;
	allPeriodsId: number;
	isAllPeriods: boolean;
	columnState: ColumnState[] = null;
	gridFilterState: any = null;
	periods: Period[] = [];
	lockedPeriodIds: number[] = [];
	periodRangeOptions: Period[] = [];
	periodRangeLabels: any[] = [];
	startPeriodRange: Period = null;
	endPeriodRange: Period = null;
	newDdargsPeriodIds: Set<number>;
	rowsAutosized: number = 0;
	leftArrowKeyDown: boolean = false;
	cellsWithValidationErrors: any = {};
	validationErrorPopupVisible: boolean = false;
	validationErrorPopupHeader: string;
	validationErrorPopupMessage: string;
	auditPopupVisible: boolean = false;
	auditPeriodValue: any;
	auditableRules: any = [];
	deletedTagIds: number[] = [];
	savedColumns: SavedColumn[] = [];

	bulkEditEditorType: CoreInputEditorType = CoreInputEditorType.TextBox;
	bulkEditFieldSelectorType: CoreInputEditorType = CoreInputEditorType.SelectBox;
	bulkEditFieldSelectorOptions: CoreEditorOptions;
	bulkEditEditorOptions: CoreEditorOptions = new CoreEditorOptions().textBox(null, null, true);
	bulkEditFieldUnmapped: string = null;
	bulkEditFieldMapped: string = null;
	bulkEditNewValue: any = null;
	bulkEditSubmitButtonOptions = {
		text: 'Submit',
		useSubmitBehavior: true,
	  };
	columnMappings: any;
	columnValues: string[];
	enableRangeSelection: boolean = false;
	waitIncrement: number = 100;
	elapsedWaitTime: number = 0;
	maxWaitTime: number = 10000;
	initialDataRetrieved: boolean = false;
	defaultLayoutSet: boolean = false;
	columnTotals: any[] = [];
	lastRowsRequest: GetFlatXactionForProcessing;
	lastInsertColumnStates: any[];
	lastGroupedColumns: any[] = [];
	lastUngroupedColumnId: string = '';
	lastColumnRelativeLocations: any[] = [];
	filteredRecordCount: number;
	selectAllMaxRowCount: number = 10000;
	loadAllModeWarningRowCount: number = 100000;
	insertGridVisible: boolean = false;
	mainGridHover: boolean = false;
	insertGridHover: boolean = false;
	insertRecords: any[] = [];
	traceOutputId: number;
	traceOutputDisabled: boolean = true;
	diagramAuditDisabled: boolean = true;
	lastOpenMultiFilterColId: string;
	traceOutputVisible: boolean;
	exportOutputVisible: boolean;
	exportWarningVisible: boolean;
	exportWarningRowCount: number = 50000;
	exportWithImport: boolean = false;
	filterBuilderValue: any[] = [];
	dateFormat: any = {
		parser: (e) => this.helperService.parseDate(e, null, true),
		formatter: (date) => String.format('{0:g}', date)
	};
	exportItems: any[] = [
		{
			name: 'CSV',
			id: 'csv'
		},
		{
			name: 'XLSX',
			id: 'xlsx'
		}
	];

	mainPanelHeight: number;
	bottomPanelHeight: number = 0;
	defaultBottomPanelHeight: number = 300;
	mainPanelMinHeight: number = 300;
	bottomPanelMinHeight: number = 200;

	requestList: Array<RDVRequestsEnum> = [];
	rowCount: number = 0;
	previousQuickSearchValue: string = '';

	private _periodFilterVisible: boolean = false;
	get periodFilterVisible(): boolean {
		return this._periodFilterVisible;
	}
	set periodFilterVisible(newValue: boolean) {
		if (!this.isAllPeriods) {
			this._periodFilterVisible = newValue;
		}
	}
	private _bulkEditPopupVisible: boolean = false;
	get bulkEditPopupVisible(): boolean {
		return this._bulkEditPopupVisible;
	}
	set bulkEditPopupVisible(newValue: boolean) {
		if (!this.checkIfPeriodLocked()) {
			this._bulkEditPopupVisible = newValue;
		}
	}

	constructor(private xactionService: XactionService,
		private fieldService: FieldService,
		private helperService: HelperService,
		private calculationService: CalculationService,
		private periodService: PeriodService,
		private toast: ToastrService,
		private viewContainerRef: ViewContainerRef,
		private datasourceService: DatasourceService,
		private cdr: ChangeDetectorRef,
		private permissionService: PermissionService,
		private authService: AuthService,
		private uiViewService: UiViewService,
		private buildingBlockService: BuildingBlocksService,
		private buildingBlockHelperService: BuildingBlockHelperService,
		private settingService: SettingService,
		private siteThemeService: SiteThemeService)
	{
		this.ddArgs = window['displayDataArguments'];

		// Todo: once fully switched over, change the class definiton to not send an empty array of function ids when opening a plan
		if (this.ddArgs.popupArgs.ruleIds?.length === 0) {
			this.ddArgs.popupArgs.ruleIds = undefined;
		}
		if (this.ddArgs.popupArgs?.datasourceIds?.length > 1) {
			this.$isGridEditable.next(false);
			this.defaultColDef.editable = false;
		}
		if (this.ddArgs.functionEnum === processingDataViewerFunctions.Processed) {
			this.isProcessedOutput = true;
		}

		this.periodId = this.ddArgs.popupArgs.periodIds[0];
		this.seriesId = this.ddArgs.popupArgs.seriesId;
		this.datasourceId = this.ddArgs.popupArgs.datasourceIds?.[0] ?? null;
		this.ruleId = this.ddArgs.popupArgs.ruleIds?.[0] ?? null;
		this.functionGroupId = this.ddArgs.popupArgs.planIds?.[0] ?? null;
		this.sellerFilterVals = this.ddArgs.popupArgs.sellerFilterIds ?? null;
		// Todo: Confirm that you don't need a list of ids becuase then you are opening multiple which will use view all layouts instead
		this.layoutSourceId = this.datasourceId ?? +(this.ruleId?.slice(2) ?? this.functionGroupId);
		this.isMultipleSources = this.ddArgs.popupArgs.datasourceIds?.length > 1 || this.ddArgs.popupArgs.ruleIds?.length > 1 || this.ddArgs.popupArgs.planIds?.length > 1;
		this.layoutSourceField = this.determineLayoutField();

		this.buildingBlockService.getDownstreamRules(this.datasourceId ? 'Ds' + this.datasourceId : this.ruleId, this.periodId).subscribe(res => {
			const uniqueRules = [];
			res.result.filter(obj => obj.id.startsWith('Co')).forEach(obj => {
				if(uniqueRules.some(rule => rule.name === obj.name)) {
					return;
				}
				uniqueRules.push(obj);
			});
			this.auditableRules = uniqueRules.map(obj => ({
					id: obj.id,
					name: obj.name
				})
			).sort((a,b) => a.name.localeCompare(b.name));
		});

		if (this.ddArgs.popupArgs.title) {
			document.title = this.ddArgs.popupArgs.title;
		};

		const localStorageRapidLoadMode = localStorage.getItem(this.getRapidLoadModeLocalStorageKey());
		if (localStorageRapidLoadMode != null) {
			this.rapidLoadMode = (localStorageRapidLoadMode.toUpperCase() === true.toString().toUpperCase());
		}

		// Set default options for Tree View, so that the one in the filter builder will have the ability to search
		dxTreeView.defaultOptions({
			options: {
				searchEnabled: true
			}
		});
	}

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event): boolean {
        if (event && this.nodesToDelete.size + this.nodesToInsert.size + Object.keys(this.cellsToUpdate).length > 0) {
            event.returnValue = false;
            return event;
        }
    }

	@HostListener('document:keydown.arrowleft', ['$event'])
    leftArrowKeyDownHandler(event: KeyboardEvent) {
		this.leftArrowKeyDown = true;
	}

	@HostListener('document:keyup.arrowleft', ['$event'])
    leftArrowKeyUpHandler(event: KeyboardEvent) {
		setTimeout(() => {
			this.leftArrowKeyDown = false;
		}, 100);
	}

	async ngOnInit(): Promise<void> {
        this.settingService.getBoolSetting(EnumSettingClassId.EnableBITheme).subscribe(isBITheme => {
			this.isBITheme = isBITheme;
            const isDarkMode = this.siteThemeService.getIsDarkMode();
            this.setTheme(isDarkMode);
			this.showLoadingThemeOverlay = false;
        });

		await this.permissionService.initializePermissions().then(x => {
			if (this.ddArgs.functionEnum === processingDataViewerFunctions.Imported){;
				this.permissionEditGrid = this.permissionService.checkCurrentUserPermission(CoreFeature.EditImportedRecords.toString());
			}
			else
			{
				this.permissionEditGrid = this.permissionService.checkCurrentUserPermission(CoreFeature.EditProcessedRecords.toString());
			}
			this.defaultColDef.editable = this.permissionEditGrid;
		});
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.resizePanels();
		});
	}

	getRowStyle = params => {
		if (params?.data?.color) {
			return {background: this.helperService.getColorStringFromNumber(params.data.color)};
		}
	};

	setTagGroupColumnNames(): void {
		this.universalMappings.product_id = this.columnMappings.product_id;
		this.universalMappings.customer_id = this.columnMappings.customer_id;
		this.universalMappings.product_group_id = this.columnMappings.product_id + ' Group';
		this.universalMappings.customer_group_id = this.columnMappings.customer_id + ' Group';
		this.universalMappings.product_import_name = this.columnMappings.product_id + ' (import)';
		this.universalMappings.customer_import_name = this.columnMappings.customer_id + ' (import)';
	}

	determineLayoutField(): string {
		if (this.ruleId) {
			return this.ruleId.startsWith('Sg') ? 'functionId' : 'ruleId';
		} else if (this.functionGroupId) {
			return 'functionGroupId';
		} else if(this.datasourceId) {
			return 'datasourceId';
		}
	}

	createColumns(xactionColumns: Record<string, string>) {
		let columns = [];
		this.orderedListOfColumns = [];

		// Order Columns: Period => Role Fields => Annoying Hard Coded Tags => Qty Fields => Date Fields => Tag Fields => Text Fields => Import Notes
		const listOfColumns: string[] = Object.keys(xactionColumns);
		if(!this.isAllPeriods){
			this.orderedListOfColumns.push('period_id');
		}
		if (this.isProcessedOutput) {
			this.orderedListOfColumns.push(...Object.keys(this.calculationMappings));
			this.orderedListOfColumns = this.orderedListOfColumns.filter(col => col !== 'cNotes');
		}

		this.orderedListOfColumns = [
			...this.orderedListOfColumns,
			...Object.keys(this.universalMappings).filter(val => !['period_id', 'product_group_id', 'product_id', 'customer_id',
				'customer_group_id', 'product_import_name', 'customer_import_name', 'notes'].includes(val)),
			...listOfColumns.filter(val => val.includes('seller_id_')),
			...listOfColumns.filter(val => val.includes('seller_import')),
			...Object.keys(this.universalMappings).filter(val => ['product_import_name', 'product_group_id', 'product_id'].includes(val) && listOfColumns.includes('product_id')),
			...Object.keys(this.universalMappings).filter(val => ['customer_import_name', 'customer_group_id', 'customer_id'].includes(val) && listOfColumns.includes('customer_id')),
			...listOfColumns.filter(val => val.includes('qty_')),
			...listOfColumns.filter(val => val.includes('date')),
			...listOfColumns.filter(val => val.includes('tag_')),
			...listOfColumns.filter(val => val.includes('text_')),
			'notes'
		];
		if(this.ddArgs.functionEnum === processingDataViewerFunctions.Processed){
			this.orderedListOfColumns = this.orderedListOfColumns.filter(col => this.uniqueListOfActiveColumns.has(col));
			if(!this.orderedListOfColumns.includes('cNotes')){
				this.orderedListOfColumns.push('cNotes');
			}
		}
		columns.push({ checkboxSelection: true, maxWidth: 50, filter: false, field: '', headerName: '', lockVisible: true },);
		for (const col of this.orderedListOfColumns) {
			const types = this.setColumnType(col);
			if (this.isProcessedOutput) {
				if (this.editableCalculationColumns.indexOf(col) < 0) {
					if (types.indexOf('notEditable') < 0) {
						types.push('notEditable');
					}
				}
				else {
					const notEditableIndex = types.indexOf('notEditable');
					if (types.indexOf('notEditable') >= 0) {
						types.splice(notEditableIndex, 1);
					}
				}
			}
			columns.push({
				colId: col,
				field: col,
				headerName: this.setColumnHeadername(col),
				type: types,
			});
		}

		// Define order of the seller import columns to be immediately to the right of the corresponding seller column
		const sellerImportColumns = columns.filter(x => x.colId?.includes('seller_import'));
		columns = columns.filter(x => !(x.colId?.includes('seller_import')));
		sellerImportColumns.forEach(sellerImportColumn => {
			const index = columns.map(x => x.colId).indexOf(this.getSellerColId(sellerImportColumn.colId));
			columns.splice(index + 1, 0, sellerImportColumn);
		});

		const currentColumnCount: number = this.gridColumnApi.getColumnState().filter(x => x.hide === false && x.colId !== '0').length;
		if ((!this.layoutChangeHappening || columns?.filter(x => x?.type?.indexOf('defaultHidden') === -1).length > 2) && currentColumnCount <= 2) {
			this.columns = columns;
			this.gridApi.setColumnDefs(this.columns);
		}
		this.colDefsSet = true;
		this.createFieldsForFilterBuilder();

		this.insertGridColumns = [];
		this.columns.forEach(x => {
			const insertGridColDef = this.helperService.deepCopyTwoPointO(x);
			insertGridColDef.sortable = false;
			insertGridColDef.filter = false;
			insertGridColDef.enablePivot = false;
			insertGridColDef.enableRowGroup = false;
			insertGridColDef.suppressMovable = true;
			insertGridColDef.resizable = false;
			this.insertGridColumns.push(insertGridColDef);
		});
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		// Todo: Find longer solution to bugs introduced by having this standard true in template
		this.enableRangeSelection = false;

		this.elapsedWaitTime = 0;
		this.setPinnedBottomRowData();
		this.syncHorizontalScrollbars();

        // Setting up the Server Side Row Model
        const datasource = {
            getRows: requestParams => {
				if (!this.rapidLoadMode) {
					this.gridApi.showLoadingOverlay();
				}
				const isInit = !this.initialDataRetrieved || this.uniqueListOfActiveColumns?.size === 0;
                const apiRequest = new GetFlatXactionForProcessing(this.ddArgs.functionEnum, this.ddArgs.popupArgs, isInit, this.prepareSSRMRequest(requestParams.request), this.requestList);
				this.requestList = [RDVRequestsEnum.ROWS];
				if(this.filterBuilderValue && this.filterBuilderValue.length > 0){
					this.requestList.push(RDVRequestsEnum.FILTER);
				}
				this.lastRowsRequest = apiRequest;

                if (isInit) {
					const rowDataApiRequest = new GetFlatXactionForProcessing(this.ddArgs.functionEnum, this.ddArgs.popupArgs, false,
						this.prepareSSRMRequest(requestParams.request), [RDVRequestsEnum.ROWS]);
					const rowCountApiRequest = new GetFlatXactionForProcessing(this.ddArgs.functionEnum, this.ddArgs.popupArgs, false,
						this.prepareSSRMRequest(requestParams.request), [RDVRequestsEnum.NUMROWS]);
					const isNotFirstDataRetrieval = this.initialDataRetrieved;
                    this.initialDataRetrieved = true;
					this.leftStatusBarComponent.clearRowCount();
                    forkJoin([this.xactionService.getXactionMappings(),
                        this.xactionService.getFlatXactionForProcessing(apiRequest),
                        this.xactionService.getAllXactionColumns(this.ddArgs.functionEnum === processingDataViewerFunctions.Processed),
                        // Todo: Don't hack this, potentially always send a datasource id through the ddArgs
                        this.datasourceId ? this.datasourceService.getDefaultDataSourceMappingId(this.datasourceId) : of(null),
                        this.periodService.isPeriodLocked(this.periodId),
                        this.periodService.getAllPeriodId(),
                        this.periodService.getPeriods(),
						this.buildingBlockService.getAllSavedColumns(),
						this.fieldService.getDeletedTagIds()])
                    .subscribe(([mappings, flatXactionRowsResponse, allXactionColumns, datasourceMapppingId, isPeriodLocked, allPeriodsId, periods, savedColumns, deletedTagIds]) => {
						savedColumns.results.forEach(savedColumn => this.calculationMappings[savedColumn.systemName] = savedColumn.name);
						this.savedColumns = savedColumns.results;
						this.periods = periods;
						this.lockedPeriodIds = periods.filter(x => x.isLocked).map(x => x.id);
                        if (this.periodRangeOptions.length === 0) {
                            this.periodRangeOptions = this.periods.filter(period => period.recurrenceId === this.ddArgs.popupArgs.recurrenceId);
                        }
						this.periodRangeLabels = this.periodRangeOptions.map(period => ({
							id: period.id,
							name: `${this.helperService.createDateRangeString(period.beginDate, period.endDate)}`
						}));
                        if (!this.startPeriodRange) {
                            this.startPeriodRange = this.endPeriodRange = this.periods.find(period => period.id === this.periodId && this.ddArgs.popupArgs.recurrenceId === period.recurrenceId);
                        }

						const shouldRetainOverlay = this.handleFlatXactionForProcessingResponse(flatXactionRowsResponse, requestParams, apiRequest);
						this.uniqueListOfActiveColumns = new Set<string>();
						flatXactionRowsResponse.result.nonNullColumns.forEach(element => {
							this.uniqueListOfActiveColumns.add(element);
						});
                        this.masterMapping = mappings;
						this.deletedTagIds = deletedTagIds;
						this.addDynamicTagColumnTypes();
                        this.columnMappings = allXactionColumns;
                        this.prepareColumnsForBulkEdit();
						if (this.isProcessedOutput) {
							this.columnValues = [];
							this.editableCalculationColumns.forEach(x => {
								this.columnValues.push(this.calculationMappings[x]);
							});
						} else {
							this.columnValues = Object.values(this.columnMappings);
						}
                        this.columnTypes['sellerLookup'].refData = this.masterMapping['seller'];
                        this.columnTypes['sellerImportLookup'].refData = this.masterMapping['sellerImport'];
                        this.columnTypes['tagLookup'].refData = this.masterMapping;
                        this.columnTypes['productLookup'].refData = this.masterMapping['product'];
                        this.columnTypes['customerLookup'].refData = this.masterMapping['customer'];
                        this.datasourceMappingId = datasourceMapppingId;
                        this.isPeriodLocked = isPeriodLocked;
                        this.allPeriodsId = allPeriodsId;
                        if (this.isProcessedOutput) {
                            this.isAllPeriods = false;
                        } else {
                            this.isAllPeriods = Object.keys(this.masterMapping['allPeriodsDatasource']).includes(this.datasourceId.toString());
                        }
                        if(this.isPeriodLocked && !this.isAllPeriods){
                            this.defaultColDef.editable = false;
                            this.$isGridEditable.next(false);
                        }

                        this.setTagGroupColumnNames();
                        this.createColumns(allXactionColumns);
                        this.bulkEditFieldSelectorOptions = new CoreEditorOptions().selectBox(this.columnValues, this.bulkEditFieldSelected, null, null, null, false);
                        this.applyState();

						// This handles cases in which user opens an empty datasource but then broadens the period range to get records
						if (isNotFirstDataRetrieval && this.uniqueListOfActiveColumns.size > 0) {
							const columnState = this.gridColumnApi.getColumnState();
							if (columnState.filter(x => x.colId !== '0' && x.hide === false).length <= 2) {
								this.columns.filter(x => x.type?.indexOf('defaultHidden') < 0).forEach(col => {
									columnState.find(x => x.colId === col.colId).hide = false;
								});
								this.gridColumnApi.applyColumnState({ state: columnState, applyOrder: true });
							}
						}
                        const defaultLayoutProp = this.ddArgs.requiredProperties.find(prop => prop.propertyName === 'uiViewId');

						if (!this.defaultLayoutSet || this.uniqueListOfActiveColumns?.size > 0) {
							this.defaultLayoutSet = true;
							if (defaultLayoutProp) {
								this.defaultSendLayout(defaultLayoutProp.propertyValue);
								this.ddArgs.requiredProperties = this.ddArgs.requiredProperties.filter(prop => prop.propertyName !== 'uiViewId');
							} else {
								const savedDefaultLayout = this.uiViewService.getDefaultRecordLayout(this.authService.getTenant(), this.layoutSourceField, this.layoutSourceId);
								if (savedDefaultLayout) {
									this.defaultSendLayout(+savedDefaultLayout);
								}
							}
						}

						if (!shouldRetainOverlay) {
							this.gridApi.hideOverlay();
						}

						// Pass data to filter builder
						this.createFieldsForFilterBuilder();

						this.layoutChangeHappening = false;

						this.xactionService.getFlatXactionForProcessing(rowDataApiRequest).subscribe(response => {
							this.handleFlatXactionForProcessingResponse(response, requestParams, rowDataApiRequest);
							this.layoutChangeHappening = false;
						});

						this.xactionService.getFlatXactionForProcessing(rowCountApiRequest).subscribe(response => {
							this.handleFlatXactionForProcessingResponse(response, requestParams, rowCountApiRequest);
							this.layoutChangeHappening = false;
						});
                    });

                } else {
                    this.xactionService.getFlatXactionForProcessing(apiRequest).subscribe(response => {
						this.handleFlatXactionForProcessingResponse(response, requestParams, apiRequest);
						this.layoutChangeHappening = false;
                    });
                }
            }
        };
        params.api.setServerSideDatasource(datasource);
	}

	onInsertGridReady(params) {
		this.insertGridApi = params.api;
		this.insertGridColumnApi = params.columnApi;
	}

	handleFlatXactionForProcessingResponse(response: CoreResponse<AgGridServerSideRowsResponse>, requestParams: any, apiRequest: GetFlatXactionForProcessing): boolean {
		let shouldRetainOverlay = false;
		if (response.responseCode === coreResponseCodes.Success) {
			this.setSecondaryColumnsForPivot(response.result.secondaryColumnData);
			if(response.result){
				const successParams = {};
				successParams['rowData'] = this.records;
				successParams['rowCount'] = this.rowCount;
				if(apiRequest.hasRowContentChanged){
					this.applyPendingUpdates(response.result.rows);
					successParams['rowData'] = response.result.rows;
					this.records = response.result.rows;
				}

				if(apiRequest.hasNumRowsChanged){
					successParams['rowCount'] = response.result.rowCount;
					this.rowCount = response.result.rowCount;
				}

				if(apiRequest.hasNumRowsChanged || apiRequest.hasRowContentChanged){
					const areRowsLoadedBeforeCount: boolean = successParams['rowData'].length > successParams['rowCount'];
					if(areRowsLoadedBeforeCount){
						successParams['rowCount'] = 'Loading';
					}
					requestParams.success(successParams);
					if(!areRowsLoadedBeforeCount){
						this.leftStatusBarComponent.updateRowCountText(this.rowCount, this.rowCount);
					}
				}

				if (!this.rapidLoadMode && requestParams.request.endRow < response.result.filteredRecordCount) {
					shouldRetainOverlay = true;
					setTimeout(() => {
						const row = this.gridApi.getRowNode(response.result.rows[response.result.rows.length - 1].id);
						this.gridApi.ensureIndexVisible(row ? row.rowIndex : 0, 'top');
						this.gridApi.hideOverlay();
					});
				} else if (!this.rapidLoadMode) {
					this.gridApi.ensureIndexVisible(0, 'top');
				}
			}

			if(apiRequest.hasNumRowsChanged){
				if (requestParams.request.groupKeys.length === 0 || requestParams.request.groupKeys.length !== requestParams.request.rowGroupCols.length) {
					this.columnTotals = response.result.columnTotals;
				}
			}
			if(apiRequest.hasFilterChanged){
				this.filteredRecordCount = response.result.filteredRecordCount;
				this.toolPanelComponent.filteredRecordCount = response.result.filteredRecordCount;
				this.exportWarningVisible = response.result.filteredRecordCount > this.exportWarningRowCount;
				this.leftStatusBarComponent.updateRowCountText(response.result.filteredRecordCount, response.result.unfilteredRecordCount);
			}
			this.elapsedWaitTime = 0;
			this.setPinnedBottomRowData();

			response.result.invalidCondFormats?.forEach(condFormat => {
				this.toast.error('Invalid conditional format expression: ' + condFormat.expression);
			});
		} else if(response.responseCode === coreResponseCodes.CanceledByUser) {
			return false;
		} else {
			this.toast.error(response.message, response.messageHeader);
			requestParams.fail();
			if (response.message.includes('timeout')) {
				this.checkForOverloadedColumnFilter();
			}
		}
		if(this.rowsAutosized === 1){
			this.gridColumnApi.autoSizeAllColumns();
			this.rowsAutosized = 2;
		} else if(this.rowsAutosized === 0){
			this.rowsAutosized = 1;
		}
		if (!shouldRetainOverlay) {
			this.gridApi.hideOverlay();
		}
		return shouldRetainOverlay;
	}

	prepareSSRMRequest(rawRequest: AgGridServerSideRowsRequest): AgGridServerSideRowsRequest {
		const request = this.helperService.deepCopyTwoPointO(rawRequest);

		// Quick search
		if (this.statusBarComponent.quickSearchValue !== this.previousQuickSearchValue) {
			this.previousQuickSearchValue = this.statusBarComponent.quickSearchValue;
			if(!this.helperService.isNullOrEmpty(this.statusBarComponent.quickSearchValue)){
				request.quickSearchValue = this.statusBarComponent.quickSearchValue;
				request.quickSearchCols = this.getQuickSearchCols();
			}
			this.requestList.push(RDVRequestsEnum.NUMROWS);
		}

		// If grouping by seller import - tell the API to group by the seller
		request.groupKeys.forEach((groupKey, i) => {
			if (request.rowGroupCols[i].id.includes('seller_import')) {
				request.rowGroupCols[i].id = request.rowGroupCols[i].id.replace('seller_import', 'seller_id');
				request.rowGroupCols[i].field = request.rowGroupCols[i].id;
				const sellerImportId = this.lookupKey(this.masterMapping['sellerImport'], groupKey, 'sellerImport');
				const sellerId = this.lookupKey(this.masterMapping['sellerToDefaultImport'], sellerImportId, 'sellerToDefaultImport');
				request.groupKeys[i] = this.masterMapping['seller'][sellerId];
			}
		});

		request.filterModel = this.cleanSSRMFilterModel(request.filterModel);
		request.filterBuilderValue = this.filterBuilderValue;

		return request;
	}

	checkForOverloadedColumnFilter(): void {
		// Given that there was a server timeout, check the filters to determine if it was most likely caused by attempting to filter on
		//   an extremely large number of values - and if so, inform the user
		const highFilterCount = 1000;
		let columnKey;
		let columnFilterCount = 0;
		Object.entries(this.lastRowsRequest.request.filterModel).forEach(([key, value]) => {
			if (value['filterType'] === 'set' && value['values'].length > highFilterCount && value['values'].length > columnFilterCount) {
				columnKey = key;
				columnFilterCount = value['values'].length;
			} else if (value['filterType'] === 'multi' && value['filterModels']) {
				Object.entries(value['filterModels']).forEach(([mKey, mValue]) => {
					if (mValue && mValue['filterType'] === 'set' && mValue['values'].length > highFilterCount && mValue['values'].length > columnFilterCount) {
						columnKey = key;
						columnFilterCount = mValue['values'].length;
					}
				});
			}
		});
		if (columnKey && this.columnMappings[columnKey]) {
			setTimeout(() => {
				let warningMessage = `Column '${this.columnMappings[columnKey]}' is attempting to filter on an unusually high number of values`;
				warningMessage += ` (${String.format('{0:n0}', columnFilterCount)}). Try using 'Reset' or filtering by less values for faster load times.`;
				this.toast.warning(warningMessage);
			}, 1500);
		}
	}

    getColumnFilterValues(columnName: string, fieldType: ProcessingFieldType): Observable<any[]> {
        const request: AgGridFilterValuesRequest = {
            columnName,
            fieldType,
            parameters: this.lastRowsRequest
        };
		request.parameters.request.filterModel = this.currentFilterModel;
        return this.xactionService.getColumnFilterValues(request).pipe(map(response => {
			let columnFilterValues = [];
            switch (fieldType) {
                case ProcessingFieldType.Long:
                    columnFilterValues = response.longValues;
					break;
                case ProcessingFieldType.String:
                    columnFilterValues = response.stringValues;
					break;
                case ProcessingFieldType.Double:
                    columnFilterValues = response.doubleValues;
					break;
                case ProcessingFieldType.DateTime:
                    columnFilterValues = response.dateValues;
					break;
                case ProcessingFieldType.Boolean:
                    columnFilterValues = response.booleanValues;
					break;
                default:
                    columnFilterValues = [];
            }

			// Include values that are currently in the filter
			if (request.parameters.request.filterModel && request.parameters.request.filterModel[columnName]) {
				const setValues = request.parameters.request.filterModel[columnName].filterType === 'set' ? request.parameters.request.filterModel[columnName].values
					: request.parameters.request.filterModel[columnName].filterModels.find(x => x?.filterType === 'set')?.values;
				setValues?.forEach(value => {
					if (!columnFilterValues.includes(value)) {
						columnFilterValues.push(value);
					}
				});
			}

			return columnFilterValues;
        }));
    }

	sortColumnFilterValuesComparator(type: agGridSecondaryColumnType, a: any, b: any, field: string = null): number {
		// We want '(Blanks)' to appear before real values
		if (a === null) { return -1; };
		if (b === null) { return 1; };

		const aMapped = this.getMappedValue(type, a, field);
		const bMapped = this.getMappedValue(type, b, field);

		if (aMapped === bMapped) {
			return 0;
		} else {
			return aMapped.localeCompare(bMapped);
		}
	}

	emptyColumnFilterValuesComparator(a: any, b: any): number {
		// By simply returning 0 in this comparator method, we keep the same order that was returned from the server
		return 0;
	}

	addDynamicTagColumnTypes(): void {
		Object.keys(this.masterMapping).forEach(key => {
			if (key.startsWith('tag_id_')) {
				this.columnTypes[key] = {
					filterParams: {
						comparator: (a, b) => this.sortColumnFilterValuesComparator(agGridSecondaryColumnType.TagId, a, b, key)
					}
				};
			}
		});
	}

	setSecondaryColumnsForPivot(secondaryColumnData: AgGridSecondaryColumn[]): void {
		const secondaryColumns: (ColDef | ColGroupDef)[] = this.recursiveCreateSecondaryColumns(secondaryColumnData);
		this.gridColumnApi.setPivotResultColumns(secondaryColumns);
	}

	recursiveCreateSecondaryColumns(secondaryColumnData: AgGridSecondaryColumn[]): (ColDef | ColGroupDef)[] {
		const secondaryColumns: (ColDef | ColGroupDef)[] = [];
		secondaryColumnData.forEach(x => {
			if (x.type === agGridSecondaryColumnType.Aggregate) {
				const colDef: ColDef = {
					colId: x.columnName,
					field: x.columnName,
					headerName: this.getSecondaryColumnHeaderName(x),
					type: this.columns.find(y => y.colId === x.field).type
				};
				secondaryColumns.push(colDef);
			} else {
				const colGroupDef: ColGroupDef = {
					groupId: x.value,
					headerName: this.getSecondaryColumnHeaderName(x),
					children: this.recursiveCreateSecondaryColumns(x.children)
				};
				secondaryColumns.push(colGroupDef);
			}
		});
		return secondaryColumns;
	}

	getSecondaryColumnHeaderName(data: AgGridSecondaryColumn): string {
		return this.getMappedValue(data.type, data.value, data.field);
	}

	getMappedValue(columnType: agGridSecondaryColumnType, value: any, field: string = null, isGroupedColumn: boolean = false): string {
		let mappedValue = '';
		switch (columnType) {
			case agGridSecondaryColumnType.Aggregate:
				mappedValue = this.columns.find(x => x.colId === field).headerName;
				break;
			case agGridSecondaryColumnType.SellerId:
				mappedValue = this.lookupMappingFunction(parseInt(value, 10), this.masterMapping['seller']);
				break;
			case agGridSecondaryColumnType.SellerImportId:
				mappedValue = this.lookupMappingFunction(parseInt(value, 10), this.masterMapping['sellerImport']);
				break;
			case agGridSecondaryColumnType.RuleId:
				mappedValue = this.lookupMappingFunction(value, this.masterMapping['rule']);
				break;
			case agGridSecondaryColumnType.TagId:
				mappedValue = this.lookupMappingFunction(parseInt(value, 10), this.masterMapping[field]);
				break;
			case agGridSecondaryColumnType.SeriesId:
				mappedValue = this.lookupMappingFunction(parseInt(value, 10), this.masterMapping['series']);
				break;
			case agGridSecondaryColumnType.DatasourceId:
				mappedValue = this.lookupMappingFunction(parseInt(value, 10), this.masterMapping['datasourceId']);
				break;
			case agGridSecondaryColumnType.PeriodId:
				mappedValue = this.lookupMappingFunction(parseInt(value, 10), this.masterMapping['period']);
				break;
			case agGridSecondaryColumnType.ProductGroupId:
				mappedValue = this.lookupMappingFunction(parseInt(value, 10), this.masterMapping['productGroup']);
				break;
			case agGridSecondaryColumnType.ProductId:
				mappedValue = this.lookupMappingFunction(parseInt(value, 10), this.masterMapping['product']);
				break;
			case agGridSecondaryColumnType.CustomerGroupId:
				mappedValue = this.lookupMappingFunction(parseInt(value, 10), this.masterMapping['customerGroup']);
				break;
			case agGridSecondaryColumnType.CustomerId:
				mappedValue = this.lookupMappingFunction(parseInt(value, 10), this.masterMapping['customer']);
				break;
			case agGridSecondaryColumnType.Date:
				if (value !== null) {
					const formatString = this.helperService.getDateFormatString(this.masterMapping['singletonFormat'][field]);
					mappedValue = String.format(`{0:${formatString}}`, new Date(value));
				}
				break;
			case agGridSecondaryColumnType.Number:
				if (isGroupedColumn && mappedValue !== undefined && mappedValue !== null) {
					mappedValue = value.toString();
				}
				break;
			case agGridSecondaryColumnType.Boolean:
				mappedValue = parseInt(value, 10) === 1 ? 'Checked' : 'Unchecked';
				break;
		}
		if (mappedValue === undefined || mappedValue === null || mappedValue.length === 0) {
			mappedValue = value;
		}

		return mappedValue;
	}

	resetPinnedBottomRowData() {
		this.gridApi.setPinnedBottomRowData([this.getPinnedTotals(this.gridColumnApi.getAllGridColumns())]);
	}

	onFilterChanged(params) {
		this.requestList.push(RDVRequestsEnum.NUMROWS);
		this.requestList.push(RDVRequestsEnum.FILTER);
		this.resetPinnedBottomRowData();

		// For multi-filters (numeric columns) when the user adds a number filter, we want to update the set filter options
		const filterModel = params.api.getFilterModel();
		const filterModelCopy = this.helperService.deepCopyTwoPointO(filterModel);
		this.currentFilterModel = this.cleanSSRMFilterModel(filterModel);
		Object.keys(filterModel).forEach(col => {
			if (filterModel[col].filterType === 'multi' && this.lastOpenMultiFilterColId && col === this.lastOpenMultiFilterColId) {
				this.refreshFilterValues(col, params);
			}
		});
	}

	refreshFilterValues(colId: string, params: any): void {
		const multiFilterInstance = params.api.getFilterInstance(colId);
		if (multiFilterInstance.filters.length >= 2 && multiFilterInstance.filters[1].filterNameKey === 'setFilter') {
			multiFilterInstance.filters[1].refreshFilterValues();
		}
	}

	onFilterOpened(e): void {
		this.lastOpenMultiFilterColId = e.column.colDef.filter === 'agMultiColumnFilter' ? e.column.colId : null;
	}

	onFirstDataRendered(e): void {
		this.gridColumnApi.autoSizeAllColumns();
		if (this.sellerFilterVals && this.isProcessedOutput){
			this.setSellerColumnFilter(this.sellerFilterVals);
		}
	}

	getAllColumnDefs(filterValue = undefined): ColDef[] {
		const allColumns = this.gridColumnApi.getColumns();
		if (filterValue) {
			return allColumns.map(column => column.getColDef()).filter(colDef => colDef.field?.includes(filterValue));
		} else {
			return allColumns.map(column => column.getColDef())
				.filter(colDef => !colDef.field?.includes('seller_id_') && !colDef.field?.includes('seller_import_') && !colDef.field?.includes('text_') &&
					!colDef.field?.includes('tag_id_') && !colDef.field?.includes('qty_') && !colDef.field?.includes('date_'));
		}
	}

	setColumnHeadername(unmappedColumnName: string): string {
		if (Object.keys(this.universalMappings).includes(unmappedColumnName)) {
			return this.universalMappings[unmappedColumnName];
		} else if (Object.keys(this.calculationMappings).includes(unmappedColumnName)) {
			return this.calculationMappings[unmappedColumnName];
		} else if (unmappedColumnName.includes('seller_import')) {
			return this.columnMappings[unmappedColumnName];
		} else {
			return this.masterMapping['singleton'][unmappedColumnName];
		}
	}

	setColumnType(unmappedColumnName: string): string[] {
		const groupableCheck: string[] = this.groupableColumnCheckVisible();
		const defaultHidden: string[] = this.uniqueListOfActiveColumns.has(unmappedColumnName) ? [] : ['defaultHidden'];

		if (Object.keys(this.universalMappings).includes(unmappedColumnName)) {
			if (unmappedColumnName === 'id') {
				return ['defaultHidden', 'textField', 'notEditable'];
			} else if (unmappedColumnName === 'datasource_id') {
				if (this.ddArgs.popupArgs?.datasourceIds?.length > 1) {
					return ['notEditable', 'datasourceLookup'];
				} else {
					return ['notEditable','defaultHidden','datasourceLookup'];
				}
			} else if (unmappedColumnName === 'series_id') {
				return ['notEditable','defaultHidden','seriesLookup'];
			} else if (unmappedColumnName === 'period_id') {
				return ['periodLookup', 'notEditable',];
			} else if (unmappedColumnName === 'datasource_mapping_id') {
				return ['notEditable', 'defaultHidden', 'datasourceMappingLookup'];
			} else if (unmappedColumnName === 'product_id') {
				if (!groupableCheck.includes('product_id')) {
					return ['productLookup', 'defaultHidden'];
				}
				return ['productLookup'];
			} else if (unmappedColumnName === 'product_group_id') {
				if (!groupableCheck.includes('product_group_id')) {
					return ['productGroupLookup', 'notEditable', 'defaultHidden'];
				}
				return ['productGroupLookup', 'notEditable'];
			} else if (unmappedColumnName === 'customer_id') {
				if (!groupableCheck.includes('customer_id')) {
					return ['customerLookup', 'defaultHidden'];
				}
				return ['customerLookup'];
			} else if (unmappedColumnName === 'customer_group_id') {
				if (!groupableCheck.includes('customer_group_id')) {
					return ['customerGroupLookup', 'defaultHidden', 'notEditable'];
				}
				return ['customerGroupLookup', 'notEditable'];
			} else if (unmappedColumnName === 'notes') {
				return ['textArea'];
			}
			return ['notEditable', 'defaultHidden'];
		} else if (Object.keys(this.calculationMappings).includes(unmappedColumnName)) {
			if (unmappedColumnName === 'seller_id') {
				return ['sellerLookup'];
			} else if (unmappedColumnName === 'set_id') {
				return ['defaultHidden'];
			} else if (unmappedColumnName === 'saved_column_id') {
				return ['savedColumnLookup', 'notEditable'];
			} else if (unmappedColumnName === 'rule_id') {
				return ['ruleLookup'];
			} else if (unmappedColumnName === 'is_payout') {
				return ['notEditable', 'checkbox'];
			} else if (unmappedColumnName === 'cId') {
				return ['notEditable', 'defaultHidden'];
			} else if (unmappedColumnName === 'value') {
				return [...defaultHidden, 'qtyField', 'numericColumn', 'currency'];
			} else if (unmappedColumnName.startsWith('saved_column_')) {
				return [...defaultHidden, 'qtyField', 'numericColumn'];
			} else if (unmappedColumnName === 'cNotes') {
				return ['textField', 'defaultHidden'];
			}
			return ['notEditable'];
		} else if (unmappedColumnName.includes('seller_id')) {
			return [...defaultHidden,'sellerLookup'];
        } else if (unmappedColumnName.includes('seller_import')) {
			return ['defaultHidden','sellerImportLookup'];
        } else if (unmappedColumnName.includes('tag_')) {
            // Each tag lookup column has its own type, for filter values sorting
			return [...defaultHidden, 'tagLookup', unmappedColumnName];
		} else if (unmappedColumnName.includes('calc_qty_')) {
			return [...defaultHidden, 'qtyField', 'numericColumn', 'notEditable'];
		} else if (unmappedColumnName.includes('qty_')) {
			return [...defaultHidden, 'qtyField', 'numericColumn'];
		} else if (unmappedColumnName.includes('date_')) {
			return [...defaultHidden,'dateField', 'rightAligned'];
		} else if (unmappedColumnName.includes('text_')) {
			return [...defaultHidden,'textField'];
		} else {
			return [...defaultHidden];
		}
	}

	lookupMappingFunction(column, mapping, mainKey = null): string {
		try {
			if (mainKey) {
				return mapping[mainKey][column];
			}
			return mapping[column];
		} catch {
			this.toast.error('Failed Mapping');
			return 'Failed To Map';
		}
	}

	lookupKey(mapping, name, masterMappingKey) {
		if (masterMappingKey !== null) {
			for (const key in mapping[masterMappingKey]) {
				if (mapping[masterMappingKey][key] === name) {
				  return key;
				}
			  }
		}
		for (const key in mapping) {
		  if (mapping[key] === name) {
			return key;
		  }
		}
	}

	groupableColumnCheckVisible(): string[] {
		const uniqueValues = new Set<string>();
		for (const record of this.records) {
			if (record['product_id']) {
				uniqueValues.add('product_id');
				uniqueValues.add('product_group_id');
			}
			if (record['customer_id']) {
				uniqueValues.add('customer_id');
				uniqueValues.add('customer_group_id');
			}
			if (uniqueValues.values.length === 4) {
				return [...uniqueValues];
			}
		}
		return [...uniqueValues];
	}

    formatQtyFields(column, mapping: any): string {
		let field = column.colDef.field;
		if (field.startsWith('_')) {
			if (field.includes('#')) {
				const splitField = field.split('#');
				field = splitField[splitField.length - 1];
			} else if (field.startsWith('_qty') || field.startsWith('_calc_qty')) {
				field = field.substr(1);
			}
		} else if (field.startsWith('saved_column_')) {
			mapping = this.masterMapping['ruleColumnFormat'];
		}

		return (!column.value && column.value !== 0) ? '' : isNaN(column.value) ? column.value : String.format(`{0:${mapping[field] ?? 'c'}}`, parseFloat(column.value));
    }

    formatDateFields(column, mapping: any): string {
		if (column?.value) {
			const dateValue = new Date(column.value as string);
			if (dateValue.getTime() === this.helperService.defaultEmptyDate().getTime()) {
                return 'INVALID';
            } else {
                const formatString = this.helperService.getDateFormatString(mapping[column.colDef.field]);
                return String.format(`{0:${formatString}}`, dateValue);
            }
		} else {
			return '';
		}
    }

	extractValues(mapping): string[] {
        return ['<null>',...Object.keys(mapping)];
	}

	extractSelectValues(mapping, isTagLookup: boolean = false): any[] {
		const selectValues = [];
		selectValues.push({ id: '<null>', name: '<null>' });

		Object.keys(mapping).forEach(key => {
			selectValues.push({ id: key, name: mapping[key], visible: (!isTagLookup || !this.deletedTagIds.includes(parseInt(key, 10))) });
		});

		return selectValues;
	}

	deleteProcedure = () => {
		if (this.checkIfPeriodLocked()) {
			return;
		}
		const selectedRows = this.gridApi.getSelectedNodes();
		for (const selectedRow of selectedRows) {
			if (![...this.nodesToInsert].includes(selectedRow)) {
				this.nodesToDelete.add(selectedRow);
			} else {
				this.toast.error('Cannot stage a delete on a row that is currently staged for insert.');
			}
		 }
		this.gridApi.redrawRows({ rowNodes: selectedRows });

		// Removing checked rows from insert grid
		const selectedInsertRows = this.insertGridApi.getSelectedNodes();
		for (const insertNode of this.nodesToInsert) {
			for (const selectedRow of selectedInsertRows) {
				if (insertNode === selectedRow) {
					this.nodesToInsert.delete(insertNode);
					this.insertGridApi.applyTransaction({ remove: [insertNode.data] });
					if (this.nodesToInsert.size === 0) {
						this.setInsertGridVisibility(false);
					}
				}
			}
		}

		this.valueChangedEvent.next(true);
	};

	unstageSelectedRows = () => {
		this.gridApi.showLoadingOverlay();
		const selectedRows = this.gridApi.getSelectedNodes();
		const selectedInsertRows = this.insertGridApi.getSelectedNodes();
		// eslint-disable-next-line guard-for-in
		for (const deleteIndex of this.nodesToDelete) {
			for (const selectedRow of selectedRows) {
				if (deleteIndex === selectedRow) {
					this.nodesToDelete.delete(deleteIndex);
				}
			}
		}

		for (const insertNode of this.nodesToInsert) {
			for (const selectedRow of selectedInsertRows) {
				if (insertNode === selectedRow) {
					this.nodesToInsert.delete(insertNode);
					this.insertGridApi.applyTransaction({ remove: [insertNode.data] });
				}
			}
		}
		if (this.nodesToInsert.size === 0) {
			this.setInsertGridVisibility(false);
		}

		for (const updateRowId in this.cellsToUpdate) {
			for (const selectedRow of selectedRows) {
				if (selectedRow.data.id === updateRowId) {
					for (const field in this.cellsToUpdate[updateRowId]) {
						const data = selectedRow.data;
						data[field] = this.cellsToUpdate[updateRowId][field][0];
						this.gridApi.applyTransaction({ update: [data]});
						delete this.cellsToUpdate[updateRowId][field];
					}
					// Todo: Should always hit this. Potentially remove.
					if (Object.keys(this.cellsToUpdate[updateRowId]).length === 0) {
						delete this.cellsToUpdate[updateRowId];
					}
				}
			}
		}
		this.gridApi.refreshCells();
		this.gridApi.redrawRows({ rowNodes: selectedRows });
		this.gridApi.hideOverlay();
	};

	async checkForCalculationRows(ourDeletes: number[]): Promise<boolean> {
		const collissions = await this.calculationService.getCalculationsCountByXactionIds(ourDeletes).toPromise();
			if (collissions > 0) {
				const warning = (`${collissions} dependent calculation row(s) will also be deleted. Are you sure you want to proceed?`);
				return confirm(warning, 'Delete calculation rows?');
			} else if (collissions === 0) {
				return true;
			} else {
				this.toast.error(this.toastConsts.lockedPeriodCalculationDelete);
				return false;
			}
	}

	getColumns = () => (this.columns.filter(col => col.editable).map(col => col.field));

	UpdateLoadingOverlay(numberOfActiveEditTypes: number) {
		if (numberOfActiveEditTypes === 0) {
			this.gridApi.hideOverlay();
		}
	}

	deselectRows(ids: number[], idProp: string): void {
		const selectedNodes = this.gridApi.getSelectedNodes();
		selectedNodes.forEach(node => {
			if (ids.find(x => x.toString() === node.data[idProp])) {
				node.setSelected(false);
			}
		});
	}

	saveChanges = async () => {
		if (this.gridApi.getEditingCells().length > 0) {
			// If there is a cell in edit mode, stop the editing so that the active cell's changes register,
			//  and are included as part of the edits that are being saved
			this.gridApi.stopEditing(false);
			setTimeout(() => {
				this.saveChanges();
			});
		} else if (this.insertGridApi.getEditingCells().length > 0) {
			// Same check as above, but for the insert grid
			this.insertGridApi.stopEditing(false);
			setTimeout(() => {
				this.saveChanges();
			});
		} else if (this.findInvalidCell()) {
			this.toast.error('Please correct all validation errors before finalizing edits.');
		} else {
			this.storeState();
			let willRefreshData = false;
			this.gridApi.showLoadingOverlay();
			let numberOfActiveEditTypes: number =
				[this.nodesToDelete.size > 0 ? 1 : 0, this.nodesToInsert.size > 0 ? 1 : 0, Object.keys(this.cellsToUpdate).length > 0 ? 1 : 0]
					.reduce((prev, curr) => (prev + curr));
			this.UpdateLoadingOverlay(numberOfActiveEditTypes);
			const xactionIdsToDelete = [...this.nodesToDelete]
				.filter(node => node.data && node.data['id'])
				.map(node => parseInt(node.data['id'], 10));
			const setIdsToDelete = [...this.nodesToDelete]
				.filter(node => node.data && node.data['set_id'])
				.map(node => node.data['set_id']);
			const promises = [];
			if (this.isProcessedOutput) {
				if (setIdsToDelete.length > 0) {
					willRefreshData = true;
					promises.push(new Promise(resolve => {
						this.calculationService.deleteCalculationsBySetIds(setIdsToDelete)
							.subscribe(_ => {
								this.requestList = [RDVRequestsEnum.NUMROWS, RDVRequestsEnum.ROWS];
								this.toast.success('Successfully deleted rows');
								this.deselectRows(setIdsToDelete, 'cId');
								this.nodesToDelete.clear();
								numberOfActiveEditTypes--;
								this.UpdateLoadingOverlay(numberOfActiveEditTypes);
								resolve(0);
							},
							(err) => {
								numberOfActiveEditTypes--;
								this.UpdateLoadingOverlay(numberOfActiveEditTypes);
								this.toast.error('Failed to delete rows.');
								resolve(0);
							});
						})
					);
				}

			}
			else {
				if (xactionIdsToDelete.length > 0) {
					willRefreshData = true;
					if (await this.checkForCalculationRows(xactionIdsToDelete)) {
						promises.push(new Promise(resolve => {
							this.calculationService.deleteCalculationsByXactionIds(xactionIdsToDelete)
							.pipe(
								mergeMap(_ => this.xactionService.deleteXactions(xactionIdsToDelete))).subscribe(response => {
										if (response.responseCode === coreResponseCodes.Success) {
											this.requestList = [RDVRequestsEnum.NUMROWS, RDVRequestsEnum.ROWS];
											this.toast.success('Successfully deleted rows');
											if (xactionIdsToDelete.length < 10000) {
												// Low amount of deleted rows - deselect them programmatically
												this.deselectRows(xactionIdsToDelete, 'id');
											} else {
												// Large amount of deleted rows, high risk that programmatic deselection would never resolve - deselect everything
												this.gridApi.deselectAll();
											}
											this.nodesToDelete.clear();
											numberOfActiveEditTypes--;
											this.UpdateLoadingOverlay(numberOfActiveEditTypes);
											resolve(0);
									} else {
										numberOfActiveEditTypes--;
										this.UpdateLoadingOverlay(numberOfActiveEditTypes);
										this.toast.error('Failed to delete rows. ' + response.message);
										resolve(0);
									}});
								})
						);
					} else {
						numberOfActiveEditTypes--;
						this.UpdateLoadingOverlay(numberOfActiveEditTypes);
					}
				}
			}

			if (this.nodesToInsert.size > 0) {
				willRefreshData = true;
				const finalNodes = [];
				const nodesToRedraw = [...this.nodesToInsert];
				const allXactionColumns = Object.keys(this.columnMappings).map(key => [key, null]);
				const nodesToRemove = [...this.nodesToInsert].map(node => node.data);
				for (const node of this.nodesToInsert) {
					const finalNode = {};
					if (this.isProcessedOutput) {
						finalNode['functionId'] = node.data['rule_id'].startsWith('Sg') ? node.data['rule_id'].substr(2): null;
						finalNode['containerId'] = node.data['rule_id'].startsWith('Co') ? node.data['rule_id'].substr(2): null;
						finalNode['periodId'] = node.data['period_id'];
						finalNode['sellerId'] = node.data['seller_id'];
						finalNode['seriesId'] = node.data['series_id'];
						finalNode['value'] = node.data['value'];
						finalNode['notes'] = node.data['cNotes'];
						if (node.data['id']) {
							finalNode['xActionId'] = node.data['id'];
						}
					}
					else {
						for (const keyValue of allXactionColumns) {
							if (!keyValue[0].startsWith('calc_qty')) {
								finalNode[keyValue[0]] = keyValue[1];
							}
						}
						Object.assign(finalNode, node.data);
						delete finalNode['id'];
						delete finalNode['product_import_name'];
						delete finalNode['product_group_id'];
						delete finalNode['customer_import_name'];
						delete finalNode['customer_group_id'];
						delete finalNode['begin_date'];
						delete finalNode['datasource_mapping_name'];
						const calcQtyKeys = Object.keys(finalNode).filter(x => x.startsWith('calc_qty'));
						calcQtyKeys.forEach(x => delete finalNode[x]);
						const sellerImportKeys = Object.keys(finalNode).filter(x => x.startsWith('seller_import'));
						sellerImportKeys.forEach(x => delete finalNode[x]);
						finalNode['localTime'] = new Date();
						finalNode['localTimeOffset'] = (new Date().getTimezoneOffset() / 60);
					}
					finalNodes.push(finalNode);
				}
				if (this.isProcessedOutput) {
					promises.push(
						new Promise(resolve => {
							this.calculationService.insertCalculations(finalNodes).subscribe(res => {
								this.requestList = [RDVRequestsEnum.NUMROWS, RDVRequestsEnum.ROWS];
								this.gridApi.redrawRows({ rowNodes: nodesToRedraw });
								this.nodesToInsert.clear();
								numberOfActiveEditTypes--;
								this.UpdateLoadingOverlay(numberOfActiveEditTypes);
								resolve(0);
							}
							, err => {
								this.toast.error('Failed to insert row. Other rows may have been inserted fine.');
								this.isAdding = false;
								numberOfActiveEditTypes--;
								this.UpdateLoadingOverlay(numberOfActiveEditTypes);
								resolve(0);
								});
							})
					);
				}
				else {
					promises.push(
						new Promise(resolve => {
							this.xactionService.insertFlatXactions(finalNodes).subscribe(response => {
								if (response.responseCode === coreResponseCodes.Success) {
								this.requestList = [RDVRequestsEnum.NUMROWS, RDVRequestsEnum.ROWS];
								this.gridApi.redrawRows({ rowNodes: nodesToRedraw });
								this.nodesToInsert.clear();
								numberOfActiveEditTypes--;
								this.UpdateLoadingOverlay(numberOfActiveEditTypes);
								resolve(0);
							} else {
								this.toast.error(response.message);
								this.isAdding = false;
								numberOfActiveEditTypes--;
								this.UpdateLoadingOverlay(numberOfActiveEditTypes);
								resolve(0);
							}});
						})
					);
				}
			}

			if (Object.keys(this.cellsToUpdate).length > 0) {
				if (this.isProcessedOutput) {
					const calculationsToUpdate = [];
					for (const rowId in this.cellsToUpdate) {
						const fieldsToUpdate = Object.keys(this.cellsToUpdate[rowId]);
						// we only need to take row data from first field, that's why we use fieldsToUpdate[0], row data is same for all other fields
						const rowData = this.cellsToUpdate[rowId][fieldsToUpdate[0]][2].data;

						const calculation = {
							id: rowData['cId'],
							periodId: rowData['period_id'],
							sellerId: rowData['seller_id'],
							seriesId: rowData['series_id'],
							value: rowData['value']
						};
						calculation['functionId'] = rowData['rule_id'].startsWith('Sg') ? rowData['rule_id'].substr(2): null;
						calculation['containerId'] = rowData['rule_id'].startsWith('Co') ? rowData['rule_id'].substr(2): null;
						calculation['columnName'] = `${rowData['rule_id']}_value`;
						calculationsToUpdate.push(calculation);
					}

					promises.push(
						new Promise(resolve => {
							this.calculationService.updateCalculations(calculationsToUpdate).subscribe(
								(res) => {
									this.cellsToUpdate = {};
									this.gridApi.refreshCells();
									numberOfActiveEditTypes--;
									this.UpdateLoadingOverlay(numberOfActiveEditTypes);
									resolve(0);
								},
								(err: any) => {
									// Todo: Reset Values
									this.toast.error(err.error ? err.error :'Edit Failed. To keep data integrity, close window and reopen. Try again in a few moments.');
									numberOfActiveEditTypes--;
									this.UpdateLoadingOverlay(numberOfActiveEditTypes);
									resolve(0);
								}
							);
						})
					);
				}
				else {
					const updateXactionsFlat: UpdateXactionFlat[] = [];
					for (const xactionId in this.cellsToUpdate) {
						const updateXactionFlat: UpdateXactionFlat = {
							LocalDateTime: new Date(),
							Notes: null,
							id: xactionId,
							ToUpdateFields: {}
						};
						for (const field in this.cellsToUpdate[xactionId]) {
							updateXactionFlat.Notes = this.cellsToUpdate[xactionId][field][2]['data']['notes'];
							if (this.cellsToUpdate[xactionId][field][1] === null) {
								updateXactionFlat.ToUpdateFields[field] = null;
							} else {
								if (field.includes('date_')) {
									const dateValue = this.cellsToUpdate[xactionId][field][1] instanceof Date
										? (this.cellsToUpdate[xactionId][field][1] as Date) : new Date(this.cellsToUpdate[xactionId][field][1]);
									let valueToWrite = `${dateValue?.getMonth() + 1}-${dateValue?.getDate()}-${dateValue?.getFullYear()}`;
									valueToWrite += ` ${dateValue?.getHours()}:${('0' + dateValue?.getMinutes()).slice(-2)}`;
									const fieldValue = this.cellsToUpdate[xactionId][field][1];
									updateXactionFlat.ToUpdateFields[field] = fieldValue && (fieldValue instanceof Date || fieldValue.length > 0)
										? valueToWrite : '';
								} else if (field.includes('text_')){
									const fieldValue = this.cellsToUpdate[xactionId][field][1];
									updateXactionFlat.ToUpdateFields[field] = fieldValue && fieldValue.length > 0 ? fieldValue : '';
								} else if (field !== 'notes') {
									const fieldValue = this.cellsToUpdate[xactionId][field][1];
									updateXactionFlat.ToUpdateFields[field] = fieldValue === '<null>' ? null : fieldValue;
								}
							}
						}
						updateXactionsFlat.push(updateXactionFlat);
					}
					promises.push(
						new Promise(resolve => {
							this.xactionService.updateXactionFlat(updateXactionsFlat).subscribe(
								(res) => {
									if (res.responseCode === coreResponseCodes.Success) {
										for (const key in res.result) {
											const arbitraryFieldKey = Object.keys(this.cellsToUpdate[key])[0];
											this.cellsToUpdate[key][arbitraryFieldKey][2]['data']['notes'] = res.result[key];
										}
										this.cellsToUpdate = {};
										this.gridApi.refreshCells();
										numberOfActiveEditTypes--;
										this.UpdateLoadingOverlay(numberOfActiveEditTypes);
										resolve(0);
								} else {
									// Todo: Reset Values
									this.toast.error(res.message ? res.message :'Edit Failed. To keep data integrity, close window and reopen. Try again in a few moments.');
									numberOfActiveEditTypes--;
									this.UpdateLoadingOverlay(numberOfActiveEditTypes);
									resolve(0);
								}
						});
						})
					);
				}


			}

			await Promise.all(promises);
			this.applyState();
			this.resetPinnedBottomRowData();

			this.setInsertGridVisibility(false);
			this.insertRecords = [];
			if (willRefreshData) {
				this.gridApi.refreshServerSide({ route: [], purge: false });
			}

			this.valueChangedEvent.next(false);
		}
	};

	checkIfPeriodLocked(): boolean {
		const selectedRowsUniquePeriodIds = [...new Set(this.gridApi.getSelectedNodes().map(row => +row.data.period_id))];
		if (selectedRowsUniquePeriodIds.some(pid => this.lockedPeriodIds.includes(parseInt(pid.toString(), 10)))) {
			this.toast.warning('Period is locked. Inserts, Deletes, and Edits are prohibited. Contact admin if changes need to be made.');
			return true;
		}
		return false;
	}

	addRowProcedure = async () => {
		if (this.rowCount === 0) {
			// This ensures that the insert grid's columns will align with the main grid when there is a layout applied but empty
			this.alignGridColumns();
		}

		this.setInsertGridVisibility(true);

		let periodIdToInsert = this.startPeriodRange?.id ?? this.periodId;
		if (this.checkIfPeriodLocked()) {
			return;
		}

		if (this.isAllPeriods) {
			periodIdToInsert = this.allPeriodsId;
		}
		const idKey = this.isProcessedOutput ? 'set_id': 'id';
		const newRow = {
			period_id: periodIdToInsert,
			series_id: this.seriesId,
			datasource_id: this.datasourceId,
			datasource_mapping_id: this.datasourceMappingId,
			[idKey]: this.helperService.generateRandomGuid(),
			notes: '',
		};
		const changes = this.insertGridApi.applyTransaction({ add: [newRow] });
		this.nodesToInsert.add(changes.add[0]);
		this.insertGridApi.ensureIndexVisible(changes.add[0].rowIndex);
		this.insertGridApi.redrawRows({rowNodes: [...this.nodesToInsert]});
		setTimeout(
			() => (this.isAdding = false),
			500);
	};

	updateProcedure = (changes): void => {
		const idKey = this.isProcessedOutput ? 'set_id': 'id';
		if ([...this.nodesToDelete].map(node => node.data[idKey]).includes(changes.data[idKey])) {
			changes.node.data[changes.colDef.field] = changes.oldValue;
			changes.api.refreshCells({ rowNodes: [changes.node], columns: [changes.column.colId] });
			this.toast.error('Unstage delete change on this row before making edits to cells.');
			return;
		}
		else if (![...this.nodesToInsert].map(node => node.data[idKey]).includes(changes.data[idKey])) {
			const isTag: boolean = (changes.colDef.field as string).includes('tag_');
			const oldValue = changes.colDef.refData ? this.lookupKey(changes.colDef.refData, changes.oldValue, isTag ? changes.colDef.field : null) : changes.oldValue;
			if (!this.cellsToUpdate[changes.data[idKey]]) {
				this.cellsToUpdate[changes.data[idKey]] = {};
				this.cellsToUpdate[changes.data[idKey]][changes.colDef.field] = [oldValue, changes.data[changes.colDef.field], changes.node];
			} else if(this.cellsToUpdate[changes.data[idKey]][changes.colDef.field]){
				this.cellsToUpdate[changes.data[idKey]][changes.colDef.field][1] = changes.data[changes.colDef.field];
				this.cellsToUpdate[changes.data[idKey]][changes.colDef.field][2] = changes.node;
			} else {
				this.cellsToUpdate[changes.data[idKey]][changes.colDef.field] = [oldValue, changes.data[changes.colDef.field], changes.node];
			}
		}

		// If product or customer changed, check to see if we need to highlight product group or customer group
		if (changes.colDef.colId === 'product_id' || changes.colDef.colId === 'customer_id') {
			this.handleGroupColumnChanges(changes.colDef.colId, changes.oldValue, changes.newValue, changes.data, changes.node, idKey, true);
		} else if (changes.colDef.colId.includes('seller_id')) {
			this.handleSellerImportChanges(changes.colDef.colId, changes.oldValue, changes.newValue, changes.data, changes.node, idKey);
		}

		this.gridApi.refreshCells();
	};

	bulkEditUpdateProcedure = (updateObject: Record<string, string[]>, columnName: string): void => {
		const idKey = this.isProcessedOutput ? 'cId': 'id';
		const selectedRows = this.gridApi.getSelectedNodes().concat(this.insertGridApi.getSelectedNodes());
		const colDef = this.gridColumnApi.getColumn(columnName).getColDef();
		for (const node of selectedRows) {
			if (node.data[idKey] === undefined) {
				continue;
			}
			const isTag: boolean = updateObject[node.data[idKey]].includes('tag_');
			const oldValue = updateObject[node.data[idKey]][0];
			const newValue = updateObject[node.data[idKey]][1];
			if ([...this.nodesToDelete].map(nodeToDelete => nodeToDelete.data[idKey]).includes(node.data[idKey])) {
				node.data[columnName] = oldValue;
				this.gridApi.refreshCells({ rowNodes: [node], columns: [columnName] });
				this.toast.error('Unstage delete change on this row before making edits to cells.');
				return;
			}
            else {
				node.data[columnName] = newValue;
				if (!this.cellsToUpdate[node.data[idKey]]) {
					this.cellsToUpdate[node.data[idKey]] = {};
					this.cellsToUpdate[node.data[idKey]][columnName] = [oldValue, newValue, node];
				} else if(this.cellsToUpdate[node.data[idKey]][columnName]){
					this.cellsToUpdate[node.data[idKey]][columnName][1] = newValue;
					this.cellsToUpdate[node.data[idKey]][columnName][2] = node;
				} else {
					this.cellsToUpdate[node.data[idKey]][columnName] = [oldValue, newValue, node];
				}

				// If product or customer changed, check to see if we need to highlight product group or customer group
				if (colDef.colId === 'product_id' || colDef.colId === 'customer_id') {
					this.handleGroupColumnChanges(colDef.colId, oldValue, newValue, node.data, node, idKey, false);
				} else if (colDef.colId.includes('seller_id')) {
					this.handleSellerImportChanges(colDef.colId, oldValue, newValue, node.data, node, idKey);
				}
			}
		}

		this.gridColumnApi.setColumnVisible(columnName, true);
		this.gridApi.refreshCells();
		this.insertGridApi.refreshCells();
	};

	applyPendingUpdates(rows: any[]): void {
		const idKey = this.isProcessedOutput ? 'set_id' : 'id';
		rows.forEach(row => {
			if (Object.keys(this.cellsToUpdate).find(x => x === row[idKey])) {
				const update = this.cellsToUpdate[row[idKey]];
				Object.keys(update).forEach(field => {
					row[field] = this.cellsToUpdate[row[idKey]][field][1];
				});
			}
		});
	}

	handleGroupColumnChanges(columnId: string, oldValue: any, newValue: any, data: any, node: any, idKey: string, convertToKeys: boolean): void {

		let mappingKey;
		let groupMappingKey;
		let toGroupMappingKey;
		if (columnId === 'product_id') {
			mappingKey = 'product';
			groupMappingKey = 'product_group_id';
			toGroupMappingKey = 'productToGroup';
		} else if (columnId === 'customer_id') {
			mappingKey = 'customer';
			groupMappingKey = 'customer_group_id';
			toGroupMappingKey = 'customerToGroup';
		}

		let oldValueId;
		let newValueId;
		if (convertToKeys) {
			Object.entries(this.masterMapping[mappingKey]).forEach(([key, value]) => {
				if (oldValue === value) {
					oldValueId = key;
				} else if (newValue === value) {
					newValueId = key;
				}
			});
		} else {
			oldValueId = oldValue;
			newValueId = newValue;
		}
		if (this.masterMapping[toGroupMappingKey][oldValueId] !== this.masterMapping[toGroupMappingKey][newValueId]) {
			this.cellsToUpdate[data[idKey]][groupMappingKey] = [this.masterMapping[toGroupMappingKey][oldValueId], this.masterMapping[toGroupMappingKey][newValueId], node];;
		}
	}

	handleSellerImportChanges(columnId: string, oldValue: any, newValue: any, data: any, node: any, idKey: string): void {
		this.cellsToUpdate[data[idKey]][this.getSellerImportColId(columnId)] = [this.masterMapping['sellerToDefaultImport'][oldValue], this.masterMapping['sellerToDefaultImport'][newValue], node];
	}

	getSingleSelectedRow(): any {
		const selectedRows = this.gridApi.getSelectedRows();
		if (selectedRows.length === 0) {
			this.toast.error('No Rows selected.');
			return null;
		}
		if (selectedRows.length > 1) {
			this.toast.error('More than 1 row selected.');
			return null;
		}
		return selectedRows[0];
	}

	duplicateRowProcedure = () => {
		if (this.checkIfPeriodLocked()) {
			return;
		}
		const selectedRows = this.gridApi.getSelectedNodes().concat(this.insertGridApi.getSelectedNodes());
		const duplicateRows = [];
		for (const row of selectedRows) {
			const newRowData = Object.assign({}, row.data);
			if (row.data['id']) {
				const idKey = this.isProcessedOutput ? 'set_id': 'id';
				const notesKey = this.isProcessedOutput ? 'cNotes': 'notes';
				newRowData[notesKey] = newRowData[notesKey] ? newRowData[notesKey] + `\n(Duplicated id: ${row.data[idKey]})` : `\n(Duplicated id: ${row.data[idKey]})`;
				newRowData[idKey] = this.helperService.generateRandomGuid();
				duplicateRows.push(newRowData);
			}
		}

		if (duplicateRows.length > 0) {
			this.setInsertGridVisibility(true);

			const changes = this.insertGridApi.applyTransaction({ add: duplicateRows });

			for (const addition of changes.add) {
				this.nodesToInsert.add(addition);
			}

			this.insertGridApi.ensureIndexVisible(changes.add[changes.add.length - 1].rowIndex);
			// Todo: Test to see if we only need to redraw new rows
			this.insertGridApi.redrawRows({ rowNodes: [...changes.add] });
		}
	};

	toggleTheme = (e: any) => {
		this.setTheme(e.value);

		setTimeout(() => {
			this.gridApi.resetRowHeights();
		}, 0);
	};

	setTheme(isDarkMode: boolean): void {
        this.gridTheme = this.gridThemes[(!isDarkMode ? 0 : 1)];
        this.siteThemeConfig = this.siteThemeService.getSiteThemeConfig(this.isBITheme, isDarkMode);
        this.siteThemeService.applySiteTheme(document, this.siteThemeConfig);
    }

	toggleMode = (rapidLoadMode: boolean) => {
		this.rapidLoadMode = rapidLoadMode;
		if (this.rapidLoadMode) {
			this.gridApi.deselectAll();
		}
		this.gridApi.refreshServerSide({ route: [], purge: false });

		localStorage.setItem(this.getRapidLoadModeLocalStorageKey(), this.rapidLoadMode.toString());
	};

	getRapidLoadModeLocalStorageKey(): string {
		let recordsType;
		let ids;
		if (!this.isProcessedOutput) {
			recordsType = 'IMPORTED';
			ids = this.helperService.createBracketedIdString(this.ddArgs.popupArgs.datasourceIds);
		} else {
			recordsType = 'PROCESSED';
			ids = this.helperService.createBracketedIdString(this.ddArgs.popupArgs.planIds);
			if (this.ddArgs.popupArgs.ruleIds && this.ddArgs.popupArgs.ruleIds.length) {
				ids += `-${this.helperService.createBracketedIdString(this.ddArgs.popupArgs.ruleIds)}`;
			}
		}
		return `rdv_loadAllMode_${recordsType}_${ids}`;
	}

	selectAll(): void {
		if (!this.rapidLoadMode && this.filteredRecordCount > this.selectAllMaxRowCount) {
			this.toast.warning('Cannot use Ctrl+A to select all rows when there are more than 10,000 records. Try using \'Export All\'.', 'Select All');
		} else if (!this.rapidLoadMode) {
			this.gridApi.forEachNode(node => {
				node.setSelected(true);
			});
		} else {
			this.toast.warning('Cannot select all rows while in Rapid Load');
		}
	}

	showBulkEditPopup = () => {
		this.bulkEditPopupVisible = true;
		this.bulkEditFieldUnmapped = null;
		this.bulkEditEditorType = null;
		this.cdr.detectChanges();
		this.bulkEditEditorType = CoreInputEditorType.TextBox;
		this.bulkEditEditorOptions = new CoreEditorOptions().textBox(null, null, true).addOnFocusIn(this.onBulkEditFieldFocusIn);
		setTimeout(() => {
			const doc = document as any;
        	doc.querySelector('#bulk-edit-select-field input.dx-texteditor-input').focus();
		}, 400);
	};

	showPeriodFilterRangePopup = () => {
		this.periodFilterVisible = true;
	};

	showTraceOutputPopup = () => {
		const selectedRow = this.getSingleSelectedRow();
		if (!selectedRow) {
			return false;
		}
		this.traceOutputId = selectedRow.set_id;
		this.traceOutputVisible = true;
	};

	showExportOutputPopup = (withImport: boolean = false) => {
		this.exportWithImport = withImport;
		this.exportOutputVisible = true;
		return false;
	};

	openDiagramAuditPopup = () => {
		this.auditPopupVisible = true;
		this.auditPeriodValue = this.getSingleSelectedRow();
		return false;
	};

	openDiagramAudit = (scope, period) => {
		const isCalculationAudit = scope == null;
		const selectedRow = this.getSingleSelectedRow();
		if (!selectedRow) {
			return false;
		}
		const rule = this.buildingBlockHelperService.getActiveRuleVersionOrDefaultByPeriodId(isCalculationAudit ? selectedRow['rule_id'] : scope, period, this.periods);
		if(!isCalculationAudit){
			scope = rule.id;
		}
		const thinSelectedRow = {
			period_id: selectedRow['period_id'],
			series_id: selectedRow['series_id']
		};
		if(selectedRow['xaction_id']){
			thinSelectedRow['xaction_id'] = +selectedRow['xaction_id'];
		}
		if(selectedRow['id']){
			thinSelectedRow['xaction_id'] = +selectedRow['id'];
		}
		if(isCalculationAudit){
			const sellerFieldName = Object.keys(selectedRow).filter(key => key.includes('seller_id') && key !== 'seller_id')[0];
			const savedColumnIds = Object.keys(selectedRow).filter(key => key.includes('saved_column')).map(savedColName => +savedColName.replace('saved_column_', ''));
			const calcRule = this.buildingBlockHelperService.getAllContainers().find(container => container.id === selectedRow['rule_id']);
			savedColumnIds.forEach(id => {
				const valueColumnName = JSON.parse(calcRule['objectJson'])['savedColumnBindings'].find(scb => scb.SavedColumnId === id).LocalSystemName;
				thinSelectedRow[valueColumnName] = selectedRow['saved_column_' + id];
			});
			thinSelectedRow['seller_id'] = selectedRow['seller_id'];
			thinSelectedRow['set_id'] =  selectedRow['set_id'];
			thinSelectedRow[sellerFieldName] = selectedRow[sellerFieldName];
		} else {
            thinSelectedRow['period_id'] = period;
		}

		let filterCriteria: AuditGridFilterCriteria;
		const headProcessId = this.buildingBlockHelperService.getActiveRuleVersionOrDefaultByPeriodId(scope ?? selectedRow['rule_id'], period, this.periods).headProcessId;
		if(isCalculationAudit){
			filterCriteria = new AuditGridFilterCriteria().fromType(thinSelectedRow, [headProcessId], AuditType.Upstream);
		} else {
			filterCriteria = new AuditGridFilterCriteria().fromType(thinSelectedRow, [scope, `Ds${this.datasourceId}`], AuditType.EveryPathDownstream);
		}

		const requiredProperties = [new RequiredProperty('rowData', filterCriteria)];
		const dda = new DisplayDataArguments(requiredProperties, processingDataViewerFunctions.Imported, this.ddArgs.popupArgs);
		this.helperService.openNewTab(dda, '/building-blocks-audit/' +  (scope ?? selectedRow['rule_id']));
	};

	prepareColumnsForBulkEdit(): void {
		for (const key in this.columnMappings) {
			const value = this.columnMappings[key];
			if ( value === '' && (key !== 'product_id' && key !== 'customer_id')) {
				delete this.columnMappings[key];
			}
		}
	}

	bulkEditFieldSelected = (e) => {
		this.bulkEditFieldUnmapped = e.value;
		const mappings = this.isProcessedOutput ? this.calculationMappings : this.columnMappings;
		for (const key in mappings) {
			if (mappings[key] === e.value) {
				this.bulkEditFieldMapped = key;
				break;
			}
		}

		this.gridColumnApi.setColumnVisible(this.bulkEditFieldMapped, true);
		this.gridApi.ensureColumnVisible(this.bulkEditFieldMapped);

		if (Object.keys(this.universalMappings).includes(this.bulkEditFieldMapped)) {
		if (this.bulkEditFieldMapped === 'product_id') {
			this.bulkEditEditorType = CoreInputEditorType.SelectBox;
			this.bulkEditEditorOptions = new CoreEditorOptions().selectBox(Object.values(this.masterMapping['product']).sort(this.helperService.stringCompare),
				this.bulkEditValueChanged, null, null,
				(val) => {
					for (const key in this.masterMapping['product']) {
						if (this.masterMapping['product'][key] === val) {
							return key;
						}
					}
				}
			).enableSearch(true).addOnFocusIn(this.onBulkEditFieldFocusIn);
		} else if (this.bulkEditFieldMapped === 'customer_id') {
			this.bulkEditEditorType = CoreInputEditorType.SelectBox;
			this.bulkEditEditorOptions = new CoreEditorOptions().selectBox(Object.values(this.masterMapping['customer']).sort(this.helperService.stringCompare),
				this.bulkEditValueChanged, null, null,
				(val) => {
					for (const key in this.masterMapping['customer']) {
						if (this.masterMapping['customer'][key] === val) {
							return key;
						}
					}
				}
			).enableSearch(true).addOnFocusIn(this.onBulkEditFieldFocusIn);
			}
		} else if (this.bulkEditFieldMapped.includes('seller_id')) {
		this.bulkEditEditorType = CoreInputEditorType.SelectBox;
		this.bulkEditEditorOptions = new CoreEditorOptions().selectBox(Object.values(this.masterMapping['seller']).sort(this.helperService.stringCompare),
			this.bulkEditValueChanged, null, null,
			(val) => {
			for (const key in this.masterMapping['seller']) {
				if (this.masterMapping['seller'][key] === val) {
				return key;
				}
			}
			}
		).enableSearch(true).addOnFocusIn(this.onBulkEditFieldFocusIn);
		} else if (this.bulkEditFieldMapped.includes('seller_import')) {
			this.bulkEditEditorType = CoreInputEditorType.SelectBox;
			this.bulkEditEditorOptions = new CoreEditorOptions().selectBox(Object.values(this.masterMapping['sellerImport']).sort(this.helperService.stringCompare),
				this.bulkEditValueChanged, null, null,
				(val) => {
					for (const key in this.masterMapping['sellerImport']) {
						if (this.masterMapping['sellerImport'][key] === val) {
							return key;
						}
					}
				}
			).enableSearch(true).addOnFocusIn(this.onBulkEditFieldFocusIn);
		} else if (this.bulkEditFieldMapped.includes('qty_') || (this.isProcessedOutput && this.bulkEditFieldMapped === 'value')) {
		this.bulkEditEditorType = CoreInputEditorType.NumberBox;
		this.bulkEditEditorOptions = new CoreEditorOptions().textBox(this.bulkEditValueChanged).addOnFocusIn(this.onBulkEditFieldFocusIn);
		} else if (this.bulkEditFieldMapped.includes('text_') || (this.isProcessedOutput && this.bulkEditFieldMapped === 'cNotes')) {
		this.bulkEditEditorType = CoreInputEditorType.TextBox;
		this.bulkEditEditorOptions = new CoreEditorOptions().textBox(this.bulkEditValueChanged).addOnFocusIn(this.onBulkEditFieldFocusIn);
		} else if (this.bulkEditFieldMapped.includes('date_')) {
			this.bulkEditEditorType = CoreInputEditorType.DateBox;
			this.bulkEditEditorOptions = new CoreEditorOptions().dateBox(false, this.bulkEditValueChanged, this.bulkEditValueChanged, 'datetime', this.dateFormat)
					.addOnFocusIn(this.onBulkEditFieldFocusIn);
			this.bulkEditNewValue = String.format('{0:g}', new Date(new Date().setHours(0, 0, 0, 0)));
		} else if (this.bulkEditFieldMapped.includes('tag_')) {
		this.bulkEditEditorType = CoreInputEditorType.SelectBox;
		this.bulkEditEditorOptions = new CoreEditorOptions().selectBox(Object.values(this.masterMapping[this.bulkEditFieldMapped]).sort(this.helperService.stringCompare),
			this.bulkEditValueChanged, null, null,
			(val) => {
			for (const key in this.masterMapping[this.bulkEditFieldMapped]) {
				if (this.masterMapping[this.bulkEditFieldMapped][key] === val) {
				return key;
				}
			}
			}
		).enableSearch(true).addOnFocusIn(this.onBulkEditFieldFocusIn);
		} else if (this.bulkEditFieldMapped === 'rule_id') {
			this.bulkEditEditorType = CoreInputEditorType.SelectBox;
			this.bulkEditEditorOptions = new CoreEditorOptions().selectBox(Object.values(this.masterMapping['rule']).sort(this.helperService.stringCompare),
				this.bulkEditValueChanged, null, null,
				(val) => {
					for (const key in this.masterMapping['rule']) {
						if (this.masterMapping['rule'][key] === val) {
							return key;
						}
					}
				}
			).enableSearch(true).addOnFocusIn(this.onBulkEditFieldFocusIn);
		}
	};

	bulkEditValueChanged = (e) => {
		if (e.value === undefined) {
			return;
		}
		if (this.bulkEditFieldMapped.includes('date_') && e.value !== null) {
			try {
				if (e.value !== undefined) {
                    this.bulkEditNewValue = String.format('{0:G}', e.value as Date);
				}
			} catch (error: any) {
				this.toast.error('Date in wrong format.');
			}
			return;
		}
		this.bulkEditNewValue = e.value;
	};

	submitBulkEdit = async (e) => {
		e.preventDefault();

		if (!this.checkIfAnyRowsAreSelected()) {
			return;
		} else if (!this.bulkEditFieldUnmapped) {
			this.toast.error('No Field to Bulk Edit selected.');
			return;
		}
		const idKey = this.isProcessedOutput ? 'cId': 'id';
		const rowsToUpdate = this.gridApi.getSelectedNodes().concat(this.insertGridApi.getSelectedNodes());
		if (this.bulkEditFieldMapped.includes('seller_import')) {
			this.bulkEditFieldMapped = this.getSellerColId(this.bulkEditFieldMapped);
			this.bulkEditNewValue = this.bulkEditNewValue === null ? this.bulkEditNewValue
				: this.lookupMappingFunction(this.bulkEditNewValue, this.masterMapping['importToSeller']);
		}
		const bulkEditUpdateObject: Record<string, string[]> = {};
		for (const node of rowsToUpdate) {
			bulkEditUpdateObject[node.data[idKey]] = [node.data[this.bulkEditFieldMapped], this.bulkEditNewValue];
		}
		this.bulkEditUpdateProcedure(bulkEditUpdateObject, this.bulkEditFieldMapped);

		this.valueChangedEvent.next(true);
	};

	checkIfAnyRowsAreSelected(): boolean {
		if (this.gridApi.getSelectedRows().concat(this.insertGridApi.getSelectedRows()).length === 0) {
			this.toast.error('No Rows selected.');
			return false;
		}
		return true;
	}

	getMainMenuItems(params) {
		const defaultItems = params.defaultItems.slice(0);
		defaultItems.push({
			name: 'Shortcuts',
			action: () => {
				const ctx = params.context;
				const popup = ctx.helperService.createComponent(CoreSimplePopupComponent);
				const props: CoreSimplePopupProps = {
					message: `
					<b>Esc</b> - Deselect all rows<br>
					<b>Ctrl + Enter</b> - Stage new row for insert (Highlight green)<br>
					<b>Ctrl + Space</b> - Finalize edits<br>
					<b>Ctrl + A</b> - Select All (Load All needed)<br>
					<b>Ctrl + B</b> - Open Bulk Edit popup<br>
					<b>Ctrl + C</b> - Refresh Grid (clear staged updates)<br>
					<b>Ctrl + D</b> - Duplicate selected rows and stage them for insert (Highlight green)<br>
					<b>Ctrl + L</b> - Open Period Range popup<br>
					<b>Ctrl + U</b> - Unstage selected rows from insert, update, or delete.<br>
					<b>Ctrl + X</b> - Stage row to be deleted (Highlight red)<br>
					<b>Ctrl + Z</b> - Unstage selected cell from update<br>
					`,
					closeOnOutsideClick: true,
					height: 300,
					width: 400,
					resizeEnabled: true,
					showCancelButton: false,
					showCloseButton: false,
					shading: false,
					title: 'Grid Keyboard Shortcuts',
					okButtonText: 'Close',
				};
				popup.instance.props = props;
				ctx.helperService.injectComponent(ctx.viewContainerRef, popup);
			}
		});
		return defaultItems;
	}

	refreshProcedure = () => {
		this.requestList = [RDVRequestsEnum.FILTER, RDVRequestsEnum.NUMROWS, RDVRequestsEnum.ROWS];
		this.gridApi.showLoadingOverlay();
		this.clearAllStagedChanges();
		this.insertRecords = [];
		this.setInsertGridVisibility(false);
		this.gridApi.refreshServerSide({ route: [], purge: false });
	};

	storeState = () => {
		if (this.uniqueListOfActiveColumns?.size > 0) {
			this.columnState = this.gridColumnApi.getColumnState();
		}
		this.gridFilterState = this.gridApi.getFilterModel();
	};

	applyState = () => {
		setTimeout(() => {
			if (this.columnState) {
				this.gridColumnApi.applyColumnState({ state: this.columnState, applyOrder: true });
				if (this.gridFilterState) {
					this.gridApi.setFilterModel(this.gridFilterState);
				}
				this.clearAllStagedChanges();
				this.gridApi.refreshCells();
			}
			this.setColumnGroupingsToolPanel();
		}, 100);
	};

	clearAllStagedChanges = () => {
		this.cellsToUpdate = {};
		this.nodesToDelete.clear();
		this.nodesToInsert.clear();
	};

	setddArgsAndReload() {
		this.requestList.push(RDVRequestsEnum.FILTER);
		this.requestList.push(RDVRequestsEnum.NUMROWS);
		this.gridApi.showLoadingOverlay();
		this.ddArgs.popupArgs.periodIds = [...this.newDdargsPeriodIds];
		this.storeState();
		this.ngOnInit();
		this.gridApi.refreshServerSide({ route: [], purge: false });
	}

	onPeriodRangeFilterStartChanged = (event: any) => {
		if (event.previousValue) {
			if ( new Date(event.value.beginDate.toString()) > new Date(this.endPeriodRange.beginDate.toString())) {
				this.startPeriodRange = event.value;
				this.endPeriodRange = event.value;
			} else {
				this.startPeriodRange = event.value;
			}
		}
	};

	onPeriodRangeFilterEndChanged = (event: any) => {
		if (event.previousValue) {
			if ( new Date(event.value.beginDate.toString()) < new Date(this.startPeriodRange.beginDate.toString())) {
				this.startPeriodRange = event.value;
				this.endPeriodRange = event.value;
			} else {
				this.endPeriodRange = event.value;
			}
		}
	};

	periodFilterDisplayExpr = (val: Period) => {
		if (val) {
			return `${val.beginDate.toString().slice(0, val.beginDate.toString().length - 9)} - ${val.endDate.toString().slice(0, val.endDate.toString().length - 12)}`;
		}
		return 'Error';
	};

	submitPeriodRangeFilter = () => {
		this.periodFilterVisible = false;
		this.newDdargsPeriodIds = new Set<number>(this.periodRangeOptions
			.filter(period => (new Date(period.endDate.toString()) < new Date(this.endPeriodRange.beginDate.toString()))
			&& (new Date(period.beginDate.toString()) >= new Date(this.startPeriodRange.beginDate.toString())))
			.map(period => period.id));
		this.newDdargsPeriodIds.add(this.endPeriodRange.id);
		this.setddArgsAndReload();
	};

	defaultSendLayout(defaultLayoutId: number) {
		if (this.statusBarComponent) {
			setTimeout(() => {
				this.statusBarComponent.defaultLayoutId = defaultLayoutId;
			}, 50);
		}
	}

	setColumnGroupingsToolPanel() {
		const columnsToolPanel: IColumnToolPanel = this.gridApi.getToolPanelInstance('columns') as unknown as IColumnToolPanel;
		// set custom Columns Tool Panel layout
		const columnLayout = [
			{
				headerName: 'Seller Fields',
				children: [
					...this.getAllColumnDefs('seller_id_'),
					...this.getAllColumnDefs('seller_import_'),
				].sort((a, b) => this.columnMappings[a.colId].localeCompare(this.columnMappings[b.colId]))
			},
			{
				headerName: 'Tag Fields',
				children: [
					...this.getAllColumnDefs('tag_id_'),
				]
			},
			{
				headerName: 'Quantity Fields',
				children: [
					...this.getAllColumnDefs('qty_'),
				]
			},
			{
				headerName: 'Date Fields',
				children: [
					...this.getAllColumnDefs('date_'),
				]
			},
			{
				headerName: 'Text Fields',
				children: [
					...this.getAllColumnDefs('text_'),
				]
			},
			{
				headerName: 'Miscellaneous Fields',
				children: [
					...this.getAllColumnDefs(),
				]
			},
		];
		(columnsToolPanel).setColumnLayout(columnLayout);

		this.columnGroupMapping = {};
		columnLayout.forEach(columnGroup => {
			columnGroup.children.forEach(colDef => {
				this.columnGroupMapping[colDef.colId] = `${columnGroup.headerName.replace(' ', '')}.${colDef.colId}`;
			});
		});
		this.createFieldsForFilterBuilder();
	}

	processCellForClipboard(params: ProcessCellForExportParams) {
		if (params.column.getColDef().field === 'notes') {
			return '';
		} else if (params.column.getColDef().field.includes('text_')) {
			return params.value?.replaceAll('\t', ' ');
		}
		return params.value;
	}

	setPinnedBottomRowData(): void {
		// For larger grids, it takes longer to render
		// This method will continue to check if the grid columns are ready, and generate the bottom totals row when it is
		if (this.elapsedWaitTime < this.maxWaitTime) {
			setTimeout(() => {
				this.elapsedWaitTime += this.waitIncrement;
				const columns = this.gridColumnApi.getAllGridColumns();
				if (columns.length === 0) {
					// Grid columns not ready, wait a little longer and try again
					this.setPinnedBottomRowData();
				} else {
					// Grid columns ready, generate totals
					this.gridApi.setPinnedBottomRowData([this.getPinnedTotals(columns)]);
				}
			}, this.waitIncrement);
		}
	}

	getPinnedTotals(columns: any[]): any {
        const result = {};
		columns.forEach(column => {
			result[column['colId']] = null;
			const colDef = column.getColDef();
			if (column['visible'] === true && colDef?.type?.indexOf('numericColumn') > -1) {
                result[colDef.colId] = this.records.length > 0 && this.rowCount < 1 ? 'Loading' : this.columnTotals[colDef.colId];
			}
		});
        return result;
    }

	isCellEditable(params) {
		// Not letting the user edit pinned rows, since they are totals
		return !params.node.isRowPinned();
	}

    // When grouped, this defines the amount displayed in parentheses
    getChildCount = (data) => data.group_total;

	syncHorizontalScrollbars(): void {
		const doc = document as any;
		const mainGridSelector = '.main-grid';
		const insertGridSelector = '.insert-grid';
		const mainGridViewportSelector = `${mainGridSelector} .ag-body-horizontal-scroll-viewport`;
		const insertGridViewportSelector = `${insertGridSelector} .ag-body-horizontal-scroll-viewport`;
		// Keep track of where user mouse is, to avoid modifying scroll position of the grid that the user is actually scrolling
		doc.querySelector(mainGridSelector).onmouseenter = (event) => this.mainGridHover = true;
		doc.querySelector(mainGridSelector).onmouseleave = (event) => this.mainGridHover = false;
		doc.querySelector(insertGridSelector).onmouseenter = (event) => this.insertGridHover = true;
		doc.querySelector(insertGridSelector).onmouseleave = (event) => this.insertGridHover = false;
		// Move bottom insert grid scroll bar as user scrolls in main grid
		doc.querySelector(mainGridViewportSelector).onscroll = (event) => {
			if (this.mainGridHover && !this.insertGridHover && doc.querySelector(insertGridViewportSelector).scrollLeft !== event.currentTarget.scrollLeft) {
				doc.querySelector(insertGridViewportSelector).scrollLeft = event.currentTarget.scrollLeft;
			}
		};
		// Move top main scroll bar as user scrolls in insert grid
		doc.querySelector(insertGridViewportSelector).onscroll = (event) => {
			if (!this.mainGridHover && this.insertGridHover && doc.querySelector(mainGridViewportSelector).scrollLeft !== event.currentTarget.scrollLeft) {
				doc.querySelector(mainGridViewportSelector).scrollLeft = event.currentTarget.scrollLeft;
			}
		};
	}

	mainGridEditingStarted(): void {
		this.insertGridApi.stopEditing(false);
	}

	insertGridEditingStarted(): void {
		this.gridApi.stopEditing(false);
	}

	pivotModeChanged(e: any): void {
		this.requestList.push(RDVRequestsEnum.NUMROWS);
		this.alignGridColumns(e.columnApi.columnModel.pivotMode);
	}

	alignGridColumns(isPivotMode: boolean = false): void {
		if (this.gridColumnApi) {
			if (isPivotMode) {
				const columnState = this.lastInsertColumnStates.filter(x => !x.colId.startsWith('ag-Grid-AutoColumn-') && !x.colId.startsWith('placeholder-column-'));
				this.insertGridApi.setColumnDefs(this.gridApi.getColumnDefs());
				this.insertGridColumnApi.applyColumnState({ state: columnState });
				this.insertGridColumnApi.setRowGroupColumns([]);
				this.getLastProcessedOutputColId(null);
			} else {
				const placeholderColumnStates = [];
				let mainGridColumnState = this.gridColumnApi.getColumnState();
				this.lastInsertColumnStates = mainGridColumnState;

				if (this.gridColumnApi.getRowGroupColumns().length > 0) {
					// Need to replace grouping columns with placeholders of the same size
					mainGridColumnState.forEach((colState, i) => {
						if (colState.colId.startsWith('ag-Grid-AutoColumn-')) {
							placeholderColumnStates.push({
								index: i,
								width: colState.width
							});
						} else if (colState.hide === true && colState.rowGroup === true && colState.rowGroupIndex !== null) {
							colState.rowGroup = false;
							colState.rowGroupIndex = null;
						}
					});
					mainGridColumnState = mainGridColumnState.filter(x => !x.colId.startsWith('ag-Grid-AutoColumn-'));
					this.lastInsertColumnStates = mainGridColumnState;
					const columnDefs = this.gridApi.getColumnDefs();
					placeholderColumnStates.forEach((colState, i) => {
						const newColId = `placeholder-column-${i}`;
						mainGridColumnState.splice(colState.index, 0, {
							colId: newColId,
							width: colState.width,
							hide: false,
							pinned: null,
							sort: null,
							sortIndex: null,
							aggFunc: null,
							rowGroup: false,
							rowGroupIndex: null,
							pivot: false,
							pivotIndex: null,
							flex: null
						});
						columnDefs.push({
							colId: newColId,
							field: newColId,
							headerName: '',
							sortable: false,
							filter: false,
							enablePivot: false,
							enableRowGroup: false,
							suppressMovable: true,
							resizable: false,
							type: [ 'notEditable' ]
						});
					});
					this.insertGridApi.setColumnDefs(columnDefs);
					this.insertGridColumnApi.applyColumnState({ state: mainGridColumnState, applyOrder: true });
					this.insertGridColumnApi.setRowGroupColumns([]);
					this.getLastProcessedOutputColId(null);
				} else {
					if (this.uniqueListOfActiveColumns.size === 0) {
						this.insertGridApi.setColumnDefs(this.gridApi.getColumnDefs());
					}
					this.insertGridColumnApi.applyColumnState({ state: mainGridColumnState, applyOrder: true });
					this.getLastProcessedOutputColId(mainGridColumnState);
				}
			}
		}
	}

	layoutChanged(): void {
		this.layoutChangeHappening = true;
	}

	onLayoutFilterModelChanged(filterModel: any): void {
		this.requestList.push(RDVRequestsEnum.NUMROWS);
		this.requestList.push(RDVRequestsEnum.FILTER);
		this.currentFilterModel = filterModel;
	}

	setInsertGridVisibility(value: boolean): void {
		if (this.insertGridVisible !== value) {
			this.insertGridVisible = value;
			if (this.insertGridVisible) {
				this.bottomPanelHeight = this.defaultBottomPanelHeight;
			} else {
				this.bottomPanelHeight = 0;
			}
			this.resizePanels(this.bottomPanelHeight);
		}
	}

    getPanelHeightTotal(): number {
        const doc = document as any;
        const viewPane = doc.querySelector('app-record-data-viewer .resizable-area');
        return viewPane.offsetHeight - 3;
    }

    resizePanels(newBottomPanelHeight: number = null): void {
        const totalHeight = this.getPanelHeightTotal();
		if (!this.insertGridVisible) {
			this.mainPanelHeight = totalHeight;
		} else {
			this.bottomPanelHeight = newBottomPanelHeight !== null ? newBottomPanelHeight : this.bottomPanelHeight;
			this.mainPanelHeight = totalHeight - this.bottomPanelHeight;
		}
    }

    onResizeBottomPanel(e): void {
        this.resizePanels(e.height);
    }

	refreshInsertGridCells(): void {
		this.insertGridApi.refreshCells();
	}

    onWindowResize(): void {
        this.resizePanels();
    }

	selectionChanged(event: any, isInsertGrid: boolean = false): void {
		// When selecting a new row in a grid, by default all other rows in that grid are deselected - we want to also deselect rows from the other grid too
		if (event.source === 'rowClicked' && isInsertGrid && this.gridApi.getSelectedRows().length > 0) {
			this.gridApi.deselectAll();
		} else if (event.source === 'rowClicked' && !isInsertGrid && this.insertGridApi.getSelectedRows().length > 0) {
			this.insertGridApi.deselectAll();
		}

		// Enable/Disable Trace output
		const selectedRows = this.gridApi.getSelectedRows();
		const isTraceOutputDisabled = (!this.isProcessedOutput || selectedRows.length === 0 || !selectedRows[0].rule_id.startsWith('Sg'));
		if (isTraceOutputDisabled !== this.traceOutputDisabled) {
			this.traceOutputDisabled = isTraceOutputDisabled;
			this.traceOutputDisabledChangedEvent.next(this.traceOutputDisabled);
		}
		const isDiagramAuditDisabled = (selectedRows.length !== 1 || (this.isProcessedOutput && selectedRows[0].rule_id.startsWith('Sg')));
		if (isDiagramAuditDisabled !== this.diagramAuditDisabled) {
			this.diagramAuditDisabled = isDiagramAuditDisabled;
			this.diagramAuditDisabledChangedEvent.next(this.diagramAuditDisabled);
        }

		// If we are not editing, make sure that the current row's checkbox has focus - so that Ctrl+* shortcuts will work
		setTimeout(() => {
			if (this.gridApi.getEditingCells().length === 0) {
				const doc = document as any;
				const activeElement = doc.activeElement;
				if (activeElement && activeElement.className && !activeElement.className.includes('ag-checkbox-input')) {
					doc.querySelector('.ag-row-focus .ag-cell input.ag-input-field-input.ag-checkbox-input')?.focus({ preventScroll: true });
				}
			}
		}, 100);
	}

	getQuickSearchCols(): ColumnVO[] {
		const quickSearchCols: ColumnVO[] = [];

		this.columnState = this.gridColumnApi.getColumnState();
		this.columnState.forEach(col => {
			const colDef = this.columns.find(x => x.colId === col.colId);
			if (colDef && col.hide === false) {
				quickSearchCols.push({
					id: col.colId,
					field: col.colId,
					displayName: colDef.headerName,
					aggFunc: null
				});
			}
		});

		return quickSearchCols;
	}

	exportAll(fileType: any) {
		const downloadToast = this.toast.info('Generating spreadsheet...', null, {
			disableTimeOut: true,
		});
		this.exportOutputVisible = false;
		this.xactionService.getExcelExport(this.gridColumnApi.getAllDisplayedColumns().slice(1).map(col => col.getColId()), fileType,
		  this.ddArgs.popupArgs.shortTitle, this.exportWithImport).subscribe(x => {
			const mimeType = fileType === 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv';
			const fileName = `${this.ddArgs.popupArgs.shortTitle}.${fileType}`;
			const newBlob = new Blob([x], { type: mimeType });

			if (window.navigator && window.navigator['msSaveOrOpenBlob']) {
				window.navigator['msSaveOrOpenBlob'](newBlob, fileName);
				return;
			}
			const data = window.URL.createObjectURL(x);
			const link = document.createElement('a');
			link.href = data;
			link.download = fileName;
			link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

			setTimeout(() => {
				window.URL.revokeObjectURL(data);
				link.remove();
			}, 100);
			this.toast.remove(downloadToast.toastId);
		});
	}

	columnVisible(e: any): void {
		if (e.visible && e.column && !this.layoutChangeHappening) {
			const columnState = this.gridColumnApi.getColumnState();
			const index = columnState.map(x => x.colId).indexOf(e.column.colId);
			const newColumn = columnState.splice(index, 1);
			const isLeftArrowKeyDown = this.leftArrowKeyDown;
			if (isLeftArrowKeyDown) {
				columnState.unshift(...newColumn);
			} else {
				columnState.push(...newColumn);
			}
			this.gridColumnApi.applyColumnState({ state: columnState, applyOrder: true });

			if (newColumn.length > 0 && newColumn[0].colId !== this.lastUngroupedColumnId && !this.lastGroupedColumns.some(x => x.colId === newColumn[0].colId)) {
				// Scroll to the newly visible column
				setTimeout(() => {
					const doc = document as any;
					const horizontalViewportElement = doc.querySelector('.ag-body-horizontal-scroll-viewport');
					horizontalViewportElement.scrollLeft = isLeftArrowKeyDown ? 0 : horizontalViewportElement.scrollWidth;
				});
			}
		}

		this.alignGridColumns();
	}

	findInvalidCell(): boolean {
		let isInvalidCellFound = false;
		Object.keys(this.cellsWithValidationErrors).every(key => {
			if (this.cellsWithValidationErrors[key].columns.length > 0) {
				isInvalidCellFound = true;
				const colId = this.cellsWithValidationErrors[key].columns[0];
				if (!this.helperService.isGuid(key)) {
					this.gridApi.ensureIndexVisible(this.cellsWithValidationErrors[key].rowIndex, 'middle');
				} else {
					this.insertGridApi.ensureIndexVisible(this.cellsWithValidationErrors[key].rowIndex);
				}
				this.gridApi.ensureColumnVisible(colId, 'middle');
				return;
			}
		});
		return isInvalidCellFound;
	}

	isCellInvalid(rowId: string, colId: string): boolean {
		return this.cellsWithValidationErrors[rowId]?.columns?.find(x => x === colId);
	}

	displayValidationError(rowId: string, colId: string, rowIndex: number, errorMessage: string, errorMessageHeader: string = 'Error'): void {
		if (!this.cellsWithValidationErrors[rowId]) {
			this.cellsWithValidationErrors[rowId] = {
				rowIndex,
				columns: []
			};
		}
		this.cellsWithValidationErrors[rowId].columns.push(colId);
		this.gridApi.stopEditing(false);
		this.insertGridApi.stopEditing(false);
		this.gridApi.refreshCells();
		this.insertGridApi.refreshCells();

		this.validationErrorPopupMessage = errorMessage;
		this.validationErrorPopupHeader = errorMessageHeader;
		this.validationErrorPopupVisible = true;
	}

	clearAnyValidationErrors(rowId: string, colId: string): void {
		if (this.cellsWithValidationErrors[rowId]) {
			this.cellsWithValidationErrors[rowId].columns = this.cellsWithValidationErrors[rowId].columns.filter(x => x !== colId);
			if (this.cellsWithValidationErrors[rowId].columns.length === 0) {
				delete this.cellsWithValidationErrors[rowId];
			}
			this.gridApi.refreshCells();
			this.insertGridApi.refreshCells();
		}
	}

	getSellerImportColId(colId: string): string {
		return colId.replace('seller_id', 'seller_import');
	}

	getSellerColId(colId: string): string {
		return colId.replace('seller_import', 'seller_id');
	}

	convertToSellerImportIds(sellerIds: any[]): any[] {
		const sellerImportIds = [];
		sellerIds.forEach(sellerId => {
			sellerImportIds.push(sellerId === null ? sellerId : parseInt(this.masterMapping['sellerToDefaultImport'][sellerId], 10));
		});
		return sellerImportIds;
	}

	getLastProcessedOutputColId(columnState: any[]): void {
		let lastProcessedOutputColId = null;
		if (this.isProcessedOutput) {
			let continueToNextColumn = false;
			let colIndex = 0;
			const processedOutputColIds = [
				'0',
				'period_id',
				...Object.keys(this.calculationMappings)
			];
			if (columnState && columnState[colIndex] && columnState.filter(x => x.pinned === 'left' && processedOutputColIds.indexOf(x.colId) === -1).length === 0) {
				do {
					const colId = columnState[colIndex].colId;
					const isHidden = columnState[colIndex].hide === true;
					const isPinnedLeft = columnState[colIndex].pinned === 'left';
					if ((isHidden || processedOutputColIds.indexOf(colId) > -1) && columnState.length > (colIndex + 1)) {
						colIndex++;
						continueToNextColumn = true;
						if (!isHidden && !isPinnedLeft) {
							lastProcessedOutputColId = colId;
						}
					} else {
						continueToNextColumn = false;
					}
				} while (continueToNextColumn);
			}

			if (lastProcessedOutputColId !== this.lastProcessedOutputColId) {
				this.lastProcessedOutputColId = lastProcessedOutputColId;
				this.gridApi.refreshCells();
			}
		}
		return lastProcessedOutputColId;
	}

	getRowId = (params: any) => {
		if (this.lastRowsRequest.request.groupKeys.length < this.lastRowsRequest.request.rowGroupCols.length) {
			// If grouping, we need a way to make each row have a unique ID
			let rowKey = '';
			const groupKeysCount = this.lastRowsRequest.request.groupKeys.length;
			this.lastRowsRequest.request.rowGroupCols.forEach((rowGroupCol, i) => {
				if (i <= groupKeysCount) {
					rowKey += `~${rowGroupCol.id}#${params.data[rowGroupCol.id]}`;
				}
			});
			return rowKey;
		} else {
			const uniqueColId = this.isProcessedOutput ? 'set_id' : 'id';
			return `${uniqueColId}#${params.data[uniqueColId]}`;
		}
	};

    cleanSSRMFilterModel(filterModel: any): any {
        const filterModelCopy = this.helperService.deepCopyTwoPointO(filterModel);

        // If filtering by a group column - instead use the ungrouped column name for it
        Object.keys(filterModelCopy).forEach(key => {
            if (key.startsWith('ag-Grid-AutoColumn-')) {
                const columnName = key.replace('ag-Grid-AutoColumn-', '');
                if (this.gridApi.getColumnDefs().find(x => x['rowGroup'] === true && x['colId'] === columnName)) {
                    filterModel[columnName] = filterModel[key];
                    delete filterModel[key];
                }
            }
        });
        return filterModel;
    }

	onBulkEditFieldFocusIn(e: any): void {
		e.element.querySelector('input.dx-texteditor-input').select();
	}

	gridColumnsChanged(e: any): void {
		this.alignGridColumns();

		// Save relative location of each column, so that when a column is ungrouped, we can put it back to where it was
		const columnState = this.gridColumnApi?.getColumnState();
		columnState?.forEach((column, index) => {
			if (columnState[index + 1]?.colId) {
				this.lastColumnRelativeLocations[column.colId] = columnState[index + 1]?.colId;
			}
		});
	}

	columnRowGroupChanged(e: any): void {
		this.requestList.push(RDVRequestsEnum.NUMROWS);
		this.lastUngroupedColumnId = '';
		if (e.columns.length < this.lastGroupedColumns.length) {
			const ungroupedColumns = this.lastGroupedColumns.filter(x => !e.columns.some(y => y.colId === x.colId));
			if (ungroupedColumns.length === 1) {
				// If ungrouping, put the column back to the last location it held in the grid
				this.lastUngroupedColumnId = ungroupedColumns[0].colId;
				const columnState = this.gridColumnApi.getColumnState();
				const columnToRelocate = columnState.splice(columnState.findIndex(x => x.colId === this.lastUngroupedColumnId), 1);
				const indexToInsert = columnState.findIndex(x => x.colId === this.lastColumnRelativeLocations[this.lastUngroupedColumnId]);
				if (indexToInsert > -1) {
					columnState.splice(indexToInsert, 0, columnToRelocate[0]);
					this.gridColumnApi.applyColumnState({ state: columnState, applyOrder: true });
				}
			}
		}

		this.lastGroupedColumns = [];
		e.columns.forEach(x => {
			this.lastGroupedColumns.push(x);
		});
	}

	createFieldsForFilterBuilder(): void {
		if (this.colDefsSet && Object.keys(this.masterMapping).length > 0 && Object.keys(this.columnGroupMapping).length > 0) {
			this.filterBuilderToolPanelComponent.masterMapping = this.masterMapping;
			this.filterBuilderToolPanelComponent.columnGroupMapping = this.columnGroupMapping;
			this.filterBuilderToolPanelComponent.createFields();
		}
	}

	filterBuilderValueChanged = (value: any[], fromLayoutChange: boolean = false, fromToolPanelChange: boolean = false) => {
		this.requestList.push(RDVRequestsEnum.NUMROWS);
		this.requestList.push(RDVRequestsEnum.FILTER);
		this.filterBuilderValue = value === null ? [] : value;
		if (fromLayoutChange) {
			this.filterBuilderToolPanelComponent.filterBuilderValue = value;
		} else if (fromToolPanelChange) {
			this.filterBuilderToolPanelComponent.filterBuilderValue = value;
			this.statusBarComponent.filterBuilderValue = value;
		} else {
			this.statusBarComponent.filterBuilderValue = value;
		}
		this.gridApi.refreshServerSide({ route: [], purge: false });
	};

	setSellerColumnFilter(sellerIds: string[]) {
		const sellerFilter = this.gridApi.getFilterInstance('seller_id');
		sellerFilter.setModel({
			filterType: 'multi',
			filterModels: [
				null,
				{
					filterType: 'set',
					values: sellerIds
				}
			]
		});
		this.gridColumnApi.applyColumnState({
			state: [
				{colId: 'rule_id', sort: 'asc', sortIndex: 0},
				{colId: 'seller_id', sort: 'asc', sortIndex: 1}
			],
			defaultState: {sort: null}
		});
		this.applyState();
		setTimeout(() => {
			this.gridApi.onFilterChanged();
		}, 1000);
	}

	isPaymentSavedColumn(colId: string): boolean {
		const savedColumnId = parseInt(colId.replace('saved_column_', ''), 10);
		return this.savedColumns.find(x => x.id === savedColumnId)?.isPayment === true;
	}

	rowGroupOpened(event: any): void {
		this.requestList.push(RDVRequestsEnum.NUMROWS);
	}
}
