import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { GearPropertyService } from 'src/app/pages/building-blocks/gear-property.service';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';
import { SaveableBbProperty } from '../../models/saveable-bb-property';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-bb-update-xaction-field-input',
  providers: [{ provide: SaveableBbProperty, useExisting: BbUpdateXactionFieldInputComponent }],
  templateUrl: './bb-update-xaction-field-input.component.html',
  styleUrls: ['./bb-update-xaction-field-input.component.scss']
})
export class BbUpdateXactionFieldInputComponent extends SaveableBbProperty implements OnInit {
  @Input() datasource: any[];
  @Output() datasourceChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  internalDatasource: any;
  isValid: boolean = true;
  gearPropertyMappings: Record<string, any>;
  sourceFieldDropDownDatasource: { dataSource: any[], valueExpr: string, displayExpr: string };
  updateOptions: { dataSource: any[], valueExpr: string, displayExpr: string };
  constructor(private gearPropertyService: GearPropertyService,
    private bbHelper: BuildingBlockHelperService,
    private helperService: HelperService) {
    super();
  }

  ngOnInit(): void {
    const idCols = this.bbHelper.getAllIdColumns().map(col => col.systemName);
    this.internalDatasource = this.datasource.map(col => {
      const colCopy = this.helperService.deepCopyTwoPointO(col);
      if(idCols.includes(colCopy['toField'])){
        colCopy['toField'] = this.bbHelper.convertIdToNameColumn(colCopy['toField']);
      }
      if(idCols.includes(colCopy['fromField'])){
        colCopy['fromField'] = this.bbHelper.convertIdToNameColumn(colCopy['fromField']);
      }
      return colCopy;
    });
    this.gearPropertyService.getMappings().subscribe(res => {
      this.gearPropertyMappings = res;
      this.sourceFieldDropDownDatasource = this.gearPropertyMappings['fields'];
      this.updateOptions = this.gearPropertyMappings['updateFields'];
    });
    this.gearPropertyService.updateMappings(['fields']);
  }

  emitValue() {
    this.datasource = this.internalDatasource.map(entry => {
      const entryCopy = this.helperService.deepCopyTwoPointO(entry);
      const isToFieldText = entryCopy['toField'].startsWith('text_');
      const isToFieldConvertable = entryCopy['toField'].endsWith('_name');
      const isSourceConvertable = entryCopy['fromField'].endsWith('_name');
      if(!(isToFieldText && isSourceConvertable)){
        entryCopy['fromField'] = this.bbHelper.convertNameToIdColumn(entryCopy['fromField']);
      }
      if(isToFieldConvertable && isSourceConvertable){
        entryCopy['toField'] = this.bbHelper.convertNameToIdColumn(entryCopy['toField']);
      }
      return entryCopy;
    });
    this.datasourceChange.emit(this.datasource.map(entry => ({
      toField: entry['toField'],
      fromField: entry['fromField'],
      updateType: entry['updateType']
    })));
  }

  checkForChanges() {
    if (this.dataGrid.instance.hasEditData()) {
      this.bbHelper.setGridPropertyAsUnsaved('updateFields');
    } else {
      this.bbHelper.removeGridPropertyFromUnsaved('updateFields');
    }
  }

  saveInternalData(): Promise<void> {
    return this.dataGrid?.instance.saveEditData();
  }

  // TODO: Change out this method for a simpler one when updating to DevEx 21.2 or higher

  filterToolbarItems(e) {
    e.toolbarOptions.items = e.toolbarOptions.items.filter(item => ['addRowButton', 'revertButton'].includes(item.name));
  }

  sortColumn(data) {
    const column = this as any;
    const value = column.calculateCellValue(data);
    return column.lookup.calculateCellValue(value);
  }

  aliasHasGearRuleNameCollision = (e: any) => !(this.bbHelper.hasGearRuleNameCollision(e.value));

  aliasHasIntraRuleGearNameCollision = (e: any) => !(this.bbHelper.hasIntraRuleGearNameCollision(e.value));

  aliasHasReservedNameCollision = (e: any) => !(this.bbHelper.hasReservedNameCollision(e.value));

  aliasHasFieldNameCollision = async (e: any) => !(await this.bbHelper.hasFieldNameCollision(e.value));
}
