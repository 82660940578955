import { CoreTreeListCommandColumnProps } from './core-tree-list-command-column-props';
import { DevExpressDataProperties } from './dev-express-data-properties';

export class TreeListProps extends DevExpressDataProperties {
    treeId: string;
    // note - Key columns who have no parent should have null or 0 as their parentKeyColumnName value
    keyColumnName: string;
    parentKeyColumnName: string;
    isTreeLabelVisible: boolean = true;
    treeLabel: string;
    isRowSelectionRecursive: boolean = true;
    height: any;
    toolBarItems: any[];
    customCommandColumnOptions: CoreTreeListCommandColumnProps[];

    constructor(uniqueTreeId: string, keyColumnName: string, parentKeyColumnName: string, treeLabel?: string, isRowSelectionVisible: boolean = false,
        isRowSelectionRecursive: boolean = true, toolBarItems?: any[], customCommandColumnOptions?: CoreTreeListCommandColumnProps[]) {
        super();
        this.treeId = uniqueTreeId;
        this.keyColumnName = keyColumnName;
        this.parentKeyColumnName = parentKeyColumnName;
        this.treeLabel = treeLabel;
        this.isRowSelectionVisible = isRowSelectionVisible;
        this.isRowSelectionRecursive = isRowSelectionRecursive;
        this.toolBarItems = toolBarItems;
        this.customCommandColumnOptions = customCommandColumnOptions;
    }

    setColumnResizingAllowed(isColumnResizingAllowed: boolean): TreeListProps {
        this.isColumnResizingAllowed = isColumnResizingAllowed;
        return this;
    }
}
