<div class="report-page">
    <div *ngIf="hasAdminView || permissionEditReportPermissions" class="content-block admin-view-header" [style.display]="isDesigner ? 'none' : 'block'">
        <core-dropdown *ngIf="hasAdminView"
            [selectedValue]="reportID"
            [(dataSource)]="reports"
            dropdownName="reports"
            [props]="dropdownProps"
            (selectionChanged)="onReportSelected($event)"
            (onRename)="onRenameReport($event)"
            (onAdd)="onAddNewReport($event)"
            (onSave)="onSaveReport($event)"
            (onDelete)="onDeleteReport($event)">
        </core-dropdown>
        <div class="header-item-right">
            <dx-button *ngIf="permissionEditReportPermissions"
                text="Permissions"
                [disabled]="!reportID"
                (onClick)="onClickPermissions()">
            </dx-button>
            <dx-button *ngIf="useDefaultBucket && hasAdminView"
                icon="share"
                text="Home"
                hint="Add to Home Page for Users"
                [disabled]="!reportID"
                (onClick)="onClickBulkHomeDefault()">
            </dx-button>
        </div>
    </div>

    <dx-load-panel
        #loadPanel
        shadingColor="rgba(0,0,0,0.4)"
        [position]="{ of: '.report-page' }"
        [(visible)]="loadingVisible"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [hideOnOutsideClick]="false">
    </dx-load-panel>

    <div [hidden]="isDesigner">
        <ng-container *ngIf="reportID || hasAdminView">
            <app-report-viewer
                #viewer
                [showLoadingPanel]="false"
                [hasAdminView]="hasAdminView"
                [isImplementer]="isImplementer"
                [isUsingCoreBotTools]="isUsingCoreBotTools"
                [isEmailEnabled]="emailEnabled"
                [isHomeDefaultEnabled]="useDefaultBucket"
                (onEditReport)="editReport()"
                (onLoadingCompleted)="onLoadingCompleted()"
                (onReportParamsApplied)="getReportParams()"
                (onEmailClicked)="onEmailButtonClick()">
            </app-report-viewer>
        </ng-container>

        <dx-popup
            *ngIf="emailEnabled"
            [width]="700"
            [height]="700"
            [showTitle]="true"
            [title]="'Email Report: ' + reportName"
            [dragEnabled]="true"
            container=".dx-viewport"
            [hideOnOutsideClick]="true"
            [showCloseButton]="true"
            [resizeEnabled]="false"
            [(visible)]="emailPopupVisible">
            <dx-form [(formData)]="emailForm">
                <dxi-item itemType="group" [colCount]="2" caption="Report Data">
                    <dxi-item dataField="dateRange" caption="Date Range" [editorOptions]="{ onFocusIn: openDateSelector }">
                        <dxo-label location="left"></dxo-label>
                    </dxi-item>
                    <dxi-item>
                        <dxo-label text="Viewable" location="left"></dxo-label>
                        <div *dxTemplate>
                            <dx-select-box [dataSource]="includeSubs" [(value)]="emailForm['viewable accounts']">
                            <div *dxTemplate="let data of 'item'" [id]="data.toLowerCase().replaceAll(' ', '-')">
                                <span>{{ data }}</span>
        
                                <dx-tooltip 
                                    [target]="'#' + data.toLowerCase().replaceAll(' ', '-')" 
                                    showEvent="dxhoverstart" 
                                    hideEvent="dxhoverend" 
                                    [position]="{ my: 'right', at: 'left' }">
                                    <div *dxTemplate="let tooltip of 'content'">
                                        {{ includeSubsHints[includeSubs.indexOf(data)] }}
                                    </div>
                                </dx-tooltip>
                            </div>
                        </dx-select-box>    
                        </div>
                    </dxi-item>
                    <dxi-item dataField="sendZeroes" editorType="dxCheckBox">
                        <dxo-label text="Send Zeroes" location="left"></dxo-label>
                    </dxi-item>
                </dxi-item>
                <dxi-item itemType="group" caption="Email">
                    <dxi-item dataField="to" [editorOptions]="{ onFocusIn: openAccountSelector }">
                        <dxo-label location="left"></dxo-label>
                    </dxi-item>
                    <dxi-item dataField="subject">
                        <dxo-label location="left"></dxo-label>
                    </dxi-item>
                    <dxi-item itemType="group" [colCount]="2">
                        <dxi-item
                            dataField="attachmentName"
                            [editorOptions]="{
                                placeholder: 'Report file name'
                            }">
                            <dxo-label text="Attach" location="left"></dxo-label>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-item>
                    </dxi-item>
                    <dxi-item
                        dataField="body"
                        editorType="dxTextArea"
                        [editorOptions]="{
                            height: 170
                        }">
                    </dxi-item>
                </dxi-item>
            </dx-form>
            <br />

            <dx-button
                id="send-email-button"
                icon="message"
                text="Send"
                (onClick)="onSendClick()"
                [disabled]="selectedSellersCount === 0 || !emailForm.attachmentName || !emailForm.attachmentName.trim()">
            </dx-button>
            <span class="dx-field-label">
                {{ this.selectedSellersCount }} Recipient{{this.selectedSellersCount === 1 ? '' : 's'}} Selected
            </span>
        </dx-popup>

        <email-results [results]="sendEmailResults"></email-results>
    </div>
    <div [hidden]="!isDesigner" (mouseleave)="onMouseLeaveReportDesigner()">
        <app-report-designer
            #reportDesigner
            [showLoadingPanel]="false"
            [hasAdminView]="hasAdminView"
            [isDesignMode]="isDesigner"
            (onReportNameChanged)="reportNameChanged($event)"
            (onExitDesigner)="exitDesignerClick()"
            (onNewReport)="newReportClick($event)"
            (onSaveReport)="onSaveReport(null)"
            (onSaveAsNewReport)="saveAsNewReport($event)"
            (onDeleteReport)="onDeleteReport(null)"
            (onShowInactiveSegmentsChange)="showInactiveSegmentsChange()"
            (onLoadingStarted)="onLoadingStarted()"
            (onLoadingCompleted)="onLoadingCompleted()">
        </app-report-designer>
    </div>

    <app-attribute-permissions *ngIf="permissionEditReportPermissions"
        [(attributeStr)]="attributePermissionString"
        [(selectedSellerIds)]="publishedSellerIds"
        uniqueId="report-permissions"
        (onSubmit)="onAttributesChanged()"
        [(isPopupVisible)]="isAttributesPopupVisible">
    </app-attribute-permissions>
    <app-home-default-bulk *ngIf="useDefaultBucket && hasAdminView"
        [itemId]="reportID"
        [type]="reportBucketType"
        [(isPopupOpening)]="isBulkHomeDefaultPopupVisible">
    </app-home-default-bulk>
    <app-nav-bucket-folders *ngIf="hasAdminView"
        [(isPopupVisible)]="isNavBucketFoldersPopupVisible"
        [folderType]="bucketFolderType"
        [itemId]="reportID"
        [existingItemMappings]="reports">
    </app-nav-bucket-folders>
</div>
