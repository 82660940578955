import { SiteThemeConfig } from '../models/site-theme-config';

// There is no way to import values from the variables SCSS files into TS, so they are defined here in this file

export const SiteThemeDefault: SiteThemeConfig = {
    baseClassName: 'dx-swatch-base',
    addlClassName: 'dx-swatch-additional',
    colorValues: {
        baseAccent: '#03a9f4',
        baseBackground: '#ffffff',
        baseTextColor: '#000000de',
        baseAccentScroll: 'rgba(3,169,244,.4)',
        baseAccentCellEdit: 'rgba(3,169,244,.32)',
        baseAccentLookupBackground: 'rgba(3,169,244,.04)',
        addlBackground: '#363640',
        addlBackgroundHover: '#3f3f4b',
        addlBorderColor: '#515159',
        addlAccent: '#03a9f4'
    }
};

export const SiteThemeDefaultDark: SiteThemeConfig = {
    baseClassName: 'dx-swatch-base-dark core-dark-mode',
    addlClassName: 'dx-swatch-additional',
    colorValues: {
        baseAccent: '#03a9f4',
        baseBackground: '#232329',
        baseTextColor: '#ffffff',
        baseAccentScroll: 'rgba(3,169,244,.4)',
        baseAccentCellEdit: 'rgba(3,169,244,.32)',
        baseAccentLookupBackground: 'rgba(3,169,244,.04)',
        addlBackground: '#363640',
        addlBackgroundHover: '#3f3f4b',
        addlBorderColor: '#515159',
        addlAccent: '#03a9f4'
    }
};

export const SiteThemeBI: SiteThemeConfig = {
    baseClassName: 'dx-swatch-base-bi',
    addlClassName: 'dx-swatch-additional-bi',
    colorValues: {
        baseAccent: '#3cbab2',
        baseBackground: '#f2f2f2',
        baseTextColor: '#000000de',
        baseAccentScroll: 'rgba(60 186,178,.4)',
        baseAccentCellEdit: 'rgba(60,186,178,.24)',
        baseAccentLookupBackground: 'rgba(60,186,178,.04)',
        addlBackground: '#17174b',
        addlBackgroundHover: '#1e1e62',
        addlBorderColor: '#65cdc6',
        addlAccent: '#3cbab2',
        
    }
};

export const SiteThemeBIDark: SiteThemeConfig = {
    baseClassName: 'dx-swatch-base-bi-dark core-dark-mode',
    addlClassName: 'dx-swatch-additional-bi',
    colorValues: {
        baseAccent: '#3cbab2',
        baseBackground: '#232329',
        baseTextColor: '#ffffff',
        baseAccentScroll: 'rgba(60,186,178,.4)',
        baseAccentCellEdit: 'rgba(60,186,178,.32)',
        baseAccentLookupBackground: 'rgba(60,186,178,.04)',
        addlBackground: '#17174b',
        addlBackgroundHover: '#1e1e62',
        addlBorderColor: '#65cdc6',
        addlAccent: '#3cbab2'
    }
};
