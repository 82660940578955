/*  SVGs found in DevExpress 20.2\Components\Sources\DevExpress.Xpf.Dashboard\DevExpress.Xpf.Dashboard\Images
    or C:\Program Files (x86)\DevExpress 20.2\Components\Sources\DevExpress.Images
    or for the standard icon library as svgs: https://github.com/DevExpress/DevExtreme/tree/21_1/images/icons
    Open in notepad to get the string, and edit the <svg id=""> to give it a name.
*/
export enum Svgs {
    Edit = `<?xml version="1.0" encoding="utf-8"?>
    <!-- Generator: Adobe Illustrator 23.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" id="edit" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
    <style type="text/css">
        .Black{fill:#727373;}
    </style>
    <path class="Black" d="M2,11l3,3H2V11z M12.5,6.5l1.2-1.2c0.4-0.4,0.4-1,0-1.4l-1.6-1.6c-0.4-0.4-1-0.4-1.4,0L9.5,3.5L12.5,6.5z
         M8.5,4.5L3,10l3,3l5.5-5.5L8.5,4.5z"/>
    </svg>`,
    Maximize = `<svg id="maximize" data-name="maximize" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <defs>
        <style>
            .Black {
            fill: #414141;
            }
        </style>
        </defs>
        <title>Maximize</title>
        <polygon class="Black" points="6 1 1 1 1 6 3 4 5 6 6 5 4 3 6 1"/>
        <polygon class="Black" points="15 6 15 1 10 1 12 3 10 5 11 6 13 4 15 6"/>
        <polygon class="Black" points="10 15 15 15 15 10 13 12 11 10 10 11 12 13 10 15"/>
        <polygon class="Black" points="1 10 1 15 6 15 4 13 6 11 5 10 3 12 1 10"/>
    </svg>`,
    Minimize = `<svg id="minimize" data-name="minimize" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <defs>
        <style>
            .Red {
            fill: #e25454;
            }
        </style>
        </defs>
        <title>Restore</title>
        <polygon class="Red" points="15 10 10 10 10 15 12 13 14 15 15 14 13 12 15 10"/>
        <polygon class="Red" points="6 15 6 10 1 10 3 12 1 14 2 15 4 13 6 15"/>
        <polygon class="Red" points="1 6 6 6 6 1 4 3 2 1 1 2 3 4 1 6"/>
        <polygon class="Red" points="10 1 10 6 15 6 13 4 15 2 14 1 12 3 10 1"/>
    </svg>`,
    Home = `<svg id="home" version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 18 18">
        <title>home</title>
        <path fill="#727373" d="M9 2l-8 7h3v7h3v-6h4v6h3v-7h3l-8-7z"></path>
    </svg>`
}
