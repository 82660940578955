export enum AuditType {
    Upstream,
    Downstream,
    EveryPathDownstream
}

export class AuditGridFilterCriteria {
    rowKeyColumns: Record<string, any>;
    auditedObjToRowPath: Array<string>;
    type: AuditType;
    userFilter: string;

    public fromType(rowKeyColumns: Record<string, any>, auditedObjToRowPath: Array<string>, type: AuditType, userFilter: string = ''): AuditGridFilterCriteria{
        this.rowKeyColumns = rowKeyColumns;
        this.auditedObjToRowPath = auditedObjToRowPath;
        this.type = type;
        this.userFilter = userFilter;
        return this;
    }

    public fromOrigin(rowKeyColumns: Record<string, any>, auditedObjToRowPath: Array<string>, originId: string, userFilter: string = ''): AuditGridFilterCriteria{
        this.rowKeyColumns = rowKeyColumns;
        this.auditedObjToRowPath = auditedObjToRowPath;
        this.type = this.auditedObjToRowPath[0] === originId ? AuditType.Upstream : AuditType.Downstream;
        this.userFilter = userFilter;
        return this;
    }

    public getOriginId(): string{
        return this.type === AuditType.Upstream ? this.auditedObjToRowPath[0] : this.auditedObjToRowPath[this.auditedObjToRowPath.length - 1];
    }
}
