<dx-popup
    #formulaBuilderPopup
    [height]="500"
    [width]="1000"
    minHeight="500"
    minWidth="500"
    [showTitle]="true"
    title="Formula Builder"
    [dragEnabled]="true"
    container=".dx-viewport"
    [hideOnOutsideClick]="false"
    [showCloseButton]="true"
    [(visible)]="visible"
    [wrapperAttr]="{ class: 'formula-builder-popup' }"
    (onHiding)="cancelPopup()"
    [position]="{
        my: 'top center',
        at: 'top center',
        of: 'window',
        offset: '0 60'
    }"
    resizeEnabled="true">
    <dx-responsive-box>
        <dxi-row [ratio]="1" [baseSize]="150"></dxi-row>
        <dxi-row [ratio]="0" [baseSize]="275"></dxi-row>
        <dxi-row [ratio]="0" [baseSize]="30"></dxi-row>

        <dxi-col [ratio]="1"></dxi-col>
        <dxi-col [ratio]="1"></dxi-col>
        <dxi-col [ratio]="1"></dxi-col>
        <dxi-col [ratio]="1"></dxi-col>
        <dxi-col [ratio]="1"></dxi-col>
        <dxi-col [ratio]="1"></dxi-col>

        <dxi-item class="formula">
            <dxi-location [row]="0" [col]="0" [colspan]="6"></dxi-location>

            <dx-text-area
                id="formula-value-{{id}}"
                [(value)]="friendlyValue"
                valueChangeEvent="keyup"
                (click)="getFormulaCursorPosition()"
                (keyup)="getFormulaCursorPosition()">
            </dx-text-area>
        </dxi-item>

        <dxi-item class="items-section items-left">
            <dxi-location [row]="1" [col]="0" [colspan]="2"></dxi-location>

            <dx-tree-view
                [items]="treeItems"
                [height]="255"
                [selectNodesRecursive]="false"
                selectionMode="single"
                [selectByClick]="true"
                (onItemSelectionChanged)="onTreeViewItemSelectionChanged($event)"
                (onDisposing)="onTreeViewDisposing($event)">
            </dx-tree-view>
        </dxi-item>

        <dxi-item class="items-section items-middle">
            <dxi-location [row]="1" [col]="2" [colspan]="2"></dxi-location>

            <dx-data-grid
                [dataSource]="dataValues"
                keyExpr="value"
                displayExpr="name"
                [height]="255"
                [showColumnHeaders]="false"
                [showRowLines]="false"
                [hoverStateEnabled]="true"
                selection="single"
                (onSelectionChanged)="onDataValueSelectionChanged($event)"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onRowDblClick)="onRowDoubleClick($event)">
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxi-column dataField="name" [visible]="true"></dxi-column>
                <dxi-column dataField="value" [visible]="false"></dxi-column>
                <dxi-column dataField="description" [visible]="false"></dxi-column>
                <dxo-scrolling mode="virtual"></dxo-scrolling>
                <dxo-sorting mode="multiple"></dxo-sorting>
            </dx-data-grid>
        </dxi-item>

        <dxi-item class="items-section items-right">
            <dxi-location [row]="1" [col]="4" [colspan]="2"></dxi-location>
            <dx-scroll-view [height]="255" direction="vertical">
                <span style="white-space: pre-line;">{{description}}</span>
            </dx-scroll-view>
        </dxi-item>

        <dxi-item class="ok-button">
            <dxi-location [row]="2" [col]="0" [colspan]="3"></dxi-location>
            <dx-button class="dx-button-default" (click)="okClicked()">OK</dx-button>
        </dxi-item>

        <dxi-item class="cancel-button">
            <dxi-location [row]="2" [col]="3" [colspan]="3"></dxi-location>
            <dx-button (click)="cancelPopup()">Cancel</dx-button>
        </dxi-item>
    </dx-responsive-box>
</dx-popup>
