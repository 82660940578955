export const menuItemNames = {
    accountAttributes: 'Attributes',
    accountManagement: 'Accounts',
    analytics: 'Analytics',
    audit: 'Audit',
    buildDiff: 'Build Diff',
    createDashboard: 'Create Dashboard',
    dashboards: 'Dashboards',
    disputeManagement: 'Tickets',
    email: 'Email',
    etl: 'ETL',
    favorites: 'Favorites',
    fields: 'Fields',
    home: 'Home',
    plans: 'Plans & Rules',
    processing: 'Processing',
    processLogViewer: 'Logs',
    reports: 'Reports',
    setup: 'Setup',
    setupRules: 'Setup Rules',
    system: 'System',
    tagGroups: 'Tag Groups',
    whatIf: 'What-If',
    workflow: 'Workflow'
};

export const reservedEmailLayoutNames = [
    '<Report Email>',
    '<Dashboard Email>',
    '<Credentials Email>'
];

export const disputeSystemNames = ['Approved Adjustment', 'Submit Date', 'Resolve Date', 'Submitted By', 'Resolved By'];

export const dynamicXactionColumns = [
    { columnPattern: 'date_', dataType: 'datetime', sortValue: 3 }, // 0
    { columnPattern: 'qty_', dataType: 'number', sortValue: 4 }, // 1
    { columnPattern: 'tag_id_', dataType: 'string', sortValue: 2 }, // 2
    { columnPattern: 'text_', dataType: 'string', sortValue: 1 }, // 3
    { columnPattern: 'seller_id_', dataType: 'string', sortValue: 5 } // 4
];

export const yesNoArray = [
    { name: 'Yes', value: true },
    { name: 'No', value: false }
];

// This is the corresponding value for the '<blank>' option in the core dropdown component
export const coreDropdownBlankItemValue = -1;

// used on the processing page and in xaction service to append to the column name
export const importStr = ' (import)';

export const processAllPlanName = 'Process All';

export const defaultMappingNameValue = '<Default>';

export const toastrConstants = {
    lockedPeriodDelete: 'Locked periods cannot be deleted',
    lockedPeriodCalculationDelete: 'Affects calculations from locked periods which cannot be deleted',
    duplicatePeriod: 'Period not added: Another period for this recurrence exists with the same begin and end date',
    allowPopups: 'Please ensure popups are allowed, if the issue persists contact support',
    noSelectionForPopup: 'Please select records to view using the grid toolbar\'s check button',
    processingInProgress: 'Other processing is in progress, action can not be completed at this time',
    errorImportingFile: 'An error occurred and the file could not be imported',
    errorEditingXaction: 'An error occurred and the datasource could not be edited',
    planSegmentProcessingError: 'An error occurred while processing the Plan/Segment',
};

export const setupBotButtons = {
    newDatasourceObjectButton: 'Add another datasource',
    newSetupObjectButton: 'Do something with my data',
    newOutputObjectButton: 'Add an output',
    newFinishSetupRuleButton: 'I\'m done with my rule',
    newReadyForTheNextStepButton: 'I\'m ready for the next step',
    closeWindowButton: 'Close window'
};

export const setupBotMessages = {
    newRuleIntroMessage: 'Welcome to setup rules! I\'m SetupBot, your setup rule building assistant.' +
        '\r\n\r\nA setup rule is meant to reflect the logic behind one of your plans. Setup rules consist of one or more setup objects that represent ' +
        'the flow of data in your plan. Each rule begins with one or more datasource objects and ends with one or more output objects. Watch this video ' +
        'to learn more about how to build a setup rule. When you are ready for the next step, click \"I\'m ready for the next step\" below',
    welcomeBackMessage: 'Welcome back to setup rules! To continue where you left off, select an object ' +
        'on the grid and edit its properties on the side panel. If you need a refresher on how to build a setup rule, consult this video to learn more:',
    readyToMoveOnMessage: 'Once you are ready to move on, select an action below. When you are finished with your rule, select "' +
        setupBotButtons.newOutputObjectButton + '" to add outputs to your rule, and then select "' + setupBotButtons.newFinishSetupRuleButton +
        '" to finalize your rule.',
    setupRuleAlreadyFinalizedMessage: 'It looks like you have already finalized your rule. Contact your assigned implementers if you need to make a change.',
    firstDataSourceObjectMessage: 'Let\'s start with a datasource object. Where does your transaction data come ' +
        'from? Fill out the form on the right with details of your datasource and use the attachment button at the top of the form to upload sample data.' +
        '\r\n\r\nOnce you have finished detailing your datasource object, select another action below. When you are finished with your rule, select "' +
        setupBotButtons.newOutputObjectButton + '" to add outputs to your rule, and then select "' + setupBotButtons.newFinishSetupRuleButton +
        '" to finalize your rule.',
    newDataSourceObjectMessage: 'To add a new datasource object, select \'Datasource\' from the Setup Object ' +
        'dropdown at the top of the page. This will create a new datasource object for you to fill out. Click on this new object in the grid and modify ' +
        'its properties on the side panel.\r\n\r\nOnce you have finished detailing your datasource object, select another action below. When you are ' +
        'finished with your rule, select "' + setupBotButtons.newOutputObjectButton + '" to add outputs to your rule, and then select "' +
        setupBotButtons.newFinishSetupRuleButton + '" to finalize your rule.',
    newGenericObjectMessage: 'To add a new object, select the appropriate object type from the Setup Object ' +
        'dropdown at the top of the page. This will create a new object for you to fill out. Click on this new object in the grid and modify its ' +
        'properties on the side panel.\r\n\r\nEach setup object type has its own unique function and properties:\r\n\r\n-The calculation object adds ' +
        'a calculation to your rule using a formula builder on the side panel\r\n-The aggregate data object collapses your data into a single total, ' +
        'such as summing all rows for an agent or finding the max value in a range\r\n-The hierarchy object rolls data rows up or down an account ' +
        'hierarchy, for example to give a manager credit for all activity on their team\r\n-The outputs object collects all calculation and reporting ' +
        'outputs for this rule\r\n-The rate assign and tiered rate objects assign rates from a rate table\r\n\r\nOnce you have finished detailing your ' +
        'object, select another action below. When you are finished with your rule, select "' + setupBotButtons.newOutputObjectButton + '" to add outputs to ' +
        'your rule, and then select "' + setupBotButtons.newFinishSetupRuleButton + '" to finalize your rule.',
    newOutputObjectMessage: 'All setup rules must include an outputs object. An outputs object defines the outputs your rule will produce. Think of outputs as ' +
        'calculated values you would want to display on a report. Examples of this include metrics like total margin, commission amount, conversion rate, ' +
        'YTD sales, etc.\r\n\r\nTo add a new outputs object, select \'Outputs\' from the Setup Object dropdown at the top of the page. This will create a new ' +
        'outputs object for you to fill out. Click on this new object in the grid and modify its properties on the side panel.\r\n\r\nOnce you have finished ' +
        'detailing your outputs object, review your rule to make sure it includes all the details that describe your plan. Once your rule is complete, select "' +
        setupBotButtons.newFinishSetupRuleButton + '":',
    ruleFinalizedSuccessMessage: 'Great! Let your implementation representatives know your rule is complete. They will review it and let you know if they have any follow-up questions.',
    ruleFinalizedFailureMessage: 'Oops! You will need to add at least one outputs object to your setup rule before you can finalize it. Open the Setup Object dropdown ' +
        'at the top of the page and select outputs object, then detail all calculation outputs. Select "' + setupBotButtons.newOutputObjectButton + '" to learn more.' +
        '\r\n\r\nOnce you have finished detailing your outputs object, try finalizing your rule again, or select another action below:',
    somethingWentWrongMessage: 'Something went wrong. Try refreshing the page. If you get here again, please reach ' +
        'out to your assigned implementer or submit a trouble ticket, making sure to explain the exact steps you took to get here.',
    setupRuleOverviewLink: 'https://www.youtube.com/embed/pcm6PES549Q?si=Tyo_BqjGQjZXINYT'
};
