<div class="property-panel">
    <app-bb-version-selector *ngIf="scopeContainer && !(scopeContainer.typeId === 3 && scopeContainer.parentId === 'CoRoot') && !scopeContainer.id.startsWith('Sg')"></app-bb-version-selector>
    <ng-container *ngIf="this.focusedObjectChanges !== null">
        <div class="header-container">
            <h2>{{this.focusedObjectChanges.name}}</h2>
            <div class="gear-properties-buttons">
                <dx-button 
                    icon="revert"
                    hint="Discard Changes"
                    [visible]="!this.focusedObject.parentId?.startsWith('Sg') && !lockPanel"
                    (onClick)="this.onRevertNodeButtonClick($event)"
                    class="dx-button">
                </dx-button>
                <dx-button 
                    icon="save"
                    hint="Update"
                    [visible]="!this.focusedObject.parentId?.startsWith('Sg') && !lockPanel"
                    (onClick)="this.onSaveNodeButtonClick($event)"
                    class="dx-button dx-button-default">
                </dx-button>
                <dx-button
                    icon="export"
                    hint="Convert"
                    [visible]="this.focusedObject.id?.startsWith('Sg') && isImplementer"
                    (onClick)="this.convertSegment($event)"
                    class="dx-button dx-button-danger button-square">
                </dx-button>
            </div>
        </div>
        <div class="properties-container">
            <dx-scroll-view width="100%" height="100%" class="bb-property-panel">
                <div [ngSwitch]="true" class="form">
                    <ng-container *ngSwitchCase="'Co' === this.focusedObjectChanges.objectTypeCode">
                        <div class="dx-fieldset">
                            <div [ngClass]="{'dim-overlay': lockPanel}"></div>
                            <div class="dx-fieldset-header">General</div>
                            <div class="dx-field" [ngClass]="{'unsaved-change': isUnsavedChange('name'), 'border-padding': !isUnsavedChange('name')}">
                                <div class="dx-field-label">Name</div>
                                <div class="dx-field-value">
                                    <app-core-string-input [props]="stringProperties" [(value)]="this.focusedObjectChanges.name"></app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field" [ngClass]="{'unsaved-change': isUnsavedChange('description'), 'border-padding': !isUnsavedChange('description')}">
                                <div class="dx-field-label">Description</div>
                                <div class="dx-field-value">
                                    <app-core-multiline-string-input
                                        [props]="multiLineStringProperties"
                                        [(value)]="this.focusedObjectChanges.description">
                                    </app-core-multiline-string-input>
                                </div>
                            </div>
                            <div class="dx-field" *ngIf="showAdvancedView">
                                <div class="dx-field-label">ID</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="readOnlyStringProperties"
                                        [(value)]="this.focusedObjectChanges.id">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field" *ngIf="showAdvancedView">
                                <div class="dx-field-label">Parent ID</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="readOnlyStringProperties"
                                        [(value)]="this.focusedObjectChanges.parentId">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div
                                class="dx-field wide-field"
                                *ngIf="isFocusedObjectProductionRule()"
                                [ngClass]="{'unsaved-change': isUnsavedChange('savedColumns'), 'border-padding': !isUnsavedChange('savedColumns')}">
                                <div class="dx-field-label">Saved Columns</div>
                                <div class="dx-field-value">
                                    <app-bb-saved-columns
                                        [(savedColumnBindings)]="this.containerProperties['savedColumnBindings']"
                                        (savedColumnsChange)="onContainerPropertyChange($event)"
                                        [(savedColumnsEditDetails)]="this.savedColumnsEditDetails"
                                        [(savedColumnsAllDetails)]="this.savedColumnsAllDetails">
                                    </app-bb-saved-columns>
                                </div>
                            </div>
                            <div
                                class="dx-field"
                                *ngIf="isFocusedObjectProductionRule()"
                                [ngClass]="{'unsaved-change': isUnsavedChange('insertXaction'), 'border-padding': !isUnsavedChange('insertXaction')}">
                                <div class="dx-field-label">Insert Into Xaction</div>
                                <div class="dx-field-value">
                                    <dx-check-box
                                        [(value)]="this.containerProperties['insertXaction']"
                                        (onValueChanged)="onContainerPropertyChange($event)">
                                    </dx-check-box>
                                </div>
                            </div>
                            <div
                                class="dx-field"
                                *ngIf="this.containerProperties['insertXaction']"
                                [ngClass]="{'unsaved-change': isUnsavedChange('datasourceId'), 'border-padding': !isUnsavedChange('datasourceId')}">
                                <div class="dx-field-label">Datasource</div>
                                <div class="dx-field-value">
                                    <dx-select-box
                                        [dataSource]="gearPropertyMappings['nonKeyDatasources'].dataSource"
                                        [displayExpr]="gearPropertyMappings['nonKeyDatasources'].displayExpr"
                                        [valueExpr]="gearPropertyMappings['nonKeyDatasources'].valueExpr"
                                        [(value)]="this.containerProperties['datasourceId']"
                                        (onValueChanged)="onContainerPropertyChange($event)"
                                        stylingMode="underlined">
                                    </dx-select-box>
                                </div>
                            </div>
                            <div
                                class="dx-field wide-field"
                                *ngIf="this.containerProperties['insertXaction']"
                                [ngClass]="{'unsaved-change': isUnsavedChange('columnMappings'), 'border-padding': !isUnsavedChange('columnMappings')}">
                                <div class="dx-field-label">Mappings</div>
                                <div class="dx-field-value">
                                    <app-bb-xaction-mapping
                                        [(datasource)]="this.containerProperties['columnMappings']"
                                        [xactionDsId]="this.containerProperties['datasourceId']"
                                        (onValueChanged)="onContainerPropertyChange($event)">
                                    </app-bb-xaction-mapping>
                                </div>
                            </div>
                            <div
                                class="dx-field"
                                *ngIf="isFocusedObjectProductionRule()"
                                [ngClass]="{'unsaved-change': isUnsavedChange('userDependencies'), 'border-padding': !isUnsavedChange('userDependencies')}">
                                <div class="dx-field-label">User Dependencies</div>
                                <div class="dx-field-value">
                                    <dx-check-box
                                        [value]="this.containerProperties['userDependencies'] !== undefined"
                                        (onValueChanged)="onUserDependenciesChange($event)">
                                    </dx-check-box>
                                </div>
                            </div>
                            <div
                                class="dx-field wide-field"
                                *ngIf="this.scopeContainer && this.containerProperties['userDependencies']"
                                [ngClass]="{'unsaved-change': isUnsavedChange('userDependencies'), 'border-padding': !isUnsavedChange('userDependencies')}">
                                <div class="dx-field-label">Upstream Rules</div>
                                <div class="dx-field-value">
                                    <app-bb-user-dependencies [ruleId]="this.scopeContainer.id" [(datasource)]="this.containerProperties['userDependencies']"></app-bb-user-dependencies>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Ag' === this.focusedObjectChanges.objectTypeCode">
                        <div class="dx-fieldset">
                            <div [ngClass]="{'panel-lock-overlay':  lockPanel}"></div>
                            <div class="dx-fieldset-header">General</div>
                            <div class="dx-field">
                                <div class="dx-field-label">Name</div>
                                <div class="dx-field-value">
                                    <app-core-string-input 
                                        [props]="stringProperties" 
                                        [(value)]="this.focusedObjectChanges.name">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">ID</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="readOnlyStringProperties"
                                        [(value)]="this.focusedObjectChanges.id">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Parent ID</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="readOnlyStringProperties"
                                        [(value)]="this.focusedObjectChanges.parentId">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Key Columns</div>
                                <div *ngFor="let obj of this.focusedObjectChanges.keyColumns" class="dx-field-value">
                                    <div *ngFor="let keyValue of obj | keyvalue">
                                        <app-core-string-input 
                                            [props]="stringProperties" 
                                            [(value)]="keyValue.value">
                                        </app-core-string-input>
                                    </div>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Source Process ID</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="readOnlyStringProperties"
                                        [(value)]="this.focusedObjectChanges.sourceProcessId">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Input Value Column System Name</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="readOnlyStringProperties"
                                        [(value)]="this.focusedObjectChanges.inputValueColumnSystemName">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Function Code</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="readOnlyStringProperties"
                                        [(value)]="this.focusedObjectChanges.functionCode">
                                    </app-core-string-input>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Ca' === this.focusedObjectChanges.objectTypeCode">
                        <div class="dx-fieldset">
                            <div [ngClass]="{'panel-lock-overlay':  lockPanel}"></div>
                            <div class="dx-fieldset-header">General</div>
                            <div class="dx-field">
                                <div class="dx-field-label">Name</div>
                                <div class="dx-field-value">
                                    <app-core-string-input 
                                        [props]="stringProperties" 
                                        [(value)]="this.focusedObjectChanges.name">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">ID</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="readOnlyStringProperties"
                                        [(value)]="this.focusedObjectChanges.id">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Parent ID</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="readOnlyStringProperties"
                                        [(value)]="this.focusedObjectChanges.parentId">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Source Process ID</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="readOnlyStringProperties"
                                        [(value)]="this.focusedObjectChanges.sourceProcessId">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Description</div>
                                <div class="dx-field-value">
                                    <app-core-multiline-string-input 
                                        [props]="multiLineStringProperties" 
                                        [(value)]="this.focusedObjectChanges.description">
                                    </app-core-multiline-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Display Formula</div>
                                <div class="dx-field-value">
                                    <app-core-multiline-string-input
                                        [props]="multiLineStringProperties"
                                        [(value)]="this.focusedObjectChanges.displayFormulaString">
                                    </app-core-multiline-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">SQL Formula</div>
                                <div class="dx-field-value">
                                    <app-core-multiline-string-input
                                        [props]="multiLineStringProperties"
                                        [(value)]="this.focusedObjectChanges.sqlFormulaString">
                                    </app-core-multiline-string-input>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Fi' === this.focusedObjectChanges.objectTypeCode">
                        <div class="form">
                            <div class="dx-fieldset">
                                <div [ngClass]="{'panel-lock-overlay':  lockPanel}"></div>
                                <div class="dx-fieldset-header">General</div>
                                <div class="dx-field">
                                    <div class="dx-field-label">Name</div>
                                    <div class="dx-field-value">
                                        <app-core-string-input 
                                            [props]="stringProperties" 
                                            [(value)]="this.focusedObjectChanges.name">
                                        </app-core-string-input>
                                    </div>
                                </div>
                                <div class="dx-field">
                                    <div class="dx-field-label">Parent ID</div>
                                    <div class="dx-field-value">
                                        <app-core-string-input 
                                            [props]="readOnlyStringProperties" 
                                            [(value)]="this.focusedObjectChanges.parentId">
                                        </app-core-string-input>
                                    </div>
                                </div>
                                <div class="dx-field">
                                    <div class="dx-field-label">Description</div>
                                    <div class="dx-field-value">
                                        <app-core-multiline-string-input
                                            [props]="multiLineStringProperties"
                                            [(value)]="this.focusedObjectChanges.description">
                                        </app-core-multiline-string-input>
                                    </div>
                                </div>
                                <div class="dx-field">
                                    <div class="dx-field-label">Source Process ID</div>
                                    <div class="dx-field-value">
                                        <app-core-string-input 
                                            [props]="stringProperties"
                                            [(value)]="this.focusedObjectChanges.sourceProcessId">
                                        </app-core-string-input>
                                    </div>
                                </div>
                                <div class="dx-field">
                                    <div class="dx-field-label">Filter</div>
                                    <div class="dx-field-value">
                                        <app-core-multiline-string-input
                                            [props]="multiLineStringProperties"
                                            [(value)]="this.focusedObjectChanges.filterString">
                                        </app-core-multiline-string-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Tr' === this.focusedObjectChanges.objectTypeCode">
                        <div class="dx-fieldset">
                            <div [ngClass]="{'panel-lock-overlay':  lockPanel}"></div>
                            <div class="dx-fieldset-header">General</div>
                            <div class="dx-field">
                                <div class="dx-field-label">Name</div>
                                <div class="dx-field-value">
                                    <app-core-string-input 
                                        [props]="stringProperties" 
                                        [(value)]="this.focusedObjectChanges.name">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Parent ID</div>
                                <div class="dx-field-value">
                                    <app-core-string-input 
                                        [props]="readOnlyStringProperties" 
                                        [(value)]="this.focusedObjectChanges.parentId">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Description</div>
                                <div class="dx-field-value">
                                    <app-core-multiline-string-input 
                                        [props]="multiLineStringProperties"
                                        [(value)]="this.focusedObjectChanges.description">
                                    </app-core-multiline-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Source Process ID</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="readOnlyStringProperties"
                                        [(value)]="this.focusedObjectChanges.sourceProcessId">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Aux Process ID</div>
                                <div class="dx-field-value">
                                    <app-core-string-input 
                                        [props]="readOnlyStringProperties" 
                                        [(value)]="this.focusedObjectChanges.auxProcessId">
                                    </app-core-string-input>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Un' === this.focusedObjectChanges.objectTypeCode">
                        <div class="dx-fieldset">
                            <div [ngClass]="{'panel-lock-overlay':  lockPanel}"></div>
                            <div class="dx-fieldset-header">General</div>
                            <div class="dx-field">
                                <div class="dx-field-label">Name</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="stringProperties"
                                        [(value)]="this.focusedObjectChanges.name">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Parent ID</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="readOnlyStringProperties"
                                        [(value)]="this.focusedObjectChanges.parentId">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Description</div>
                                <div class="dx-field-value">
                                    <app-core-multiline-string-input 
                                        [props]="multiLineStringProperties"
                                        [(value)]="this.focusedObjectChanges.description">
                                    </app-core-multiline-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Source Process ID</div>
                                <div class="dx-field-value">
                                    <app-core-string-input 
                                        [props]="readOnlyStringProperties"
                                        [(value)]="this.focusedObjectChanges.sourceProcessId">
                                    </app-core-string-input>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Ds' === this.focusedObjectChanges.objectTypeCode">
                        <div class="dx-fieldset">
                            <div [ngClass]="{'panel-lock-overlay':  lockPanel}"></div>
                            <div class="dx-fieldset-header">General</div>
                            <div class="dx-field">
                                <div class="dx-field-label">Name</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="stringProperties"
                                        [(value)]="this.focusedObjectChanges.name">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Parent Id</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="readOnlyStringProperties"
                                        [(value)]="this.focusedObjectChanges.parentId">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Source Type</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="stringProperties"
                                        [(value)]="this.focusedObjectChanges.sourceType">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field">
                                <div class="dx-field-label">Description</div>
                                <div class="dx-field-value">
                                    <app-core-multiline-string-input
                                        [props]="multiLineStringProperties"
                                        [(value)]="this.focusedObjectChanges.description">
                                    </app-core-multiline-string-input>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Bb' === this.focusedObjectChanges.objectTypeCode">
                        <div class="dx-fieldset">
                            <div [ngClass]="{'dim-overlay': (focusedChild && !focusedChild['sourcePropertyGroups'].includes('General')), 'panel-lock-overlay':  lockPanel}"></div>
                            <div class="dx-fieldset-header">General</div>
                            <div class="dx-field" [ngClass]="{'unsaved-change': isUnsavedChange('name'), 'border-padding': !isUnsavedChange('name')}">
                                <div class="dx-field-label">Name</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="stringProperties"
                                        [(value)]="this.focusedObjectChanges.name">
                                    </app-core-string-input>
                                </div>
                            </div>
                            <div class="dx-field" [ngClass]="{'unsaved-change': isUnsavedChange('description'), 'border-padding': !isUnsavedChange('description')}">
                                <div class="dx-field-label">Description</div>
                                <div class="dx-field-value">
                                    <app-core-multiline-string-input
                                        [props]="multiLineStringProperties"
                                        [(value)]="this.focusedObjectChanges.description">
                                    </app-core-multiline-string-input>
                                </div>
                            </div>
                            <div class="dx-field" *ngIf="showAdvancedView">
                                <div class="dx-field-label">ID</div>
                                <div class="dx-field-value">
                                    <app-core-string-input
                                        [props]="readOnlyStringProperties"
                                        [(value)]="this.focusedObjectChanges.id">
                                    </app-core-string-input>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="gearPropertyMappings">
                            <ng-container *ngFor="let groupProperty of this.focusedObjectChanges.propertyValues; index as i; first as isFirst">
                                <div
                                    *ngIf="isFirst || groupProperty.property.groupFriendlyName !== this.focusedObjectChanges.propertyValues[i-1].property.groupFriendlyName"
                                    class="dx-fieldset property-group">
                                    <div 
                                        [ngClass]="{
                                            'dim-overlay': (focusedChild && !focusedChild['sourcePropertyGroups'].includes(groupProperty.property.groupFriendlyName)), 
                                            'panel-lock-overlay':  lockPanel
                                        }">
                                    </div>
                                    <div class="dx-fieldset-header">
                                        {{groupProperty.property.groupFriendlyName}}
                                    </div>
                                    <ng-container *ngFor="let propertyValue of this.focusedObjectChanges.propertyValues">
                                        <div
                                            *ngIf="propertyValue.property.groupFriendlyName === groupProperty.property.groupFriendlyName && this.evaluateConditionalDisplay(propertyValue.property)"
                                            [ngClass]="{
                                                'wide-field': isWideFieldType(propertyValue.property.datatype), 
                                                'unsaved-change': isUnsavedChange(propertyValue.property.systemName), 
                                                'border-padding': !isUnsavedChange(propertyValue.property.systemName)
                                            }"
                                            class="dx-field">
                                            <div class="dx-field-label" title="{{propertyValue.property.friendlyName}}">
                                                {{propertyValue.property.friendlyName}}
                                            </div>
                                            <div
                                                [ngSwitch]="propertyValue.property.datatype"
                                                [ngClass]="{
                                                    'filter-builder-property': propertyValue.property.datatype === dataType.SegmentFilterBuilder || propertyValue.property.datatype === dataType.FilterBuilder
                                                }"
                                                class="dx-field-value">
                                                <ng-container *ngSwitchCase="dataType.String">
                                                    <app-core-string-input [props]="stringProperties" [(value)]="propertyValue.value"></app-core-string-input>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.ReadOnlyString">
                                                    <app-core-string-input [props]="readOnlyStringProperties" [(value)]="propertyValue.value"></app-core-string-input>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.MultiLineString">
                                                    <app-core-multiline-string-input [props]="multiLineStringProperties" [(value)]="propertyValue.value"></app-core-multiline-string-input>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.SegmentFilterBuilder">
                                                    <app-segment-filter-builder
                                                        #segmentFilterBuilder
                                                        [focusedObjectChanges]="focusedObjectChanges"
                                                        [propertyValue]="propertyValue"
                                                        (dblclick)="AdjustPanelWidthForFilterBuilderValues()">
                                                    </app-segment-filter-builder>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.SegmentFormulaBuilder">
                                                    <app-core-multiline-string-input [props]="readOnlyStringProperties" [(value)]="propertyValue.value"></app-core-multiline-string-input>
                                                    <app-formula-builder
                                                        [(value)]="propertyValue.value"
                                                        id="segment-formula-builder"
                                                        [data]="gearPropertyMappings[propertyValue.property.systemName]"
                                                        icon="edit"
                                                        launchElement="button"
                                                        [onOkClick]="getSqlFormula">
                                                    </app-formula-builder>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.FilterBuilder">
                                                    <app-bb-filter-builder
                                                        [(filterBuilderObject)]="propertyValue.value"
                                                        [validDataColumns]="this.gearPropertyMappings['filterBuilderObject']"
                                                        [auxDataColumns]="this.gearPropertyMappings['auxFields']"
                                                        [isJoin]="(this.focusedObjectChanges.gearTypeCode === '0010' || this.focusedObjectChanges.gearTypeCode === '0002')"
                                                        (dblclick)="AdjustPanelWidthForFilterBuilderValues()">
                                                    </app-bb-filter-builder>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.FormulaBuilder">
                                                    <app-bb-formula-builder
                                                        [(value)]="propertyValue.value"
                                                        id="gear-formula-builder"
                                                        [convertIntegersToFloat]="convertIntegersToFloat"
                                                        [data]="gearPropertyMappings[propertyValue.property.systemName]">
                                                    </app-bb-formula-builder>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.NullableInt">
                                                    <app-core-numeric-input [(value)]="propertyValue.value" [props]="nullableIntProperties"></app-core-numeric-input>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.ReadOnlyInt">
                                                    <app-core-numeric-input [(value)]="propertyValue.value" [props]="readOnlyIntProperties"></app-core-numeric-input>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.PositiveWholeNumber">
                                                    <app-core-numeric-input [(value)]="propertyValue.value" [props]="wholeNumberProperties"></app-core-numeric-input>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.DayOfMonthWholeNumber">
                                                    <app-core-numeric-input [(value)]="propertyValue.value" [props]="dayNumberProperties"></app-core-numeric-input>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.MonthWholeNumber">
                                                    <app-core-numeric-input [(value)]="propertyValue.value" [props]="monthNumberProperties"></app-core-numeric-input>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.PeriodDropdown">
                                                    <app-period-dropdown
                                                        #periodDropdown
                                                        [dropdownProps]="periodDropdownProps"
                                                        [(selectedPeriodId)]="propertyValue.value">
                                                    </app-period-dropdown>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.DropDown">
                                                    <dx-select-box
                                                        [dataSource]="gearPropertyMappings[propertyValue.property.systemName].dataSource"
                                                        [displayExpr]="gearPropertyMappings[propertyValue.property.systemName].displayExpr"
                                                        [valueExpr]="gearPropertyMappings[propertyValue.property.systemName].valueExpr"
                                                        [readOnly]="gearPropertyMappings[propertyValue.property.systemName].readOnly"
                                                        [hint]="gearPropertyMappings[propertyValue.property.systemName].hint"
                                                        [searchEnabled]="true"
                                                        [useItemTextAsTitle]="true"
                                                        stylingMode="underlined"
                                                        [(value)]="propertyValue.value"
                                                        (onValueChanged)="onPropChangeUpdateMappings($event)">
                                                    </dx-select-box>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.GroupedDropDown">
                                                    <dx-select-box
                                                        class="dx-field"
                                                        grouped="true"
                                                        [dataSource]="gearPropertyMappings[propertyValue.property.systemName].dataSource"
                                                        [displayExpr]="gearPropertyMappings[propertyValue.property.systemName].displayExpr"
                                                        [valueExpr]="gearPropertyMappings[propertyValue.property.systemName].valueExpr"
                                                        [searchEnabled]="true"
                                                        [useItemTextAsTitle]="true"
                                                        [(value)]="propertyValue.value"
                                                        stylingMode="underlined"
                                                        (onValueChanged)="onPropChangeUpdateMappings($event)">
                                                    </dx-select-box>
                                                </ng-container>
                                                <div *dxTemplate="let data of 'sourceFieldGroup'">
                                                    <div class="source-field-group">
                                                        <span>{{data.key}}s</span>
                                                    </div>
                                                </div>
                                                <ng-container *ngSwitchCase="dataType.PeriodSelector">
                                                    <app-bb-period-range-input [(actualValue)]="propertyValue.value" [selectedRecurrenceId]="recurrenceId"></app-bb-period-range-input>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.ApplyTo">
                                                    <app-apply-to [propertyValue]="propertyValue"></app-apply-to>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.CheckBox">
                                                    <dx-check-box [(value)]="propertyValue.value" (onValueChanged)="onPropertyCheckChange($event)"></dx-check-box>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.AssignFields">
                                                    <app-bb-assign-field-input [(datasource)]="propertyValue.value" [showAlias]="(this.focusedObjectChanges.gearTypeCode === '0010')"></app-bb-assign-field-input>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.FieldGrid">
                                                    <app-bb-field-grid [(datasource)]="propertyValue.value"></app-bb-field-grid>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.UpdateXactionFields">
                                                    <app-bb-update-xaction-field-input [(datasource)]="propertyValue.value"></app-bb-update-xaction-field-input>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="dataType.UnionFields">
                                                    <app-bb-union-field-input [(datasource)]="propertyValue.value"></app-bb-union-field-input>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </dx-scroll-view>
        </div>
    </ng-container>
</div>
<core-popup #savedColumnsConfirmationPopup [props]="savedColumnsConfirmationPopupProps" [steps]="savedColumnsConfirmationPopupSteps"></core-popup>
