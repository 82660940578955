import { Injectable } from '@angular/core';
import { ContainerTypeSelector } from 'devexpress-dashboard/designer/index.internal';
import { BehaviorSubject, Observable, Subject, combineLatest, forkJoin } from 'rxjs';
import { Dictionary } from 'src/app/shared/dictionary';
import { DependencyType, EnumContainerType, BbObjectSourceCategory, containerTypeIds } from 'src/app/shared/constants/enums';
import { AggregateProcess, BbObject, Gear, CalculateProcess, Container, DataColumn, DataColumnType, GearMiniLabel,
     DatasourceProcess, FilterProcess, Group, TransformProcess, UnionProcess, GearCode } from 'src/app/shared/models/building-blocks';
import { BuildingBlocksService } from 'src/app/shared/services/building-blocks.service';
import { Node } from './node';
import { ObjectInput } from './object-input';
import { BuildingBlocksSettings } from './building-blocks-settings';
import { Singleton } from 'src/app/shared/models/singleton';
import { Product } from 'src/app/shared/models/product';
import { Customer } from 'src/app/shared/models/customer';
import { ProductGroup } from 'src/app/shared/models/product-group';
import { CustomerGroup } from 'src/app/shared/models/customer-group';
import { Tag } from 'src/app/shared/models/tag';
import { Attribute } from 'src/app/shared/models/attribute';
import { Seller } from 'src/app/shared/models/seller';
import { FieldService } from 'src/app/shared/services/field.service';
import { AccountAttributeService } from 'src/app/shared/services/account-attribute.service';
import { SellerService } from 'src/app/shared/services/seller.service';
import { DbGear } from 'src/app/shared/models/DbGear';
import { JsonDataSourceWizardPageId } from '@devexpress/analytics-core/analytics-wizard';
import { HelperService } from 'src/app/shared/services/helper.service';
import { JsonParameter } from '@devexpress/analytics-core/analytics-data';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { DatasourceService } from 'src/app/shared/services/datasource.service';
import { Datasource } from 'src/app/shared/models/datasource';
import { Period } from 'src/app/shared/models/period';
import { PeriodService } from 'src/app/shared/services/period.service';
import { SavedColumn } from 'src/app/shared/models/saved-column';
import { AuditGridFilterCriteria } from 'src/app/shared/models/audit-grid-filter-criteria';
import { SavedColumnBinding } from 'src/app/shared/models/saved-column-binding';

@Injectable()
export class BuildingBlockHelperService {

    private scopeContainer = new BehaviorSubject<Container>(null);
    private focusedObject = new BehaviorSubject<BbObject>(null);
    private focusedObjectChanges = new BehaviorSubject<Gear>(null);
    private focusedObjectPath = new BehaviorSubject<string>(null);
    private subObject = new BehaviorSubject<BbObject>(null);
    private objectStore = new BehaviorSubject<Dictionary<string, BbObject>>(null);
    private containerObjectStore = new BehaviorSubject<Dictionary<string, BbObject>>(null);
    private selectedNodes = new BehaviorSubject<Node[]>(null);
    private refreshDiagram = new Subject<void>();
    private propertyPanelWidthRequest = new Subject<number>();
    private objectStoreInitialized = new Subject<void>();
    private showAdvancedView = new BehaviorSubject<boolean>(false);
    private showGearboxView = new BehaviorSubject<boolean>(false);
    private seriesId = new BehaviorSubject<number>(null);
    private recurrenceId = new BehaviorSubject<number>(null);
    private periodId = new BehaviorSubject<number>(null);
    private dataColumns = new BehaviorSubject<DataColumn[]>(null);
    private showLoadPanel = new BehaviorSubject<boolean>(null);
    private editLock = new BehaviorSubject<boolean>(null);
    private showLoadPropertyPanel = new BehaviorSubject<boolean>(null);
    private gearboxTemplates = new BehaviorSubject<any>(null);
    private auditedObject = new BehaviorSubject<any>(null);
    private auditedRow = new BehaviorSubject<AuditGridFilterCriteria>(null);
    private originWindow = new BehaviorSubject<Window>(null);
    private diagramAuditRequest = new BehaviorSubject<boolean>(false);
    private showEndDatedRules = new BehaviorSubject<boolean>(false);

    private products: Product[];
    private customers: Customer[];
    private productGroups: ProductGroup[];
    private customerGroups: CustomerGroup[];
    private tags: Tag[];
    private accountAttributes: Attribute[];
    private accounts: Seller[];
    private accountFields: Singleton[];
    private textFields: Singleton[];
    private dateFields: Singleton[] = [];
    private qtyFields: Singleton[] = [];
    private tagFields: Singleton[] = [];
    private datasources: Datasource[];
    private periods: Period[];
    private unsavedGrids: string[] = [];
    private scopeContainerStack: BbObject[] = [];
    private copiedFilter: any;

    constructor(private buildingBlocksService: BuildingBlocksService,
        private fieldService: FieldService,
        private accountAttributeService: AccountAttributeService,
        private sellerService: SellerService,
        private helperService: HelperService,
        private datasourceService: DatasourceService,
        private periodService: PeriodService,
        private router: Router,
        private toast: ToastrService) {

        this.refreshDataColumns();
    }

    refreshDataColumns(): void {
        forkJoin({
            dataColumns: this.buildingBlocksService.getAllDataColumns(),
            objects: this.buildingBlocksService.getAllObjects(),
            fields: this.fieldService.getSingletons(),
            products: this.fieldService.getAllProducts(),
            customers: this.fieldService.getAllCustomers(),
            productGroups: this.fieldService.getAllProductGroups(),
            customerGroups: this.fieldService.getAllCustomerGroups(),
            tags: this.fieldService.getTags(),
            accountAttributes: this.accountAttributeService.getAllAttributes(),
            accounts: this.sellerService.getSellersShallow(),
            datasources: this.datasourceService.getAllDatasources(),
            periods: this.periodService.getPeriods()
        }).subscribe(results => {
            this.accountFields = results.fields.filter(xf => xf.singletonClassId === 1).sort((a, b) => (a.value > b.value) ? 1 : -1);
            this.textFields = results.fields.filter(xf => xf.singletonClassId === 4).sort((a, b) => (a.value > b.value) ? 1 : -1);
            this.dateFields = results.fields.filter(xf => xf.singletonClassId === 3).sort((a, b) => (a.value > b.value) ? 1 : -1);
            this.qtyFields = results.fields.filter(xf => xf.singletonClassId === 2).sort((a, b) => (a.value > b.value) ? 1 : -1);
            this.tagFields = results.fields.filter(xf => xf.singletonClassId === 5).sort((a, b) => (a.value > b.value) ? 1 : -1);
            this.products = results.products.sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.customers = results.customers.sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.productGroups = results.productGroups.sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.customerGroups = results.customerGroups.sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.tags = results.tags.sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.accountAttributes = results.accountAttributes.sort((a, b) => (a.name > b.name || a.name === 'unassigned') ? 1 : -1);
            this.accounts = results.accounts.filter(x => x.name !== 'Core Admin').sort((a, b) => (a.name > b.name) ? 1 : -1);
            this.datasources = results.datasources;
            this.periods = results.periods;
            this.setObjectStore(new Dictionary<string, BbObject>(results.objects));
            this.dataColumns.next(results.dataColumns);
            this.setGearboxTemplates();
        });
    }

    getShowLoadPropertyPanel(): Observable<boolean> {
        return this.showLoadPropertyPanel.asObservable();
    }

    setShowLoadPropertyPanel(value: boolean): void {
        this.showLoadPropertyPanel.next(value);
    }

    getShowLoadPanel(): Observable<boolean> {
        return this.showLoadPanel.asObservable();
    }

    setShowLoadPanel(value: boolean): void {
        this.showLoadPanel.next(value);
    }

    getDataColumns(): Observable<DataColumn[]> {
        return this.dataColumns.asObservable();
    }

    fetchDataColumns() {
        this.buildingBlocksService.getAllDataColumns().subscribe(res => {
            this.dataColumns.next(res);
        });
    }

    getShowAdvancedView(): Observable<boolean> {
        return this.showAdvancedView.asObservable();
    }

    setShowAdvancedView(value: boolean): void {
        this.showAdvancedView.next(value);
    }

    getShowEndDatedRules(): Observable<boolean> {
        return this.showEndDatedRules.asObservable();
    }

    setShowEndDatedRules(value: boolean): void {
        this.showEndDatedRules.next(value);
    }

    getGearboxView(): Observable<boolean> {
        return this.showGearboxView.asObservable();
    }

    setGearboxView(value: boolean): void {
        this.showGearboxView.next(value);
    }

    getAuditedRow(): Observable<AuditGridFilterCriteria> {
        return this.auditedRow.asObservable();
    }

    setAuditedRow(value: AuditGridFilterCriteria): void {
        this.auditedRow.next(value);
    }

    beginDiagramAudit(): void {
        this.diagramAuditRequest.next(true);
    }

    getDiagramAuditRequest(): Observable<boolean> {
        return this.diagramAuditRequest.asObservable();
    }

    getAuditedObject(): Observable<any> {
        return this.auditedObject.asObservable();
    }

    setAuditedObject(value: any): void {
        this.auditedObject.next(value);
    }

    getObjectStoreInitilized(): Observable<void> {
        return this.objectStoreInitialized.asObservable();
    }

    getScopeContainer(): Observable<Container> {
        return this.scopeContainer.asObservable();
    }

    setScopeContainer(newScope: Container): void {
        if(this.scopeContainer.value != null && newScope != null && this.scopeContainer.value.id !== newScope.id){
            if(this.scopeContainer.value.name !== newScope.name){
                this.scopeContainerStack.push(this.scopeContainer.value);
            }
            if(this.originWindow.value){
                this.router.navigate(['building-blocks-audit/' + newScope.id]);
            } else {
                this.router.navigate(['building-blocks/' + newScope.id]);
            }
        }
        this.setEditLock(newScope?.id.startsWith('Bb'));
        this.scopeContainer.next(newScope);
    }

    scopeDown(newContainer: Container) {
        this.setScopeContainer(newContainer);
        this.setFocusedObject(newContainer);
    }

    scopeUp() {
        const canScopeUp: boolean = this.scopeContainerStack.length > 0;
        if(canScopeUp){
            const newScope: Container = this.scopeContainerStack.pop() as Container;
            if(this.originWindow.value){
                this.router.navigate(['building-blocks-audit/' + newScope.id]);
            } else {
                this.router.navigate(['building-blocks/' + newScope.id]);
            }
            this.scopeContainer.next(newScope);
            this.setFocusedObject(newScope);
            this.setFocusedObjectChanges(newScope as Gear);
            this.setEditLock(newScope.id.startsWith('Bb'));
        } else {
            if(this.originWindow.value){
                window.blur();
                if(this.originWindow.value.name.startsWith('recordDataViewer')){
                    window.open('', this.originWindow.value.name).focus();
                }
                window.close();
            } else {
                this.scopeContainer.next(null);
                this.setEditLock(false);
                this.clearScopeStack();
                this.router.navigate(['/plans']);
            }
        }
    }

    clearScopeStack() {
        this.scopeContainerStack = [];
    }

    getFocusedObject(): Observable<BbObject> {
        return this.focusedObject.asObservable();
    }

    setFocusedObject(newFocusedObject: BbObject): void {
        this.focusedObject.next(newFocusedObject);
    }

    getFocusedObjectPath(): Observable<string> {
        return this.focusedObjectPath.asObservable();
    }

    setFocusedObjectPath(newFocusedObjectPath: string): void {
        this.focusedObjectPath.next(newFocusedObjectPath);
    }

    getFocusedObjectChanges(): Observable<Gear> {
        return this.focusedObjectChanges.asObservable();
    }

    setFocusedObjectChanges(newFocusedObject: Gear): void {
        this.focusedObjectChanges.next(newFocusedObject);
    }

    getSubObject(): Observable<BbObject> {
        return this.subObject.asObservable();
    }

    setSubObject(newSubObject: BbObject): void {
        this.subObject.next(newSubObject);
    }

    getObjectStore(): Observable<Dictionary<string, BbObject>> {
        return this.objectStore.asObservable();
    }

    setObjectStore(newObjectStore: Dictionary<string, BbObject>): void {
        if (this.objectStore.value === null) {
            this.objectStore.next(newObjectStore);
            this.objectStoreInitialized.next();
            return;
        }
        this.objectStore.next(newObjectStore);
    }

    getObjectStoreRules(): Observable<Container[]> {
        return this.getObjectStore().pipe(map(dict => dict.byFilter(obj => obj.id.startsWith('Co') && obj['typeId'] === EnumContainerType.ProductionRule).map(obj => obj as Container)));
    }

    setSelectedNodes(nodeArray: Node[]): void {
        this.selectedNodes.next(nodeArray);
    }

    getSelectedNodes(): Observable<Node[]> {
        return this.selectedNodes.asObservable();
    }

    getEditLock(): Observable<boolean> {
        return this.editLock.asObservable();
    }

    setEditLock(value: boolean): void {
        this.editLock.next(value);
    }

    getRefreshDiagramObservable(): Observable<void> {
        return this.refreshDiagram.asObservable();
    }

    forceRefreshDiagram() {
        this.refreshDiagram.next();
    }

    getPropertyPanelWidthRequest(): Observable<number> {
        return this.propertyPanelWidthRequest.asObservable();
    }

    setPropertyPanelWidthRequest(width: number): void {
        return this.propertyPanelWidthRequest.next(width);
    }

    getParentByChildObject(child: BbObject): Container {
        if (child.parentId === null) {
            return null;
        } else {
            return this.objectStore.value.byKey(child.parentId) as Container;
        }
    }

    getRootContainer(): Container {
        const rootContainerId = `${Group.getObjectTypeCode()}Root`;
        return this.objectStore.value.byKey(rootContainerId) as Container;
    }

    getAllContainers(): Container[] {
        if(!this.objectStore.value) { return []; }
        return this.objectStore.value.byFilter(p => p instanceof Container) as Container[];
    }

    getAllRules(): Container[] {
        return this.getAllContainers().filter(container => container.id.startsWith('Co') && container.typeId === EnumContainerType.ProductionRule);
    }

    getAllRuleVersionsById(id: number): Container[] {
        const ruleName: string = this.objectStore.value.byKey('Co' + id).name;
        return this.getAllRules().filter(rule => rule.name === ruleName);
    }

    getAllContainersForPlansView(): Container[] {
        const rootContainerId = `${Group.getObjectTypeCode()}Root`;
        const containers: Container[] = [];
        this.getAllContainers().forEach(x => {
            if (x.id !== rootContainerId) {
                const isSegment = x.id.startsWith('Sg');
                const newContainer: Container = {
                    id: x.id,
                    parentId: x.parentId,
                    typeId: x.typeId,
                    name: x.name,
                    description: x.description,
                    lastModified: x.lastModified,
                    objectJson: x.objectJson,
                    locked: x.locked,
                    isActive: x.isActive,
                    recurrenceId: x.recurrenceId,
                    objectTypeCode: x.objectTypeCode,
                    applyChanges: x.applyChanges,
                    isSegment,
                    periodBeginId: x.periodBeginId,
                    periodEndId: x.periodEndId
                };
                Object.assign(newContainer, x);
                if (newContainer.parentId === rootContainerId) {
                    newContainer.parentId = null;
                }
                newContainer['level'] = isSegment ?
                    (x as Gear).propertyValues?.find(pv => pv.property.systemName === 'orderIndex').value :
                    JSON.parse(x['objectJson'] ?? '{}').level;

                containers.push(newContainer);
            }
        });
        return containers;
    }

    getHeadObjectIdsByContainer(container: Container): string[] {
        if(this.helperService.isNullOrUndefined(container)){
            return [];
        }
        if (container instanceof Gear) {
            return [container.headProcessId];
        } else {
            const children = this.objectStore.value.byFilter(p => p.parentId === container.id);
            const extendedChildren = children.concat(...children.filter(x => x instanceof Group).map(gearbox => this.objectStore.value.byFilter(p => p.parentId === gearbox.id)));
            if (container.id.startsWith('SBUser')) {
                return children
                    .filter(child => !children
                        .some(child2 => this.getObjectInputArray(child2, container.periodBeginId)
                            .some(input => input.objectId === child.id)))
                    .map(child => child.id);
            } else {
                // NOTE: Excluding expensive filter to improve performance; revert if it causes problems. -DH
                // This probably won't work with fundamental headprocesses will need to check
                let nonHeadProcesses = [];
                (extendedChildren.filter(c => c instanceof Gear) as Gear[]).forEach(c => {
                    const sourceGears = [c.propertyValues.find(p => p.property.systemName === 'sourceProcessId')?.value, c.propertyValues.find(p => p.property.systemName === 'auxProcessId')?.value];
                    nonHeadProcesses = nonHeadProcesses.concat(sourceGears.filter(b => b !== undefined));
                });
                return children.filter(child => !nonHeadProcesses.includes(child.id)).map(c => c.id);
            }
        }
    }

    getObjectInputArray(obj: BbObject, periodId: number): ObjectInput[] {
        let result: ObjectInput[];
        if (obj instanceof DatasourceProcess) {
            result = [];
        } else if (obj instanceof TransformProcess) {
            result = [new ObjectInput(null, obj.mainSourceProcessId, DependencyType.Main)]
                .concat(obj.auxSourceProcesses
                    .map<ObjectInput>(auxSourceProcess => new ObjectInput(null, auxSourceProcess.processId, DependencyType.Aux)));
        } else if (obj instanceof FilterProcess) {
            result = [new ObjectInput(null, obj.sourceProcessId, DependencyType.Main)];
        } else if (obj instanceof CalculateProcess) {
            // TODO: Calculate formula-based dependencies in view. -DH
            result = [new ObjectInput(null, obj.sourceProcessId, DependencyType.Main)];
        } else if (obj instanceof AggregateProcess) {
            result = [new ObjectInput(null, obj.sourceProcessId, DependencyType.Main)];
        } else if (obj instanceof UnionProcess) {
            result = [new ObjectInput(null, obj.sourceProcessId, DependencyType.Main),
                      new ObjectInput(null, obj.auxProcessId, DependencyType.Aux)];
        } else if (obj instanceof Container || obj.id.startsWith('Co') || obj.id.startsWith('Bb')) {
            result = this.getContainerInputArray(obj as Container, periodId);
        } else {
            throw new Error('Bad process type');
        }
        result.map(res => {
            if(res.objectId && res.objectId !== ''){
                res.objectId = this.getActiveRuleVersionOrDefaultByPeriodId(res.objectId, periodId, this.periods)?.id ?? res.objectId;
            }
        });
        return result;
    }

    getContainerInputArray(container: Container, periodId: number): ObjectInput[] {
        const result: ObjectInput[] = [];
        const visitedObjectIds: string[] = [];
        this.recursiveGetContainerInputArray(result, visitedObjectIds, container.id, this.getHeadObjectIdsByContainer(container), false, periodId);
        return result;
    }

    recursiveGetContainerInputArray(result: ObjectInput[], visitedObjectIds: string[], containerId: string, childObjectIdArray: string[], isAux: boolean, periodId: number): void {
        for (const childObjectId of childObjectIdArray) {
            if (!visitedObjectIds.some(id => id === childObjectId)) {
                visitedObjectIds.push(childObjectId);
                const childObject = this.objectStore.value.byKey(childObjectId);
                const inputArray = this.getObjectInputArray(childObject, periodId).filter(obj => obj.objectId && obj.objectId !== containerId);
                for (const input of inputArray) {
                    if (this.objectStore.value.keyExists(input.objectId)) {
                        const sourceObject = this.objectStore.value.byKey(input.objectId);
                        if (!this.getObjectAncestors(sourceObject).some(obj => obj.id === containerId)) {
                            if (!result.some(resultInput => resultInput.objectId === input.objectId)) {
                                result.push(new ObjectInput(input.name, input.objectId, (isAux || input.type === DependencyType.Aux) ? DependencyType.Aux : DependencyType.Main));
                            }
                        } else {
                            this.recursiveGetContainerInputArray(result, visitedObjectIds, containerId, [input.objectId], isAux || input.type === DependencyType.Aux, periodId);
                        }
                    }
                }
            }
        }
    }

    getObjectAncestors(obj: BbObject): BbObject[] {
        const result: BbObject[] = [];
        for (; obj !== null; obj = this.getParentByChildObject(obj)) {
            result.push(obj);
        }
        return result;
    }

    getPathBetweenObjects(source: BbObject, dest: BbObject, periodId: number){
        if(!source?.id || !dest?.id){
            return [];
        }
        const upstreamPath = this.recursiveGetGearPath(source.id, dest.id, periodId);
        if(upstreamPath.length === 0){
            return this.recursiveGetGearPath(dest.id, source.id, periodId);
        }
        return upstreamPath;
    }

    recursiveGetGearPath(source: string, dest: string, periodId: number, list: Array<string> = [source]){
        if(source === dest){
            return list;
        }
        let fullList = [];
        const sourceObj = this.getObjectById(source);
        let objSources = [];
        if(source.startsWith('Co') || sourceObj instanceof Gear){
            objSources = this.getHeadObjectIdsByContainer(sourceObj as Container);
        } else {
            objSources = this.getObjectInputArray(sourceObj, periodId).map(obj => obj.objectId);
        }
        objSources.forEach(upstreamObjId => {
            const srcList = upstreamObjId ? this.recursiveGetGearPath(upstreamObjId, dest, periodId, list.concat(upstreamObjId)) : [];
            if(srcList.length > 0){
                fullList = srcList;
            }
        });
        return fullList;
    }

    getObjectById(id: string): BbObject {
        return this.objectStore.value.keyExists(id) ? this.objectStore.value.byKey(id) : null;
    }

    setSeriesId(seriesId: number): void {
        this.seriesId.next(seriesId);
    }

    getSeriesId(): Observable<number> {
        return this.seriesId.asObservable();
    }

    setRecurrenceId(recurrenceId: number): void {
        this.recurrenceId.next(recurrenceId);
    }

    getRecurrenceId(): Observable<number> {
        return this.recurrenceId.asObservable();
    }

    setPeriodId(periodId: number): void {
        this.periodId.next(periodId);
    }

    getPeriodId(): Observable<number> {
        return this.periodId.asObservable();
    }

    setOriginWindow(windowReference: Window): void {
        this.originWindow.next(windowReference);
    }

    getOriginWindow(): Observable<Window> {
        return this.originWindow.asObservable();
    }

    getOriginWindowValue(): Window {
        return this.originWindow.value;
    }

    replaceObjectInStore(bbObject: BbObject) {
        this.objectStore.value.remove(bbObject.id);
        this.objectStore.value.insert(bbObject);
    }

    removeObjectFromObjectStore(id: string, update: boolean = false) {
        this.objectStore.value.remove(id);
        if (update) {
            this.objectStore.next(this.objectStore.value);
        }
    }

    removeRuleAndVersionsFromObjectStore(id: string) {
        const objName = this.objectStore.value.byKey(id).name;
        const ruleVersions: BbObject[] = this.objectStore.value.byFilter(p => p.name === objName);
        for (const version of ruleVersions) {
            this.removeObjectAndChildrenFromObjectStore(version.id);
        }
    }

    removeObjectAndChildrenFromObjectStore(id: string, update: boolean = false) {
        const toRemove: BbObject[] = this.objectStore.value.byFilter(p => p.parentId === id);
        for (const child of toRemove) {
            if (child instanceof Gear) {
                this.removeObjectAndChildrenFromObjectStore(child.id);
            } else {
                this.objectStore.value.remove(child.id);
            }
        }
        this.objectStore.value.remove(id);
        if (update) {
            this.objectStore.next(this.objectStore.value);
        }
    }

    addObjectsToStore(toAdd: BbObject[], update: boolean = false) {
        for (const obj of toAdd) {
            this.objectStore.value.insert(obj);
        }
        if (update) {
            this.objectStore.next(this.objectStore.value);
        }
    }

    setFocusedObjectByName(planOrSegment: string, name: string) {
        this.setFocusedObject(this.objectStore.value.byFilter(obj => obj.name === name && obj.id.includes(planOrSegment))[0]);
        this.setScopeContainer(this.objectStore.value.byFilter(obj => obj.name === name && obj.id.includes(planOrSegment))[0] as Container);
    }

    getLookupByDatatype(datatype: string, grouped: boolean = false): { dataSource: any[], valueExpr: string, displayExpr: string, readOnly?: boolean, hint?: string } {
        let result: { dataSource: any[], valueExpr: string, displayExpr: string, readOnly?: boolean, hint?: string };
        if (datatype.endsWith('_name')){
            datatype = datatype.replace('_name', '');
        }
        if (datatype === 'seller_id_field') {
            result = { dataSource: this.accountFields, valueExpr: 'fieldNo', displayExpr: 'value' };
        } else if (datatype === 'seller') {
            result = { dataSource: this.accounts, valueExpr: 'id', displayExpr: 'name' };
        } else if (datatype.startsWith('tag_')) {
            const tagNo = parseInt(datatype.substr('tag_'.length), 10);
            result = { dataSource: this.tags.filter(tag => tag.tagNo === tagNo).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1), valueExpr: 'id', displayExpr: 'name' };
        } else if (datatype === 'product') {
            const ds = grouped ?
                this.productGroups.map(group =>
                    ({key: group.name, items: this.products.filter(product => product.productGroupId === group.id)})) :
                this.products;
            result = { dataSource: ds, valueExpr: 'id', displayExpr: 'name' };
        } else if (datatype === 'customer') {
            const ds = grouped ?
                this.customerGroups.map(group =>
                    ({key: group.name, items: this.customers.filter(customer => customer.customerGroupId === group.id)})) :
                this.customers;
            result = { dataSource: ds, valueExpr: 'id', displayExpr: 'name' };
        } else if (datatype === 'product_group') {
            result = { dataSource: this.productGroups, valueExpr: 'id', displayExpr: 'name' };
        } else if (datatype === 'customer_group') {
            result = { dataSource: this.customerGroups, valueExpr: 'id', displayExpr: 'name' };
        } else if (datatype.startsWith('account_attribute_')) {
            const classId = parseInt(datatype.substr('account_attribute_'.length), 10);
            result = { dataSource: this.accountAttributes.filter(a => a.attributeClassId === classId).sort(
                (a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1), valueExpr: 'id', displayExpr: 'name' };
        } else if (datatype === 'Datasource') {
            const datasources = this.objectStore.value.byFilter(obj => obj.objectTypeCode === 'Ds' && !obj.id.includes('Sg')).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
            result = { dataSource: datasources, valueExpr: 'id', displayExpr: 'name' };
        } else if (datatype === 'nonKeyDatasources'){
            const datasources = this.datasources.map(ds => ({
                id: 'Ds' + ds.id,
                name: ds.name,
                disabled: ds.isDeleted
            })).sort((a,b) =>  a.name.localeCompare(b.name) + (a.disabled ? 1000 : 0) - (b.disabled ? 1000 : 0));
            result = { dataSource: datasources, valueExpr: 'id', displayExpr: 'name' };
        } else if(datatype === 'datasourceId') {
            const datasources = this.datasources.map(ds => ({
                id: ds.id,
                name: ds.name,
                disabled: ds.isDeleted
            })).sort((a,b) =>  a.name.localeCompare(b.name) + (a.disabled ? 1000 : 0) - (b.disabled ? 1000 : 0));
            result = { dataSource: datasources, valueExpr: 'id', displayExpr: 'name' };
        } else if (datatype === 'sourceProcessId' || datatype === 'auxProcessId') {
            let ds = [];
            const validRules = this.showEndDatedRules.value ? this.getAllRules()
            : this.getAllRulesForPeriodRange(this.scopeContainer.value?.periodBeginId, this.scopeContainer.value?.periodEndId);
            if (this.objectStore.value != null){
                const allCategories = Object.values(BbObjectSourceCategory);
                const allProcesses = [];
                const rules = this.getAllContainers().filter((c, index, self) => (c.typeId === EnumContainerType.ProductionRule || c.id.startsWith('Sg'))
                                            && (index === self.findIndex((t) => (t.name === c.name))));
                const ruleScopeIds = this.getRuleScopeIds();

                // Todo: Compare checking every object in the store versus selecting for the list using by filter multiple times
                this.objectStore.value.allValues().forEach(element => {
                    const isGearInRuleScope = element instanceof Gear && element.gearTypeCode !== GearCode.SegmentGearCode && ruleScopeIds.includes(element.parentId);
                    const isGearboxInRuleScope = element instanceof Group && element.typeId === EnumContainerType.Group && ruleScopeIds.includes(element.parentId) && ruleScopeIds[0] !== element.id;

                    const processByGroup = {
                        id: element.id,
                        name: element.name,
                        category: '',
                        disabled: false
                    };

                    if (processByGroup.id.slice(0, 2) === 'Ds') {
                        if(isNaN(Number(processByGroup.id.slice(2)))){
                            processByGroup.category = BbObjectSourceCategory.System;
                        } else {
                            processByGroup.category = BbObjectSourceCategory.Datasource;
                            if(this.datasources.find(dbDatasource => dbDatasource.id === +(processByGroup.id.replace('Ds', ''))).isDeleted){
                                processByGroup.disabled = true;
                            }
                        }
                    } else if (isGearInRuleScope) {
                        processByGroup.category = BbObjectSourceCategory.Gear;
                    } else if (isGearboxInRuleScope) {
                        processByGroup.category = BbObjectSourceCategory.Gearbox;
                    } else if (rules.some(c => c.id === element.id)) {
                        processByGroup.category = BbObjectSourceCategory.Rule;
                        if((!rules.find(c => c.id === element.id).isActive || !validRules.some(rule => rule.name === element.name)) && !element.id.startsWith('Sg')){
                            processByGroup.disabled = true;
                        }
                    }

                    if (processByGroup.category !== '' && (this.scopeContainer.value == null || element.id !== this.focusedObjectChanges.value?.id)) {
                        allProcesses.push(processByGroup);
                    }
                });
                ds = allCategories.map(category =>
                    (
                        {
                            key: category, items: allProcesses.filter(process => process.category === category)
                                .sort((a,b) => a.name.localeCompare(b.name) + (a.disabled ? 1000 : 0) - (b.disabled ? 1000 : 0))
                        }
                ));
            }
            result = { dataSource: ds, valueExpr: 'id', displayExpr: 'name' };
        } else if (datatype === 'periodFilterString' || datatype === 'dateRangeTypeCode') {
            const periodLookup = [
                {
                    id: '.',
                    name: 'Current Period'
                },
                {
                    id: 'M',
                    name: 'Month-to-date'
                },
                {
                    id: 'Q',
                    name: 'Quarter-to-date'
                },
                {
                    id: 'Tri',
                    name: 'Trimester-to-date'
                },
                {
                    id: 'Sem',
                    name: 'Semester-to-date'
                },
                {
                    id: 'Y',
                    name: 'Year-to-date'
                },
                {
                    id: 'L',
                    name: 'Prior Month-to-date'
                },
                {
                    id: 'P',
                    name: 'Prior Quarter-to-date'
                },
                {
                    id: 'PTri',
                    name: 'Prior Trimester-to-date'
                },
                {
                    id: 'PSem',
                    name: 'Prior Semester-to-date'
                },
                {
                    id: 'X',
                    name: 'Prior Year-to-date'
                },
                {
                    id: 'N',
                    name: 'Prior Year Month-to-date'
                },
                {
                    id: 'W',
                    name: 'Prior Year Quarter-to-date'
                },
                {
                    id: 'R',
                    name: 'Rolling'
                },
                {
                    id: 'F',
                    name: 'Carry Forward'
                },
                {
                    id: 'S',
                    name: 'Static'
                }
            ];
            result = { dataSource: periodLookup, valueExpr: 'id', displayExpr: 'name' };
        } else if (datatype === 'earnDateSystemName') {
            const staticEarnDates = [
                {
                    refName: 'account_active_date',
                    value: '<Use Account\'s Active Date>'
                },
                {
                    refName: 'period_end_date',
                    value: '<Use Period\'s End Date>'
                },
                {
                    refName: 'period_begin_date',
                    value: '<Use Period\'s Begin Date>'
                },

            ];
            result = { dataSource: [...staticEarnDates,...this.dateFields], valueExpr: 'refName', displayExpr: 'value' };
        } else if (datatype === 'beginDateField' || datatype === 'endDateField') {
            result = { dataSource: this.dateFields, valueExpr: 'refName', displayExpr: 'value' };
        } else if (datatype === 'activityDateField') {
            const staticEarnDates = [
                {
                    refName: 'period_end_date',
                    value: '<Use Period\'s End Date>'
                },
                {
                    refName: 'period_begin_date',
                    value: '<Use Period\'s Begin Date>'
                },

            ];
            result = { dataSource: [...staticEarnDates,...this.dateFields], valueExpr: 'refName', displayExpr: 'value' };
        } else if (datatype === 'Apply to') {
            const applyToLookup = [
                {
                    id: 'D',
                    name: 'Roles'
                },
                {
                    id: 'S',
                    name: 'Hierarchy'
                },
                {
                    id: 'E',
                    name: 'Accounts'
                },
                {
                    id: 'A',
                    name: 'Accounts and new'
                },
                {
                    id: 'T',
                    name: 'Account attributes'
                },
                {
                    id: 'R',
                    name: 'Source record'
                }
            ];
            result = { dataSource: applyToLookup, valueExpr: 'id', displayExpr: 'name' };
        } else if (datatype === 'runningSumDateFieldSystemName') {
            result = { dataSource: [{refName:null, value: '<Remove Running Sum>'},...this.dateFields], valueExpr: 'refName', displayExpr: 'value' };
        } else if (datatype === 'Quantity Fields') {
            result = { dataSource: this.qtyFields, valueExpr: 'refName', displayExpr: 'value' };
        } else if (datatype === 'Date') {
            result = { dataSource: [...this.gearColumnsByDatatype('datetime')].sort((a, b) => a.value.localeCompare(b.value)), valueExpr: 'refName', displayExpr: 'value' };
        } else if (datatype === 'Text') {
            const nameCols = this.dataColumns.getValue().filter(col => col.systemName.endsWith('_name')).map(col => ({
                refName: col.systemName,
                value: col.friendlyName
            }));
            result = { dataSource: [...this.gearColumnsByDatatype('string'), ...nameCols].sort((a, b) => a.value.localeCompare(b.value)), valueExpr: 'refName', displayExpr: 'value' };
        } else if (datatype === 'Tag') {
            result = { dataSource: this.tagFields.sort((a, b) => a.value.localeCompare(b.value)), valueExpr: 'refName', displayExpr: 'value' };
        } else if (datatype === 'joinType') {
            result = { dataSource: [{refName: 0, value: 'Inner join'}, {refName: 1, value: 'Left join'}, {refName: 2, value: 'Right join'},
                {refName: 5, value: 'Cross join'}], valueExpr: 'refName', displayExpr: 'value' };
        } else if(datatype === 'joinHint') {
            result = { dataSource: [{refName: 0, value: 'None'}, {refName: 1, value: 'Loop'}, {refName: 2, value: 'Hash'}, {refName: 3, value: 'Merge'}], valueExpr: 'refName', displayExpr: 'value' };
        } else if (datatype === 'updateFields') {
            result = { dataSource: [
                {refName: 0, value: 'Update only null values in column'},
                {refName: 1, value: 'Update values in column'},
                {refName: 2, value: 'Wipe column in datasource, then update'},
            ], valueExpr: 'refName', displayExpr: 'value' };
        } else if (datatype === 'Quantity' || datatype === 'AccountFactors') {
            const dataSource = [...this.gearColumnsByDatatype('decimal'), ...this.gearColumnsByDatatype('id')].sort((a, b) => a.value.localeCompare(b.value));
            result = { dataSource, valueExpr: 'refName', displayExpr: 'value' };
        } else if (datatype === 'Gears'){
            result = { dataSource: [
                ...this.getLookupByDatatype('Date')['dataSource'],
                ...this.getLookupByDatatype('Text')['dataSource'],
                ...this.getLookupByDatatype('Quantity')['dataSource']
                ], valueExpr: 'refName', displayExpr: 'value' };
        } else if (datatype === 'datatype') {
            const doesFilterHaveFormulaOutput: boolean = this.objectStore.value.byFilter(obj => obj.parentId === this.focusedObject.value?.parentId && (obj as Gear).gearTypeCode === '0009')
                .some(filterGear => (filterGear as Gear).propertyValues
                .some(propertyMapping => propertyMapping.property.systemName === 'filterString' && propertyMapping.value.includes(this.focusedObject.value.id + '_value')));

            const dataTypeLookup = [
                {refName: 'decimal', value: 'Quantity'},
                {refName: 'string', value: 'Text'},
                {refName: 'datetime', value: 'Date'}
            ];

            const readOnlyTooltip = doesFilterHaveFormulaOutput ? 'Datatype cannot be set if formula gear output is used in a filter gear.' : '';

            result = { dataSource: dataTypeLookup, valueExpr: 'refName', displayExpr: 'value', readOnly: doesFilterHaveFormulaOutput, hint: readOnlyTooltip };
        } else {
            result = null;
        }
        return result;
    }

    updateStoreObject(bbObject: BbObject, update: boolean = false) {
        this.objectStore.value.byKey(bbObject.id).name = bbObject.name;
        this.objectStore.value.byKey(bbObject.id).description = bbObject.description;
        if (bbObject['isActive'] !== undefined) {
            this.objectStore.value.byKey(bbObject.id)['isActive'] = bbObject['isActive'];
        }
        if (bbObject['isPayment'] !== undefined) {
            this.objectStore.value.byKey(bbObject.id)['isPayment'] = bbObject['isPayment'];
        }
        this.objectStore.value.byKey(bbObject.id).description = bbObject.description;
        this.objectStore.value.byKey(bbObject.id).description = bbObject.description;
        if (bbObject['objectJson'] !== undefined) {
            this.objectStore.value.byKey(bbObject.id)['objectJson'] = bbObject['objectJson'];
        }
        if (bbObject['recurrenceId'] !== undefined) {
            this.objectStore.value.byKey(bbObject.id)['recurrenceId'] = bbObject['recurrenceId'];
        }
        if (bbObject['parentId'] !== undefined) {
            this.objectStore.value.byKey(bbObject.id)['parentId'] = bbObject['parentId'];
        }
        if (update) {
            this.objectStore.next(this.objectStore.value);
        }
    }

    GearToDbGear(uiGear: Gear): DbGear {
        const newDbGear = new DbGear();
        newDbGear.Id = parseInt((uiGear.id).slice(2), 10);
        newDbGear.ParentId = parseInt((uiGear.parentId).slice(2), 10);
        newDbGear.Name = uiGear.name;
        newDbGear.Description = uiGear.description;
        newDbGear.TypeId = +uiGear.gearTypeCode;
        const listOfProps: Record<any, string> = {};
        for (const prop of uiGear.propertyValues) {
            if (prop.property.systemName) {
                // Todo: fix this by adding new input type that doesn't change the array into a string
                listOfProps[prop.property.systemName] = prop.value === '[]'? [] : prop.value ?? null;
            }
        }
        listOfProps['GearTypeCode'] = uiGear.gearTypeCode;
        newDbGear.Properties = JSON.stringify(listOfProps);

        return newDbGear;
    }

    removeDuplicateGearsForLoadDiagram(headObjects: BbObject[]): BbObject[] {
        const headGearObjects: BbObject[] = headObjects.filter(obj => obj instanceof Gear);
        const listOfGearIds: string[] = headGearObjects.map(obj => obj.id);
        const repeatedGearIds: Set<string> = new Set<string>();
        // Todo: Ensure that this doesn't choke on large rules
        for (const gear of headGearObjects) {
            for (const prop of (gear as Gear).propertyValues) {
                if (prop.value !== gear.id && listOfGearIds.includes(prop.value)) {
                    repeatedGearIds.add(prop.value);
                }
            }
        }
        return headObjects.filter(obj => !repeatedGearIds.has(obj.id));
    }

    identifySelectedObject(key: string) {
        const objects = key.split('.');
        return objects[objects.length - 1];
    }

    async updateDependentSourceProcess(nodeIdToRemove: string) {
        const objects = nodeIdToRemove.split('.');
        if (objects.length > 1) {
            const objectIdRemoved = objects[objects.length - 1];
            const objectIdToUpdate = objects[objects.length - 2];
            const objectToUpdate = this.objectStore.value.byKey(objectIdToUpdate);

            if (objectToUpdate instanceof Gear) {
                this.updateGearSource(objectToUpdate, objectIdRemoved);
            } else if (objectToUpdate instanceof Group) {
                this.objectStore.getValue().allValues().filter(x => x.parentId === objectToUpdate.id).forEach(gearboxChild => {
                    this.updateGearSource(gearboxChild as Gear, objectIdRemoved);
                });
            }
        }
    }

    async updateGearSource(gear: Gear, objIdRemoved: string) {
        for (const prop of gear.propertyValues) {
            if (prop.property.systemName === 'sourceProcessId' && prop.value === objIdRemoved) {
                prop.value = null;
            }
            if (prop.property.systemName === 'auxProcessId' && prop.value === objIdRemoved) {
                prop.value = null;
            }
        }
        const updateResponse = await this.buildingBlocksService.updateDbGear(this.GearToDbGear(gear)).toPromise();
        this.removeObjectAndChildrenFromObjectStore(gear.id);
        this.addObjectsToStore(updateResponse.objects);
    }

    getDataColumnBySystemName(systemName: string): DataColumn {
        let result = this.dataColumns.value.filter(col => col.systemName === systemName)[0];
        if (result === undefined) {
            result = this.helperService.deepCopyTwoPointO(this.dataColumns.value.filter(col => systemName?.endsWith(col.systemName))[0]);
            if (result === undefined) {
                if(this.toast.currentlyActive === 0){
                    this.toast.error('No DataColumn found for ' + systemName);
                }
            } else {
                const prefix = systemName.split('_' + result.systemName)[0];
                if (!result.friendlyName.includes(prefix)) {
                    result.friendlyName = '(' + systemName.split('_' + result.systemName)[0] + ') ' + result.friendlyName;
                    result.systemName = systemName;
                    this.addColumnsToStore([result]);
                }
            }
        }
        return result;
    }

    getDataColumnByFriendlyName(friendlyName: string): DataColumn {
        const result = this.dataColumns.value.filter(col => col.friendlyName === friendlyName)[0];
        if (result === undefined) {
            this.toast.error('No DataColumn found for ' + friendlyName);
        }
        return result;
    }

    addColumnsToStore(toAdd: DataColumn[]) {
        const updatedDataColumns = this.dataColumns.value.map(col => toAdd.find(addedCol => addedCol.systemName === col.systemName) || col);
        this.dataColumns.next(updatedDataColumns.concat(toAdd.filter(addedCol => !updatedDataColumns.includes(addedCol))));
    }

    containerNameCollisionCheck(name: string){
        return this.getAllContainers().filter(container => name.toLocaleUpperCase() === container.name.toLocaleUpperCase()).length < 1;
    }

    convertProcessDataColumnsToDropdownDatasource(processDataColumns: any[], isSavedColumns: boolean = false){
        const ds = [];
        const idColumnNames = this.getAllIdColumns().map(col => col.systemName);
        processDataColumns.filter(pdc => !idColumnNames.includes(pdc.systemName)).forEach(f => {
            const dataColumn = this.getDataColumnBySystemName(f.systemName);
            const friendlyName = dataColumn?.friendlyName;
            const mapping = { refName: f.systemName, value: friendlyName ? friendlyName : f.systemName };
            if (isSavedColumns) {
                mapping['format'] = dataColumn.formatString;
            }
            ds.push(mapping);
        });
        ds.sort((a, b) => a.value.localeCompare(b.value));
        return { dataSource: ds, valueExpr: 'refName', displayExpr: 'value' };
    }

    revertUnsavedChanges(){
        this.setFocusedObject(this.focusedObject.getValue());
        this.clearUnsavedGridProperties();
    }

    setGridPropertyAsUnsaved(propertyName: string){
        if(!this.unsavedGrids.includes(propertyName)){
            this.unsavedGrids.push(propertyName);
        }
    }

    removeGridPropertyFromUnsaved(propertyName: string){
        this.unsavedGrids = this.unsavedGrids.filter(gridName => gridName !== propertyName);
    }

    clearUnsavedGridProperties(){
        this.unsavedGrids = [];
    }

    getUnsavedChanges(){
        const originalObject = this.focusedObject.getValue();
        const currentObject = this.focusedObjectChanges.getValue();
        if(!originalObject || !currentObject) {return [];}
        const changedProperties: string[] = [].concat(this.unsavedGrids);

        if(currentObject.name !== originalObject.name) {changedProperties.push('name');}
        if(currentObject.description !== originalObject.description) {changedProperties.push('description');}
        if(originalObject['objectJson']){
            const originalContainerProps = JSON.parse(originalObject['objectJson']);
            const currentContainerProps = JSON.parse(currentObject['objectJson']);
            changedProperties.push(...Object.keys(originalContainerProps).filter(key => JSON.stringify(originalContainerProps[key]) !== JSON.stringify(currentContainerProps[key])));
        }
        if(originalObject['propertyValues']){
            const originalGearProps = originalObject['propertyValues'];
            const currentGearProps = currentObject['propertyValues'];
            if (originalObject.id.startsWith('Sg') && (typeof originalGearProps[originalGearProps.length - 1].value === 'string')){
                originalGearProps[originalGearProps.length - 1].value = JSON.parse(originalGearProps[originalGearProps.length - 1].value);
            }
            changedProperties
            .push(...originalGearProps.filter((prop, index) => JSON.stringify(prop.value) !== JSON.stringify(currentGearProps[index].value))
            .map(obj => obj['property']['systemName']));
        }
        return changedProperties;
    }

    hasUnsavedChanges(){
        return this.getUnsavedChanges().length > 0 && !this.editLock.value;
    }

    gearColumnsByDatatype(datatype: string){
        const gears = [];
        this.dataColumns.value.forEach(element => {
            if (element.datatype === datatype){
                gears[gears.length] = {
                    refName: element.systemName,
                    value: element.friendlyName
                };
            }
        });
        return gears;
    }

    ruleHasGearNameCollision(name: string, id?: string){
        return this.objectStore.getValue().allValues().some(obj => name.toLocaleUpperCase() === obj.name.toLocaleUpperCase() && !obj.id.includes('-') && id !== obj.id && !id.startsWith('Co'));
    }

    hasGearRuleNameCollision(name: string, id?: string) {
        return this.getAllContainers().some(container => container.id.includes('Co')
        && name.toLocaleUpperCase() === container?.name.toLocaleUpperCase()
        && id !== container.id);
    }

    hasIntraRuleGearNameCollision(name: string, id?: string) {
        return this.getAllContainers().filter(gear => gear.id.includes('Bb') && gear.parentId === this.scopeContainer.value.id)
        .some(gear => name.toLocaleUpperCase() === gear.name.toLocaleUpperCase() && id !== gear.id);
    }

    hasReservedNameCollision(name: string) {
        return this.helperService.isNameReserved(name);
    }

    // TODO: Make this not async w/ local columns
    async hasFieldNameCollision(name: string) {
        return this.buildingBlocksService.getAllDataColumns().toPromise()
            .then((cols) => cols.filter(col => col.type !== DataColumnType.GearIntroduced && !col.systemName.startsWith(`${this.focusedObject.value.id}_`)).some(col => name === col.friendlyName));
    }

    hasFieldNameCollisionSetup(name: string) {
        return this.dataColumns.value.filter(col => col.datatype !== DataColumnType.GearIntroduced.toString()).some(col => name === col.friendlyName);
    }

    getGearboxTemplates(): Observable<any> {
        return this.gearboxTemplates.asObservable();
    }

    getGearboxTemplatesFlat(): Observable<any[]> {
        return this.getGearboxTemplates().pipe(
            map(gearboxTemplates => gearboxTemplates ? [...gearboxTemplates[0].items, ...gearboxTemplates[1].items] : [])
        );
    }

    setGearboxTemplates(): void {
        const objs = this.objectStore.getValue().allValues();
        const mixedGearboxTemplates: Group[] = objs.filter(x => x instanceof Group && x.parentId === 'CoRoot').map(x => x as Group).filter(x => x.typeId === containerTypeIds.Group);

        mixedGearboxTemplates.forEach(x => {
            this.getGearboxGearNodes(x, objs);
        });
        this.gearboxTemplates.next([
            {
                key: 'Global',
                items: mixedGearboxTemplates.filter(x => x.id.includes('-')).sort((a, b) => a.name.localeCompare(b.name))
            },
            {
                key: 'Local',
                items: mixedGearboxTemplates.filter(x => !x.id.includes('-')).sort((a, b) => a.name.localeCompare(b.name))
            }
        ]);
    }

    getGearboxGearNodes(gearbox: Group, allObjects: BbObject[]) {
        const gearboxGears: Gear[] = allObjects.filter(x => x instanceof Gear && x.parentId === gearbox.id).map(x => x as Gear);
        const headGears: Gear[] = gearboxGears.filter(x => !gearboxGears.some(y =>
            y.propertyValues.find(p => p.property.systemName === 'sourceProcessId').value === x.id ||
            y.propertyValues.find(p => p.property.systemName === 'auxProcessId')?.value === x.id
        ));
        gearbox['nodes'] = headGears.map(gear => this.getSourceNode(gear, gearboxGears));
    }

    getSourceNode(gear: Gear, gearboxGears: Gear[]): any {
        if (!gear) {
            return null;
        }
        const sourceGear = gearboxGears.find(x => x.id === gear.propertyValues.find(p => p.property.systemName === 'sourceProcessId').value);
        const auxGear = gearboxGears.find(x => x.id === gear.propertyValues.find(p => p.property.systemName === 'auxProcessId')?.value);
        const label = Object.entries(GearMiniLabel).find(([key, value]) => value === gear.gearTypeCode)[0];
        return {gear, label, nodes: [this.getSourceNode(sourceGear, gearboxGears), this.getSourceNode(auxGear, gearboxGears)].filter(x => x)};
    }

    getNewGearboxTemplateNameSuggestion() {
        const defaultName = 'Custom Gearbox ';
        const localGearboxes = this.gearboxTemplates.value[1].items;
        let ordinal = localGearboxes.length + 1;

        while (localGearboxes.some(x => x.name === (defaultName + ordinal))) {
            ordinal++;
        }
        return defaultName + ordinal;
    }

    getLocalLookupForForeignGearboxes() {
        return [
            ...this.getLookupByDatatype('sourceProcessId').dataSource.map(x => ({key: x.key, items: x.items.map(y => ({systemName: y.id, friendlyName: y.name}))})),
            {key: 'Seller Field', items: this.getLookupByDatatype('seller_id_field').dataSource.map(x => ({systemName: x.refName, friendlyName: x.value}))},
            {key: 'Quantity Field', items: this.getLookupByDatatype('Quantity Fields').dataSource.map(x => ({systemName: x.refName, friendlyName: x.value}))},
            {key: 'Date Field', items: this.getLookupByDatatype('Date').dataSource.map(x => ({systemName: x.refName, friendlyName: x.value}))},
            {key: 'Text Field', items: this.getLookupByDatatype('Text').dataSource.map(x => ({systemName: x.refName, friendlyName: x.value}))},
            {key: 'Tag Field', items: this.getLookupByDatatype('Tag').dataSource.map(x => ({systemName: x.refName, friendlyName: x.value}))}
        ];
    }

    notifyUserOfUnsavedChanges(){
        this.toast.warning('You have unsaved changes.');
    }

    setCopiedFilter(filterObject: any){
        this.copiedFilter = filterObject;
    }

    getCopiedFilter(){
        return this.copiedFilter;
    }

    formatValueByFieldName(fieldName: string, value: any): string {
        const dataColumn = this.getDataColumnBySystemName(fieldName);
        const isDateCol = dataColumn?.datatype === 'datetime';
        let format = dataColumn?.formatString;
        if(!format){
            format = isDateCol ? 'MM-dd-yy' : '0.#';
        }
        let valueObj = isDateCol && value ? new Date(value) : value;
        if(dataColumn?.datatype === 'string' && typeof(valueObj) === 'string' && (valueObj as string)?.includes('\n')){
            valueObj = (valueObj as string).split('\n')[0];
        }
		return String.format(`{0:${format}}`, valueObj);
    }

    getAllIdColumns(): DataColumn[]{
        if(this.dataColumns.value){
            const columnNames: string[] = this.dataColumns.value.map(col => col.systemName);
            const onlyIds = columnNames.filter(name => name.includes('_id') && columnNames.includes(this.convertIdToNameColumn(name)));
            return this.dataColumns.value.filter(col => onlyIds.includes(col.systemName));
        }
        return [];
    }

    convertIdToNameColumn(column: string){
        let colName = column;
        if(colName.startsWith('tag_')){
            colName = column.replace('tag_id_', 'tag_');
        }
        if(colName.startsWith('tag_') || colName.includes('seller_id') || colName.includes('aliased')){
            return `${colName}_name`;
        } else {
            return colName.replace('_id', '_name');
        }
    }

    convertNameToIdColumn(column: string){
        let colName = column;
        if(colName.startsWith('tag_')){
            colName = column.replace('tag_', 'tag_id_');
        }
        if(colName.startsWith('tag') || colName.includes('seller_id') || colName.includes('aliased')){
            return colName.replace('_name', '');
        } else {
            return colName.replace('_name', '_id');
        }
    }

    getViewModesLocalStorageKey(id: string): string {
        return `core-buildingblocks-viewmodes-${id}`;
    }

    storeViewModes(id: string): Observable<void> {
        return new Observable(obs => {
            combineLatest([
                this.getShowAdvancedView(),
                this.getGearboxView()
            ]).subscribe(([advancedViewMode, gearboxViewMode]) => {
                localStorage.setItem(this.getViewModesLocalStorageKey(id), JSON.stringify({
                    expiry: new Date(new Date().getTime() + 60000),
                    advancedViewMode,
                    gearboxViewMode
                }));
                obs.next();
            });
        });
    }

    filterRuleVersionsByPeriodId(ruleVersions: any[], activePeriodId: number, periods: Period[]): any[] {
        const unbound = new Date('9999-01-01');
        const activePeriod = periods.find(p => p.id === activePeriodId);
        const beginDate = new Date(activePeriod.beginDate);
        const endDate = new Date(activePeriod.endDate);

        const activeRuleVersions = ruleVersions.filter(v =>
            new Date(periods.find(p => p.id === v.periodBeginId)?.beginDate) <= beginDate &&
            new Date(periods.find(p => p.id === v.periodEndId)?.endDate ?? unbound) >= endDate
        );

        return activeRuleVersions;
    }

    getActiveRuleVersionOrDefaultByPeriodId(id: string, activePeriodId: number, periods: Period[]): Group {
        const unbound = new Date('9999-01-01');
        const givenVersion = this.getObjectById(id) as Group;
        const activePeriod = periods.find(p => p.id === activePeriodId);
        let activeVersion;
        if (activePeriod && givenVersion?.periodBeginId) {
            const beginDate = new Date(activePeriod.beginDate);
            const endDate = new Date(activePeriod.endDate);
            const versions = this.objectStore.getValue().byFilter(x => x instanceof Group && x.name === givenVersion.name).map(co => co as Group);
            activeVersion = versions.find(v =>
                new Date(periods.find(p => p.id === v.periodBeginId)?.beginDate) <= beginDate &&
                new Date(periods.find(p => p.id === v.periodEndId)?.endDate ?? unbound) >= endDate
            );
        }
        return activeVersion ?? givenVersion;
    }

    getLatestRuleVersion(id: string, periods: Period[]): Group {
        let latestVersion = this.getObjectById(id) as Group;
        const versions = this.objectStore.getValue().byFilter(x => x instanceof Group && x.name === latestVersion.name).map(co => co as Group);
        for (const v of versions) {
            if (!latestVersion.periodEndId) {
                continue;
            } else if (!v.periodEndId || periods.find(p => p.id === v.periodEndId)?.endDate > periods.find(p => p.id === latestVersion.periodEndId)?.endDate) {
                latestVersion = v;
            }
        }
        return latestVersion;
    }

    getLatestUnlockedPeriodId(periods: Period[], ruleRecurrenceId: number): number {
        let earliestPeriodIdInRecurrence: number = null;
        if(ruleRecurrenceId){
            let periodsInRecurrence = periods.filter(period => period.recurrenceId === ruleRecurrenceId);
            if(periodsInRecurrence.length > 0){
                periodsInRecurrence = periodsInRecurrence.sort((a, b) => Date.parse(a.beginDate as unknown as string)  - Date.parse(a.beginDate as unknown as string));
                const lockedPeriods = periodsInRecurrence.filter(period => period.isLocked);
                const lastLockedPeriod = lockedPeriods.reverse()[0];
                const lastLockedPeriodIndex = periodsInRecurrence.lastIndexOf(lastLockedPeriod);
                if(lastLockedPeriodIndex >= periodsInRecurrence.length - 1){
                    earliestPeriodIdInRecurrence = periodsInRecurrence[lastLockedPeriodIndex].id;
                } else {
                    earliestPeriodIdInRecurrence = periodsInRecurrence[lastLockedPeriodIndex + 1].id;
                }
            }
        }
        return earliestPeriodIdInRecurrence;
    }

    saveWithUpdatedOutputId(gridDatasource, dataGrid): Promise<void> {
        const maxIds = gridDatasource.filter(row => !this.helperService.isNullOrUndefined(row['outputId'])).sort((a, b) => b['outputId'] - a['outputId']);
        let maxId = maxIds.length > 0 ? maxIds[0]['outputId'] + 1 : 1;
        dataGrid.editing.changes.filter(change => change.type === 'insert').map(change => {
            change.data['outputId'] = maxId;
            maxId++;
            return change;
        });
        return dataGrid?.instance.saveEditData();
    }

    getAllSavedColumns(): Promise<SavedColumn[]> {
        return this.buildingBlocksService.getAllSavedColumns().toPromise().then(res => res.results);
    }

    getAllRulesForPeriodRange(periodBeginId: number, periodEndId: number){
        return this.getAllRules().filter(container => {
            const containerPeriods = this.periodService.getPeriodsInRange(container['periodBeginId'], container['periodEndId'], this.periods).map(p => p.id);
            return containerPeriods.includes(periodBeginId) && (containerPeriods.includes(periodEndId) || !periodEndId);
        });
    }

    getAllSavedColumnsForPeriodRange(periodBeginId: number, periodEndId: number){
        const containers = this.getAllRulesForPeriodRange(periodBeginId, periodEndId);
        const savedCols: SavedColumnBinding[] = [];
        containers.forEach(container => savedCols.push(...JSON.parse(container.objectJson)['savedColumnBindings']));
        return savedCols.map(binding => `saved_column_${binding.SavedColumnId}`);
    }

    private getRuleScopeIds(): string[] {
        const ruleScopeIds = [this.scopeContainer.value?.id ?? ''];
        if (this.focusedObject.value) {
            const focusedObjectParent = this.objectStore.value.byKey(this.focusedObject.value.parentId);
            // need inner nested scope when focused obj is in an expanded gearbox from rule scope
            if (this.showGearboxView.value && focusedObjectParent.parentId === ruleScopeIds[0]) {
                ruleScopeIds[0] = focusedObjectParent.id;
            }
            // if scope is a gearbox then the outer rule scope is also valid if it exists
            if (ruleScopeIds[0] && focusedObjectParent instanceof Group && focusedObjectParent.typeId === EnumContainerType.Group && focusedObjectParent.parentId !== 'CoRoot') {
                ruleScopeIds.push(focusedObjectParent.parentId);
            }
        }
        return ruleScopeIds;
    }
}
