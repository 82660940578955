import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Permission } from '../models/permission';
import { Seller } from '../models/seller';
import { HelperService } from './helper.service';
import { PermissionService } from './permission.service';
import { SellerService } from './seller.service';

@Injectable()
export class PermissionGuard implements CanActivate {
    private initialCall: boolean = true;

    constructor(private router: Router,
        private permissionService: PermissionService,
        private helperService: HelperService,
        private sellerService: SellerService) { }

    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | boolean {

        if (this.initialCall) {
            return forkJoin([this.sellerService.getMe(), this.permissionService.getPermissions()])
            .pipe(map((response) =>
                this.initalSetUp(response[0].userGroupId, response[1], next.data['permissionId'])
            ));
        } else {
            return this.idCheck(next.data['permissionId']);
        }
    }

    idCheck(idToCheck: string): boolean {
        if (idToCheck === undefined || this.permissionService.checkPermission(idToCheck,
        this.permissionService.getCurrentSellerUserGroupPermissionList())) {
            return true;
        }

        this.router.navigate(['/home']);
        return false;
    }

    initalSetUp(groupId: number , permissions: Permission[], pagePermissionId: string) {
        this.permissionService.setUserGroupDict(permissions);
        this.permissionService.setUserGroupId(groupId);
        this.initialCall = false;
        return this.idCheck(pagePermissionId);
    }

    resetOnLogout() {
        this.initialCall = true;
    }
}
