<div id="upper-controls">
    <dx-drop-down-button
        #rulesDropdownButton
        class="back-button bb-header-item dx-button dx-button-normal dx-button-mode-contained dx-widget dx-button-has-icon dx-button-has-text"
        icon="arrowleft"
        text="Back"
        [splitButton]="true"
        dropDownContentTemplate="tree-content"
        [dropDownOptions]="{ width: 'auto', closeOnOutsideClick: true }"
        (onButtonClick)="onClickBackButton()">
        <dx-tree-view 
            #rulesTreeView
            id="rulesTreeView"
            *dxTemplate="let data of 'tree-content'"
            [dataSource]="plansRules"
            valueExpr="id"
            displayExpr="name"
            itemsExpr="items"
            (onItemClick)="navigateToOtherRule($event)"
            (onInitialized)="highlightScopeInRuleDropdown($event)"
            [selectNodesRecursive]="false"
            [activeStateEnabled]="true"
            [searchEnabled]="true"
            selectionMode="single">
        </dx-tree-view>
    </dx-drop-down-button>
    <div class="bb-header-dropdown-area bb-header-item" *ngIf="!auditPageMode">
        <div class="bb-header-dropdown-image">
            <img src="assets/images/blank-gear.svg" />
        </div>
        <div class="bb-header-dropdown">
            <span>Gear Library</span>
            <dx-select-box
                [dataSource]="gearTypesForDropdown"
                [searchEnabled]="true"
                [useItemTextAsTitle]="true"
                searchMode="contains"
                searchExpr="title"
                keyExpr="type"
                displayExpr="title"
                (onSelectionChanged)="onSelectGearType($event)"
                (onFocusOut)="onDropdownClickOut($event)"
                stylingMode="underlined">
                <div *dxTemplate="let data of 'item'" class="bb-header-dropdown-list-item">
                    <ng-container *ngIf="data.icon">
                        <i class="dx-icon-{{ data.icon }}"></i>
                    </ng-container>
                    <span>{{ data.title }}</span>
                </div>
            </dx-select-box>
        </div>
    </div>
    <div class="bb-header-dropdown-area bb-header-item" *ngIf="!auditPageMode">
        <div class="bb-header-dropdown-image">
            <img src="assets/images/bot.svg" />
        </div>
        <div class="bb-header-dropdown">
            <span>Gearbox Library</span>
            <ng-container *ngIf="gearboxTemplates">
                <dx-select-box
                    [dataSource]="gearboxTemplates"
                    [searchEnabled]="true"
                    [grouped]="true"
                    searchMode="contains"
                    [searchExpr]="['name', 'description']"
                    [useItemTextAsTitle]="true"
                    keyExpr="id"
                    width="250"
                    [displayExpr]="blankDisplayExpr"
                    (onSelectionChanged)="onGearboxSelect($event)"
                    stylingMode="underlined">
                    <div *dxTemplate="let data of 'item'" [id]="data.id" class="bb-header-dropdown-list-item">
                        <i *ngIf="data.id === scopeContainer?.id" class="dx-icon-rename"></i>
                        <span>{{ data.name }}</span>

                        <dx-tooltip 
                            [target]="'#' + data.id" 
                            showEvent="dxhoverstart" 
                            hideEvent="dxhoverend"
                            maxWidth="500" 
                            [position]="{ my: 'right', at: 'left' }">
                            <div *dxTemplate="let tooltip of 'content'">
                                <p>{{ data.description }}</p>

                                <div class="tree">
                                    <ng-container *ngTemplateOutlet="nodeTemplate; context: { nodes: data.nodes }"></ng-container>
                                </div>

                                <ng-template #nodeTemplate let-nodes="nodes">
                                    <ul *ngIf="nodes.length">
                                        <li *ngFor="let node of nodes">
                                            <div class="mini-gear" [ngClass]="'label-' + node.label.toLowerCase()">
                                                {{ node.label }}
                                            </div>
                                            <ng-container *ngTemplateOutlet="nodeTemplate; context: { nodes: node.nodes }"></ng-container>
                                        </li>
                                    </ul>
                                </ng-template>
                            </div>
                        </dx-tooltip>

                        <dx-context-menu #GearboxTemplateContextMenu
                            [target]="'#'+data.id"
                            [items]="data.id.includes('-') ? [] : 
                                [{
                                    name: 'edit', 
                                    text: 'Edit Template', 
                                    icon: 'edit' 
                                }, 
                                { 
                                    name: 'delete', 
                                    text: 'Delete Template', 
                                    icon: 'trash' 
                                }]"
                            (onItemClick)="onGearboxContextItemClick($event)"
                            [position]="{
                                my: 'top center', 
                                at: 'bot right'
                            }">
                        </dx-context-menu>
                    </div>
                </dx-select-box>
            </ng-container>
        </div>
    </div>
    <span *ngIf="scopeContainer?.typeId === 3 && scopeContainer?.parentId === 'CoRoot'" id="templateEditFlag">Template Edit</span>
    <div class="toggle-switch">
        <p>{{ gearboxViewMode ? 'Gearbox' : 'Gear' }} View</p>
        <dx-switch (onValueChanged)="toggleGearboxView($event)" [(value)]="gearboxViewMode"></dx-switch>
    </div>
</div>

<dx-popup
    *ngIf="gearboxMap?.length && localFriendlyNames"
    [showTitle]="true"
    [title]="selectedGearboxTemplate.name + ' Mapping'"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [height]="400"
    [width]="500"
    [(visible)]="isGearboxMappingPopupVisible">
    <dxi-toolbar-item 
        widget="dxButton" 
        location="after" 
        [options]="{ 
            icon: 'help', 
            hint: selectedGearboxTemplate.description 
        }"> 
    </dxi-toolbar-item>

    <dx-data-grid #gearboxMapGrid [(dataSource)]="gearboxMap" [height]="280">
        <dxo-scrolling mode="virtual" preloadEnabled="true"></dxo-scrolling>
        <dxo-paging [pageSize]="100"></dxo-paging>
        <dxo-scrolling mode="virtual"></dxo-scrolling>
        <dxo-editing mode="cell" [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false"></dxo-editing>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxi-column caption="Type" [allowEditing]="false" [calculateCellValue]="calculateForeignRefType" [width]="100"></dxi-column>
        <dxi-column caption="Foreign" dataField="foreignName" [allowEditing]="false">
            <dxo-lookup [dataSource]="foreignRefLookup" valueExpr="id" displayExpr="name"></dxo-lookup>
        </dxi-column>
        <dxi-column caption="Local" dataField="localName" [editorOptions]="{ grouped: true, searchEnabled: false }">
            <dxo-lookup [dataSource]="getFilteredlocalFriendlyNames"></dxo-lookup>
        </dxi-column>
    </dx-data-grid>
    <dx-button text="submit" (onClick)="onSubmitGearboxMapping()"></dx-button>
    <dx-button text="cancel" (onClick)="onCancelGearboxMapping()"></dx-button>
</dx-popup>
